import { Action, NgxsAfterBootstrap, Selector, State, StateContext, Store, createSelector } from '@ngxs/store';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { AuthService } from '../../services/auth.service';
import { BucketAction } from '../buckets/buckets.actions';
import { LocalStoreService } from '../../services/localstore.service';
import { MediaTypes } from '../../models/consts';
import { PlaylistAction } from '../playlists/playlists.actions';
import { PromotionAction } from '../promotions/promotions.actions';
import { Venue } from 'src/app/shared/models/models';
import { VenueAction } from './venue.actions';
import { VenueService } from 'src/app/shared/services/venue.service';
import { VideoPlaylistAction } from '../video-playlists/video-playlists.actions';
import { ZoneAction } from '../zones/zones.actions';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';

type Context = StateContext<VenueStateModel>;

export interface VenueStateModel {
  venue: Venue;
  id: number;
  errored: boolean;
}

@State<VenueStateModel>({
  name: 'venue',
  defaults: {
    venue: null,
    id: null,
    errored: false
  },
})
@Injectable()
export class VenueState implements NgxsAfterBootstrap{

  constructor(
    private venueService: VenueService,
    private store: Store,
    private localstoreService: LocalStoreService,
    private authService: AuthService
    ) {}


    ngxsAfterBootstrap({dispatch}: StateContext<any>) {
    if (this.authService.isAuthenticated()) {
      const savedVenue = this.localstoreService.getItem('venueId');
      dispatch(new VenueAction.SetCurrentVenue(+this.localstoreService.getItem('venue.id') || +savedVenue));
    }
  }

  @Action(VenueAction.GetVenue)
  get({ patchState, dispatch }: Context, { id }: VenueAction.GetVenue) {
    return this.venueService.getVenueData(id)
      .pipe(
        tap((venue: Venue) => {
          patchState({
            venue: venue
          });

          dispatch(new ZoneAction.GetZones(venue.id));
          dispatch(new ZoneAction.GetJukeos(venue.id));
        }),
        catchError(() => {
          patchState({ errored: true });
          return of([]);
        })
      );
  }

  @Action(VenueAction.EditVenue)
  edit({ patchState, getState }: Context, { venue }: VenueAction.EditVenue) {
    patchState({
      venue
    });
  }

  @Action(VenueAction.SetCurrentVenue)
  setCurrentVenue({ patchState, getState }: Context, { id }: VenueAction.SetCurrentVenue) {
    const state = getState();

    patchState({
      id
    });

    if (!state.venue || state.venue.id !== id ) {
      this.store.dispatch(new VenueAction.GetVenue(id));
    }

  }

  @Action(VenueAction.ChangeCurrentVenue)
  changeCurrentVenue({ patchState, getState, dispatch }: Context, { id }: VenueAction.ChangeCurrentVenue) {
    dispatch(new ZoneAction.UpdateState)
    .pipe(
      switchMap(() =>
        dispatch([
          new PlaylistAction.UpdateState(),
          new BucketAction.UpdateState(),
          new VideoPlaylistAction.UpdateState(),
          new PromotionAction.UpdateState()
        ]
      )),
      tap(() => {
        const state = getState();

        patchState({
          id
        });

        this.venueService.saveSelectedVenue(id);

        if (!state.venue || state.venue.id !== id ) {
          dispatch(new VenueAction.GetVenue(id));
        }
      })
    )
    .subscribe();

  }

  @Selector()
  static venue(state: VenueStateModel): Venue {
    return state.venue;
  }

  @Selector()
  static venueId(state: VenueStateModel): number {
    return state.id;
  }

  @Selector()
  static errored(state: VenueStateModel): boolean {
    return state.errored;
  }

  static hasZoneByMediaType(type: MediaTypes) {
    return createSelector([VenueState], (state: VenueStateModel) => {
      return state.venue && state.venue.zones && state.venue.zones.some(({mediaType}) => mediaType === type);
    });
  }
}
