<div id="venues">
  <header class="d-flex justify-content-between align-items-center">
    <h1 class="m-0">Venues</h1>
  </header>

  <div class="search-container d-flex align-items-center justify-content-between">
    <input #searchInput (keyup)="searchVenue(searchInput.value.trim())" type="text" class="input-search"
      placeholder="Search a Venue" />
    <button *ngxPermissionsOnly="['admin', 'venue-admin']" (click)="createVenue()" class="btn">Create Venue</button>
  </div>

  <ng-scrollbar appearance=true>
    <ng-container *ngIf="!isLoading; else spinner">
      <div *ngIf="venues?.length > 0 && filteredVenues?.length === 0"
        class="no-venues-found-message-container d-flex justify-content-center">
        <div class="message-wrapper d-flex justify-content-center align-items-center flex-column">
          <div class="no-venues-found-image"></div>
          <p class="text-center">No Venues found</p>
        </div>
      </div>

      <div class="venues-list-wrapper">
        <div class="venue" *ngFor="let venue of filteredVenues; index as i">
          <div class="cover-wrapper w-100 position-relative text-center">
            <img [src]="venue.bannerImageUrl || '../../assets/images/logo-gray.svg'"
              [ngClass]="{ 'default-cover': !venue.bannerImageUrl }" appDefault="/assets/images/logo-gray.svg"
              defaultClass="default-cover" class="cover" alt="Venue banner" loading="lazy" />
            <img [src]="venue.logoImageUrl || '../../assets/images/logo-white.svg'"
              class="logo position-absolute rounded-circle" alt="Venue logo" appDefault="/assets/images/logo-white.svg"
              loading="lazy" />
            <button *ngxPermissionsOnly="['admin', 'venue-admin']" (click)="editVenue(venue.id)"
              class="btn btn-edit position-absolute rounded-circle">
              <div class="icon icon-edit-empty-light"></div>
            </button>
          </div>

          <div class="venue-info d-flex justify-content-between">
            <p [title]="venue.name" class="venue-name text-truncate m-0">{{ venue.name }}</p>
            <p [class.is-active]="venue.isActive" class="venue-status m-0 align-self-center">
              {{ venue.isActive ? 'Online' : 'Offline' }}
            </p>
          </div>

          <p [title]="venue.owner?.userEmail" class="venue-owner-email pt-0 m-0 text-truncate">{{ venue.owner?.userEmail
            }}</p>

          <div class="venue-additional-info">
            <div class="venue-address-container d-flex justify-content-between">
              <div class="venue-address d-flex flex-column">
                <div [title]="venue.address?.line1" class="address text-truncate">{{ venue.address?.line1 || 'n/a' }}
                </div>
                <div class="coordinates-container d-flex align-items-center">
                  <div class="icon icon-location-mark"></div>
                  <div [title]="'Latitude: ' + venue.latitude + '°   Longitude: ' + venue.longitude + '°'"
                    *ngIf="venue.latitude && venue.longitude" class="coordinates d-flex">
                    <span class="coordinate text-truncate">{{ venue.latitude }}&#176;</span>
                    <span class="coordinate text-truncate">{{ venue.longitude }}&#176;</span>
                  </div>
                  <div *ngIf="!venue.latitude || !venue.longitude" class="coordinates">n/a</div>
                </div>
              </div>
              <button *ngxPermissionsOnly="['admin', 'venue-admin']" class="btn btn-add-zones"
                (click)="addZones(venue)">Add Zones</button>
            </div>

            <div class="contact-data d-flex justify-content-between">
              <div class="contact-name">
                <div class="header">Name</div>
                <div [title]="venue.contact.name || ''" class="data text-truncate">{{ venue.contact.name || 'n/a' }}
                </div>
              </div>
              <div class="contact-number">
                <div class="header">Phone Number</div>
                <div [title]="venue.contact.phoneNumber || ''" class="data text-truncate">
                  {{ venue.contact.phoneNumber || 'n/a' }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <app-spinner [isLoading]="isMoreVenuesLoading" [diameter]="50" [minimal]="true"></app-spinner>
    </ng-container>
  </ng-scrollbar>
</div>
<ng-template #spinner>
  <div class="d-flex align-items-center justify-content-center h-100 w-100">
    <app-spinner [isLoading]="isLoading" [errored]="errored"></app-spinner>
  </div>
</ng-template>