import { DragDropModule } from '@angular/cdk/drag-drop';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';

import { NgxPermissionsModule } from 'ngx-permissions';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { PromotedPlaylistItemComponent } from '../components/promoted-playlist-item/promoted-playlist-item.component';
import { ArtistItemComponent } from '../components/artist-item/artist-item.component';
import { AlbumItemComponent } from '../components/album-item/album-item.component';
import { TrackItemModule } from '../components/track-item/track-item.module';
import { SharedPlaylistPreviewItemModule } from '../components/playlist-preview-item/playlist-preview-item.module';
import { SharedPlaylistDialogModule } from '../components/playlist-list-dialog/playlist-list-dialog.module';
import { SharedNewTracksContainerDialogModule } from '../components/new-tracks-container-dialog/shared-new-tracks-container-dialog.module';
import { SharedBucketPreviewItemModule } from '../components/bucket-preview-item/bucket-preview-item.module';
import { SharedBucketDialogModule } from '../components/bucket-list-dialog/bucket-list-dialog.module';
import { ImagePreloadModule } from '../directives/image-preload/image-preload.module';
import { SharedPlaylistItemModule } from '../components/playlist-item/playlist-item.module';
import { SpinnerModule } from 'src/app/system/spinner/spinner.module';

@NgModule({
  declarations: [
    ArtistItemComponent,
    AlbumItemComponent,
    PromotedPlaylistItemComponent
  ],
  imports: [
    CommonModule,
    MatMenuModule,
    MatDialogModule,
    NgScrollbarModule,
    ReactiveFormsModule,
    DragDropModule,
    ImagePreloadModule,
    NgxPermissionsModule,
    SharedPlaylistPreviewItemModule,
    SharedPlaylistDialogModule,
    TrackItemModule,
    SharedNewTracksContainerDialogModule,
    SharedBucketPreviewItemModule,
    SharedBucketDialogModule,
    SharedPlaylistItemModule,
    SpinnerModule
  ],
  exports: [
    ArtistItemComponent,
    AlbumItemComponent,
    PromotedPlaylistItemComponent
  ]
})
export class ListItemsModule { }
