import { Component, Inject, ViewChild } from '@angular/core';

import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';

import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';

interface DialogData {
  aspectRatio?: number;
}

@Component({
  selector: 'app-image-upload-dialog',
  templateUrl: './image-upload-dialog.component.html',
  styleUrls: ['./image-upload-dialog.component.scss']
})
export class ImageUploadDialogComponent {
  private MAX_FILE_SIZE = 5242880; // 5 MB
  private file: any;

  public MAX_FILE_SIZE_STR = '5 MB';
  public imageChangedEvent: any = '';
  public isImageLoaded = false;
  public fileName: string;
  public image: any = '';

  @ViewChild(ImageCropperComponent, { static: false }) imageCropper: ImageCropperComponent;

  constructor(
    private dialogRef: MatDialogRef<ImageUploadDialogComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  fileChangeEvent(event: any) {
    if (event.target.files.length) {
      const file = event.target.files[0];

      if (file.size <= this.MAX_FILE_SIZE) {
        this.fileName = file.name;
        this.imageChangedEvent = event;
      } else {
        this.dialog.open(AlertDialogComponent, {
          data: {
            type: 'error',
            message: `File is too large! Maximum upload file size: ${this.MAX_FILE_SIZE_STR}`
          }
        });
      }
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.image = event.base64;
    this.file = event.file;
  }

  imageLoaded() {
    this.isImageLoaded = true;
  }

  loadImageFailed () {
    this.dialog.open(AlertDialogComponent, {
      data: {
        type: 'error',
        message: 'Oops, something went wrong. Please try again.'
      }
    });
  }

  saveImage() {
    this.dialogRef.close( {
      image: this.image,
      file: this.file
    });
  }
}
