import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

import { NgScrollbarModule } from 'ngx-scrollbar';
import { OwlModule } from 'ngx-owl-carousel';

import { GooglePlaceModule } from '../../shared/modules/google-places-autocomplete.module';

import { ActivationCodeModalComponent } from './activation-code-modal/activation-code-modal.component';
import { CreateEditVenueComponent } from './create-edit-venue/create-edit-venue.component';
import { CreateEditZoneComponent } from './create-edit-zone/create-edit-zone.component';
import { VenueListComponent } from './venue-settings/venue-list/venue-list.component';
import { VenueSettingsComponent } from './venue-settings/venue-settings.component';
import { ZoneListComponent } from './zone-list/zone-list.component';
import { FormatDateModule } from '../../shared/pipes/date.pipe';
import { VenuesComponent } from './venues.component';
import { IncludesOneOfPipe } from '../../shared/pipes/includes-one-of.pipe';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ImagePreloadModule } from 'src/app/shared/directives/image-preload/image-preload.module';
import { SpinnerModule } from '../spinner/spinner.module';

const routes: Routes = [
  {
    path: '',
    component: VenuesComponent
  },
  {
    path: 'settings',
    component: VenueSettingsComponent
  }
];

@NgModule({
    declarations: [
        VenuesComponent,
        CreateEditVenueComponent,
        CreateEditZoneComponent,
        ZoneListComponent,
        ActivationCodeModalComponent,
        VenueSettingsComponent,
        VenueListComponent,
        IncludesOneOfPipe
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FormsModule,
        MatDialogModule,
        MatMenuModule,
        MatButtonModule,
        MatSliderModule,
        MatSelectModule,
        ReactiveFormsModule,
        NgScrollbarModule,
        OwlModule,
        FormatDateModule,
        GooglePlaceModule,
        NgxPermissionsModule,
        ImagePreloadModule,
        SpinnerModule
    ],
    exports: [
        VenueListComponent
    ]
})
export class VenuesModule {}
