<div class="dialog-header">
    <h5 class="p-0 m-0 mb-3" mat-dialog-title>How the New Scheduler Works?</h5>
  </div>
  <div mat-dialog-content class="mb-3">
    <ul>
        <li><span class="font-weight-bold">Weekly Scheduling</span> - The new Scheduler now operates on a week-to-week basis instead of fixed dates.
        </li>
        <li>
            <span class="font-weight-bold">Navigating the Schedule</span> - Use the scrollbar to scroll up or down to access the full 24-hour view.
        </li>
        <li>
            <span class="font-weight-bold">Switching Zones</span> - Select different Zone schedules by using the dropdown Zone selector menu.
        </li>
        <li>
            <span class="font-weight-bold">Scheduling Playlists/Buckets</span> - Drag and drop any Playlist/Bucket from the Library to any day of the week.
        </li>
        <!-- <li>
            <span class="font-weight-bold">Repeating a Playlist</span> - Right Click a playlist on the schedule and select REPEAT to select which days you'd like to repeat the playlist.
        </li> -->
        <li>
            <span class="font-weight-bold">Deleting a Playlist</span> - Right Click a playlist on the schedule and select DELETE to remove the playlist from the schedule.
        </li>
        <li>
            <span class="font-weight-bold">Expert Shortcut</span> - Hold down letter ALT on Windows or CMD on Mac and drag a playlist onto another day to copy a playlist. 
        </li>
        <li>
            <span class="font-weight-bold">Adjusting Times</span> - Grab the top or bottom and push up or down on a playlist to change it's start and end time. 
        </li>
    </ul>
    <div>
        Need further help? Click the SUPPORT tab to the left for more FAQ's and full Audalize Manual.     
    </div>
  </div>
  <div mat-dialog-actions class="d-flex justify-content-center">
    <button mat-dialog-close class="btn primary" (click)="onClose()">Ok</button>
  </div>
  