import { Component, Inject, OnDestroy, OnInit } from '@angular/core';

import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { Zone } from '../../models/models';
import { ZonesState } from '../../store/zones/zones.state';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter, finalize, tap } from 'rxjs/operators';
import { MediaTypes } from '../../models/consts';

interface DialogData {
  zoneId?: number;
  mediaType?: MediaTypes;
  allowedZones?: number[];
}

@Component({
  selector: 'app-zone-copy-dialog',
  templateUrl: './zone-copy-dialog.component.html',
  styleUrls: ['./zone-copy-dialog.component.scss']
})
export class ZonesCopyComponent implements OnInit, OnDestroy {
  public sourceZoneId: number;
  public destinationZoneIds: Record<number, boolean>;
  public hasDestinations: boolean;
  public initialSelectedZoneId: number;
  public isLoading = false;
  public errored = false;
  public zones: Zone[] = [];
  @Select(ZonesState.zones) zonesStore$: Observable<Zone[]>;

  constructor(
    public dialogRef: MatDialogRef<ZonesCopyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private store: Store
  ) { 
    this.zonesStore$ = this.store.select(ZonesState.zones);
  }

  ngOnInit() {
    this.isLoading = true;
    this.destinationZoneIds = {};
    this.zonesStore$
    .pipe(
      filter(() => this.store.selectSnapshot(ZonesState.isZonesLoaded)),
      tap(() => this.isLoading = false)
    )
      .subscribe((zones: Zone[]) => {
        const mediaType = (this.data && this.data.mediaType) ? this.data.mediaType : MediaTypes.AUDIO;
        this.zones = zones.filter(
          (zone) =>
            zone.player &&
            zone.mediaType === mediaType &&
            this.data.allowedZones.some((allowedZone) => [-1, zone.id].includes(allowedZone))
        );
        if (!this.zones.length) {
          this.dialogRef.close({
            error: `There are no active players for zones with '${mediaType}' type. Please activate a zone's player to add music.`
          });
        }
        this.zones.map((zone) => {
          this.destinationZoneIds[zone.id] = false;
        });
        this.hasDestinations = false;
      }, () => this.errored = true);
  }

  selectZone(zone: Zone) {
    this.sourceZoneId = zone.id;
    if (this.destinationZoneIds[zone.id]) {
      this.destinationZoneIds[zone.id] = false;
    }
  }

  selectDestinationZone(zone: Zone) {
    if (!this.destinationZoneIds[zone.id] && zone.id !== this.sourceZoneId) {
      this.destinationZoneIds[zone.id] = true;
    } else {
      this.destinationZoneIds[zone.id] = false;
    }
    // if it has destinations set as true
    this.hasDestinations = !!this.getDestinationZonesAsArray().length;
  }

  getDestinationZonesAsArray() {
    return Object.entries(this.destinationZoneIds).filter((zoneId) => zoneId[1]).map((validZoneId) => parseInt(validZoneId[0]));
  }

  // returns source zone Id as a number & destination zone Ids as an array of numbers
  save() {
    this.dialogRef.close({
      sourceId: this.sourceZoneId,
      destinationIds: this.getDestinationZonesAsArray()
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  ngOnDestroy() {}
}
