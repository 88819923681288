<div class="dialog-header">
  <h2 mat-dialog-title>Add Zones</h2>
  <button mat-dialog-close class="btn close-dialog-btn"></button>
</div>

<mat-dialog-content>
  <div class="zone-list-wrapper" *ngIf="!isLoading; else spinner">
    <ng-scrollbar>
      <ul *ngIf="!!zones?.length; else noZones" class="zone-list">
        <li *ngFor="let zone of zones" class="zone-container">
          <div class="zone-item-container">
            <p class="zone-name">{{ zone.name }}</p>
            <p class="zone-location-name">{{ zone.name }}</p>
            <div class="buttons">
              <div [ngClass]="{ isGenerating: generatingCodeZoneId === zone.id }" class="activation-code-btn-wrapper">
                <button (click)="getActivationCode(zone)" class="btn btn-key" title="Generate activation code"></button>
                <app-spinner [isLoading]="true" [errored]="activationErrored" [minimal]="true"></app-spinner>
              </div>
              <button (click)="editZone(zone)" class="btn btn-edit" title="Edit zone"></button>
              <button (click)="deleteZone(zone)" class="btn btn-delete" title="Delete zone"></button>
            </div>
          </div>
        </li>
      </ul>
    </ng-scrollbar>
  </div>
</mat-dialog-content>

<div mat-dialog-actions>
  <button class="btn create-zone-btn" (click)="createZone()">Create New Zone</button>
  <button mat-dialog-close class="btn save-zones-btn">Ok</button>
</div>

<ng-template #noZones>
  <div class="no-zones-message text-uppercase">No Zones have been added</div>
</ng-template>

<ng-template #spinner>
  <app-spinner [isLoading]="isLoading" [errored]="errored"></app-spinner>
</ng-template>
