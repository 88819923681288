import { Playlist } from '../models/models';

export class PlaylistPrepare {

  static sortPlaylists(playlists: Playlist[]) {
    return playlists.sort((p1, p2) => {
      if (!p1.updatedAt || p1.updatedAt.val) {
        return -1;
      }
      if (!p2.updatedAt || p2.updatedAt.val) {
        return 1;
      }

      return new Date(p2.updatedAt).getTime() - new Date(p1.updatedAt).getTime();
    });
  }

  static playlistArtwork(playlist: Playlist, count = 4) {
    const artwork = [];
    let i = 0;

    if (playlist.logoImageUrl) {
      artwork.push(playlist.logoImageUrl);

      return artwork;
    }

    if (playlist.tracks && playlist.tracks.length >= count) {
      while (artwork.length < count && playlist.tracks.length > i) {
        if (playlist.tracks[i].artwork && playlist.tracks[i].artwork.artworkUrl100) {
          const src = playlist.tracks[i].artwork.artworkUrl100;

          if (!artwork.includes(src)) {
            artwork.push(src);
          }
        }

        i++;
      }
    } else if (playlist.tracks && playlist.tracks.length >= 1 && playlist.tracks[0] && playlist.tracks[0].artwork) {
      if (count > 4) {
        artwork.push(playlist.tracks[0].artwork.artworkUrl1000);
      } else {
        artwork.push(playlist.tracks[0].artwork.artworkUrl100);
      }
    }

    return artwork;
  }

  static videoPlaylistArtwork(playlist) {
    const artwork = [];
    let i = 0;

    if (playlist.logoImageUrl) {
      artwork.push(playlist.logoImageUrl);

      return artwork;
    }

    if (playlist.tracks && playlist.tracks.length >= 1) {
      const trackArtwork = playlist.tracks.find((track) => track.artwork && track.artwork.artworkUrl1000)
      if (!!trackArtwork) {
        artwork.push(trackArtwork.artwork.artworkUrl1000);
      }
    }

    return artwork;
  }

  static prepareSpotifyTracksPackage(tracksPackage) {
    return tracksPackage.map((item, index) => ({
      spotifyTrackId: item.track.id,
      isrc: item.track.external_ids.isrc,
      spotifyTrackName: item.track.name,
      spotifyArtistNames: item.track.artists.map(i => i.name),
      spotifyAlbumName: item.track.album.name
    }));
  }
}
