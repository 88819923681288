import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { PrepareDataService } from '../../../shared/services/prepare-data.service';
import { ZoneService } from '../../../shared/services/zone.service';
import { PlayerCommandType } from 'src/app/shared/gql/zone-consts';
import { Timer, Zone, TodayTimeslot } from 'src/app/shared/models/models';
import { TrackService } from '../../../shared/services/track.service';
import { TrackQueueComponent } from '../../../shared/components/track-queue/track-queue.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
//import { PlayerCommandDialogComponent } from './player-command-dialog/player-command-dialog.component';
import { TimerService } from 'src/app/shared/services/timer.service';
import { Observable, Subscription } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { ZonesState } from 'src/app/shared/store/zones/zones.state';

import * as moment from 'moment';
import { TimeslotService } from 'src/app/shared/services/timeslot.service';
import { DATE_FORMAT_FOR_BACKEND, MediaTypes } from 'src/app/shared/models/consts';
import { TracksQueueService } from 'src/app/shared/services/tracks-queue.service';

@Component({
  selector: 'app-playing-timeslot',
  templateUrl: './playing-timeslot.component.html',
  styleUrls: ['./playing-timeslot.component.scss']
})
export class PlayingTimeslotComponent implements OnInit {
  @Input() atimeslot: TodayTimeslot;
  @Input() timeslots: TodayTimeslot[];
  @Input() zone: Zone;

  public timeslot: TodayTimeslot;
  // container cache e.g. playlist or bucket if track is overridden
  public containerQueueCache;
  
  constructor(
    private zoneService: ZoneService,
    private tracksQueueService: TracksQueueService
  ) { }

  ngOnInit() {
    console.log('PlayingFrom', this.zone, this.timeslots);
    const currentTime = moment().format("HH:mm:ss");
    //console.log('currday', currentTime);

    const filteredTS = this.timeslots.filter((t) => (t.zoneId == this.zone.id) && (t.from <= currentTime) && (t.to >= currentTime));
    if (filteredTS.length > 0) {
      console.log('PlayingFromFiltered', this.zone, currentTime, filteredTS);
      this.timeslot = filteredTS[0];
    } else {
      this.timeslot = {
        zoneName: this.zone.name,
        zoneId: 1946,
        tracksContainer: {
          id: 6745,
          name: 'NO SCHEDULE',
          mediaType: 'MUSIC',
          trackCount: 70,
          duration: '04:54:18.000',
          logoImageUrl: null,
          tracks: [],
          artwork: []
        },
        from: '00:00:00',
        to: '00:00:00'
      };

    }

    this.zoneService.onPlayingStateTrackQueueIdUpdated.subscribe((trackQueueId: number) => {
      // if (this.isPlaylistOverridden(this.zone)) {
        // load playlist / bucket data
        this.tracksQueueService.getOverrideTrackQueue(this.zone.id).subscribe((response) => {
          this.containerQueueCache = {
            trackQueueId: trackQueueId,
            ...response
          }
        })
      // }
    });
  }

  /**
   * Checks if playlist is overridden
   * @param zone current playing zone
   * @returns true / false if playlist is overridden or not
   */
  isPlaylistOverridden(zone: Zone) {
    return zone && zone.currentTrackQueue && zone.currentTrackQueue.__typename === 'OverrideTrackQueue';
  }
}
