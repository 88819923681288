import * as moment from 'moment';

import { Action, Selector, State, StateContext, Store, createSelector } from '@ngxs/store';
import { JukeoPlaylist, JukeoSetting, PaginationInfo, PlayerEvent, VideoPlayerState, Zone } from 'src/app/shared/models/models';
import { Subscription, from, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { patch, updateItem } from '@ngxs/store/operators';

import { MediaTypes } from '../../models/consts';
import { PlayerCommandType } from '../../gql/zone-consts';
import { StateReset } from 'ngxs-reset-plugin';
import { TimerService } from '../../services/timer.service';
import { ZoneAction } from './zones.actions';
import { ZoneService } from 'src/app/shared/services/zone.service';
import { Injectable } from '@angular/core';

type Context = StateContext<ZoneStateModel>;

export interface ZoneStateModel {
  zones: Zone[];
  isZonesLoaded: boolean; // will be true after getting of first zone bunch
  jukeoSettings: JukeoSetting[];
  isJukeosLoaded: boolean;
  jukeoPlaylists: JukeoPlaylist[];
  isJukeoPlaylistsLoaded: boolean;
  hasNextPage: boolean;
  nextCursor?: string;
  errored: boolean;
  currentZoneId?: number;
}

@State<ZoneStateModel>({
  name: 'zones',
  defaults: {
    zones: [],
    isZonesLoaded: false,
    jukeoSettings: [],
    isJukeosLoaded: false,
    jukeoPlaylists: [],
    isJukeoPlaylistsLoaded: false,
    hasNextPage: false,
    nextCursor: null,
    errored: false,
    currentZoneId: null
  }
})

@Injectable()
export class ZonesState {
  playerSubs: Map<number, Subscription> = new Map();
  videoPlayerSubs: Map<number, Subscription> = new Map();
  monitorModeSubs: Map<number, Subscription> = new Map();

  constructor(
    private zoneService: ZoneService,
    private store: Store,
    private timerService: TimerService
    ) {}

  @Action(ZoneAction.GetZones)
  fetch({patchState, getState, dispatch}: StateContext<ZoneStateModel>, { venueId, count }: ZoneAction.GetZones) {
    return this.zoneService.getZonesByVenue()
      .pipe(
        tap((paginationInfo: PaginationInfo) => {
          patchState({
            hasNextPage: paginationInfo.pageInfo.hasNextPage,
            nextCursor: paginationInfo.pageInfo.endCursor
          });
        }),
        map(({edges}) => edges.map(({node}) => node)),
        tap((zones: Zone[]) => {
          patchState({
            zones: zones.map((zone) => {
              zone.monitorMode = false;
              return zone;
            }),
            isZonesLoaded: true
          });
          const state = getState();
          if (state.hasNextPage) {
            dispatch(new ZoneAction.GetMoreZones(venueId, count));
          } else {
            getState().zones.forEach(({player, id, mediaType}) => {
              dispatch(new ZoneAction.ZoneChangeSubscription(id));
              if (!!player) {
                if (mediaType === MediaTypes.AUDIO && id === getState().currentZoneId) {
                  dispatch([
                    new ZoneAction.ZonePlayerEventChangeSubscription(id),
                    new ZoneAction.MonitorModeSubscription(id)
                  ])
                } else if (mediaType === MediaTypes.VIDEO) {
                  if (!this.videoPlayerSubs.has(player.id)) {
                    dispatch(new ZoneAction.VideoPlayerSubscription(player.id));
                  }
                }
              }
            });
          }
        }),
        catchError(() => {
          patchState({ errored: true })
          return of([]);
        }),
      );
  }

  @Action(ZoneAction.GetMoreZones)
  fetchMore({patchState, getState, dispatch}: StateContext<ZoneStateModel>, { venueId, count }: ZoneAction.GetMoreZones) {
    const state = getState();
    return this.zoneService.getZonesByVenue(venueId, count, state.nextCursor)
      .pipe(
        tap((paginationInfo: PaginationInfo) => {
          patchState({
            hasNextPage: paginationInfo.pageInfo.hasNextPage,
            nextCursor: paginationInfo.pageInfo.endCursor
          });
        }),
        map(({edges}) => edges.map(({node}) => node)),
        tap((zones: Zone[]) => {
          const moreZones = zones.map((zone) => {
            zone.monitorMode = false;
            return zone;
          });
          patchState({
            zones: [...state.zones, ...moreZones],
            isZonesLoaded: true
          });

          const newState = getState();
          if (newState.hasNextPage) {
            dispatch(new ZoneAction.GetMoreZones(venueId, count));
          } else {
            getState().zones.forEach(({player, id, mediaType}) => {
              dispatch(new ZoneAction.ZoneChangeSubscription(id));
              if (!!player) {
                if (mediaType === MediaTypes.AUDIO && id === getState().currentZoneId) {
                  dispatch([
                    new ZoneAction.ZonePlayerEventChangeSubscription(id),
                    new ZoneAction.MonitorModeSubscription(id)
                  ])
                } else if (mediaType === MediaTypes.VIDEO) {
                  if (!this.videoPlayerSubs.has(player.id)) {
                    dispatch(new ZoneAction.VideoPlayerSubscription(player.id));
                  }
                }
              }
            });
          }
        }),
        catchError(() => {
          patchState({ errored: true })
          return of([]);
        }),
      );
  }

  @Action(ZoneAction.VideoPlayerSubscription)
  videoChangeSub({getState, setState}: Context, { playerId }: ZoneAction.VideoPlayerSubscription) {
    const state = getState();

    const videoPlayerSub = this.zoneService.videoPlayerSubscription(playerId)
      .pipe(
        tap((playerState: VideoPlayerState) => {
          const zoneUpd = {...state.zones.find(({id}) => id === playerState.zone.id)};
          const track = (!playerState.track) ? null :
            (!zoneUpd.currentTrack || zoneUpd.currentTrack.id !== playerState.track.id) ?
            playerState.track : zoneUpd.currentTrack;

          if (playerState.playingState) {
            this.timerService.changeTimer(
              playerState.zone.id,
              playerState.isPlaying,
              moment.duration(playerState.playingState.length, 'ms').asSeconds(),
              moment.duration(playerState.playingState.time, 'ms').asSeconds()
            );
          }

          setState(
            patch({
              zones: updateItem<Zone>(
                zone => zone.id === playerState.zone.id,
                { ...zoneUpd, currentTrack: track, playing: playerState.isPlaying || false }
              )
            })
          );
        })
      )
      .subscribe();

      this.videoPlayerSubs.set(playerId, videoPlayerSub);
  }

  @Action(ZoneAction.ZoneChangeSubscription)
  changeSub({getState, setState, dispatch}: Context, { zoneId }: ZoneAction.ZoneChangeSubscription) {
    const state = getState();
    this.zoneService.zoneChangeSubscription(zoneId)
      .pipe(
        tap((zone: Zone) => {
          const zonePlayer = {...state.zones.find(zone => zone.id === zoneId).player};
          const zoneUpd = {
            ...state.zones.find(zone => zone.id === zoneId),
            ...zone
          }
          setState(
            patch({
              zones: updateItem<Zone>(zone => zone.id === zoneId, zoneUpd)
            })
          );

          if (zoneUpd.mediaType === MediaTypes.AUDIO && zoneUpd.id === getState().currentZoneId) {
            if (!!zoneUpd.player && !this.playerSubs.has(zoneId)) {
              dispatch(new ZoneAction.ZonePlayerEventChangeSubscription(zoneId));
              dispatch(new ZoneAction.MonitorModeSubscription(zoneId));
            } else if (!zoneUpd.player && this.playerSubs.has(zoneId)) {
              this.closePlayerEventSub(zoneId);
            }
          } else if (zoneUpd.mediaType === MediaTypes.VIDEO){
            if (zone.player && zone.player.id && !this.videoPlayerSubs.has(zone.player.id)) {
              dispatch(new ZoneAction.VideoPlayerSubscription(zone.player.id));
            }
            if (zonePlayer && zonePlayer.id && !state.zones.some(({player}) => !!player && player.id === zonePlayer.id)) {
              this.closeVideoPlayerSub(zonePlayer.id);
            }
          }
        })
      ).subscribe();
  }

  @Action(ZoneAction.ZonePlayerEventChangeSubscription)
  playerEventSub({getState, setState, patchState}: Context, { zoneId }: ZoneAction.ZonePlayerEventChangeSubscription) {
    const state = getState();
    const playerSub = this.zoneService.zonePlayerEventSubscription(zoneId)
    .pipe(
      // check is it still actual rule?
      filter((player: PlayerEvent) => player.playerEventType.name !== 'END'),
      tap((playerEvent: PlayerEvent) => {
        const zoneUpd: Zone = {
          ...state.zones.find(({id}) => id === zoneId),
          currentTrack: playerEvent.track,
          playing: playerEvent.zone.playing,
          currentTrackQueue: playerEvent.zone.currentTrackQueue
        }
        setState(
          patch({
            zones: updateItem<Zone>(zone => zone.id === zoneId, zoneUpd)
          })
        );
      })
    ).subscribe();

    this.playerSubs.set(zoneId, playerSub);
  }

  @Action(ZoneAction.MonitorModeSubscription)
  monitorMode({getState, setState, patchState}: Context, { zoneId }: ZoneAction.MonitorModeSubscription) {
    const state = getState();

    if (!this.monitorModeSubs.has(zoneId)) {
      this.zoneService.createPlayerCommand(PlayerCommandType.monitorOn, zoneId)
      .pipe(
        tap(() => {
          this.timerService.changeTimer(zoneId, false, 0, 0);
          const zoneUpd = {
            ...state.zones.find(zone => zone.id === zoneId),
            monitorMode: true
          };
          setState(
            patch({
              zones: updateItem<Zone>(zone => zone.id === zoneId, zoneUpd)
            })
          );

          this.monitorModeSubs.set(zoneId, this.zoneService.monitorModeSub(zoneId));
        })
      ).subscribe();
    }
  }

  @Action(ZoneAction.CloseMonitorModeSubscription)
  monitorModeStop({getState, setState, patchState}: Context, { zoneId }: ZoneAction.CloseMonitorModeSubscription) {
    const state = getState();

    if (this.monitorModeSubs.has(zoneId)) {
      this.zoneService.createPlayerCommand(PlayerCommandType.monitorOff, zoneId)
      .pipe(
        tap(() => {
          this.timerService.changeTimer(zoneId, false, 0, 0);
          const zoneUpd = {
            ...state.zones.find(zone => zone.id === zoneId),
            monitorMode: false
          };
          setState(
            patch({
              zones: updateItem<Zone>(zone => zone.id === zoneId, zoneUpd)
            })
          );

          this.monitorModeSubs.get(zoneId).unsubscribe();
          this.monitorModeSubs.delete(zoneId);
        })
      ).subscribe();
    }
  }

  @Action(ZoneAction.SetCurrentZoneId)
  setCurrentZone({getState, patchState}: Context, {id}: ZoneAction.SetCurrentZoneId) {
    const state = getState();
    if (state.currentZoneId !== id) {
      patchState({
        currentZoneId: id || null
      });
    }
  }

  closePlayerEventSub(zoneId: number) {
    if (this.playerSubs.has(zoneId)) {
      this.playerSubs.get(zoneId).unsubscribe();
      this.playerSubs.delete(zoneId);
    }
    if (this.monitorModeSubs.has(zoneId)) {
      this.store.dispatch(new ZoneAction.CloseMonitorModeSubscription(zoneId));
    }
  }

  closeVideoPlayerSub(playerId: number) {
    if (this.videoPlayerSubs.has(playerId)) {
      this.videoPlayerSubs.get(playerId).unsubscribe();
      this.videoPlayerSubs.delete(playerId);
    }
  }

  @Action(ZoneAction.AddZone)
  add(ctx: Context, { zone }: ZoneAction.AddZone) {
    const state = ctx.getState();

    ctx.patchState({
      zones: [...state.zones, zone]
    });

    this.store.dispatch(new ZoneAction.ZoneChangeSubscription(zone.id));
  }

  @Action(ZoneAction.EditZone)
  edit({getState, setState}: Context, { zone }: ZoneAction.EditZone) {
    const state = getState();
    const zoneUpd = {
      ...state.zones.find(({id}) => id === zone.id),
      ...zone
    };

    setState(
      patch({
        zones: updateItem<Zone>(zone => zone.id === zoneUpd.id, zoneUpd)
      })
    );
  }

  @Action(ZoneAction.DeleteZone)
  delete(ctx: Context, { id }: ZoneAction.DeleteZone) {
    const state = ctx.getState();

    ctx.patchState({
      zones: state.zones.filter((item) => item.id !== id)
    });

    this.closePlayerEventSub(id);
  }

  @Action(ZoneAction.ClearZoneData)
  clear(ctx: Context) {
    this.monitorModeSubs.forEach((sub) => {
      sub && sub.unsubscribe();
    });
    this.monitorModeSubs.clear();

    this.playerSubs.forEach((sub) => {
      sub && sub.unsubscribe();
    });
    this.playerSubs.clear();

    this.videoPlayerSubs.forEach((sub) => {
      sub && sub.unsubscribe();
    });
    this.videoPlayerSubs.clear();
  }

  @Action(ZoneAction.UpdateState)
  updateState({dispatch}: Context) {
    return dispatch(new ZoneAction.ClearZoneData())
    .pipe(
      switchMap(() => dispatch(new StateReset(ZonesState)))
    )
  }

  @Selector()
  static zones(state: ZoneStateModel): Zone[] {
    return state.zones;
  }

  @Selector()
  static isZonesLoaded(state: ZoneStateModel): boolean {
    return state.isZonesLoaded;
  }

  @Selector()
  static jukeoSettings(state: ZoneStateModel): Zone[] {
    return state.jukeoSettings;
  }

  @Selector()
  static isJukeosLoaded(state: ZoneStateModel): boolean {
    return state.isJukeosLoaded;
  }

  @Selector()
  static currentZone(state: ZoneStateModel): Zone | null {
    return state.zones.find(({id}) => id === state.currentZoneId) || null;
  }

  @Action(ZoneAction.GetJukeos)
  getJukeos({ patchState }: StateContext<ZoneStateModel>, { venueId }: ZoneAction.GetJukeos) {
    return this.zoneService.getJukeosByVenue()
      .pipe(
        tap((jukeoSettings: JukeoSetting[]) => {
          patchState({
            jukeoSettings,
            isJukeosLoaded: true
          });
        }),
        catchError(() => {
          patchState({ errored: true })
          return of([]);
        }),
      );
  }

  @Action(ZoneAction.UpdateJukeoSettings)
  updateJukeoSettings({ patchState }: StateContext<ZoneStateModel>, { zoneIds, enable }: ZoneAction.UpdateJukeoSettings) {
    return this.zoneService.updateJukeoSettings(zoneIds, enable)
      .pipe(
        switchMap(() => from(this.zoneService.getJukeosByVenue())),
        tap((jukeoSettings: JukeoSetting[]) => {
          patchState({ jukeoSettings });
        }),
        catchError(() => {
          patchState({ errored: true })
          return of([]);
        }),
      );
  }

  @Selector()
  static jukeoZonePlaylists(state: ZoneStateModel): JukeoPlaylist[] {
    return state.jukeoPlaylists;
  }

  @Action(ZoneAction.GetJukeoZonePlaylists)
  getJukeoZonePlaylists({ patchState }: StateContext<ZoneStateModel>, { zoneIds, venueId }: ZoneAction.GetJukeoZonePlaylists) {
    return this.zoneService.getJukeoZonePlaylists(zoneIds, venueId)
      .pipe(
        tap((jukeoPlaylists: JukeoPlaylist[]) => {
          patchState({
            jukeoPlaylists,
            isJukeoPlaylistsLoaded: true
          });
        }),
        catchError(() => {
          return of([]);
        }),
      );
  }

  @Action(ZoneAction.JukeoSetZonePlaylists)
  jukeoSetZonePlaylist({ patchState }: StateContext<ZoneStateModel>, { zoneId, playlistIds, venueId }: ZoneAction.JukeoSetZonePlaylists) {
    return this.zoneService.jukeoSetZonePlaylist(zoneId, playlistIds, venueId)
      .pipe(
        catchError(() => {
          return of([]);
        }),
      );
  }

  @Selector()
  static errored(state: ZoneStateModel): boolean {
    return state.errored;
  }

  static zone(zoneId: number) {
    return createSelector([ZonesState], (state: ZoneStateModel) => {
      return state.zones.find(({id}) => id === zoneId);
    });
  }
}
