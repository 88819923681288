<div class="track-cover">
  <img
    [src]="(track.artwork && track.artwork.artworkUrl100) ? track.artwork.artworkUrl100 : '/assets/images/default-cover.jpg'"
    appDefault
    alt="Track cover"
  />
</div>
<div class="track-details">
  <div class="track-title text-truncate">
    <span [title]="track.name">{{ track.name }}</span>
  </div>
  <div class="track-artist text-truncate">
    <span [title]="track.artist.name">{{ track.artist.name }}</span>
  </div>
  <div class="track-album text-truncate">
    <span [title]="track.album.name">{{ track.album.name }}</span>
  </div>
</div>
