import { Injectable } from '@angular/core';

import { filter, map, take } from 'rxjs/operators';
import { BehaviorSubject, timer } from 'rxjs';

import { Player, Track } from '../models/models';

@Injectable({
  providedIn: 'root'
})
export class PlayerService {
  private players: Player[] = [];

  public onPlayersChanged: BehaviorSubject<Player[]> = new BehaviorSubject<Player[]>([]);
  public onZonePlayerChanged: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor() { }

  changePlayer(zoneId: number, playing: boolean, track:Track) {
    const curPlayerId = this.players.findIndex(x => x.id === zoneId);
    if (curPlayerId > -1) {
      const curPlayer = this.players[curPlayerId];
      curPlayer.playing = playing;
      curPlayer.track = track;
      //this.onPlayersChanged.next(this.players);
    } else {
      this.newPlayerAdd(zoneId, playing, track);
    }
    this.onPlayersChanged.next(this.players);
    this.onZonePlayerChanged.next({
      players: this.players,
      zoneId: zoneId
    })
  }

  newPlayerAdd(zoneId: number, playing: boolean, track: Track) {
    this.players.push({
      id: zoneId,
      playing: playing,
      track: track,
    });
    //this.onPlayersChanged.next(this.players);
  }
}
