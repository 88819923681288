import { Playlist } from '../../models/models';

export namespace PlaylistAction {

  export class GetMyPlaylists {
    static readonly type = '[Library Playlists] Get Playlists';
    constructor() {}
  }

  export class GetMorePlaylists {
    static readonly type = '[Library Playlists] Get More Playlists';
    constructor(
      public count?: number
    ) {}
  }

  export class AddPlaylist {
    static readonly type = '[Library Playlists] Add Playlist To Library';
    constructor(
      public playlist: Playlist
    ) {}
  }

  export class CreatePlaylist {
    static readonly type = '[Library Playlists] Create New Library Playlist';
    constructor(
      public name: string
    ) {}
  }

  export class ImportSpotifyPlaylist {
    static readonly type = '[Library Playlists] Import Playlist From Spotify';
    constructor(
      public playlist: any,
      public spotifyPlaylistImportId: any
    ) {}
  }

  export class SpotifyImportedTracksReset {
    static readonly type = '[Library Playlists] Reset Spotify Tracks Count'
  }

  export class EditPlaylist {
    static readonly type = '[Library Playlists] Edit Playlist';
    constructor(
      public playlist: Playlist
    ) {}
  }

  export class RemovePlaylistFromLibrary {
    static readonly type = '[Library Playlists] Remove Playlist from Library';
    constructor(
      public id: number
    ) {}
  }

  export class RemoveMultiplePlaylistsFromLibrary {
    static readonly type = '[Library Playlists] Remove Multiple Playlists from Library';
    constructor(
      public ids: number[]
    ) {}
  }

  export class DeletePlaylist {
    static readonly type = '[Library Playlists] Delete Playlist';
    constructor(
      public id: number
    ) {}
  }

  export class DeleteMultiplePlaylists {
    static readonly type = '[Library Playlists] Delete Multiple Playlists';
    constructor(
      public ids: number[]
    ) {}
  }

  export class AddTracksToPlaylist {
    static readonly type = '[Library Playlists] Add Tracks To Playlist';
    constructor(
      public trackIds: Array<number>,
      public playlistId: number
    ) {}
  }

  export class RemoveTracksFromPlaylist {
    static readonly type = '[Library Playlists] Remove Tracks From Playlist';
    constructor(
      public trackIds: Array<number>,
      public playlistId: number
    ) {}
  }

  export class UpdatePlaylistsOnBucketTrackRemove {
    static readonly type = '[Library Playlists] Update Playlists On Bucket Track Remove';
    constructor(
      public trackId: number,
      public playlistsIds: Array<number>
    ) {}
  }

  export class AddCatalogPlaylistsToLibrary {
    static readonly type = '[Library Playlists] Add Catalog Playlists To Library';
    constructor(
      public playlistsIds: Array<number>
    ) {}
  }

  export class UpdateState {
    static readonly type = '[Library Playlists] Update State';
  }
}