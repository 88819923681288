import { Component, Inject, OnDestroy, OnInit, ViewChild, AfterViewInit, NgZone, DoCheck } from '@angular/core';

import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

//import { Subscription } from 'rxjs';

import debounce from 'lodash/debounce';
import uniqBy from 'lodash/uniqBy';

import { ConfirmationDialogComponent } from 'src/app//shared/components/confirmation-dialog/confirmation-dialog.component';
import { PlaylistService } from 'src/app//shared/services/playlist.service';
import { Bucket, Playlist, Track, PlaylistCursor } from 'src/app//shared/models/models';
//import { tap, map, filter } from 'rxjs/operators';
import { NgScrollbar } from 'ngx-scrollbar';
//import { Store } from '@ngxs/store';
// import { BucketsState } from 'src/app/shared/store/buckets/buckets.state';
// import { BucketAction } from 'src/app/shared/store/buckets/buckets.actions';
//import { SNACK_BAR_DURATION, TrackSource } from 'src/app/shared/models/consts';
import { ErrorParserService } from 'src/app/shared/services/error-parcing.service';
import { AlertDialogComponent } from 'src/app/shared/components/alert-dialog/alert-dialog.component';
import { ZonesListComponent } from 'src/app/shared/components/zone-list-dialog/zone-list-dialog.component';
import { ZoneService } from 'src/app/shared/services/zone.service';
import { MediaTypes, PlaylistColumns, PlaylistMediaType, SCROLL_BOTTOM_OFFSET, SNACK_BAR_DURATION, ViewType, TrackSource } from 'src/app/shared/models/consts';
import { ZonesState } from 'src/app/shared/store/zones/zones.state';
import { User, Venue, Zone, TodayTimeslot, PaginationInfo } from 'src/app/shared/models/models';

import { Observable, Subject, Subscription } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { tap, map, filter, takeUntil } from 'rxjs/operators';

import { TrackService } from 'src/app/shared/services/track.service';
import { VenueState } from 'src/app/shared/store/venue/venue.state';


interface DialogData {
  track: Track;
}

@Component({
  selector: 'app-queue-track-dialog',
  templateUrl: './queue-track-dialog.component.html',
  styleUrls: ['./queue-track-dialog.component.scss']
})
export class QueueTrackDialogComponent implements OnInit, OnDestroy, DoCheck {
  @ViewChild('trackScroll', { static: false }) scrollbarRef: NgScrollbar;
  @Select(ZonesState.currentZone) currentZone$: Observable<Zone>;
  //@Select(TracksState.tracks) tracks$: Observable<Track[]>;
  public view: 'TRACKS' | 'TRACKS' = 'TRACKS';
  public tracksToAdd: Set<number> = new Set();
  //public filteredTracks: Track[] = [];
  public filteredTracks: Track[] = [];
  public addTracksMode = true;
  //public tracks: Track[];
  //public tracks: Track[];
  public isLoading = true;
  public errored = false;
  public track: Track;
  public tracks: Track[] = [];
  public currentZone: Zone;
  public tracksLoading = true;
  public tracksErrored = false;

  //public cursors: TrackCursor[] = [];
  public loadMore: boolean = false;

  @Select(VenueState.venue) venue$: Observable<Venue>;
  private selectedVenueSub: Subscription;
  public hasMoreTracks: boolean = false;
  public trackCursor: string;
  public isLoaded: boolean = true;
  
  private tracksSub: Subscription;
  private scrollSub: Subscription;
  private unSubscribe$: Subject<{}> = new Subject;
  private currentZoneSub: Subscription;

  //mediaView: TrackMediaType = TrackMediaType.TRACK;


  constructor(
    private trackService: TrackService,
    private ngZone: NgZone,
    private dialog: MatDialog,
    private store: Store,
    private errorService: ErrorParserService,
    private _snackBar: MatSnackBar,
    private zoneService: ZoneService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.currentZone$ = this.store.select(ZonesState.currentZone);
    this.venue$ = this.store.select(VenueState.venue);
    //this.searchTrack = debounce(this.searchTrack, 500);
  }

  ngOnInit() {
    this.selectedVenueSub = this.venue$
    .pipe(
      tap(() => this.tracksLoading = true),
      filter((venue: Venue) => !!venue)
    )
    .subscribe(() => {
      if (!this.trackService.getTracks()) {
        this.trackService.getLibraryTracks()
        .then((pagenateInfo: PaginationInfo) => {
          this.hasMoreTracks = pagenateInfo.pageInfo.hasNextPage;
          if (this.hasMoreTracks) {
            this.trackCursor = pagenateInfo.pageInfo.endCursor;
          }
        });
      }
    }, () => this.tracksErrored = true);

    this.tracksSub = this.trackService.onTracksChanges.subscribe((tracks) => {
      this.tracks = this.filteredTracks= tracks;
      this.isLoading = false;
      console.log("filteredTracks", this.filteredTracks);
    }, () => this.tracksErrored = true);
    
    this.currentZoneSub = this.currentZone$
    .pipe(
      filter((zone) => !!zone),
      takeUntil(this.unSubscribe$)
    )
    .subscribe((zone) => {
      this.currentZone = zone;
      console.log('queue track on zone ', zone.name);
    });
  }

  ngDoCheck() {}
  // ngDoCheck() {
  //   if (this.scrollbarRef && !this.scrollSub) {
  //     this.scrollSub = this.scrollbarRef.scrolled.pipe(
  //       filter((e: any) => !this.loadMore && e.target.scrollBottom < 10),
  //       tap(() => this.ngZone.run(() => {
  //         this.loadMore = true;
  //         this.tracksLoading = true;
  //         this.getMoreTracks();
  //       }))
  //     ).subscribe();
  //   }
  // }

  get trackSource() {
    return TrackSource;
  }

  setView(view: 'TRACKS' | 'TRACKS') {
    this.view = view;
  }

  backToTrackDetails() {
    this.tracksToAdd.clear();
    this.addTracksMode = false;
    this.isLoading = false;
  }


  trackChange(event, isUpdated = false) {
    if (!isUpdated) {
      this.tracks = this.tracks.filter(x => x.id !== event);
    } else {
      this.tracks = this.tracks.splice(this.tracks.findIndex(({id}) => id === event.id), 1, event);
    }
  }



  get hasSelected() {
    return (this.tracksToAdd.size > 0);
  }
  
  addToPlayingQueue() {
    if (!this.hasSelected) {
      return
    }

    // this.dialog.open(ZonesListComponent)
    //   .afterClosed()
    //   .subscribe(data => {
    //     if (!data) {
    //       return;
    //     }
    //     if (data.error) {
    //       this.dialog.open(AlertDialogComponent, {
    //         data: {
    //           type: 'error',
    //           message: data.error
    //         }
    //       });
    //     } else {
    //       this.confirmAddTrackInQueue(data.id);
    //     }
    //   });
    this.confirmAddTrackInQueue(this.currentZone.id);
  }

  confirmAddTrackInQueue(zoneId) {
    //const trackIndex = this.tracks.findIndex(({isChecked}) => isChecked);

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: 'The track will start after the current song finishes',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary'
          },
          {
            text: 'OK',
            cssClass: 'primary',
            handler: () => {
              //this.zoneService.addTrackToPlayQueue(this.tracks[trackIndex].id, zoneId)
	      this.zoneService.addTrackToPlayQueue(this.tracksToAdd.values().next().value, zoneId)
              .then(() => {
                //this.tracks[trackIndex].isChecked = false;
                dialogRef.close();
                this._snackBar.open(
                  `Track successfully added to the queue`,
                  null,
                  { duration: SNACK_BAR_DURATION }
                );
              })
              .catch((error) => {
                const errIdStr = this.errorService.parseError(error);
                dialogRef.close();
                this.dialog.open(AlertDialogComponent, {
                  data: {
                    type: 'error',
                    message: `Oops, something went wrong. Please try again.
                    ${(!!errIdStr) ? ('Error id: ' + errIdStr) : ''}`
                  }
                });
              });
            }
          }
        ]
      },
      width: '340px'
    });
  }

  // getTrackForOverrideZoneTracksUsingTrack() {
  //   if (!this.hasSelected) {
  //     return
  //   }
  //   this.getZoneForOverrideZoneTracksUsingTrack(this.tracksToAdd.values().next().value);
  // }

  // getZoneForOverrideZoneTracksUsingTrack(buckedId: number) {
  //   this.confirmOverrideZoneTracksUsingTrack(this.currentZone.id, buckedId);
  // }

  // confirmOverrideZoneTracksUsingTrack(zoneId: number, trackId: number) {
  //   this.dialog.open(ConfirmationDialogComponent, {
  //     data: {
  //       title: 'Override current tracks with track?',
  //       message: "Are you sure you want to override the current tracks playing with track tracks? This will play until the next allocated timeslot",
  //       buttons: [
  //         {
  //           text: 'Cancel',
  //           role: 'cancel',
  //           cssClass: 'secondary'
  //         },
  //         {
  //           text: 'Play',
  //           cssClass: 'primary',
  //           handler: () => {
  //             this.overrideZoneTracksUsingTrack(zoneId, trackId);
  //           }
  //         }
  //       ],
  //     },
  //     width: '500px'
  //   });
  // }

  // overrideZoneTracksUsingTrack(zoneId: number, trackId: number, forbidSimilar?: boolean) {
  //   this.store.dispatch(new TrackAction.OverrideZoneTracksUsingTrackUntilNextTimeslot(zoneId, trackId, forbidSimilar))
  //     .subscribe(() => {
  //       this.dialog.closeAll();
  //       this.tracks = this.tracks.map((item) => {
  //         //item.isChecked = false;
  //         return item;
  //       });

  //       this._snackBar.open(
  //         `Track successfully playing for current timeslot`,
  //         null,
  //         { duration: SNACK_BAR_DURATION }
  //       );
  //     }, (error) => {
  //       const errIdStr = this.errorService.parseError(error);
  //       this.dialog.open(AlertDialogComponent, {
  //         data: {
  //           type: 'error',
  //           message: `Oops, something went wrong. Please try again.
  //           ${(!!errIdStr) ? ('Error id: ' + errIdStr) : ''}`
  //         }
  //       });
  //     });
  // }

  searchTrack(term: string) {
    if (term === '') {
      this.filteredTracks = this.tracks;
    } else {
      this.filteredTracks = this.tracks.filter((track) => {
        return track.name.toLowerCase().includes(term.toLowerCase());
      });
    }
  }

  onTrackSelected(trackId: number) {
    // if (this.tracksToAdd.has(trackId)) {
    //   this.tracksToAdd.delete(trackId);
    // } else {
    //   this.tracksToAdd.add(trackId);
    // }
    this.tracksToAdd.clear();
    this.tracksToAdd.add(trackId);
  }

  ngOnDestroy() {
    // if (this.scrollSub) {
    //   this.scrollSub.unsubscribe();
    // }
    if (this.tracksSub) {
      this.tracksSub.unsubscribe();
    }
    this.unSubscribe$.next(true);
    this.unSubscribe$.complete();
  }
}
