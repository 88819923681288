import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { LocalStoreService } from './localstore.service';

@Injectable({
  providedIn: 'root'
})
export class ImageUploadService {

  constructor(private http: HttpClient, private localstoreService: LocalStoreService) { }

  uploadUserAvatar(avatarFile) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();

      formData.append('avatar-file', avatarFile);

      this.http.post(`${environment.API_UPLOAD_BASE_URL}/uploadUserAvatar`, formData, this.getRequestOptions())
        .subscribe((data: any) => {
          if (data.success) {
            resolve(true);
          } else {
            reject();
          }
        });
    });
  }

  uploadVenueLogo(logoFile, venueId) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();

      formData.append('venue-logo-file', logoFile);

      this.http.post(`${environment.API_UPLOAD_BASE_URL}/uploadVenueLogo/${venueId}`, formData, this.getRequestOptions())
        .subscribe((data: any) => {
          if (data.success) {
            resolve(true);
          } else {
            reject();
          }
        });
    });
  }

  uploadVenueBanner(bannerFile, venueId) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();

      formData.append('venue-banner-file', bannerFile);

      this.http.post(`${environment.API_UPLOAD_BASE_URL}/uploadVenueBanner/${venueId}`, formData, this.getRequestOptions())
        .subscribe((data: any) => {
          if (data.success) {
            resolve(true);
          } else {
            reject();
          }
        });
    });
  }

  uploadPlaylistCover(coverFile, playlistId) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();

      formData.append('playlist-logo-file', coverFile);

      this.http.post(`${environment.API_UPLOAD_BASE_URL}/uploadPlaylistLogo/${playlistId}`, formData, this.getRequestOptions())
        .subscribe((data: any) => {
          if (data.success) {
            resolve(true);
          } else {
            reject();
          }
        });
    });
  }

  getRequestOptions() {
    return {
      headers: new HttpHeaders({
        'Accept': 'application/json'
      })
    };
  }
}
