import { BehaviorSubject, from } from 'rxjs';
import {
  addTrackToLibrary,
  getSimilarTracks,
  getTrack,
  getTrackContentUrl,
  getTracksForLibrary,
  removeTrackFromLibrary,
  removeTracksFromLibrary,
  setTrackHeartState
} from '../gql/track-consts';
import { map, switchMap, tap } from 'rxjs/operators';

import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { LocalStoreService } from './localstore.service';
import { Track } from '../models/models';

@Injectable({
  providedIn: 'root'
})
export class TrackService {

  private tracks: Track[];

  public newTracksLoaded: BehaviorSubject<Track[]> = new BehaviorSubject<Track[]>([]);
  public onTracksChanges: BehaviorSubject<Track[]> = new BehaviorSubject<Track[]>([]);
  public onSimilarTracksChanges: BehaviorSubject<Track[]> = new BehaviorSubject<Track[]>([]);

  constructor(
    private localstoreService: LocalStoreService,
    private apollo: Apollo
  ) {}

  getTrack(id: number) {
    return new Promise<any>((resolve, reject) => {
      this.apollo.query({
        query: getTrack,
        variables: {id, venueId: this.localstoreService.getItemParsed('venue.id')}
      })
      .pipe(
        map((data: any) => data.data.track)
      )
        .subscribe((track: Track) => {
          resolve(track);
        },
          (error) => {
          console.log(error);
          reject(error);
        });
    });
  }

  setTrackHeartState(trackId: number, like: boolean) {
    return new Promise<any>((resolve, reject) => {
      this.apollo.mutate({
        mutation: setTrackHeartState,
        variables: { trackId, venueId: this.localstoreService.getItemParsed('venue.id'), like }
      })
      .pipe(
        map((data: any) => data.data.updateTrackHeartState)
      )
        .subscribe(
          (updateTrackHeartState: any) => {
            resolve(updateTrackHeartState);
          },
          (error) => {
            console.log(error);
            reject(error);
          }
        );
    });
  }

  addTrackToLibrary(trackId: number) {
    return new Promise<any>((resolve, reject) => {
      this.apollo.mutate({
        mutation: addTrackToLibrary,
        variables: { trackId, venueId: this.localstoreService.getItemParsed('venue.id') }
      })
      .pipe(
        switchMap(() => from(this.getTrack(trackId)))
      )
        .subscribe(
          (track: any) => {
            if (this.tracks) {
              this.tracks = [...this.tracks, track];
              this.onTracksChanges.next(this.tracks);
            }
            resolve(true);
          },
          (error) => {
            console.log(error);
            reject(error);
          }
        );
    });
  }

  removeTrackFromLibrary(trackId: number) {
    return new Promise<any>((resolve, reject) => {
      this.apollo.mutate({
        mutation: removeTrackFromLibrary,
        variables: { trackId, venueId: this.localstoreService.getItemParsed('venue.id') }
      })
        .subscribe(
          (data: any) => {
            if (data.data.removeTrackFromLibrary && this.tracks) {
              this.tracks = this.tracks.filter(
                (track) => track && track.id !== trackId
              );
              this.onTracksChanges.next(this.tracks);
            }
            resolve(true);
          },
          (error) => {
            console.log(error);
            reject(error);
          }
        );
    });
  }

  removeMultipleTracks(trackIds: number[]) {
    return this.apollo.mutate({
      mutation: removeTracksFromLibrary,
      variables: {
        trackIds,
        venueId: this.localstoreService.getItemParsed('venue.id')
      }
    })
    .pipe(
      map((data: any) => data.data.removeTracksFromLibrary),
      tap((res) => {
        if (!!res && this.tracks) {
          this.tracks = this.tracks.filter(
            ({id}) => !trackIds.includes(id)
          );
          this.onTracksChanges.next(this.tracks);
        }
      })
    )
  }

  getLibraryTracks(cursor?: string, count: number = 10) {
    return new Promise<any>((resolve, reject) => {
      this.apollo.query({
        query: getTracksForLibrary,
        variables: {
          venueId: this.localstoreService.getItemParsed('venue.id'),
          first: count,
          after: cursor || null
        }
      })
        .subscribe(
          (data: any) => {
            const resultArr = data.data.libraryTracksConnection.edges;

            if (!this.tracks) {
              this.tracks = [];
            }

            this.tracks = [...this.tracks, ...resultArr.map(({node}) => node.track)];
            this.newTracksLoaded.next(resultArr.map(({node}) => node.track));
            this.onTracksChanges.next(this.tracks);

            resolve(data.data.libraryTracksConnection);
          },
          (error) => {
            console.log(error);
            reject(error);
          }
        );
    });
  }

  getTrackContentUrl(id: number) {
    return this.apollo.query({
      query: getTrackContentUrl,
      variables: {id, venueId: this.localstoreService.getItemParsed('venue.id')}
    }).pipe(
      map((data: any) => data.data.track)
    );
  }

  getSimilarTracks(trackIds: number[], perTrackLimit?) {
    return this.apollo.query({
      query: getSimilarTracks,
      variables: { trackIds, perTrackLimit }
    }).pipe(
      map((data: any) => {
        this.onSimilarTracksChanges.next(data.data.findSimilarTracks);
        return data.data.findSimilarTracks;
      })
    );
  }

  getTracks() {
    return this.tracks;
  }

  pauseTrack() {
    const audioOutput: any = document.getElementById('play-tracks-audio');

    if (audioOutput) {
      audioOutput.pause();
    }
  }

  clearData() {
    this.tracks = null;
    this.onTracksChanges.next(this.tracks);
  }
}
