import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';

import { SharedNewTracksContainerDialogComponent } from './shared-new-tracks-container-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SpinnerModule } from 'src/app/system/spinner/spinner.module';

@NgModule({
    declarations: [
        SharedNewTracksContainerDialogComponent
    ],
    imports: [
        CommonModule,
        MatMenuModule,
        MatDialogModule,
        FormsModule,
        ReactiveFormsModule,
        SpinnerModule
    ],
    exports: [
        SharedNewTracksContainerDialogComponent
    ]
})
export class SharedNewTracksContainerDialogModule { }
