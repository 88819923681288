<div class="venue-page-wrapper">
  <div class="logo-wrapper">
    <h1 class="brend-name">Choose Venue</h1>
  </div>

  <div class="venues-wrapper">
    <div *ngIf="user" class="content-header">
      <div class="content-header-data">
        <p>You are logged in as:</p>
        <p [title]="user.displayName" class="text-truncate">{{ user.displayName }}</p>
        <p [title]="user.userEmail" class="text-truncate">{{ user.userEmail }}</p>
      </div>
    </div>
    <div class="scroll-wrapper">
      <input #searchInput (keyup)="searchVenue(searchInput.value.trim())" placeholder="Search a Venue"
        class="input-search" type="text" />
      <ng-scrollbar appearance=true>
        <ng-container *ngIf="!isLoading; else spinner">
          <div *ngFor="let venue of filteredVenues" (click)="selectVenue(venue.id)"
            class="venue-item d-flex overflow-hidden">
            <div class="d-flex justify-content-center cover-thumbnail">
              <img [src]="venue.bannerImageUrl || '/assets/images/logo-gray.svg'"
                appDefault="/assets/images/logo-gray.svg" defaultClass="default-image"
                [class.default-image]="!venue.bannerImageUrl" alt="Venue banner" />
            </div>
            <div class="data-wrapper">
              <h2 [title]="venue.name" class="block-with-text">{{ venue.name }}</h2>
              <div class="sub-data">
                <p>{{ venue.zones.length }} {{ (venue.zones.length === 1) ? 'Zone' : 'Zones' }}</p>
                <p [title]="venue.address.line1" class="text-truncate">{{ venue.address.line1 || 'n/a' }}</p>
              </div>
            </div>
          </div>
          <app-spinner [isLoading]="isMoreVenuesLoading" [diameter]="50" [minimal]="true"></app-spinner>
        </ng-container>
      </ng-scrollbar>
    </div>
  </div>
</div>

<ng-template #spinner>
  <app-spinner [isLoading]="isLoading" [errored]="errored" [minimal]="true"></app-spinner>
</ng-template>