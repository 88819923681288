<div id="venue-settings">

  <header class="d-flex justify-content-between align-items-center">
    <h1 class="m-0">Settings</h1>
  </header>

  <app-spinner *ngIf="isVenueLoading" [isLoading]="isVenueLoading" [errored]="venueErrored"></app-spinner>

  <div *ngIf="!isVenueLoading" class="venue">

    <div class="venue-image-container position-relative d-flex justify-content-center align-items-center">
      <img
        [src]="venue?.bannerImageUrl || '/assets/images/logo-gray.svg'"
        [ngClass]="{ 'default-image': !venue?.bannerImageUrl }"
        class="venue-image h-100"
        alt="Venue banner"
        appDefault="/assets/images/logo-gray.svg"
        defaultClass="default-image"
      />
      <img
        [src]="venue?.logoImageUrl || '/assets/images/logo-white.svg'"
        class="venue-logo position-absolute rounded-circle"
        alt="Venue logo"
        appDefault="/assets/images/logo-white.svg"
      />
    </div>

    <div class="venue-details-container position-relative">
      <div class="venue-details-header d-flex justify-content-between">
        <div class="venue-name-wrapper">
          <p class="label m-0">My Company</p>
          <p [title]="venue.name" class="venue-name text-truncate">{{ venue.name }}</p>
        </div>
        <button (click)="chooseVenue()" class="btn align-self-start">Choose a Venue</button>
      </div>
      <p [title]="venue.address.line1" class="venue-address text-truncate">{{ venue.address.line1 }}</p>
      <div *ngIf="venue.latitude && venue.longitude" class="coordinates-wrapper d-flex align-items-center">
        <div class="icon icon-location-mark d-inline-block"></div>
        <div class="coordinates d-flex align-items-center">{{ venue.latitude }}&#176;&nbsp;&nbsp;{{ venue.longitude }}&#176;</div>
      </div>
    </div>

  </div>

  <app-spinner *ngIf="!isVenueLoading && isSettingsLoading" [isLoading]="isSettingsLoading" [errored]="settingsErrored" [minimal]="true"></app-spinner>

  <div *ngIf="!isVenueLoading && !isSettingsLoading" class="venue-settings-container">

    <div class="default-schedule-container container">
      <h4>Default Schedule</h4>
      <div class="btn-wrapper d-flex justify-content-center">
        <button (click)="defaultScheduleRoute()" class="btn">{{ settings.schedule ? 'Edit Schedule' : 'Create Schedule' }}</button>
      </div>
    </div>

    <div class="music-selection-container container">
      <h4>Limit the Music Selection</h4>
      <div class="limit-music-switcher d-flex">
        <label>
          <input
            type="radio"
            value="true"
            (change)="onLimitMusicSelectionChange(true)"
            [checked]="venue.limitMusicSelection"

          />
          <div class="label">Yes</div>
        </label>

        <label>
          <input
            type="radio"
            value="false"
            (change)="onLimitMusicSelectionChange(false)"
            [checked]="!venue.limitMusicSelection"
          />
          <div class="label">No</div>
        </label>
      </div>
    </div>

    <div class="bandwidth-limitation-container container">
      <h4>Bandwidth Limitation</h4>
      <div class="slider-wrapper">
        <div class="selected-value-wrapper d-flex justify-content-end">
          <span class="selected-value">{{ settings.downloadSpeed }} Kbts</span>
        </div>
        <mat-slider
          [min]="0"
          [max]="5"
          [value]="bandwidthSliderValue"
          (change)="onBandwidthLimatationChange($event)"
          class="venue-settings-slider"
        >
        </mat-slider>
        <div class="min-max-values-wrapper d-flex justify-content-between">
          <span class="min-value">16 Kbts</span>
          <span class="max-value">512 Kbts</span>
        </div>
      </div>
    </div>

    <div class="default-cross-fade-container container">
      <h4>Default Cross Fade</h4>
      <div class="slider-wrapper">
        <div class="selected-value-wrapper d-flex justify-content-end">
          <span class="selected-value">{{ settings.fadeTimeSeconds }} Sec</span>
        </div>
        <mat-slider
          [min]="MIN_CROSSFADE_DURATION"
          [max]="MAX_CROSSFADE_DURATION"
          [value]="settings.fadeTimeSeconds"
          (change)="onCrossfadeDurationChange($event)"
          class="venue-settings-slider"
        >
        </mat-slider>
        <div class="min-max-values-wrapper d-flex justify-content-between">
          <span class="min-value">{{ MIN_CROSSFADE_DURATION }} Sec</span>
          <span class="max-value">{{ MAX_CROSSFADE_DURATION }} Sec</span>
        </div>
      </div>
    </div>

  </div>

</div>
