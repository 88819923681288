<div class="dialog-wrapper">
  <div class="dialog-header">
    <h2 mat-dialog-title>Tracks Queue - {{ typeReadable }}</h2>
      <button
        *ngIf="mediaType !== MediaTypes.CHANNEL && mediaType !== MediaTypes.VIDEO"
        class="btn revert-btn"
        (click)="promptForTrackQueueId()">
        Revert to schedule
      </button>
    <button mat-dialog-close class="btn close-dialog-btn"></button>
    <div *ngIf="manualPlaylist">
      Will resume to previously scheduled/override playlist after manually selected tracks are finished.
    </div>
  </div>

  <mat-dialog-content>
    <div class="lists-wrapper">
      <div class="track-list-wrapper edit-mode">
        <div class="track-list-header" *ngIf="mediaType !== MediaTypes.CHANNEL">
          <span>Title</span>
          <span>Artist</span>
          <span>Album</span>
          <span>Type</span>
        </div>

        <ng-scrollbar appearance=true class="track-list">
          <div *ngFor="let trackObj of tracks; let i = index" class="track-items-wrapper" [class.active]="i === 0">
            <div class="track-item-wrapper">
              <div class="track-cover">
                <img
                  appDefault
                  [src]="(trackObj.track.artwork && trackObj.track.artwork.artworkUrl100) ?
                        trackObj.track.artwork.artworkUrl100 : '/assets/images/default-cover.jpg'"
                  alt="Track cover"
                />
              </div>
              <div class="track-details">
                <div class="track-title text-truncate" [class.channel-track]="mediaType === MediaTypes.CHANNEL">
                  <span [title]="trackObj.track.name">{{ trackObj.track.name }}</span>
                </div>
                <div class="track-artist text-truncate" *ngIf="mediaType !== MediaTypes.CHANNEL">
                  <span [title]="trackObj.track.artist.name">{{ trackObj.track.artist.name }}</span>
                </div>
                <div class="track-album text-truncate" *ngIf="mediaType !== MediaTypes.CHANNEL">
                  <span [title]="trackObj.track.album.name">{{ trackObj.track.album.name }}</span>
                </div>
                <div class="track-album text-truncate" *ngIf="mediaType !== MediaTypes.CHANNEL">
                  <span [title]="trackObj.type">{{ trackObj.type }}</span>
                </div>
                <button
                  class="icon icon-delete remove-track-icon"
                  *ngIf="trackObj['type'] == 'Manual' && i !== 0"
                  (click)="promptRemoveTrack(i)"
                ></button>
              </div>
            </div>
          </div>
        </ng-scrollbar>

      </div>
    </div>
  </mat-dialog-content>
</div>
