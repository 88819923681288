import { NgModule } from '@angular/core';

import { GooglePlaceDirective } from '../directives/google-places-autocomplete/google-places-autocomplete.directive';

@NgModule({
  declarations: [GooglePlaceDirective],
  exports: [GooglePlaceDirective]
})
export class GooglePlaceModule {
}
