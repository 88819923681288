import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZoneHistoryComponent } from './zone-history.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { ImagePreloadModule } from '../../directives/image-preload/image-preload.module';

@NgModule({
    declarations: [ZoneHistoryComponent],
    imports: [
        CommonModule,
        NgScrollbarModule,
        MatDialogModule,
        ImagePreloadModule
    ]
})
export class ZoneHistoryModule { }
