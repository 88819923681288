import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { Bucket } from '../../models/models';
import { PrepareDataService } from '../../services/prepare-data.service';

@Component({
  selector: 'app-bucket-preview-item',
  templateUrl: './bucket-preview-item.component.html',
  styleUrls: ['./bucket-preview-item.component.scss']
})
export class SharedBucketPreviewItemComponent implements OnInit {
  @Input() bucket: Bucket;
  @Input() isSelected: boolean;

  @Output() selected = new EventEmitter<number>();

  constructor(
    private prepareService: PrepareDataService
  ) { }

  ngOnInit() {}

  selectBucket() {
    this.selected.emit(this.bucket.id);
  }

  coverToSize(url) {
    return this.prepareService.coverToSize(url, '200x200');
  }

}
