<div class="dialog-header">
  <h2 mat-dialog-title>New {{ data.tracksContainerType === 'PLAYLIST' ? 'Playlist' : 'Bucket' }}</h2>
  <button mat-dialog-close class="btn close-dialog-btn"></button>
</div>

<mat-dialog-content>
  <form [formGroup]="form">
    <div class="form-group">
      <label for="tracksContainerName">{{ data.tracksContainerType === 'PLAYLIST' ? 'Playlist' : 'Bucket' }}'s Name</label>
      <input
        formControlName="tracksContainerName"
        placeholder="Type something"
        class="form-control"
        id="tracksContainerName"
        type="text"
      />
    </div>
  </form>
</mat-dialog-content>

<div mat-dialog-actions>
  <button
    [disabled]="form.invalid"
    (click)="saveTracksContainer()"
    class="btn save-tracks-container-btn"
  >
    Create {{ data.tracksContainerType === 'PLAYLIST' ? 'Playlist' : 'Bucket' }}
  </button>
</div>
