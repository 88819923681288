import { Component, OnInit, OnDestroy, EventEmitter, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { Subscription, Observable, Subject } from 'rxjs';

import { OwlCarousel } from 'ngx-owl-carousel';

import { TrackQueueComponent } from '../../shared/components/track-queue/track-queue.component';
import { ReportingComponent } from './reports/reporting/reporting.component';
import { VenueService } from 'src/app/shared/services/venue.service';
import { TrackService } from '../../shared/services/track.service';
import { TimerService } from '../../shared/services/timer.service';
import { ZoneService } from '../../shared/services/zone.service';
import { AuthService } from '../../shared/services/auth.service';
import { User, Venue, Zone } from 'src/app/shared/models/models';
import { Select, Store } from '@ngxs/store';
import { filter, takeUntil } from 'rxjs/operators';
import { VenueState } from 'src/app/shared/store/venue/venue.state';
import { VenueListState } from 'src/app/shared/store/venue-list/venue-list.state';
import { VenueListAction } from 'src/app/shared/store/venue-list/venue-list.actions';
import { ZonesState } from 'src/app/shared/store/zones/zones.state';
import { ZoneAction } from 'src/app/shared/store/zones/zones.actions';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})

export class UserMenuComponent implements OnInit, OnDestroy {
  @Output() menuItemSelected = new EventEmitter();
  @ViewChild('owlMenuCarousel', { static: false }) owlElement: OwlCarousel;

  @Select(VenueState.venue) venue$: Observable<Venue>;
  private selectedVenueSub: Subscription;
  private timersSub: Subscription;
  private zonesSub: Subscription;
  private venueSub: Subscription;
  private userSub: Subscription;
  private currentZoneSub: Subscription;
  private venueUpdateSub: Subscription;

  @Select(ZonesState.zones) zones$: Observable<Zone[]>;
  @Select(ZonesState.currentZone) currentZone$: Observable<Zone>;

  private selectedZoneId: number;

  public zoneIdArr: any = [];
  public zonesWithPlayer: any = [];
  
  public timers: any[] = [];
  public user: User;
  public venue: Venue;
  public zone: Zone;

  private unSubscribe$: Subject<{}> = new Subject;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private authService: AuthService,
    private venueService: VenueService,
    private zoneService: ZoneService,
    private trackService: TrackService,
    private timerService: TimerService,
    private store: Store
  ) {
    this.currentZone$ = this.store.select(ZonesState.currentZone);
    this.venue$ = this.store.select(VenueState.venue);
    this.zones$ = this.store.select(ZonesState.zones);
    this.onZoneChanged = this.onZoneChanged.bind(this);
  }

  ngOnInit() {
    this.currentZoneSub = this.currentZone$
    .pipe(
      filter((zone) => !!zone),
      takeUntil(this.unSubscribe$)
    )
    .subscribe((zone) => {
      this.zone = zone;
      if (!this.selectedZoneId || this.selectedZoneId !== this.zone.id) {
        this.selectedZoneId = this.zone.id;
      }
      if (this.zone && this.owlElement) {
        this.owlElement.to([this.zonesWithPlayer.findIndex(i => i.id === zone.id)]);
      }
    });

    this.venue$
    .pipe(
      filter((venue) => !!venue),
      takeUntil(this.unSubscribe$)
    )
    .subscribe((venue) => {
      this.venue = venue;
      this.fetchZones();
    });

    this.userSub = this.authService.onUserChanged.subscribe(user => {
      this.user = user;
    });

  }

  fetchZones() {
      this.zonesSub = this.zones$
      .pipe(
        filter((zones: Zone[]) => !!zones && !!zones.length),
      )
      .subscribe((zones) => {
        this.zonesWithPlayer = zones.filter((zone) => zone.player && zone.currentTrack);

        if (!!this.zoneIdArr.length || this.zoneIdArr.length !== zones.length) {
          this.zoneIdArr = this.zonesWithPlayer.map(({id}) => id);
        }

        if (!!this.zonesWithPlayer.length && !this.zone) {
          this.selectedZoneId = this.zonesWithPlayer[0].id;
          this.store.dispatch(new ZoneAction.SetCurrentZoneId(this.selectedZoneId));
        } else if (this.zone && !this.zonesWithPlayer.length) {
          this.store.dispatch(new ZoneAction.SetCurrentZoneId());
        }
    });

    this.timersSub = this.timerService.onTimersChanged.subscribe((timers) => {
      timers.forEach((timer) => {
        this.timers[timer.id] = {
          position: timer.position || 0,
          playing: timer.playing,
          duration: timer.duration
        };
      });
    });
  }

  onZoneChanged(data) {
    const carouselZoneItemIndex = data.item.index;

    if (carouselZoneItemIndex !== null) {
      this.zone = this.zonesWithPlayer[carouselZoneItemIndex];
      if (this.selectedZoneId !== this.zone.id) {
        this.selectedZoneId = this.zone.id;
        this.store.dispatch(new ZoneAction.SetCurrentZoneId(this.selectedZoneId));
      }
    }
  }

  openReportingModal() {
    const dialogRef = this.dialog.open(ReportingComponent);

    dialogRef.afterClosed()
      .subscribe((result) => {});
  }

  logout() {
    this.authService.logout().then((data) => {
      this.router.navigate(['/login']);
    });
  }

  goToVenueSettings() {
    this.router.navigate(['/venues/settings']);
  }

  selectMenuItem() {
    this.menuItemSelected.emit();
  }

  goToQueue() {
    this.dialog.open(TrackQueueComponent, {
      data: {
        zone: this.zone
      }
    });
  }

  ngOnDestroy() {
    this.unSubscribe$.next(true);
    this.unSubscribe$.complete();

    if (this.selectedVenueSub) {
      this.selectedVenueSub.unsubscribe();
    }

    if (this.venueUpdateSub) {
      this.venueUpdateSub.unsubscribe();
    }

    if (this.zonesSub) {
      this.zonesSub.unsubscribe();
    }

    if (this.timersSub) {
      this.timersSub.unsubscribe();
    }

    if (this.userSub) {
      this.userSub.unsubscribe();
    }

    if (this.venueSub) {
      this.venueSub.unsubscribe();
    }

    if (this.currentZoneSub) {
      this.currentZoneSub.unsubscribe();
    }
  }
}
