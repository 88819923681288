import gql from 'graphql-tag';

export const MinimalFragments = {
    fragments: {
        artist: gql`
            fragment ArtistMinData on Artist {
                id
                name
            }
        `,
        album: gql`
            fragment AlbumMinData on Album {
                id
                name
            }
        `,
        track: gql`
            fragment TrackMinData on Track {
                id
                name
                releaseYear
                artwork {
                    artworkUrl100
                    artworkUrl1000
                }
            }
        `,
        playlistSummary: gql`
            fragment PlaylistSummaryData on Playlist {
                id
                name
                duration
                logoImageUrl
            }
        `,
        playlist: gql`
            fragment PlaylistMinData on Playlist {
                id
                name
                generalGenre
                duration
                trackCount
                mediaType
                createdBy {
                    id
                    displayName
                    userNicename
                }
                updatedAt
                logoImageUrl
                isInLibrary(venueId: $venueId)
                isPublished
                isCatalog
            }
        `,
        bucket: gql`
            fragment BucketMinData on Bucket {
                id
                name
                duration
                trackCount
                createdAt
                updatedAt
            }
        `
    }
}
export const CombinedFragments = {
    fragments: {
        track: gql`
            fragment TrackData on Track {
                duration
                ...TrackMinData
                artist {
                ...ArtistMinData
                }
                album {
                ...AlbumMinData
                }
            }
            ${MinimalFragments.fragments.album}
            ${MinimalFragments.fragments.artist}
            ${MinimalFragments.fragments.track}
        `,
        paginationTrack: gql`
            fragment TrackConnection on TrackConnection {
                totalCount
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                edges {
                    node {
                        ...TrackMinData
                        artist {
                            ...ArtistMinData
                            likes
                            likedByMe
                            countOfTracks
                            artwork {
                                artworkUrl100
                            }
                        }
                        album {
                            ...AlbumMinData
                        }
                        playlists {
                            id
                            name
                        }
                        trackNo
                        duration
                        heartState(venueId: $venueId) {
                            like
                        }
                        isInLibrary(venueId: $venueId)
                    }
                    cursor
                }
            }
            ${MinimalFragments.fragments.album}
            ${MinimalFragments.fragments.artist}
            ${MinimalFragments.fragments.track}
        `
    }
}

export const PlaylistsMiniConnectionFragment = (additionOptions) => gql`
    fragment PlaylistConnection on PlaylistConnection {
        totalCount
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
        edges {
            node {
                ${additionOptions}
                ...PlaylistMinData
                tracks(first: 8) { # For artwork only.
                    artwork {
                        artworkUrl100
                    }
                }
            }
            cursor
        }
    }
    ${MinimalFragments.fragments.playlist}
`;

export const PlaylistsConnectionEssentialFragment = (additionOptions) => gql`
    fragment PlaylistConnection on PlaylistConnection {
        totalCount
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
        edges {
            node {
                ${additionOptions}
                ...PlaylistMinData
                tracksConnection(first: 8) { # For artwork only.
                    totalCount
                    pageInfo {
                        hasNextPage
                        endCursor
                    }
                    edges {
                        node {
                            name
                            artwork {
                                artworkUrl100
                            }
                            artist {
                                name
                            }
                            album {
                                name
                            }
                        }
                    }
                }
            }
            cursor
        }
    }
    ${MinimalFragments.fragments.playlist}
`;

export const PlaylistsConnectionFragment = (additionOptions) => gql`
    fragment PlaylistConnection on PlaylistConnection {
        totalCount
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
        edges {
            node {
                ${additionOptions}
                ...PlaylistMinData
                tracksConnection { # For artwork only.
                    totalCount
                    edges {
                        node {
                            name
                            artist {
                                name
                            }
                            album {
                                name
                            }
                            artwork {
                                artworkUrl100
                            }
                        }
                    }
                }
                tracks(first: 8) { # For artwork only.
                    artwork {
                        artworkUrl100
                    }
                }
            }
            cursor
        }
    }
    ${MinimalFragments.fragments.playlist}
`;

export const BucketConnectionFragment = gql`
    fragment BucketConnection on BucketConnection {
        totalCount
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
        edges {
            node {
                ...BucketMinData
                playlists {
                    id
                    name
                    trackCount
                    generalGenre
                    logoImageUrl
                    duration
                    tracksConnection(first: 8) { # For artwork only.
                        totalCount
                        edges {
                            node {
                              artwork {
                                artworkUrl100
                              }
                            }
                          }
                      }
                }
            }
            cursor
        }
    }
    ${MinimalFragments.fragments.bucket}
`;

export const PromotionFragment = {
    promotion: gql`
    fragment PromotionMinData on Promotion {
      id
      name
      duration
      contentUrl
      fileType
    }
  `,
    promotionSchedule: gql`
    fragment PromotionScheduleData on PromotionSchedule {
      id
      scope
      promotion {
        id
        name
        duration
      }
      zone {
        id
        name
      }
      venue {
        id
      }
      status
      weekDays {
        mon
        tue
        wed
        thu
        fri
        sat
        sun
      }
      fromDate
      toDate
      fromTime
      toTime
      promotionContentOrder
      policies {
        id
        policyType
        value
        status
      }
    }
  `
}
