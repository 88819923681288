import { Component, Inject, OnDestroy, OnInit, ViewChild, NgZone } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import { Subscription } from 'rxjs';

import { PlaylistService } from '../../../shared/services/playlist.service';
import { Playlist, Track } from '../../../shared/models/models';
import { NgScrollbar } from 'ngx-scrollbar';
import { filter, tap, map } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { NewPlaylistAction } from 'src/app/shared/store/new-playlists/new-playlists.actions';
import { PlaylistFormData } from 'src/app/shared/models/inner-models';
import { NewPlaylistsState } from 'src/app/shared/store/new-playlists/new-playlists.state';
import { ErrorParserService } from 'src/app/shared/services/error-parcing.service';
import { AlertDialogComponent } from 'src/app/shared/components/alert-dialog/alert-dialog.component';
import { SNACK_BAR_DURATION } from 'src/app/shared/models/consts';

interface DialogData {
  playlist: Playlist;
}

@Component({
  selector: 'app-edit-playlist-dialog',
  templateUrl: './edit-playlist-dialog.component.html',
  styleUrls: ['./edit-playlist-dialog.component.scss']
})
export class EditPlaylistDialogComponent implements OnInit, OnDestroy {
  @ViewChild(NgScrollbar, { static: false }) scrollbarRef: NgScrollbar;
  private playlistSub: Subscription;

  public playlist: Playlist;
  public tracks: Track[];
  public addTracksMode = false;
  public selectedTrackIds: Set<number> = new Set();

  public view: 'INFO' | 'TRACKS' = 'TRACKS';
  public isLoading = false;
  public errored = false;

  public loadMore: boolean = false;
  public last: string;

  private scrollSub: Subscription;

  constructor(
    private dialogRef: MatDialogRef<EditPlaylistDialogComponent>,
    private playlistService: PlaylistService,
    private ngZone: NgZone,
    private store: Store,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialog: MatDialog,
    private errorService: ErrorParserService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.isLoading = true;

    this.playlistService.getPlaylist(this.data.playlist.id, 10000).then((playlist) => {
      this.isLoading = false;
      this.playlist = playlist;

      this.setView('TRACKS');
    }).catch(() => this.errored = true);

    this.playlistSub = this.playlistService.onPlaylistChanged.subscribe((playlist) => {
      this.playlist = playlist;
    });

    this.last =
            this.data.playlist.tracksConnection.pageInfo.hasNextPage ?
            this.data.playlist.tracksConnection.pageInfo.endCursor : null;
  }

  ngAfterViewInit() {
    if (this.scrollbarRef) {
      this.scrollSub = this.scrollbarRef.scrolled.pipe(
        filter((e: any) => !this.loadMore && e.target.offsetHeight + e.target.scrollTop > e.target.scrollHeight - 10),
        tap(() => this.ngZone.run(() => {
          this.loadMore = true;
          this.getMoreTracks();
        }))
      ).subscribe();
    }
  }

  setView(view: 'INFO' | 'TRACKS') {
    this.view = view;
  }

  onDrop(event: CdkDragDrop<string[]>) {
    moveItemInArray(event.container.data,
      event.previousIndex,
      event.currentIndex);
  }

  backToPlaylistDetails() {
    this.addTracksMode = false;
    this.isLoading = false;
  }

  addTracks() {
    this.addTracksMode = true;
  }

  changePlaylistTracksOrder() {
    this.dialogRef.close();
  }

  onFormSave(playlistFormData: PlaylistFormData) {
    this.store.dispatch(new NewPlaylistAction.EditPlaylist(
      this.playlist.id,
      playlistFormData.playlistInput,
      playlistFormData.coverImage
    ))
    .subscribe(() => {
      this.playlist = this.store.selectSnapshot(NewPlaylistsState.lastUpdated);
      this.dialog.open(AlertDialogComponent, {
          data: {
            type: 'success',
            message: 'Playlist successfully updated'
          }
        });
      }, (err) => {
      const errIdStr = this.errorService.parseError(err);
      this.dialog.open(AlertDialogComponent, {
        data: {
          type: 'error',
          message: `Oops, something went wrong. Please try again.
          ${(!!errIdStr) ? ('Error id: ' + errIdStr) : ''}`
        }
      });
    });
  }

  onAddTracks(event) {
    if (this.playlist.id) {
      this.store.dispatch(new NewPlaylistAction.AddTracksToPlaylist(event, this.playlist.id))
      .subscribe(() => {
        this.addTracksMode = false;
        this.isLoading = false;
      }, () => this.errored = true);
    }
  }

  toggleTrackSelection(trackId) {
    if (this.selectedTrackIds.has(trackId)) {
      this.selectedTrackIds.delete(trackId);
    } else {
      this.selectedTrackIds.add(trackId);
    }
  }

  removePlaylistTracks() {
    const trackIds = Array.from(this.selectedTrackIds);
    this.store.dispatch(new NewPlaylistAction.RemoveTracksFromPlaylist(trackIds, this.playlist.id))
    .subscribe(() => {
      this.selectedTrackIds.clear();
      this._snackBar.open(
        `Tracks successfully removed from the playlist ${this.playlist.name}`,
        null,
        { duration: SNACK_BAR_DURATION }
      );
    }, () => this.errored = true);
  }

  getMoreTracks() {
    if (this.last) {
      this.playlistService.nextTrackPage(this.data.playlist.id, this.last)
        .pipe(
          tap((tracksConnection) => {
            this.last = tracksConnection.pageInfo.hasNextPage ? tracksConnection.pageInfo.endCursor : null;
          }),
          map(({edges}) => edges.map(({ node }) => node))
        )
        .subscribe((tracks: Track[]) => {
          this.tracks = [...this.tracks, ...tracks];
          this.loadMore = false;
        }, () => this.errored = true);
    }
  }

  ngOnDestroy() {
    this.playlistSub.unsubscribe();
    if (this.scrollSub) {
      this.scrollSub.unsubscribe();
    }
  }
}
