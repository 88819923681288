<div class="d-flex justify-content-center align-items-center login-page w-100 vh-100">
  <div class="login-page-wrapper">
    <div class="logo-wrapper">
      <h1 class="brend-name">Reset password</h1>
    </div>
    <div class="login-form-wrapper">
      <form [formGroup]="resetForm" (ngSubmit)="reset()">
        <div class="form-group">
          <label for="email">Email</label>
          <input id="email" inputmode="email" formControlName="email" type="email" placeholder="Enter Email">
        </div>
        <div class="controls-wrapper">
          <a routerLink=".." class="d-block text-center forgot-link">I remember my password</a>
          <div class="control-buttons d-flex justify-content-center p-0">
            <button type="submit" [disabled]="resetForm.invalid || resetInProgress" class="btn signin-btn p-0">Send new password</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
