import { Component, OnInit, Inject, OnDestroy, Input } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Track, Zone } from 'src/app/shared/models/models';
import { Subscription, Observable } from 'rxjs';
import { QueueType, QueueReadableType, TrackReadableType } from 'src/app/shared/gql/zone-consts';
import { Store, Select } from '@ngxs/store';
import { ZonesState } from 'src/app/shared/store/zones/zones.state';
import { MediaTypes, SNACK_BAR_DURATION } from 'src/app/shared/models/consts';
import { TracksQueueService } from 'src/app/shared/services/tracks-queue.service';
import { ConfirmationDialogComponent } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { AlertDialogComponent } from 'src/app/shared/components/alert-dialog/alert-dialog.component';
import { ErrorParserService } from 'src/app/shared/services/error-parcing.service';
import { TrackService } from 'src/app/shared/services/track.service';
import { ZoneService } from 'src/app/shared/services/zone.service';
import { Router } from '@angular/router';
import { SharedPlaylistListDialogComponent } from 'src/app/shared/components/playlist-list-dialog/playlist-list-dialog.component';

interface DialogData {
  zone: Zone;
}

@Component({
  selector: 'rhs-zone-history',
  templateUrl: './rhs-zone-history.component.html',
  styleUrls: ['./rhs-zone-history.component.scss']
})

export class RhsZoneHistoryComponent implements OnInit, OnDestroy {
  public tracks: { track: Track, type: typeof TrackReadableType }[] = [];
  public tracksToRemoveFromQueue: Track[] = [];
  public type = '';
  public typeReadable = ''; // human readable type name
  public mediaType: MediaTypes;
  public manualPlaylist = false;
  //@Input() curZone: Zone;
  
  private currentZone: Zone;
  private zoneSub: Subscription;
  @Select(ZonesState.currentZone) currentZone$: Observable<Zone>;

  showArtist: boolean = true;

  constructor(
    // public dialogRef: MatDialogRef<RhsTrackQueueComponent>,
    // @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private store: Store,
    // private tracksQueueService: TracksQueueService,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private errorService: ErrorParserService,
    private trackService: TrackService,
    private zoneService: ZoneService,
    private router: Router,
    ) { 
      this.currentZone$ = this.store.select(ZonesState.currentZone);
    }

  ngOnInit() {
    this.zoneSub = this.currentZone$
    .subscribe((zone) => {
      this.currentZone = zone;
      if (zone.playedHistory) {
	this.tracks = zone.playedHistory.edges.map(({ node: { track } }) => track);
	console.log("tracks history", this.tracks);
      }
    });
  }

  toggleLikeDislike(track, state: boolean) {
    if (track.heartState && track.heartState.like === state) {
      state = null;
    }

    this.trackService.setTrackHeartState(track.id, state)
      .then((heartState) => {
        track.heartState = heartState;
      });
  }

  addToPlaylist(track) {
    this.dialog.open(SharedPlaylistListDialogComponent, {
      data: {
        track: track,
        playlistsWithTrack: track.playlists.map((playlist) => playlist.id)
      }
    });
  }

  addTrackToLibrary(track) {
    this.trackService.addTrackToLibrary(track.id)
    .then((res) => {
      track.isInLibrary = true;
      this._snackBar.open(
        `Track successfully added to the favourites`,
        null,
        { duration: SNACK_BAR_DURATION }
      );
    })
    .catch((err) => {
      const errIdStr = this.errorService.parseError(err);
      this.dialog.open(AlertDialogComponent, {
        data: {
          type: 'error',
          message: `Oops, something went wrong. Please try again.
          ${(!!errIdStr) ? ('Error id: ' + errIdStr) : ''}`
        }
      });
    });
  }

  removeTrackFromLibrary(track) {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: 'Are you sure you want to remove the track from favourites?',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary'
          },
          {
            text: 'Remove',
            cssClass: 'primary',
            handler: () => {
              this.dialog.closeAll();
              this.trackService.removeTrackFromLibrary(track.id)
                .then(() => {
                  track.isInLibrary = false;
                  this._snackBar.open(
                    `Track successfully removed from the favourites`,
                    null,
                    { duration: SNACK_BAR_DURATION }
                  );
                })
                .catch((err) => {
                  const errIdStr = this.errorService.parseError(err);
                  this.dialog.open(AlertDialogComponent, {
                    data: {
                      type: 'error',
                      message: `Oops, something went wrong. Please try again.
                      ${(!!errIdStr) ? ('Error id: ' + errIdStr) : ''}`
                    }
                  });
                });
            }
          }
        ]
      },
      width: '340px'
    });
  }

  addToPlayingQueue(track) {
    // this.dialog.open(ZonesListComponent)
    //   .afterClosed()
    //   .subscribe(data => {
    //     if (!data) {
    //       return;
    //     }
    //     if (data.error) {
    //       this.dialog.open(AlertDialogComponent, {
    //         data: {
    //           type: 'error',
    //           message: data.error
    //         }
    //       });
    //     } else {
    //       this.confirmAddTrackInQueue(data.id);
    //     }
    //   });
    this.confirmAddTrackInQueue(this.currentZone.id, track.id);
  }

  confirmAddTrackInQueue(zoneId, trackId) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: 'The track will start after the current song finishes',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary'
          },
          {
            text: 'OK',
            cssClass: 'primary',
            handler: () => {
              this.zoneService.addTrackToPlayQueue(trackId, zoneId)
              .then(() => {
                dialogRef.close();
                this._snackBar.open(
                  `Track successfully added to the queue`,
                  null,
                  { duration: SNACK_BAR_DURATION }
                );
              })
              .catch((error) => {
                const errIdStr = this.errorService.parseError(error);
                dialogRef.close();
                this.dialog.open(AlertDialogComponent, {
                  data: {
                    type: 'error',
                    message: `Oops, something went wrong. Please try again.
                    ${(!!errIdStr) ? ('Error id: ' + errIdStr) : ''}`
                  }
                });
              });
            }
          }
        ]
      },
      width: '340px'
    });
  }

  goToArtist(track) {
    this.router.navigate(['/artist', track.artist.id]);
  }

  addTrackToPlaylist(track) {
    this.dialog.open(SharedPlaylistListDialogComponent, {
      data: {
        track: track,
        //playlistsWithTrack: track.playlists.map((playlist) => playlist.id),
	//here the track data does not have playlists field.
	playlistsWithTrack:[],
      }
    });
  }

  ngOnDestroy() {
    this.zoneSub.unsubscribe();
  }
}
