export default function paginationQueryTemplate(entityString) {
  return `
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ${entityString}
      }
    }
  `;
}
