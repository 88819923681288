<div *ngIf="addPlaylistsMode" class="add-playlists-section">
  <div class="dialog-header">
    <!-- <button (click)="backToBucketDetails()" class="btn back-btn"></button> -->
    <h2 mat-dialog-title>Add Playlists</h2>
    <button mat-dialog-close class="btn close-dialog-btn"></button>
  </div>

  <mat-dialog-content>

    <app-spinner *ngIf="isLoading" [isLoading]="isLoading" [errored]="errored" [minimal]="true"></app-spinner>

    <ng-container *ngIf="!isLoading">
      <input
        #searchInput
        *ngIf="playlists.length > 0"
        (keyup)="searchPlaylist(searchInput.value.trim())"
        placeholder="Search a Playlist"
        class="input-search"
        type="text"
      />

      <div *ngIf="playlists.length > 0 && filteredPlaylists.length === 0" class="no-playlists-found-message-container">
        <div class="message-wrapper">
          <div class="no-playlists-found-image"></div>
          <p>No Playlists found</p>
        </div>
      </div>

      <div *ngIf="filteredPlaylists.length > 0" class="playlist-list-container">
        <ng-scrollbar>
          <ul class="playlist-list">
            <li *ngFor="let playlist of filteredPlaylists" class="playlist-item-container">
              <app-playlist-preview-item
                (selected)="onPlaylistSelected($event)"
                [isSelected]="playlistsToAdd.has(playlist.id)"
                [playlist]="playlist"
                [isSelectable]="true"
                [isAdded]="false"
              >
              </app-playlist-preview-item>
            </li>
          </ul>
        </ng-scrollbar>
      </div>

      <div *ngIf="filteredPlaylists.length === 0 && playlists.length === 0" class="no-playlists-message text-uppercase">No Playlists available</div>
    </ng-container>

  </mat-dialog-content>

  <div mat-dialog-actions>
      <button class="shuffle"
	  [ngClass]="{
	      'hide': false,
	      'shuffle-active': isShuffled
	  }"
	  (click)="toggleShuffle()"
      >
      </button>
    <button (click)="addPlaylistsToBucket()" class="btn save-btn">Queue</button>
  </div>
</div>


