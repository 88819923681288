import { Component, OnInit, OnDestroy, Inject, ViewChild, NgZone, DoCheck } from '@angular/core';
import { MatLegacyDialog as MatDialog, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Subscription } from 'rxjs';

import { ConfirmationDialogComponent } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { CreateEditZoneComponent } from '../create-edit-zone/create-edit-zone.component';
import { ZoneService } from 'src/app/shared/services/zone.service';
import { PaginationInfo, Zone } from 'src/app/shared/models/models';
import { ActivationCodeModalComponent } from '../activation-code-modal/activation-code-modal.component';
import { Store } from '@ngxs/store';
import { VenueState } from 'src/app/shared/store/venue/venue.state';
import { filter, map, tap } from 'rxjs/operators';
import { NgScrollbar } from 'ngx-scrollbar';
import { ZoneAction } from 'src/app/shared/store/zones/zones.actions';
import { SNACK_BAR_DURATION } from 'src/app/shared/models/consts';
import { AlertDialogComponent } from 'src/app/shared/components/alert-dialog/alert-dialog.component';
import { ErrorParserService } from 'src/app/shared/services/error-parcing.service';

interface DialogData {
  venueId: number;
}

@Component({
  selector: 'app-zone-list',
  templateUrl: './zone-list.component.html',
  styleUrls: ['./zone-list.component.scss']
})
export class ZoneListComponent implements OnInit, OnDestroy, DoCheck {
  @ViewChild(NgScrollbar, { static: false }) scrollbarRef: NgScrollbar;
  private zonesSub: Subscription;
  private scrollSub: Subscription;
  private isCurrentVenue: boolean;

  public generatingCodeZoneId = -1;
  public zones: Zone[];
  public loadMore: boolean = false;
  public last: string;
  public isLoading: boolean = true;
  public errored: boolean;
  public activationErrored: boolean;

  constructor(
    private zoneService: ZoneService,
    private dialog: MatDialog,
    private store: Store,
    private ngZone: NgZone,
    private errorService: ErrorParserService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit() {
    this.isCurrentVenue = this.data.venueId === this.store.selectSnapshot(VenueState.venueId);

    this.zonesSub = this.zoneService.getZonesByVenue(this.data.venueId, 20)
    .pipe(
      tap((paginationInfo: PaginationInfo) => {
          this.last = paginationInfo.pageInfo.endCursor;
      }),
      map(({edges}) => edges.map(({node}) => node)),
    )
    .subscribe(
      (zones: Zone[]) => {
        this.zones = zones;
        this.isLoading = false;
      },
      () => this.errored = true
    )
  }

  ngDoCheck() {
    if (this.scrollbarRef && !this.scrollSub) {
      this.scrollSub = this.scrollbarRef.scrolled.pipe(
        filter((e: any) => !this.loadMore && e.target.offsetHeight + e.target.scrollTop > e.target.scrollHeight - 20),
        tap(() => this.ngZone.run(() => {
          this.loadMore = true;
          this.getMoreZones();
        }))
      ).subscribe();
    }
  }

  deleteZone(zone: Zone) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent , {
      data: {
        message: 'Are you sure you want to remove the zone?',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary'
          },
          {
            text: 'Delete',
            cssClass: 'danger',
            handler: () => {
              dialogRef.close();
              this.zoneService.deleteZone(zone.id)
                .subscribe(
                  () => {
                    this.zones = this.zones.filter((item) => item.id !== zone.id);
                    if (this.isCurrentVenue) {
                      this.store.dispatch(new ZoneAction.DeleteZone(zone.id));
                    }
                    this._snackBar.open(
                      `Zone successfully removed`,
                      null,
                      { duration: SNACK_BAR_DURATION }
                    );
                  },
                  (error) => {
                    this.errored = true;
                    const errIdStr = this.errorService.parseError(error);
                    this.dialog.open(AlertDialogComponent, {
                      data: {
                        type: 'error',
                        message: `Oops, something went wrong. Please try again.
                        ${(!!errIdStr) ? ('Error id: ' + errIdStr) : ''}`
                      }
                    });
                  }
                );
            }
          }
        ]
      },
      width: '340px'
    });
  }

  editZone(zone: Zone) {
    this.dialog.open(CreateEditZoneComponent, {
      data: {
        zone
      }
    })
    .afterClosed()
    .pipe(
      filter((zone?: Zone) => !!zone)
    )
    .subscribe((zone: Zone) => {
      const updetedIndex = this.zones.findIndex(({id}) => id === zone.id);
      this.zones.splice(updetedIndex, 1, zone);
      if (this.isCurrentVenue) {
        this.store.dispatch(new ZoneAction.EditZone(zone));
      }
    });
  }

  createZone() {
    this.dialog.open(CreateEditZoneComponent, {
      data: {
        venueId: this.data.venueId
      }
    })
    .afterClosed()
    .pipe(
      filter((zone?: Zone) => !!zone)
    )
    .subscribe((zone: Zone) => {
      this.zones = [zone, ...this.zones];
      if (this.isCurrentVenue) {
        this.store.dispatch(new ZoneAction.AddZone(zone));
      }
    });
    // TODO: error?
  }

  getActivationCode(zone: Zone) {
    this.generatingCodeZoneId = zone.id;

    this.zoneService.resetActivationCodeUsingDevCredentials(zone.id)
      .then((activationCode) => {
        this.generatingCodeZoneId = -1;

        this.dialog.open(ActivationCodeModalComponent, {
          data: {
            activationCode
          }
        });
      }).catch(() => this.activationErrored = true);
  }

  getMoreZones() {
    if (this.last) {
      this.zoneService.getZonesByVenue(this.data.venueId, 20, this.last)
        .pipe(
          tap((zoneConnection: PaginationInfo) => {
            this.last = zoneConnection.pageInfo.hasNextPage ? zoneConnection.pageInfo.endCursor : null;
          }),
          map(({edges}) => edges.map(({ node }) => node))
        )
        .subscribe((zones: Zone[]) => {
          this.zones = [...this.zones, ...zones];
          this.loadMore = false;
        });
    }
  }

  ngOnDestroy() {
    this.zonesSub.unsubscribe();

    if (this.scrollSub) {
      this.scrollSub.unsubscribe();
    }
  }
}
