import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';

import { AlertDialogComponent } from '../shared/components/alert-dialog/alert-dialog.component';
import { CommonModule } from '@angular/common';
import { ConfirmationDialogComponent } from '../shared/components/confirmation-dialog/confirmation-dialog.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FooterComponent } from './footer/footer.component';
import { FormatTimeModule } from '../shared/pipes/timer.pipe';
import { HeaderComponent } from './header/header.component';
import { ImagePreloadModule } from '../shared/directives/image-preload/image-preload.module';
import { ImageUploadModule } from '../shared/modules/image-upload.module';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { MainMenuModule } from './main-menu/main-menu.module';
import { RhsMenuModule } from './rhs-menu/rhs-menu.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MiniplayerComponent } from './miniplayer/miniplayer.component';
import { NgModule } from '@angular/core';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { OwlModule } from 'ngx-owl-carousel';
import { PermissionsModule } from './permissions/permissions.module';
import { PlayerItemComponent } from './user-menu/player-item/player-item.component';
import { ReportVenuesComponent } from './user-menu/reports/report-venues/report-venues.component';
import { ReportingComponent } from './user-menu/reports/reporting/reporting.component';
import { SpinnerModule } from './spinner/spinner.module';
import { SystemComponent } from './system.component';
import { SystemRoutingModule } from './system-routing.module';
import { TrackQueueComponent } from '../shared/components/track-queue/track-queue.component';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { VideoPlaylistModule } from './video-playlist/video-playlist.module';
import { ZoneHistoryModule } from '../shared/components/zone-history/zone-history.module';
import { ZonesCopyComponent } from '../shared/components/zone-copy-dialog/zone-copy-dialog.component';
import { ZonesListComponent } from '../shared/components/zone-list-dialog/zone-list-dialog.component';
import { PlayerCommandDialogComponent } from './miniplayer/player-command-dialog/player-command-dialog.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';

@NgModule({
    declarations: [
        SystemComponent,
        HeaderComponent,
        FooterComponent,
        UserMenuComponent,
        ConfirmationDialogComponent,
        AlertDialogComponent,
        ReportVenuesComponent,
        ReportingComponent,
        MiniplayerComponent,
        PlayerItemComponent,
        ZonesListComponent,
        ZonesCopyComponent,
        TrackQueueComponent,
        PlayerCommandDialogComponent
    ],
    imports: [
        CommonModule,
        SystemRoutingModule,
        MatSliderModule,
        MatDialogModule,
        MatSidenavModule,
        MatSlideToggleModule,
        NgScrollbarModule,
        FormsModule,
        ReactiveFormsModule,
        MatNativeDateModule,
        MatDatepickerModule,
        OwlModule,
        FormatTimeModule,
        DragDropModule,
        MatMenuModule,
        ImageUploadModule,
        PermissionsModule,
        ZoneHistoryModule,
        ImagePreloadModule,
        SpinnerModule,
        VideoPlaylistModule,
        RhsMenuModule,
        MainMenuModule
    ],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'en-AU' },
    ]
})

export class SystemModule { }
