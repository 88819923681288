import gql from 'graphql-tag';

const timeslotItem = `
  id
  from
  to
  day
  fromDateTime
  toDateTime
  playlist {
    id
    name
    mediaType
    trackCount
    duration
    logoImageUrl
    tracks {
      id
      artwork {
        artworkUrl100
      }
    }
  }
  bucket {
    id
    name
    trackCount
    duration
    playlists {
      id
      logoImageUrl
      tracks {
        id
        artwork {
          artworkUrl100
        }
      }
    }
  }
  schedule {
    id
  }
  copyOf {
    id
    day
  }
  repeatUntilDate
`;

export const timeslotsForRange = gql`
  query timeslots(
    $scheduleId: Int!
    $from: Date!
    $to: Date!
  ) {
    timeslots(
      scheduleId: $scheduleId
      from: $from
      to: $to
    ) {
      __typename
      ... on RepeatedTimeslot {
        day
        from
        to
        playlist {
          name
          id
        }
        bucket {
          id
          name
          trackCount
          duration
        }
        schedule {
          id
        }
        repeatOf {
          ${timeslotItem}
        }
      }
      ... on Timeslot {
        ${timeslotItem}
      }
    }
  }
`;

export const timeslotsForDay = gql`
  query timeslotsForDay(
    $scheduleId: Int!
    $day: Date
  ) {
    timeslotsForDay(
      scheduleId: $scheduleId
      day: $day
    ) {
      __typename
      ... on RepeatedTimeslot {
        day
        from
        to
        playlist {
          id
        }
        bucket {
          id
          name
          trackCount
          duration
        }
        schedule {
          id
        }
        repeatOf {
          ${timeslotItem}
        }
      }
      ... on Timeslot {
        ${timeslotItem}
      }
    }
  }
`;

export const createTimeslot = gql`
  mutation createTimeslot(
    $scheduleId: Int!
    $day: Date!
    $from: Time!
    $to: Time!
    $playlistId: Int
    $bucketId: Int
    $repeatUntilDate: Date
    $deleteOverlapped: Boolean
  ) {
    createTimeslot(
      scheduleId: $scheduleId,
      day: $day,
      from: $from,
      to: $to,
      playlistId: $playlistId
      bucketId: $bucketId
      repeatUntilDate: $repeatUntilDate
      deleteOverlapped: $deleteOverlapped
    ) {
      ${timeslotItem}
    }
  }
`;

export const createTimeslotsForRepeatedDay = gql`
  mutation createTimeslotsForRepeatedDay(
    $scheduleId: Int!
    $day: Date!
  ) {
    createTimeslotsForRepeatedDay(
      scheduleId: $scheduleId,
      day: $day
    ) {
      copyOf {
        id
        day
      }
      ${timeslotItem}
    }
  }
`;

export const updateTimeslot = gql`
  mutation updateTimeslot(
    $id: Int!
    $values: TimeslotInput!
    $deleteOverlapped: Boolean
    $freezeTimeslotsInAWeek: Boolean
  ) {
    updateTimeslot(
      id: $id,
      values: $values,
      deleteOverlapped: $deleteOverlapped
      freezeTimeslotsInAWeek: $freezeTimeslotsInAWeek
    ) {
      ${timeslotItem}
    }
  }
`;

export const updateRepeatedTimeslot = gql`
  mutation updateRepeatedTimeslot(
    $id: Int!
    $values: TimeslotInput!
    $deleteOverlapped: Boolean
    $freezeTimeslotsInAWeek: Boolean
    $day: Date!
  ) {
    updateRepeatedTimeslot(
      id: $id,
      values: $values,
      deleteOverlapped: $deleteOverlapped
      freezeTimeslotsInAWeek: $freezeTimeslotsInAWeek
      day: $day
    ) {
      ${timeslotItem}
    }
  }
`;

export const deleteTimeslot = gql`
  mutation deleteTimeslot(
    $id: Int!
    $includingRepeats: Boolean
  ) {
    deleteTimeslot(
      id: $id,
      includingRepeats: $includingRepeats
    )
  }
`;

export const copyTimeslotToDays = gql`
  mutation copyTimeslotToDays(
    $id: Int!
    $daysOfWeek: DaysOfWeekInput!
    $from: Date!
    $to: Date
    $repeatUntilDate: Date
  ) {
    copyTimeslotToDays(
      id: $id,
      daysOfWeek: $daysOfWeek,
      from: $from,
      to: $to
      repeatUntilDate: $repeatUntilDate
    )
  }
`;

export const copyAllTimeslotsInDayToDays = gql`
  mutation copyAllTimeslotsInDayToDays(
    $scheduleId: Int!
    $day: Date
    $daysOfWeek: DaysOfWeekInput!
    $from: Date
    $to: Date
  ) {
    copyAllTimeslotsInDayToDays(
      scheduleId: $scheduleId,
      day: $day,
      daysOfWeek: $daysOfWeek,
      from: $from,
      to: $to
    )
  }
`;

export const getTimeslotWeekDays = gql`
  query timeslot(
    $id: Int!
  ) {
    timeslot(
      id: $id
    ) {
      ... on Timeslot {
        matchesInWeekFromTimeslotDay {
          mon,
          tue,
          wed,
          thu,
          fri,
          sat,
          sun
        }
        from
        to
        fromDateTime
        toDateTime
        repeatUntilDate
      }
    }
  }
`;

export const overlappedTimeslots = gql`
  query overlappedTimeslots(
    $scheduleId: Int!,
    $day: Date!,
    $from: Time!,
    $to: Time!
  ) {
    overlappedTimeslots(
      scheduleId: $scheduleId,
      day: $day,
      from: $from,
      to: $to
    ) {
      __typename
      ... on RepeatedTimeslot {
        day
        playlist {
          id
          name
        }
        bucket {
          id
          name
        }
        repeatOf {
          id
        }

      }
      ... on Timeslot {
        id
        day
        playlist {
          id
          name
        }
        bucket {
          id
          name
        }
      }
    }
  }
`;
