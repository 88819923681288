import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

import { ImageCropperModule } from 'ngx-image-cropper';

import { ImageUploadDialogComponent } from '../components/image-upload-dialog/image-upload-dialog.component';

@NgModule({
    declarations: [
        ImageUploadDialogComponent
    ],
    imports: [
        CommonModule,
        ImageCropperModule,
        MatDialogModule
    ]
})
export class ImageUploadModule { }
