<div class="dialog-header">
  <h2 class="p-0 m-0" mat-dialog-title>{{ dialogTypeTitle[data.type] }}</h2>
</div>
<div mat-dialog-content>{{ data.message }}</div>
<div class="list-view" *ngIf="data.list">
  <ul>
    <li class="list-item" *ngFor="let item of data.list">{{item}}</li>
  </ul>
</div>
<div mat-dialog-actions>
  <button mat-dialog-close class="btn primary">Ok</button>
</div>
