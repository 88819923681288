import { Zone } from 'src/app/shared/models/models';

export namespace ZoneAction {
  export class GetZones {
    static type = '[Zone] Get Zones';

    constructor(
      public venueId?: number,
      public count?: number
    ) {}
  }

  export class GetMoreZones {
    static type = '[Zone] Get More Zones';

    constructor(
      public venueId?: number,
      public count?: number
    ) {}
  }

  export class ZoneChangeSubscription {
    static type = '[Zone] Zone Change Subscription';

    constructor (
      public zoneId: number
    ) {}
  }

  export class ZonePlayerEventChangeSubscription {
    static type = '[Zone] Zone Player Event Subscription';

    constructor (
      public zoneId: number
    ) {}
  }

  export class MonitorModeSubscription {
    static type = '[Zone] MonitorMode Subscription';

    constructor (
      public zoneId: number
    ) {}
  }

  export class VideoPlayerSubscription {
    static type = '[Zone] Video Player Subscription';

    constructor (
      public playerId: number
    ) {}
  }

  export class CloseMonitorModeSubscription {
    static type = '[Zone] Close MonitorMode Subscription';

    constructor (
      public zoneId: number
    ) {}
  }

  export class AddZone {
    static type = '[Zone] Add';

    constructor(
      public zone: Zone
    ) {}
  }

  export class EditZone {
    static type = '[Zone] Edit';

    constructor(
      public zone: Zone
    ) {}
  }

  export class DeleteZone {
    static type = '[Zone] Delete';

    constructor(
      public id: number
    ) {}
  }


  export class SetCurrentZoneId {
    static type = '[Zone] Set Current Zone Id';

    constructor(
      public id?: number
    ) {}
  }

  export class ClearZoneData {
    static type = '[Zone] Clear Zone Data';
  }

  export class UpdateState {
    static type = '[Zone] Update Zone State';
  }

  export class GetJukeos {
    static type = '[Zone] Get Jukeos';

    constructor(
      public venueId?: number,
    ) {}
  }

  export class UpdateJukeoSettings {
    static type = '[Zone] Update Jukeo Settings';

    constructor(
      public zoneIds: number[],
      public enable: boolean,
      public venueId?: number,
    ) {}
  }

  export class GetJukeoZonePlaylists {
    static type = '[Zone] Get Jukeo Playlists';

    constructor(
      public zoneIds: number[],
      public venueId?: number,
    ) {}
  }

  export class JukeoSetZonePlaylists {
    static type = '[Zone] Set Jukeo Playlists';

    constructor(
      public zoneId: number,
      public playlistIds: number[],
      public venueId?: number,
    ) {}
  }

  export class GetJukeoZoneTracks {
    static type = '[Zone] Get Jukeo Tracks';

    constructor(
      public zoneIds: number[],
      public venueId?: number,
    ) {}
  }

  export class JukeoSetZoneTracks {
    static type = '[Zone] Set Jukeo Tracks';

    constructor(
      public zoneId: number,
      public playlistIds: number[],
      public venueId?: number,
    ) {}
  }
}
