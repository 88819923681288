<div class="dialog-header">
  <h2 mat-dialog-title>Upload image</h2>
  <button mat-dialog-close class="btn close-dialog-btn"></button>
</div>

<mat-dialog-content [class.image-loaded]="isImageLoaded">
  <div class="input-file-container d-flex">
    <div class="input-file-wrapper">
      <input
        (change)="fileChangeEvent($event)"
        id="file"
        type="file"
        class="input-file w-0 h-0 overflow-hidden position-absolute"
      />
      <label class="btn upload-btn m-0" for="file">Choose a file...</label>
    </div>
    <p class="max-file-size-message">Maximum upload file size: {{ MAX_FILE_SIZE_STR }}</p>
  </div>

  <div class="image-container">
    <image-cropper
      [imageChangedEvent]="imageChangedEvent"
      [aspectRatio]="data && data.aspectRatio || 1"
      format="jpeg"
      [imageQuality]="60"
      outputType="both"
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded()"
      (loadImageFailed)="loadImageFailed()"
      [style.display]="isImageLoaded ? null : 'none'"
    >
    </image-cropper>
  </div>

  <p *ngIf="fileName" [title]="fileName" class="file-name text-truncate">{{ fileName }}</p>
</mat-dialog-content>

<div mat-dialog-actions>
  <button (click)="saveImage()" class="save-btn btn">Ok</button>
  <button mat-dialog-close class="cancel-btn btn">Cancel</button>
</div>
