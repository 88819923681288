import { Injectable } from '@angular/core';

import { filter, map, take } from 'rxjs/operators';
import { BehaviorSubject, timer } from 'rxjs';

import { Timer } from '../models/models';

@Injectable({
  providedIn: 'root'
})
export class TimerService {
  private timers: Timer[] = [];

  public onTimersChanged: BehaviorSubject<Timer[]> = new BehaviorSubject<Timer[]>([]);

  constructor() { }

  changeTimer(zoneId: number, playing: boolean, duration: number, position?: number) {
    const curTimerId = this.timers.findIndex(x => x.id === zoneId);
    if (curTimerId > -1) {
      const curTimer = this.timers[curTimerId];

      curTimer.position = position || 0;
      curTimer.duration = duration;
      curTimer.playing = playing;

      this.onTimersChanged.next(this.timers);
    } else {
      this.newTimerAdd(zoneId);
    }
  }

  newTimerAdd(zoneId: number) {
    this.timers.push({
      id: zoneId,
      playing: false,
      position: 0,
      duration: 0
    });

    this.onTimersChanged.next(this.timers);
  }
}
