<div class="dialog-header">
  <h2 mat-dialog-title>Add Playlist to Buckets</h2>
  <button mat-dialog-close class="btn close-dialog-btn"></button>
</div>

<mat-dialog-content>

  <ng-template #spinner>
    <app-spinner [isLoading]="isLoading" [errored]="errored" [minimal]="true"></app-spinner>
  </ng-template>

  <div *ngIf="!isLoading; else spinner" class="dialog-content-wrapper">

    <a (click)="createNewBucket()" class="create-bucket-link d-flex align-items-center">
      <div class="icon icon-plus"></div>
      <span class="link-label">Create New Bucket</span>
    </a>

    <div *ngIf="buckets && buckets.length > 0; else noBuckets">
      <input
        #searchInput
        (keyup)="searchBucket(searchInput.value.trim())"
        placeholder="Search a Bucket"
        class="input-search"
        type="text"
      />

      <div *ngIf="filteredBuckets.length > 0; else noBucketsFounded" class="bucket-list-container">
        <ng-scrollbar>
          <ul class="bucket-list">
            <li
              *ngFor="let bucket of filteredBuckets"
              class="bucket-item-container"
            >
              <app-bucket-preview-item
                [bucket]="bucket"
                [isSelected]="selectedBucketsId.has(bucket.id) || loadedPlaylistsInBucket.has(bucket.id)"
                (selected)="onBucketSelected($event)"
              >
              </app-bucket-preview-item>
            </li>
          </ul>
        </ng-scrollbar>
      </div>

      <ng-template #noBucketsFounded>
        <div class="no-buckets-found-message-container">
          <div class="message-wrapper">
            <div class="no-buckets-found-image"></div>
            <p>No Bucket found</p>
          </div>
        </div>
      </ng-template>

    </div>

    <ng-template #noBuckets>
      <div class="no-buckets-message text-uppercase">
        No Buckets available
      </div>
    </ng-template>

  </div>

</mat-dialog-content>

<div mat-dialog-actions>
  <button mat-dialog-close class="btn cancel-btn">Cancel</button>
  <button (click)="addPlaylistToBuckets()" class="btn add-btn">Add</button>
</div>

