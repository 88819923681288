<div class="dialog-wrapper">
  <div class="dialog-header">
    <h2 mat-dialog-title>Reporting</h2>
    <button mat-dialog-close class="btn close-dialog-btn"></button>
  </div>

  <mat-dialog-content>
    <div class="venue-modal">

      <div class="d-flex justify-content-between">
        <div class="flex-col f-1 disabled">
          <div class="btn-custom-extralarge text-center">
            <div class="icon-item m-auto icon-report-light"></div>
            <span class="d-inline-block">Report Based</span>
          </div>
        </div>

        <div class="flex-col f-1">
          <div class="btn-custom-extralarge text-center" (click)="openReportPlayedTracksModal()">
            <div class="icon-item m-auto icon-notes-light"></div>
            <span class="d-inline-block">Played Tracks</span>
          </div>
        </div>

        <div class="flex-col f-1 disabled">
          <div class="btn-custom-extralarge text-center" (click)="openReportVenuesModal()">
            <div class="icon-item m-auto icon-map-flag-light"></div>
            <span class="d-inline-block">List of Active Venues</span>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>
