import { CombinedFragments, MinimalFragments, PlaylistsConnectionEssentialFragment, PlaylistsConnectionFragment } from './fragments';

import gql from 'graphql-tag';

export const promotedPlaylists = gql`
  query promotedPlaylists(
    $venueId: Int!
  ) {
    promotedPlaylists {
      id
      name
      generalGenre
      duration
      createdAt
      updatedAt
      isInLibrary(venueId: $venueId)
      logoImageUrl
      trackCount
      isCatalog
      tracks {
        ...TrackData
      }
    }
  }
  ${CombinedFragments.fragments.track}
`;

export const lovedPlaylists = gql`
  query lovedPlaylists(
    $venueId: Int!
  ) {
    lovedPlaylists{
      id
      name
      generalGenre
      duration
      createdAt
      updatedAt
      isInLibrary(venueId: $venueId)
      logoImageUrl
      trackCount
      isCatalog
      tracks {
        ...TrackData
      }
    }
  }
  ${CombinedFragments.fragments.track}
`;

export const getMyPlaylistsEssential = gql`
  query playlistsConnection(
    $venueId: Int!
    $first: Int
    $after: Cursor
    $last: Int
    $before: Cursor
    $mediaType: MediaType
    $nameLike: String
  ) {
    playlistsConnection(
      venueId: $venueId
      first: $first
      after: $after
      last: $last
      before: $before
      mediaType: $mediaType
      nameLike: $nameLike
    ) {
      ...PlaylistConnection
    }
  }
  ${PlaylistsConnectionEssentialFragment('')}
`;

export const getMyPlaylistsMini = gql`
  query playlistsConnection(
    $venueId: Int!
    $first: Int
    $after: Cursor
    $last: Int
    $before: Cursor
    $mediaType: MediaType
  ) {
    playlistsConnection(
      venueId: $venueId
      first: $first
      after: $after
      last: $last
      before: $before
      mediaType: $mediaType
    ) {
      ...PlaylistConnection
    }
  }
  ${PlaylistsConnectionFragment('')}
`;

export const getMyPlaylists = gql`
  query playlistsConnection(
    $venueId: Int!
    $first: Int
    $after: Cursor
    $last: Int
    $before: Cursor
    $mediaType: MediaType
  ) {
    playlistsConnection(
      venueId: $venueId
      first: $first
      after: $after
      last: $last
      before: $before
      mediaType: $mediaType
    ) {
      ...PlaylistConnection
    }
  }
  ${PlaylistsConnectionFragment('description artists{ id name }')}
`;

export const getPlaylistsForBucket = gql`
  query venuePlaylists(
    $id: Int!
    $firstTracks: Int
    $venueId: Int!
  ) {
    venue(id: $id) {
      id
      playlists {
        ...PlaylistMinData
        tracks {
          ...TrackData
          genre {
              id
              name
          }
        }
        tracksConnection(first: $firstTracks) {
          ...TrackConnection
        }
      }
    }
  }
  ${CombinedFragments.fragments.track}
  ${CombinedFragments.fragments.paginationTrack}
  ${MinimalFragments.fragments.playlist}
`;

export const fullPlaylist = gql`
  query playlist(
    $id: Int!,
    $venueId: Int!
    $first: Int
  ) {
    playlist(id: $id) {
      id
      name
      generalGenre
      duration
      trackCount
      logoImageUrl
      isPublished
      isCatalog
      mediaType
      channels {
        id
      }
      createdBy {
        id
      }
      description
      promoted {
        start
        end
      }
      loved {
        start
        end
      }
      tracksConnection(first: $first) {
        ...TrackConnection
      }
      tracks {
        id
        name
        playlists {
          id
          name
        }
        artist {
          id
          name
          likes
          likedByMe
          countOfTracks
          artwork {
            artworkUrl100
          }
        }
        album {
          ...AlbumMinData
        }
        trackNo
        duration
        heartState(venueId: $venueId) {
          like
        }
        artwork {
          artworkUrl100
          artworkUrl1000
        }
        releaseYear
        isInLibrary(venueId: $venueId)
      }
      isInLibrary(venueId: $venueId)
    }
  }
  ${MinimalFragments.fragments.album}
  ${CombinedFragments.fragments.paginationTrack}
`;

export const nextTrackPage = gql`
  query playlistTrackPage(
    $id: Int!,
    $venueId: Int!,
    $after: Cursor,
    $first: Int
  ) {
    playlist(id: $id) {
      tracksConnection(first: $first, after: $after) {
        ...TrackConnection
      }
    }
  }
  ${CombinedFragments.fragments.paginationTrack}
`;

export const playlistToLibrary = gql`
  mutation addCatalogPlaylistToVenue(
    $playlistId: Int!
    $venueId: Int!
  ) {
    addCatalogPlaylistToVenue(
      playlistId: $playlistId
      venueId: $venueId
    )
  }
`;

export const playlistToBucket = gql`
  mutation addPlaylistToBucket(
    $playlistId: Int!
    $bucketId: Int!
  ) {
    addPlaylistToBucket(
      playlistId: $playlistId
      bucketId: $bucketId
    )
  }
`;

export const playlistsToBucket = gql`
  mutation addPlaylistsToBucket(
    $playlistIds: [Int!]!
    $bucketId: Int!
  ) {
    addPlaylistsToBucket(
      playlistIds: $playlistIds
      bucketId: $bucketId
    )
  }
`;

export const createPlaylist = gql`
  mutation createPlaylist(
    $name: String!
    $venueId: Int!
    $colourCode: String
    $spotifyPlaylistId: String
    $mediaType: MediaType
  ) {
    createPlaylist(
      name: $name
      venueId: $venueId
      colourCode: $colourCode
      spotifyPlaylistId: $spotifyPlaylistId
      mediaType: $mediaType
    ) {
      id
      name
      trackCount
      generalGenre
      duration
      createdAt
      updatedAt
      logoImageUrl
      mediaType
      isCatalog
      isInLibrary(venueId: $venueId)
    }
  }
`;

export const updatePlaylist = gql`
  mutation updatePlaylist(
    $id: Int!
    $values: PlaylistInput!
    $venueId: Int!
    $firstTracks: Int
  ) {
    updatePlaylist(
      id: $id
      values: $values
    ) {
      ...PlaylistMinData
      description
      promoted {
        start
        end
      }
      loved {
        start
        end
      }
      tracks {
        ...TrackData
        genre {
            id
            name
        }
      }
      tracksConnection(first: $firstTracks) {
        ...TrackConnection
      }
    }
  }
${MinimalFragments.fragments.playlist}
${CombinedFragments.fragments.track}
${CombinedFragments.fragments.paginationTrack}
`;

export const removeTrackFromPlaylist = gql`
  mutation removeTrackFromPlaylist(
    $trackId: Int!
    $playlistId: Int!
  ) {
    removeTrackFromPlaylist(
      playlistId: $playlistId
      trackId: $trackId
    )
  }
`;

export const deletePlaylistFromLibrary = gql`
  mutation deletePlaylist(
    $id: Int!
  ) {
    deletePlaylist(
      id: $id
    )
  }
`;

export const removePlaylistFomLibrary = gql`
  mutation removeCatalogPlaylistFromVenue(
    $playlistId: Int!
    $venueId: Int!
  ) {
    removeCatalogPlaylistFromVenue(
      playlistId: $playlistId
      venueId: $venueId
    )
  }
`;

export const deletePlaylistFromBucket = gql`
  mutation removePlaylistFromBucket(
    $playlistId: Int!
    $bucketId: Int!
  ) {
    removePlaylistFromBucket (
      playlistId: $playlistId
      bucketId: $bucketId
    )
  }
`;

export const createSpotifyPlaylistImport = gql`
  mutation createSpotifyPlaylistImport(
    $venueId: Int!
    $name: String!
    $spotifyPlaylistId: String!
  ) {
    createSpotifyPlaylistImport(
      venueId: $venueId
      name: $name
      spotifyPlaylistId: $spotifyPlaylistId
    ) {
      id
      spotifyPlaylistId
      importedAsPlaylist {
        id
        name
      }
    }
  }
`;

export const addTracksToSpotifyPlaylistImport = gql`
  mutation addTracksToSpotifyPlaylistImport(
    $spotifyPlaylistImportId: Int!
    $tracks: [SpotifyPlaylistImportTrackMatchInput!]!
  ) {
    addTracksToSpotifyPlaylistImport(
      spotifyPlaylistImportId: $spotifyPlaylistImportId
      tracks: $tracks
    ) {
      id
      spotifyTrackId
      firstMatchingTrack {
        ...TrackData
        genre {
            id
            name
        }
      }
    }
  }
${CombinedFragments.fragments.track}
`;

export const matchSpotifyPlaylistTracks = gql`
  mutation matchSpotifyPlaylistTracks(
  $tracks: [SpotifyPlaylistImportTrackMatchInput!]!
  ) {
    matchSpotifyPlaylistTracks(
      tracks: $tracks
    ) {
      isrc
      spotifyTrackId
      firstMatchingTrack {
        ...TrackData
        genre {
          id
          name
        }
      }
    }
  }
${CombinedFragments.fragments.track}
`

export const createPlaylistFromSpotifyPlaylistImport = gql`
  mutation createPlaylistFromSpotifyPlaylistImport(
    $spotifyPlaylistImportId: Int!
    $colourCode: String
  ) {
    createPlaylistFromSpotifyPlaylistImport(
      spotifyPlaylistImportId: $spotifyPlaylistImportId
      colourCode: $colourCode
    ) {
      id
      name
      generalGenre
      duration
      updatedAt
      trackCount
      isCatalog
      createdBy {
        id
      }
      tracks {
        ...TrackData
        trackNo
      }
    }
  }
  ${CombinedFragments.fragments.track}
`;

export const playlistCoverImage = gql`
  query playlist(
    $id: Int!
  ) {
    playlist(id: $id) {
      id
      logoImageUrl
    }
  }
`;

export const newPlaylistsConnection = gql`
  query newPlaylistsConnection(
    $first: Int
    $after: Cursor
    $venueId: Int!
    $withUnpublished: Boolean
    $mediaType: MediaType
  ) {
    newPlaylistsConnection (
      first: $first
      after: $after
      withUnpublished: $withUnpublished
      mediaType: $mediaType
    ) {
      ...PlaylistConnection
    }
  }
${PlaylistsConnectionFragment(`description promoted { start end } loved { start end } channels { id }`)}
`;

export const curationPlaylistsConnection = gql`
  query curationPlaylistsConnection(
    $first: Int
    $after: Cursor
    $venueId: Int!
    $withUnpublished: Boolean
    $mediaType: MediaType
  ) {
    curationPlaylistsConnection (
      first: $first
      after: $after
      withUnpublished: $withUnpublished
      mediaType: $mediaType
    ) {
      ...PlaylistConnection
    }
  }
${PlaylistsConnectionFragment(`description promoted { start end } loved { start end } channels { id } publishedAt artists{ id name }`)}
`;

export const createNewPlaylist = gql`
  mutation createCatalogPlaylist(
    $name: String!
    $colourCode: String
    $spotifyPlaylistId: String
    $firstTracks: Int
    $venueId: Int!
    $isPublished: Boolean
    $description: String
    $promoted: DateIntervalInput
    $loved: DateIntervalInput
    $mediaType: MediaType
    $channelId: Int
  ) {
    createCatalogPlaylist(
      name: $name
      colourCode: $colourCode
      spotifyPlaylistId: $spotifyPlaylistId
      isPublished: $isPublished
      description: $description
      promoted: $promoted
      loved: $loved
      mediaType: $mediaType
      channelId: $channelId
    ) {
      ...PlaylistMinData
      description
      promoted {
        start
        end
      }
      loved {
        start
        end
      }
      tracks {
        ...TrackData
        genre {
            id
            name
        }
      }
      tracksConnection(first: $firstTracks) {
        ...TrackConnection
      }
    }
  }
${MinimalFragments.fragments.playlist}
${CombinedFragments.fragments.track}
${CombinedFragments.fragments.paginationTrack}
`;

export const channelTypes = gql`
  query channelsType {
    channels {
      id
      name
    }
  }
`;

export const updatePlaylistTracks = gql`
  query playlistForTracks(
    $id: Int!
  ) {
    playlist(id: $id) {
      id
      generalGenre
      duration
      trackCount
      updatedAt
      logoImageUrl
      tracks {
        id
        artwork {
          artworkUrl100
          artworkUrl1000
        }
      }
    }
  }
`;

export const channels = gql`
  query channels (
    $firstTracks: Int
    $venueId: Int!
  ) {
    channels {
      id
      name
      playlists {
        ...PlaylistMinData
        tracks {
          ...TrackData
          genre {
            id
            name
          }
        }
        tracksConnection(first: $firstTracks) {
          ...TrackConnection
        }
      }
    }
  }
${MinimalFragments.fragments.playlist}
${CombinedFragments.fragments.track}
${CombinedFragments.fragments.paginationTrack}
`;

export const removeMultipleCatalogPlFromLibrary = gql`
  mutation removeCatalogPlaylistsFromVenue(
    $playlistIds: [Int]!
    $venueId: Int!
  ) {
    removeCatalogPlaylistsFromVenue(
      playlistIds: $playlistIds
      venueId: $venueId
    )
  }
`;

export const deletePlaylists = gql`
  mutation deletePlaylists(
    $ids: [Int!]!
  ) {
    deletePlaylists(
      ids: $ids
    )
  }
`;

export const addCatalogPlaylistsToVenue = gql`
  mutation addCatalogPlaylistsToVenue(
    $playlistIds: [Int!]
    $venueId: Int!
  ) {
    addCatalogPlaylistsToVenue(
      playlistIds: $playlistIds
      venueId: $venueId
    ) {
      ...PlaylistMinData
      description
      artists {
        id
        name
      }
      tracks {
        ...TrackData
        genre {
            id
            name
        }
      }
    }
  }
${MinimalFragments.fragments.playlist}
${CombinedFragments.fragments.track}
`;

export const resolveTracks = gql`
  query resolveTracks(
    $input: [ResolveTrackInput!]!
  ) {
    resolveTracks(
      input: $input
    ) {
      search {
        ref
        isrc
        trackName
        artistName
      }
      matches {
        textScore
        track {
          id
          artist {
            name
          }
          album {
            name
          }
        }
      }
    }
  }
`;

export const searchPlaylists0 = gql`
query catSearch($size: Int, $search: String!, $genre: String) {
  searchCatalogue(size: $size, search: $search, genre: $genre) {
    playlists {
      description
      artists {
        id
        name
      }
      id
      name
      generalGenre
      duration
      trackCount
      mediaType
      createdBy {
        id
        displayName
        userNicename
      }
      updatedAt
      logoImageUrl
      venue {
        name
      }
      isPublished
      isCatalog
    }
  }
}
`;

export const searchPlaylists = gql`
query catSearch($size: Int, $search: String!, $genre: String, $venueId:Int!) {
  searchCatalogue(size: $size, search: $search, genre: $genre) {
    playlists {
      id
      name
      duration
      trackCount
      mediaType
      isInLibrary(venueId: $venueId)
      isPublished
      isCatalog
    }
  }
}
`;

const PlaylistsConnectionFragmentEssential = () => gql`
  fragment PlaylistConnectionMini on PlaylistConnection {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
      __typename
    }
    edges {
      node {
        id
        name
        description
        generalGenre
        duration
        trackCount
        isInLibrary(venueId: $venueId)
        logoImageUrl
        tracksConnection(first: 4) { # For artwork only.
            totalCount
            edges {
                node {
                    artwork {
                        artworkUrl100
                    }
                }
            }
        }
      }
    }
  }
`;

const PlaylistsConnectionFragmentMini = () => gql`
  fragment PlaylistConnectionMini on PlaylistConnection {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
      __typename
    }
    edges {
      node {
        id
        name
        description
        generalGenre
        duration
        trackCount
        isInLibrary(venueId: $venueId)
        logoImageUrl
        tracksConnection(first: 4) { # For artwork only.
            totalCount
            edges {
                node {
                    artwork {
                        artworkUrl100
                    }
                }
            }
        }
        tracks(first: 8) { # For artwork only.
          artwork {
              artworkUrl100
          }
        }
      }
    }
  }
`;

export const curationPlaylistsConnectionEssential = gql`
  query curationPlaylistsConnection(
    $first: Int
    $after: Cursor
    $venueId: Int!
    $withUnpublished: Boolean
    $mediaType: MediaType
  ) {
    curationPlaylistsConnection (
      first: $first
      after: $after
      withUnpublished: $withUnpublished
      mediaType: $mediaType
    ) {
      ...PlaylistConnectionMini
    }
  }
  ${PlaylistsConnectionFragmentEssential()}
`;

export const curationPlaylistsConnectionMini = gql`
  query curationPlaylistsConnection(
    $first: Int
    $after: Cursor
    $venueId: Int!
    $withUnpublished: Boolean
    $mediaType: MediaType
  ) {
    curationPlaylistsConnection (
      first: $first
      after: $after
      withUnpublished: $withUnpublished
      mediaType: $mediaType
    ) {
      ...PlaylistConnectionMini
    }
  }
  ${PlaylistsConnectionFragmentMini()}
`;
