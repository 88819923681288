<div *ngIf="!addTracksMode" class="playlist-section">
  <div class="dialog-header">
    <h2 mat-dialog-title>Edit Playlist</h2>
    <div class="buttons">
      <button (click)="addTracks()" class="btn add-playlist-btn">Add Tracks</button>
      <button mat-dialog-close class="btn close-dialog-btn"></button>
    </div>
  </div>

  <mat-dialog-content>
    <div *ngIf="!isLoading; else spinner">
      <div *ngIf="playlist">
        <div class="switcher">
          <label>
            <input [checked]="view === 'INFO'" (change)="setView('INFO')" value="INFO" type="radio" />
            <div class="label">Info</div>
          </label>

          <label>
            <input [checked]="view === 'TRACKS'" (change)="setView('TRACKS')" value="TRACKS" type="radio" />
            <div class="label">Tracks</div>
          </label>
        </div>

        <div *ngIf="view === 'INFO'" class="playlist-info-container">
          <app-playlist-form
            [playlist]="playlist"
            mode="edit"
            (onSaved)="onFormSave($event)"
          >
          </app-playlist-form>
        </div>

        <div *ngIf="view === 'TRACKS'" class="track-list-container">
          <div *ngIf="playlist.tracks?.length; else noTracks" class="lists-wrapper">
            <div class="track-list-wrapper edit-mode">
              <div class="track-list-header">
                <span>Title</span>
                <span>Artist</span>
                <span>Album</span>
              </div>

              <ng-scrollbar appearance=true class="track-list" cdkDropList [cdkDropListData]="playlist.tracks" (cdkDropListDropped)="onDrop($event)">
                <div cdkDrag
                  *ngFor="let track of playlist.tracks"
                  class="edit-mode track-items-wrapper"
                  [class.selected]="selectedTrackIds.has(track.id)"
                >
                  <div class="track-item-wrapper">
                    <div class="icon-wrapper">
                      <div
                        (click)="toggleTrackSelection(track.id)"
                        class="icon"
                        [ngClass]="{'icon-delete': !selectedTrackIds.has(track.id), 'icon-plus': selectedTrackIds.has(track.id)}"
                      >
                      </div>
                    </div>
                    <div class="track-cover">
                      <img
                        [src]="(track.artwork && track.artwork.artworkUrl100) ?
                              track.artwork.artworkUrl100 : '/assets/images/default-cover.jpg'"
                        appDefault
                        alt="track-cover"
                      />
                    </div>
                    <div class="track-details">
                      <div class="track-title text-truncate">
                        <span [title]="track.name">{{ track.name }}</span>
                      </div>
                      <div class="track-artist text-truncate">
                        <span [title]="track.artist.name">{{ track.artist.name }}</span>
                      </div>
                      <div class="track-album text-truncate">
                        <span [title]="track.album.name">{{ track.album.name }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="draggable" cdkDragHandle>
                    <div class="drag-handle"></div>
                  </div>
                </div>
              </ng-scrollbar>

            </div>
          </div>

        </div>
      </div>
    </div>
  </mat-dialog-content>

  <div mat-dialog-actions *ngIf="view === 'TRACKS'">
    <button (click)="removePlaylistTracks()" class="btn save-btn">Save</button>
  </div>
</div>

<div *ngIf="addTracksMode" class="add-tracks-section">
  <div class="dialog-header">
    <button (click)="backToPlaylistDetails()" class="btn back-btn"></button>
    <h2 mat-dialog-title>Add Tracks</h2>
    <button mat-dialog-close class="btn close-dialog-btn"></button>
  </div>

  <app-add-tracks
    mode="edit"
    (onAddTracks)="onAddTracks($event)"
  >
  </app-add-tracks>
</div>

<ng-template #noTracks>
  <div class="no-tracks-message text-uppercase">No Tracks available</div>
</ng-template>

<ng-template #spinner>
  <app-spinner *ngIf="isLoading" [isLoading]="isLoading" [errored]="errored"></app-spinner>
</ng-template>
