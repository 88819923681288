import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { VenueService } from '../../shared/services/venue.service';
import { AuthService } from '../../shared/services/auth.service';
import { Store } from '@ngxs/store';
import { VenueState } from 'src/app/shared/store/venue/venue.state';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public loginForm: UntypedFormGroup;
  public needToChooseVenue = false;
  public isInvalid = false;
  public venueId = 0;

  constructor(
    private venueService: VenueService,
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private store: Store
    ) { }

  ngOnInit() {
    this.venueId = this.store.selectSnapshot(VenueState.venueId);

    if (this.authService.isAuthenticated()) {

      if (this.venueId) {
        this.router.navigate(['/discover']);
      } else {
        this.needToChooseVenue = true;

        this.authService.getUser();
      }
    }

    this.loginForm = this.formBuilder.group({
      email: ['', { validators: Validators.required}],
      password: ['', { validators: Validators.required}]
    });
  }

  get formControl() { return this.loginForm.controls; }

  login() {
    if (this.loginForm.invalid) {
      return;
    }

    this.authService.login(this.formControl.email.value, this.formControl.password.value)
      .then(() => {
        this.needToChooseVenue = true;
      })
      .catch(() => {
        this.isInvalid = true;
      });
  }

}
