import { PlaylistInput } from '../../models/models';

export namespace NewVideoPlaylistAction {

  export class GetNewVideoPlaylists {
    static readonly type = '[New Video Playlists] Get Video Playlists';
    constructor() {}
  }

  export class GetMoreVideoPlaylists {
    static readonly type = '[New Video Playlists] Get More Video Playlists';
    constructor(
      public count?: number
    ) {}
  }

  export class CreatePlaylist {
    static readonly type = '[New Video Playlists] Create New Video Playlist';
    constructor(
      public playlistInput: PlaylistInput,
      public cover?: any
    ) {}
  }

  export class EditPlaylist {
    static readonly type = '[New Video Playlists] Edit Video Playlist';
    constructor(
      public id: number,
      public playlistInput: PlaylistInput,
      public cover?: any
    ) {}
  }

  export class DeletePlaylist {
    static readonly type = '[New Video Playlists] Delete Video Playlist';
    constructor(
      public id: number
    ) {}
  }

  export class UpdateTracksInVideo {
    static readonly type = '[New Video Playlists] Update Clip To Video Playlist';
    constructor(
      public playlistId: number
    ) {}
  }

  export class RemoveTracksFromPlaylist {
    static readonly type = '[New Video Playlists] Remove Clip From Playlist';
    constructor(
      public trackIds: Array<number>,
      public playlistId: number
    ) {}
  }

  export class UpdatePlaylistCover {
      static readonly type = '[New Video Playlist] Update Video Playlist Cover';
  }

  export class ChangePlaylistLibraryState {
    static readonly type = '[New Video Playlists] Change Playlist Library State';
    constructor(
      public playlistIds: number[],
      public isInLibrary: boolean
    ) {}
  }
}