import { Component, OnInit } from '@angular/core';

import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { ReportVenuesComponent } from '../report-venues/report-venues.component';

@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.scss']
})
export class ReportingComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<ReportingComponent>,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
  }

  openReportVenuesModal() {
    this.dialogRef.addPanelClass('hidden');

    this.dialog.open(ReportVenuesComponent)
      .afterClosed()
      .subscribe((isClosed) => {
        if (isClosed) {
          this.dialogRef.close();
        }

        this.dialogRef.removePanelClass('hidden');
      });
  }

  openReportPlayedTracksModal() {
    this.dialogRef.addPanelClass('hidden');

    this.dialog.open(
      ReportVenuesComponent,
      {
        data: {
          type: 'playedTracks',
        }
      }
    ).afterClosed()
      .subscribe((isClosed) => {
        if (isClosed) {
          this.dialogRef.close();
        }

        this.dialogRef.removePanelClass('hidden');
      });
  }
}
