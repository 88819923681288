<ng-container *ngIf="!isPlaylistOverridden(zone)">
  <div class="tracks-container-cover overflow-hidden">
    <div class="quarter-images d-flex flex-wrap" *ngIf="timeslot.tracksContainer.artwork?.length === 4">
      <img *ngFor="let artwork of timeslot.tracksContainer.artwork" appDefault [src]="artwork" class="quarter-img"
        alt="Cover" />
    </div>

    <img *ngIf="timeslot.tracksContainer.artwork?.length < 4"
      [src]="(timeslot.tracksContainer.artwork && timeslot.tracksContainer.artwork[0]) ? timeslot.tracksContainer.artwork[0] : '/assets/images/default-cover.jpg'"
      appDefault class="cover w-100 h-100" alt="Cover" />
  </div>
  <div class="timeslot-details-container d-flex flex-column justify-content-between">
    <div [title]="timeslot.tracksContainer.name" class="tracks-container-name">{{ timeslot.tracksContainer.name }}</div>
    <div [title]="timeslot.zoneName" class="zone-name text-truncate">{{ timeslot.zoneName }}</div>
    <div class="timeslot-timerange d-flex align-items-center">
      {{ timeslot.from.substr(0, 5) }} - {{ timeslot.to.substr(0, 5) }}
    </div>
  </div>
</ng-container>

<!-- Override -->
<ng-container *ngIf="isPlaylistOverridden(zone)">
  <ng-container *ngIf="containerQueueCache && containerQueueCache.playlist">
    <div class="tracks-container-cover overflow-hidden">
      <img
        [src]="containerQueueCache.playlist && containerQueueCache.playlist.logoImageUrl ? containerQueueCache.playlist.logoImageUrl : '/assets/images/default-cover.jpg'"
        appDefault class="cover w-100 h-100" alt="Cover" />

    </div>
    <div class="timeslot-details-container d-flex flex-column">
      <div *ngIf="containerQueueCache" [title]="containerQueueCache.playlist.name" class="tracks-container-name">{{
        containerQueueCache.playlist.name }}</div>
      <div class="timeslot-timerange d-flex align-items-center">
        Playlist override
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="containerQueueCache && containerQueueCache.bucket">
    <div class="tracks-container-cover overflow-hidden">
      <img
        [src]="containerQueueCache.bucket && containerQueueCache.bucket.logoImageUrl ? containerQueueCache.bucket.logoImageUrl : '/assets/images/default-cover.jpg'"
        appDefault class="cover w-100 h-100" alt="Cover" />

    </div>
    <div class="timeslot-details-container d-flex flex-column">
      <div *ngIf="containerQueueCache" [title]="containerQueueCache.bucket.name" class="tracks-container-name">{{
        containerQueueCache.bucket.name }}</div>
      <div class="timeslot-timerange d-flex align-items-center">
        Bucket override
      </div>
    </div>
  </ng-container>
</ng-container>