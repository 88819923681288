import { Action, State, StateContext, Selector, createSelector } from '@ngxs/store';
import { ChannelAction } from './channels.actions';
import { PlaylistService } from '../../services/playlist.service';
import { Channel, Playlist } from '../../models/models';
import { tap, map } from 'rxjs/operators';
import { PlaylistPrepare } from '../../utils/prepare-playlist';
import { Injectable } from '@angular/core';

type Context = StateContext<ChannelStateModel>;

export interface ChannelStateModel {
  channels: Channel[];
  isPlaylistsLoaded: boolean;
}

@State<ChannelStateModel>({
  name: 'channel',
  defaults: {
    channels: null,
    isPlaylistsLoaded: false,
  }
})
@Injectable()
export class ChannelsState {

  constructor(
    private playlistService: PlaylistService,
  ) { }

  @Action(ChannelAction.GetMyChannels)
  fetch({ patchState }: StateContext<ChannelStateModel>) {
    return this.playlistService.getChannels()
      .pipe(
        tap((channels: Channel[]) => {

          // add artwork to playlist or create collage
          channels = channels
            .map((channel) => ({
              ...channel,
              playlists: channel.playlists.map((item) => {
                item = { ...item, artwork: PlaylistPrepare.playlistArtwork(item) };
                // item.artwork = PlaylistPrepare.playlistArtwork(item);
                return item;
              })
            })
            );
          patchState({
            channels,
            isPlaylistsLoaded: true
          });
        })
      );
  }


  @Selector()
  static channelsAll(state: ChannelStateModel): Channel[] {
    return state.channels;
  }

  static playlistByChannelId(channelId: number) {
    return createSelector([ChannelsState], (state: ChannelStateModel) => {
      return state.channels.find(({ id }) => id === channelId).playlists;
    });
  }

  @Selector()
  static isPlaylistsLoaded(state: ChannelStateModel): boolean {
    return state.isPlaylistsLoaded;
  }
}

