import { Playlist } from '../../models/models';

export namespace VideoPlaylistAction {

  export class GetMyVideoPlaylists {
    static readonly type = '[Library Video Playlists] Get Video Playlists';
    constructor() {}
  }

  export class GetMoreVideoPlaylists {
    static readonly type = '[Library Video Playlists] Get More Video Playlists';
    constructor(
      public count?: number
    ) {}
  }

  export class AddVideoPlaylist {
    static readonly type = '[Library Video Playlists] Add Video Playlist To Library';
    constructor(
      public playlist: Playlist
    ) {}
  }

  export class AddCatalogPlaylistsToLibrary {
    static readonly type = '[Library Video Playlists] Add Catalog Video Playlists To Library';
    constructor(
      public playlistsIds: Array<number>
    ) {}
  }

  export class DeleteVideoPlaylist {
    static readonly type = '[Library Video Playlists] Delete Video Playlist';
    constructor(
      public id: number
    ) {}
  }

  export class DeleteMultiplePlaylists {
    static readonly type = '[Library Video Playlists] Delete Multiple Video Playlists';
    constructor(
      public ids: number[]
    ) {}
  }

  export class RemovePlaylistFromLibrary {
    static readonly type = '[Library Video Playlists] Remove Video Playlist from Library';
    constructor(
      public id: number
    ) {}
  }

  export class RemoveMultiplePlaylistsFromLibrary {
    static readonly type = '[Library Video Playlists] Remove Multiple Video Playlists from Library';
    constructor(
      public ids: number[]
    ) {}
  }

  export class UpdateState {
    static readonly type = '[Library Video Playlists] Update Video Playlists State';
  }
}