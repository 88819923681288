import { Pipe, PipeTransform, NgModule } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'formatDate'
})

export class FormatDatePipe implements PipeTransform {
  private DATE_MASK = 'DD/MM/YYYY - HH:mm:ss';

  transform(value: Date, args?: string): string {
    const data = moment(value);
    return data.format(this.DATE_MASK);
  }
}

@NgModule({
  imports: [],
  declarations: [FormatDatePipe],
  exports: [FormatDatePipe]
})
export class FormatDateModule { }
