import { AfterViewInit, Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { NgScrollbar } from 'ngx-scrollbar';
import { Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { AlertDialogComponent } from 'src/app/shared/components/alert-dialog/alert-dialog.component';
import { ConfirmationDialogComponent } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { ZonesListComponent } from 'src/app/shared/components/zone-list-dialog/zone-list-dialog.component';
import { SNACK_BAR_DURATION } from 'src/app/shared/models/consts';
import { Playlist, Track } from 'src/app/shared/models/models';
import { ErrorParserService } from 'src/app/shared/services/error-parcing.service';
import { PlaylistService } from 'src/app/shared/services/playlist.service';
import { PrepareDataService } from 'src/app/shared/services/prepare-data.service';
import { TrackService } from 'src/app/shared/services/track.service';
import { ZoneService } from 'src/app/shared/services/zone.service';
import { NewVideoPlaylistAction } from 'src/app/shared/store/new-video-playlist/new-video-playlists.actions';
import { VideoPlaylistAction } from 'src/app/shared/store/video-playlists/video-playlists.actions';

const TRACK_COUNT = 50;
@Component({
  selector: 'app-video-playlist',
  templateUrl: './video-playlist.component.html',
  styleUrls: ['./video-playlist.component.scss']
})
export class VideoPlaylistComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(NgScrollbar, { static: false }) scrollbarRef: NgScrollbar;
  @ViewChild('video', { static: false }) video: ElementRef;

  private playlistSub: Subscription;
  private scrollSub: Subscription;

  public selectedTrackId: number;

  public playlist: Playlist;
  public last: string;

  public tracks: Track[];

  public loadMore: boolean = false;

  public selectedVideoUrl: string;
  public videoLoading: boolean;

  constructor(
    private prepareDataService: PrepareDataService,
    private playlistService: PlaylistService,
    private trackService: TrackService,
    private dialog: MatDialog,
    private router: Router,
    private zoneService: ZoneService,
    private ngZone: NgZone,
    private store: Store,
    private errorService: ErrorParserService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit() {

    this.playlistSub = this.playlistService.onPlaylistChanged.subscribe(playlist => {

      if (!playlist) { return; }

      this.playlist = playlist;

      this.tracks = this.playlist.tracks;
      if (this.tracks.length > 0 && !this.selectedTrackId) {
        this.selectedTrackId = this.tracks[0].id;
      }
      this.last = this.playlist.tracksConnection.pageInfo.hasNextPage ? this.playlist.tracksConnection.pageInfo.endCursor : null;
      if (!this.selectedVideoUrl && this.tracks && this.tracks[0]) {
        this.selectClip(this.tracks[0].id);
      }
    });
  }

  ngAfterViewInit() {
    if (this.scrollbarRef) {
      this.scrollSub = this.scrollbarRef.scrolled.pipe(
        filter((e: any) => !this.loadMore && e.target.offsetHeight + e.target.scrollTop > e.target.scrollHeight - 10),
        tap(() => this.ngZone.run(() => {
          this.loadMore = true;
          this.getMoreTracks();
        }))
      ).subscribe();
    }
  }

  selectClip(id: number) {
    this.videoLoading = true;
    this.selectedTrackId = id;
    this.trackService.getTrackContentUrl(id)
    .pipe(
      map((track) => track.contentUrl)
    )
    .subscribe((url) => {
      if (!url.endsWith('null')) {
        this.selectedVideoUrl = url;
      } else {
        this.selectedVideoUrl = null;
      }
      if (this.video) {
        this.video.nativeElement.load();
      }
      this.videoLoading = false;
    })
  }


  getUrlError(event) {
    this.selectedVideoUrl = null;
    console.error('url error', event);
  }

  videoLoadError(event) {
    this.selectedVideoUrl = null;
    console.error('error', event);
  }

  addPlaylistToLibrary() {
    this.store.dispatch([
      new VideoPlaylistAction.AddVideoPlaylist(this.playlist),
      new NewVideoPlaylistAction.ChangePlaylistLibraryState([this.playlist.id], true)
    ])
    .subscribe(() => {
      this.playlist.isInLibrary = true;
      this._snackBar.open(
        `Video Playlist successfully added to the library`,
        null,
        { duration: SNACK_BAR_DURATION }
      );
    });
  }

  deletePlaylist() {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: 'Are you sure you want to delete the playlist?',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary'
          },
          {
            text: 'Delete',
            cssClass: 'danger',
            handler: () => {
              this.dialog.closeAll();
              const action = (this.playlist.isCatalog) ?
                [
                  new VideoPlaylistAction.RemovePlaylistFromLibrary(this.playlist.id),
                  new NewVideoPlaylistAction.ChangePlaylistLibraryState([this.playlist.id], false)
                ] :
                [
                  new VideoPlaylistAction.DeleteVideoPlaylist(this.playlist.id),
                  new NewVideoPlaylistAction.ChangePlaylistLibraryState([this.playlist.id], false)
                ];

              this.store.dispatch(action)
              .subscribe(
                () => {
                  this._snackBar.open(
                    `Playlist successfully removed from the library`,
                    null,
                    { duration: SNACK_BAR_DURATION }
                  );
                  this.playlist = {
                    ...this.playlist,
                    isInLibrary: false
                  };
                },
                (error) => {
                  const errIdStr = this.errorService.parseError(error);
                  this.dialog.open(AlertDialogComponent, {
                    data: {
                      type: 'error',
                      message: `Oops, something went wrong. Please try again.
                      ${(!!errIdStr) ? ('Error id: ' + errIdStr) : ''}`
                    }
                  });
                }
              );
            }
          }
        ]
      },
      width: '340px'
    });
  }

  addToQueue() {
    this.dialog.open(ZonesListComponent, {
      data: {
        mediaType: this.playlist.mediaType
      }
    })
      .afterClosed()
      .subscribe(data => {
        if (!data) {
          return;
        }
        if (data.error) {
          this.dialog.open(AlertDialogComponent, {
            data: {
              type: 'error',
              message: data.error
            }
          });
        } else {
          this.confirmAddPlaylistInQueue(data.id);
        }
      });
  }

  confirmAddPlaylistInQueue(zoneId) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: 'The playlist will start after the current song finishes',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary'
          },
          {
            text: 'OK',
            cssClass: 'danger',
            handler: () => {
              this.zoneService.addPlaylistToZoneQueue(
                zoneId,
                this.playlist.id
              )
              .then(() => {
                dialogRef.close();
                this._snackBar.open(
                  `Playlist successfully added to the queue`,
                  null,
                  { duration: SNACK_BAR_DURATION }
                );
              })
              .catch((error) => {
                const errIdStr = this.errorService.parseError(error);
                dialogRef.close();
                this.dialog.open(AlertDialogComponent, {
                  data: {
                    type: 'error',
                    message: `Oops, something went wrong. Please try again.
                    ${(!!errIdStr) ? ('Error id: ' + errIdStr) : ''}`
                  }
                });
              });
            }
          }
        ]
      },
      width: '340px'
    });
  }

  getMoreTracks() {
    if (this.last) {
      this.playlistService.nextTrackPage(this.playlist.id, this.last, TRACK_COUNT)
        .pipe(
          tap((tracksConnection: any) => {
            this.last = tracksConnection.pageInfo.hasNextPage ? tracksConnection.pageInfo.endCursor : null;
          }),
          map(({edges}) => edges.map(({ node }) => node))
        )
        .subscribe((tracks) => {
          this.tracks = [...this.tracks, ...tracks];
          this.loadMore = false;
        });
    }
  }

  ngOnDestroy() {
    this.playlistSub.unsubscribe();
    if (this.scrollSub) {
      this.scrollSub.unsubscribe();
    }
  }

}
