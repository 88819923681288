import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';

import { NgScrollbarModule } from 'ngx-scrollbar';

import { SharedBucketPreviewItemModule } from '../bucket-preview-item/bucket-preview-item.module';
import { SharedBucketListDialogComponent } from './bucket-list-dialog.component';
import { SpinnerModule } from 'src/app/system/spinner/spinner.module';

@NgModule({
    declarations: [
        SharedBucketListDialogComponent
    ],
    imports: [
        CommonModule,
        MatMenuModule,
        MatDialogModule,
        NgScrollbarModule,
        SharedBucketPreviewItemModule,
        SpinnerModule
    ],
    exports: [
        SharedBucketListDialogComponent
    ]
})
export class SharedBucketDialogModule { }
