<div class="dialog-wrapper">
  <div class="dialog-header">
    <h2 mat-dialog-title>Recently played tracks</h2>
    <button mat-dialog-close class="btn close-dialog-btn"></button>
  </div>

  <mat-dialog-content>
    <div class="lists-wrapper" *ngIf="data.tracks?.length; else noRecentlyPlayed">
      <div class="track-list-wrapper edit-mode">
        <div class="track-list-header">
          <span>Title</span>
          <span>Artist</span>
          <span>Album</span>
        </div>

        <ng-scrollbar appearance=true class="track-list">
          <div *ngFor="let track of data.tracks" class="edit-mode track-items-wrapper">
            <div class="track-item-wrapper">
              <div class="track-cover">
                <img
                  appDefault
                  [src]="track.artwork?.artworkUrl100 || '/assets/images/default-cover.jpg'"
                  alt="Track cover"
                />
              </div>
              <div class="track-details">
                <div class="track-title text-truncate">
                  <span [title]="track.name">{{ track.name }}</span>
                </div>
                <div class="track-artist text-truncate">
                  <span [title]="track.artist.name">{{ track.artist.name }}</span>
                </div>
                <div class="track-album text-truncate">
                  <span [title]="track.album.name">{{ track.album.name }}</span>
                </div>
              </div>
            </div>
          </div>
        </ng-scrollbar>
      </div>
    </div>
    <ng-template #noRecentlyPlayed>
      <div class="no-recently-played text-uppercase d-flex justify-content-center align-items-center text-center">
        There are no recently played tracks
      </div>
    </ng-template>
  </mat-dialog-content>
</div>
