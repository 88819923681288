import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { Track } from '../../models/models';
import { PrepareDataService } from 'src/app/shared/services/prepare-data.service';

@Component({
  selector: 'app-track-preview-item',
  templateUrl: './track-preview-item.component.html',
  styleUrls: ['./track-preview-item.component.scss']
})
export class SharedTrackPreviewItemComponent implements OnInit {
  @Input() track: Track;
  @Input() isAdded: boolean;
  @Input() isSelected: boolean;
  @Input() isRemovable: boolean;
  @Input() isSelectable?: boolean;

  @Output() selected = new EventEmitter<number>();
  @Output() removed = new EventEmitter<number>();

  constructor(
    private prepareService: PrepareDataService
  ) { }

  ngOnInit() {
    // if (!('artwork' in this.track)) {
    //   this.track.artwork = this.prepareService.trackArtwork(this.track);
    // }
    console.log("tpi:", this.track);
  }

  selectTrack() {
    if (!this.isAdded) {
      this.selected.emit(this.track.id);
    }
  }

  remove() {
    this.removed.emit(this.track.id);
  }

  coverToSize(url) {
    return this.prepareService.coverToSize(url, '200x200');
  }
}
