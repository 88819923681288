import { BehaviorSubject, Subject } from 'rxjs';
import { Venue, VenueUpdateData } from '../models/models';
import {
  createVenue,
  enqueueCreateBmsReportJob,
  updateVenue,
  updateVenueLimitMusicSelection,
  venue,
  venueBannerImage,
  venueLogoImage,
  venuesConnection,
  venuesExtended,
  venuesList
} from '../gql/venue-consts';

import { Apollo } from 'apollo-angular';
import { AuthService } from './auth.service';
import { ClearDataService } from './clear-data.service';
import { Injectable } from '@angular/core';
import { LocalStoreService } from './localstore.service';
import { VenuePayload } from '../models/inner-models';
import { map } from 'rxjs/operators';
import { zonePlayerEventSubscription } from '../gql/zone-consts';

@Injectable({
  providedIn: 'root'
})
export class VenueService {
  constructor(
    private apollo: Apollo,
    private localstoreService: LocalStoreService,
    private clearService: ClearDataService
  ) { }

  zonePlayerEventSubscription(zoneId: number, venueId: number) {
    return this.apollo.subscribe({
        query: zonePlayerEventSubscription,
        variables: { zoneId, venueId }
      })
      .pipe(
        map((data: any) => data.data.zonePlayerEvent.track)
      )
  }

  getVenues(nameLike?: string) {
    return this.apollo.query({
      query: venuesExtended,
      variables: { nameLike },
      fetchPolicy: 'no-cache'
    })
    .pipe(
      map((data: any) => data.data.venues)
    )
  }

  getVenuesConnection(cursor?: string, first: number = 10, nameLike?: string) {
    return this.apollo.query({
      query: venuesConnection,
      variables: { 
        nameLike, 
        first, 
        after: cursor
       },
      fetchPolicy: 'no-cache'
    })
    .pipe(
      map((data: any) => data.data.venuesConnection)
    )
  }

  getVenuesList(nameLike?: string) {
    return this.apollo.query({
      query: venuesList,
      variables: { nameLike },
      fetchPolicy: 'no-cache'
    })
    .pipe(
      map((data: any) => data.data.venues)
    )
  }

  getVenueData(venueId: number) {
    return this.apollo.query({
        query: venue,
        variables: { venueId }
      })
      .pipe(
        map((data: any) => data.data.venue)
      );
  }

  createVenue(variables: VenuePayload) {
    return this.apollo.mutate({
      mutation: createVenue,
      variables,
    })
    .pipe(
      map((data: any) => data.data.createVenue)
    );
  }

  updateVenue(id, values) {
    return this.apollo.mutate({
      mutation: updateVenue,
      variables: { id, values }
    })
    .pipe(
      map((data: any) => data.data.updateVenue)
    );
  }

  saveSelectedVenue(id: number) {
    this.apollo.getClient().cache.reset();
    this.clearService.clearDataAfterVenueChange();

    // this.localstoreService.setItem('venueId', id);
  }

  getSelectedVenueId(): number {
    return +this.localstoreService.getItem('venue.id');
  }

  updateLimitMusicSelection(venueId: number, newValue: boolean) {
    return new Promise<boolean>((resolve, reject) => {
      this.apollo.mutate({
        mutation: updateVenueLimitMusicSelection,
        variables: { venueId, newValue }
      })
        .subscribe(
          (data: any) => {
            resolve(data.data.updateVenueLimitMusicSelection);
          },
          (error) => {
            console.log(error);
            reject(error);
          }
        );
    });
  }

  updateVenueLogoImage(venueId) {
    return this.apollo.subscribe({
      query: venueLogoImage,
      variables: { id: venueId }
    })
    .pipe(
      map((data: any) => data.data.venue.logoImageUrl)
    );
  }

  updateVenueBannerImage(venueId) {
    return this.apollo.subscribe({
      query: venueBannerImage,
      variables: { id: venueId }
    })
    .pipe(
      map((data: any) => data.data.venue.bannerImageUrl)
    );
  }

  enqueueCreateBmsReportJob(email, venueId, from, to) {
    return this.apollo.mutate({
      mutation: enqueueCreateBmsReportJob,
      variables: { email, venueId, from, to }
    })
  }
}
