<mat-dialog-content>
  <div class="search-track-wrapper">
    <input
      #searchString
      type="text"
      id="search-track"
      class="input-search"
      (keyup.enter)="search(searchString.value)"
      placeholder="Type something"
    />
  </div>

  <div *ngIf="!isLoading; else spinner" class="container-wrapper">
    <div class="track-list-wrapper" *ngIf="tracks?.length; else noTracks">
      <div class="track-list-header">
        <span>Title</span>
        <span>Artist</span>
        <span>Album</span>
      </div>

      <ng-scrollbar appearance=true>
        <ul class="track-list">
          <li
            *ngFor="let track of tracks;"
            (click)="toggleTrackSelection(track.id)"
            class="track-item-wrapper"
            [ngClass]="{ 'active': selectedTrackIds.has(track.id) }"
          >
            <app-track-item-line [track]="track"></app-track-item-line>
            <div class="checkmark-container"></div>
          </li>
        </ul>
      </ng-scrollbar>
    </div>
  </div>
</mat-dialog-content>
<div class="align-right dialog-controls">
  <button
    (click)="savePlaylist()"
    class="btn save-playlist-btn"
  >
    {{ mode === 'edit' ? 'Add' : 'Create' }}
  </button>
</div>

<ng-template #spinner>
  <app-spinner [isLoading]="isLoading" [errored]="errored" [minimal]="true"></app-spinner>
</ng-template>

<ng-template #noTracks>
 <div class="no-tracks-message text-uppercase hover">No Tracks available</div>
</ng-template>
