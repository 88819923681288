import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Apollo } from 'apollo-angular';

import { BehaviorSubject } from 'rxjs';

import { loginWithPassword, logout, resetPassword, currentUser, currentUserNoVenue } from '../gql/consts';
import { LocalStoreService } from './localstore.service';
import { User } from '../models/models';
import { Store } from '@ngxs/store';
import { StateReset } from 'ngxs-reset-plugin';
import { ZoneAction } from '../store/zones/zones.actions';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private user: User;
  public onUserChanged: BehaviorSubject<User> = new BehaviorSubject(null);

  constructor(
    private localstoreService: LocalStoreService,
    private apollo: Apollo,
    private router: Router,
    private store: Store
  ) { }

  public isAuthenticated(): boolean {
    const token = this.localstoreService.getItemParsed('access_token');

    return (token != null && token.loginToken != null);
  }

  login(email: string, password: string) {
    return new Promise<any>((resolve, reject) => {
      this.apollo.mutate({
        mutation: loginWithPassword,
        variables: { email, password }
      })
        .subscribe(
          (token: any) => {
            if (token.data  && token.data.loginWithPassword) {
              this.localstoreService.setItem('access_token', JSON.stringify(token.data.loginWithPassword));
              this.localstoreService.removeItem('venueId');
              this.localstoreService.removeItem('venue.id');
              this.getUser()
                .then(() => {
                  resolve(true);
                })
                .catch((err) => {
                  console.log(err);
                  reject();
                });
            } else {
              reject();
            }
          },
          (error) => {
            console.log(error);
            reject();
          }
        );
    });
  }

  logout() {
    return new Promise<void>((resolve, reject) => {
      this.apollo.mutate({
        mutation: logout
      })
        .subscribe(
          (data) => {
            if (data) {
              this.localstoreService.clear();
              this.store.dispatch(new ZoneAction.ClearZoneData());
              this.store.dispatch(new StateReset());

              this.user = null;
              this.onUserChanged.next(this.user);

              this.restoreAuthorization();
            }

            resolve();
          },
          (error) => {
            console.log(error);
            reject(error);
          }
        );
    });
  }

  resetPassword(email: string) {
    return this.apollo.mutate({
      mutation: resetPassword,
      variables: { email }
    });
  }

  getUserWithVenue() {
    return new Promise<any>((resolve, reject) => {
      this.apollo.query({
        query: currentUser,
        variables: { venueId: this.localstoreService.getItemParsed('venue.id') }
      })
        .subscribe(
          (data: any) => {
            if (data && data.data && data.data.me) {
              this.user = data.data.me;
              this.onUserChanged.next(this.user);
            }
            if (data.errors && data.errors.length > 0) {
              const isServerError = data.errors.some(
                err => err.extensions && err.extensions.code === 'INTERNAL_SERVER_ERROR'
              );
              if (isServerError) {
                this.localstoreService.removeItem('access_token');
                this.router.navigate(['login']);
              }
              reject();
            }
            resolve(this.user);
          },
          (error) => {
            console.log(error);
            reject(error);
          }
        );
    });
  }

  getUser() {
    return new Promise<void>((resolve, reject) => {
      this.apollo.query({
        query: currentUserNoVenue
      })
        .subscribe(
          (data: any) => {
            if (data && data.data && data.data.me) {
              this.user = data.data.me;
              this.onUserChanged.next(this.user);
            }

            if (data.errors && data.errors.length > 0) {
              const isServerError = data.errors.some(err => err.extensions && err.extensions.code === 'INTERNAL_SERVER_ERROR');

              if (isServerError) {
                this.localstoreService.removeItem('access_token');
                this.router.navigate(['login']);
              }

              reject();
            }

            resolve();
          },
          (error) => {
            console.log(error);
            reject(error);
          }
      );
    });
  }

  private restoreAuthorization() {
    // reset the store
    this.apollo.getClient().resetStore();
  }
}
