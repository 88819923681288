// based on https://github.com/skynet2/ngx-google-places-autocomplete

import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, NgZone, OnDestroy, Output } from '@angular/core';
import { GoogleMapsAddress } from './models/address';
import { Options } from './models/options/options';

declare let google: any;

@Directive({
  selector: '[appGooglePlacesAutocomplete]',
  exportAs: 'app-places'
})

export class GooglePlaceDirective implements AfterViewInit, OnDestroy {
  @Input() options: Options;
  @Output() addressChanged: EventEmitter<GoogleMapsAddress> = new EventEmitter();
  private autocomplete: any;
  private eventListener: any;
  public place: GoogleMapsAddress;

  constructor(private el: ElementRef, private ngZone: NgZone) {
    this.keyDownHandler = this.keyDownHandler.bind(this);
  }

  ngAfterViewInit(): void {
    if (!this.options) {
      this.options = new Options();
    }

    this.initialize();
  }

  private isGoogleLibExists(): boolean {
    return !(!google || !google.maps || !google.maps.places);
  }

  private initialize(): void {
    if (!this.isGoogleLibExists()) {
      throw new Error('Google maps library can not be found');
    }

    this.autocomplete = new google.maps.places.Autocomplete(this.el.nativeElement, this.options);

    if (!this.autocomplete) {
      throw new Error('Autocomplete is not initialized');
    }

    if (!this.autocomplete.addListener != null) {
      this.eventListener = this.autocomplete.addListener('place_changed', () => {
        this.handleChangeEvent();
      });
    }

    this.el.nativeElement.addEventListener('keydown', this.keyDownHandler);

    if (window && window.navigator && window.navigator.userAgent && navigator.userAgent.match(/(iPad|iPhone|iPod)/g)) {
      setTimeout(() => {
        const containers = document.getElementsByClassName('pac-container');

        if (containers) {
          const arr = Array.from(containers);

          if (arr) {
            for (const container of arr) {
              if (!container) {
                continue;
              }

              container.addEventListener('touchend', (e) => {
                e.stopImmediatePropagation();
              });
            }

          }
        }
      }, 500);
    }
  }

  private keyDownHandler(event: KeyboardEvent) {
    if (!event.key) {
      return;
    }

    const key = event.key.toLowerCase();

    if (key === 'enter' && event.target === this.el.nativeElement) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  public reset(): void {
    this.autocomplete.setComponentRestrictions(this.options.componentRestrictions);
    this.autocomplete.setTypes(this.options.types);
  }

  private handleChangeEvent(): void {
    this.ngZone.run(() => {
      this.place = this.autocomplete.getPlace();

      if (this.place && this.place.place_id) {
        this.addressChanged.emit(this.place);
      }
    });
  }

  ngOnDestroy(): void {
    this.el.nativeElement.removeEventListener('keydown', this.keyDownHandler);

    if (this.eventListener) {
      this.eventListener.remove();
    }

    if (this.autocomplete) {
      google.maps.event.clearInstanceListeners(this.autocomplete);
    }
  }
}
