import { Component, OnInit } from '@angular/core';

import { AuthService } from '../shared/services/auth.service';
import { MiniplayerService } from 'src/app/shared/services/miniplayer.service';
import { PermissionsService } from './permissions/permissions.service';
import { ResizeService } from 'src/app/shared/services/resize.service';
import { VenueService } from '../shared/services/venue.service';

@Component({
  selector: 'app-system',
  templateUrl: './system.component.html',
  styleUrls: ['./system.component.scss']
})
export class SystemComponent implements OnInit {
  public showMiniPlayer: boolean;

  constructor(
    private resizeService: ResizeService,
    private venueService: VenueService,
    private authService: AuthService,
    private miniplayerService: MiniplayerService,
    private permissionService: PermissionsService
  ) { }

  async ngOnInit() {
    await this.authService.getUserWithVenue();
    await this.permissionService.forceUpdate();
    this.miniplayerService.isVisible.subscribe(visible => this.showMiniPlayer = visible);
  }

}
