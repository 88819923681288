import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResizeService {
  private numberOfColumns = 5;

  constructor() { }

  private NumberOfSliderColumns = new BehaviorSubject<number>(this.numberOfColumns);
  numberOfSliderColumns = this.NumberOfSliderColumns.asObservable();

  setContentWidth(contentWidth: number) {
    switch (contentWidth) {
      case 990:
        this.numberOfColumns = 5;
        break;

      case 810:
        this.numberOfColumns = 4;
        break;

      case 690:
        this.numberOfColumns = 3;
        break;

      default:
        this.numberOfColumns = 5;
        break;
    }

    this.NumberOfSliderColumns.next(this.numberOfColumns);
  }
}
