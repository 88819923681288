import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { debounceTime, filter, map, takeUntil, tap } from 'rxjs/operators';

import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { PageInfo, PaginationInfo, Venue } from 'src/app/shared/models/models';
import { VenueListAction } from 'src/app/shared/store/venue-list/venue-list.actions';
import { VenueListState } from 'src/app/shared/store/venue-list/venue-list.state';
import { VenueService } from 'src/app/shared/services/venue.service';
import { NgScrollbar } from 'ngx-scrollbar';
import { SCROLL_BOTTOM_OFFSET } from 'src/app/shared/models/consts';

@Component({
  selector: 'app-venue-list',
  templateUrl: './venue-list.component.html',
  styleUrls: ['./venue-list.component.scss']
})
export class VenueListComponent implements OnInit, OnDestroy {
  @ViewChild(NgScrollbar, { static: false }) scrollbarRef: NgScrollbar;
  @Select(VenueListState.minimalVenues) venues$: Observable<Array<Venue>>;
  searchFilter$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  public isLoading = true;
  public errored = false;
  public filteredQuery = false;
  public venues: Array<Venue>;
  public filteredVenues: Array<Venue>;
  public allowQuery = true;
  public cursor: string;
  public pageSize: number = 10;
  public pageInfo: PageInfo;
  public isMoreVenuesLoading = false;
  public scrollSub: Subscription;
  public venueLike: string = '';

  private debounce = 600;
  private unSubscribe$: Subject<{}> = new Subject;

  constructor(
    private dialogRef: MatDialogRef<VenueListComponent>,
    private store: Store,
    private venueService: VenueService,
    private ngZone: NgZone,
  ) {
    this.venues$ = this.store.select(VenueListState.minimalVenues);
  }

  ngOnInit() {
    this.venueService.getVenuesConnection(this.cursor, this.pageSize, '')
      .pipe(
        tap((paginationInfo: PaginationInfo) => {
          this.pageInfo = paginationInfo.pageInfo;
        }),
        map(({ edges }) => edges.map(({ node }) => node))
      ).subscribe((response) => {
        this.filteredVenues = this.venues = response;
        this.isLoading = false;
      });

    // Apply debounce to search filter
    this.searchFilter$.pipe(debounceTime(500)).subscribe((value) => {
      this.isLoading = true;
      this.venueService.getVenuesConnection(this.cursor, this.pageSize, value)
      .pipe(
        tap((paginationInfo: PaginationInfo) => {
          this.pageInfo = paginationInfo.pageInfo;
        }),
        map(({ edges }) => edges.map(({ node }) => node))
      ).subscribe((response) => {
        this.filteredVenues = this.venues = response;
        this.isLoading = false;
      });
    })
  }

  ngAfterViewInit() {
    if (this.scrollbarRef && !this.scrollSub) {
      this.scrollSub = this.scrollbarRef.verticalScrolled.pipe(
        filter((e: any) => {
          return !this.isLoading && e.target.offsetHeight + e.target.scrollTop > e.target.scrollHeight - SCROLL_BOTTOM_OFFSET;
        }),
        tap(() => this.ngZone.run(() => {
          this.isMoreVenuesLoading = true;
          this.loadMore();
        }))
      ).subscribe();
    }
  }

  loadMore() {
    this.venueService.getVenuesConnection(this.pageInfo.endCursor, this.pageSize, this.venueLike).pipe(
      tap((paginationInfo: PaginationInfo) => {
        this.pageInfo = paginationInfo.pageInfo;
      }),
      map(({ edges }) => edges.map(({ node }) => node))
    ).subscribe((response) => {
      this.filteredVenues = [...this.filteredVenues, ...response];
      this.isMoreVenuesLoading = false;
    }, (err) => {
      this.isMoreVenuesLoading = false;
    });
  }

  selectVenue(venueId: number) {
    this.dialogRef.close(venueId);
  }

  searchVenue(term: string) {
    if (term && term !== '') {
      term = `%${term}%`;
    }
    this.searchFilter$.next(term.toString().toLowerCase());
  }

  debounceQuery() {
    if (this.allowQuery) {
      this.allowQuery = false;
      setTimeout(() => {
        this.allowQuery = true;
      }, this.debounce);
      return true;
    } else return false;
  }

  ngOnDestroy() {
    this.unSubscribe$.next(true);
    this.unSubscribe$.complete();
  }
}

