import { Venue } from 'src/app/shared/models/models';

export namespace VenueAction {
  export class GetVenue {
    static type = '[Venue] Get Venue';

    constructor(
      public id: number
    ) {}
  }

  export class EditVenue {
    static type = '[Venue] Update Venue';

    constructor(
      public venue: Venue
    ) {}
  }

  export class ChangeCurrentVenue {
    static type = '[Venue] Change Current';

    constructor(
      public id: number
    ) {}
  }

  export class SetCurrentVenue {
    static type = '[Venue] Set Current Venue';

    constructor(
      public id: number
    ) {}
  }
}