
import { ApolloClient, InMemoryCache, ApolloLink, split } from '@apollo/client/core';
import { APOLLO_OPTIONS, Apollo, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { HttpClientModule, HttpHeaders } from '@angular/common/http';
import { setContext } from '@apollo/client/link/context';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthGuard } from './shared/guards/auth.guard';
import { AuthModule } from './auth/auth.module';
import { AuthService } from './shared/services/auth.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ImagePreloadModule } from './shared/directives/image-preload/image-preload.module';
import { LocalStoreService } from './shared/services/localstore.service';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { NgModule } from '@angular/core';
import { RetryLink } from '@apollo/client/link/retry';
import { Router } from '@angular/router';
import { SpotifyCallbackComponent } from './spotify-callback.component';
import { StoreModule } from './shared/store/store.module';
import { SystemModule } from './system/system.module';
import { WebSocketLink } from '@apollo/client/link/ws';
import { environment } from 'src/environments/environment';
import { getMainDefinition } from 'apollo-utilities';
// Graphql unions
import introspectionResults from '../introspection-result';
import { onError } from '@apollo/client/link/error';
import { SchedulerV2IntroComponent } from './shared/scheduler-v2-intro/scheduler-v2-intro.component';

@NgModule({
  declarations: [
    AppComponent,
    SpotifyCallbackComponent,
    SchedulerV2IntroComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AuthModule,
    SystemModule,
    MatMenuModule,
    MatSidenavModule,
    MatSliderModule,
    MatSnackBarModule,
    HttpClientModule,
    ImagePreloadModule,
    StoreModule,
    ApolloModule
  ],
  providers: [
    AuthGuard,
    AuthService,
    {
      provide: APOLLO_OPTIONS,
      useFactory(httpLink: HttpLink) {
        const http = httpLink.create({ uri: environment.API_URL })
        const authMiddleware = setContext((operation, context) => {
          const token = JSON.parse(localStorage.getItem('access_token'))?.loginToken;

          if (token === null) {
            return {};
          } else {
            return {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            };
          }
        });

        const retryLink = new RetryLink({
          delay: {
            initial: 1000,
            max: 15000,
            jitter: true,
          },
          attempts: (count, operation, error) => {
            const doNotRetryCodes = [400, 401];
            const shouldRetry = count < 5 || (error && error.statusCode && !doNotRetryCodes.includes(error.statusCode));
            if (!shouldRetry) {
              if (this.snackBar && !this.snackBar._openedSnackBarRef) {
                this.snackBar.open('There was an error while processing your request. Please try again.', undefined, { duration: 10000 });
              }
            }
            return shouldRetry;
          },
        });

        const errorAfterMiddleware = onError(({ networkError, graphQLErrors }) => {
          if (graphQLErrors)
            graphQLErrors.forEach(({ message, locations, path }) => {
              console.log(
                `[GraphQL error]: Message: ${message}`
              );

              if (message.includes('Not logged in')) {
                localStorage.clear();
                location.reload();
                // this.router.navigate(['/login']);
                return;
              }
            });
          if (networkError) console.log(`[Network error]: ${networkError}`);
        });

        const authorizedHttpLink = authMiddleware
          .concat(retryLink)
          .concat(errorAfterMiddleware)
          .concat(http);

        const wsLink = new WebSocketLink({
          uri: environment.GRAPHQL_WS_URL,
          options: {
            lazy: true,
            reconnect: true,
            connectionParams: () => {
              const token = JSON.parse(localStorage.getItem('access_token'))?.loginToken;
              // const token = this.localstoreService.getItemParsed('access_token').loginToken;

              return {
                authorization: token ? `Bearer ${token}` : '',
              };
            }
          },
        });

        const link = split(
          ({ query }) => {
            const item = getMainDefinition(query);
            return item.kind === 'OperationDefinition' && item.operation === 'subscription';
          },
          wsLink,
          authorizedHttpLink,
        );

        return {
          link,
          cache: new InMemoryCache(),
        };
      },
      deps: [HttpLink],
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    apollo: Apollo,
    httpLink: HttpLink,
    private authService: AuthService,
    private localstoreService: LocalStoreService,
    private snackBar: MatSnackBar,
    private router: Router
  ) {
  }
}
