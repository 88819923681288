import { Injectable } from '@angular/core';

import { GoogleMapsAddress } from '../directives/google-places-autocomplete/models/address';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapsService {
  constructor() { }

  public getAddressData(address: GoogleMapsAddress) {
    return {
      latitude: address.geometry.location.lat(),
      longitude: address.geometry.location.lng(),
      ...this.parseAddressComponents(address.address_components)
    };
  }

  private parseAddressComponents(address_components) {
    const addressObj = {
      building: ['street_number'],
      postcode: ['postal_code'],
      street: ['street_address', 'route'],
      state: ['administrative_area_level_1'],
      city: ['locality'],
      country: ['country']
    };

    const address = {
      building: '',
      postcode: '',
      street: '',
      state: '',
      city: '',
      country: '',
      line1: ''
    };

    address_components.forEach(component => {
      for (const addressComponent in addressObj) {
        if (addressObj[addressComponent].indexOf(component.types[0]) !== -1) {
          address[addressComponent] = component.long_name;
        }
      }
    });

    address['line1'] = (address.street && address.building) ? `${address.building} ${address.street}` : address.street;

    return address;
  }
}
