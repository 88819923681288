<div (click)="selectPlaylist()" class="playlist-cover-container">
  <div [ngClass]="{ blurred: isAdded || isSelected }" class="cover-wrapper">
    <div class="quarter-images" *ngIf="playlist.artwork?.length === 4">
      <img
        *ngFor="let artwork of playlist.artwork"
        [src]="artwork"
        appDefault
        class="quarter-img"
        alt="Playlist cover"
      />
    </div>
    <img
      *ngIf="playlist.artwork?.length < 4"
      appDefault
      [src]="(playlist.artwork && playlist.artwork[0]) ? coverToSize(playlist.artwork[0]) : '/assets/images/default-cover.jpg'"
      class="cover"
      alt="Playlist cover"
    />
  </div>
  <button *ngIf="isRemovable" (click)="$event.stopPropagation(); remove()" class="menu-button">
    <div class="menu">
      <div class="cover-blur"></div>
      <div class="cover-dark"></div>
      <div class="custom-icon icon-delete"></div>
    </div>
  </button>
  <div *ngIf="isSelected" class="checkmark-wrapper"></div>
  <div *ngIf="isAdded" class="added-label-wrapper">
    <div class="added-label">Added</div>
  </div>
</div>
<div [title]="playlist.name" class="playlist-name text-truncate">{{ playlist.name }}</div>
<div class="playlist-genre text-truncate">{{ playlist.generalGenre }}</div>
