import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { PrepareDataService } from '../../../shared/services/prepare-data.service';
import { ZoneService } from '../../../shared/services/zone.service';
import { PlayerCommandType } from 'src/app/shared/gql/zone-consts';
import { Timer, Zone, TodayTimeslot } from 'src/app/shared/models/models';
import { TrackService } from '../../../shared/services/track.service';
import { TrackQueueComponent } from '../../../shared/components/track-queue/track-queue.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PlayerCommandDialogComponent } from './player-command-dialog/player-command-dialog.component';
import { TimerService } from 'src/app/shared/services/timer.service';
import { Observable, Subscription } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { ZonesState } from 'src/app/shared/store/zones/zones.state';

import * as moment from 'moment';
import { TimeslotService } from 'src/app/shared/services/timeslot.service';
import { DATE_FORMAT_FOR_BACKEND, MediaTypes } from 'src/app/shared/models/consts';
import { PlayerService } from 'src/app/shared/services/player.service';

@Component({
  selector: 'app-player-item',
  templateUrl: './player-item.component.html',
  styleUrls: ['./player-item.component.scss']
})
export class PlayerItemComponent implements OnInit {
  @Input() zone: Zone;
  @Input() timer: Timer;
  private currentZoneSub: Subscription;
  private timerSub: Subscription;
  @Select(ZonesState.currentZone) currentZone$: Observable<Zone>;

  public playerPosition = 0;
  public isPlaying = false;
  public duration: number = 1;
  public currentZone: Zone;

  constructor(
    private prepareService: PrepareDataService,
    private trackService: TrackService,
    private zoneService: ZoneService,
    private router: Router,
    private timerService: TimerService,
    private store: Store,
    public dialog: MatDialog,
    private playerService: PlayerService
  ) {
    this.currentZone$ = this.store.select(ZonesState.currentZone);
  }

  ngOnInit() {
    this.currentZoneSub = this.currentZone$.subscribe((zone) => {
      this.currentZone = zone;

      // if (this.zone) {
      //   if (this.owlElement) {
      //     this.owlElement.to([this.zonesWithPlayer.findIndex(i => i.id === zone.id)]);
      //   }
      //   if (!!this.zone.player.settings.length) {
      //     this.volume = this.getVolumeFromPlayer(this.zone.player);
      //   }
      // }

      this.playerService.onZonePlayerChanged.subscribe((data: any) => {
        if (data && data.players) {
          // find the player for current zone
          let zonePlayer = data.players.find(player => player.id === this.zone.id);
          if (zonePlayer) {
            this.zone.currentTrack = zonePlayer.track;
            this.zone.currentTrack.album = zonePlayer.track.album;
          }
        }
      })
    });

    this.timerSub = this.timerService.onTimersChanged.subscribe((timers) => {
      if (this.zone) {
        const zoneTimer = timers.find((timer) => timer && timer.id === this.zone.id);
        if (zoneTimer) {
          this.playerPosition = zoneTimer.position || 0;
          this.isPlaying = zoneTimer.playing;
          this.duration = zoneTimer.duration || this.maxRangeValue();
        }
      }
    });
  }

  playPause() {
    const playerCommandType = this.isPlaying ? PlayerCommandType.pause : PlayerCommandType.resume;

    this.zoneService.createPlayerCommand(playerCommandType, this.zone.id)
      .subscribe(
        () => { },
        (error) => {
          console.log(error);
        }
      );
    //show modal to prevent user doing other activity
    this.dialog.open(PlayerCommandDialogComponent, {
      data: {
        zoneId: this.currentZone.id,
        title: 'Request Received',
        playing: this.currentZone.playing,
        track: this.currentZone.currentTrack,
        playerCommand: playerCommandType,
        message: 'Your command has been sent successfully.\n Awaiting player\'s response, please hold on...',
        buttons: [{
          text: 'OK',
          role: 'cancel',
          cssClass: 'secondary'
        }]
      },
      width: '340px',
      disableClose: true,
    });
  }

  playPrevTrack() {
    this.zoneService.createPlayerCommand(PlayerCommandType.prev, this.zone.id)
      .subscribe(
        () => { },
        (error) => {
          console.log(error);
        }
      );
  }

  // playNextTrack() {
  //   this.zoneService.createPlayerCommand(PlayerCommandType.next, this.zone.id)
  //   .subscribe(
  //     () => {},
  //     (error) => {
  //       console.log(error);
  //     }
  //   );
  // }

  playNextTrack() {
    console.log("zone.currentTrack", this.zone.currentTrack);
    console.log("czone.currentTrack", this.currentZone.currentTrack);
    const playerCommandType = PlayerCommandType.next;

    this.zoneService.createPlayerCommand(playerCommandType, this.zone.id)
      .subscribe(
        () => { },
        (error) => {
          console.log(error);
        }
      );
    //show modal to prevent user doing other activity
    this.dialog.open(PlayerCommandDialogComponent, {
      data: {
        zoneId: this.currentZone.id,
        title: 'Request Received',
        playing: this.currentZone.playing,
        track: this.currentZone.currentTrack,
        playerCommand: playerCommandType,
        message: 'Your command has been sent successfully.\n Awaiting player\'s response, please hold on...',
        buttons: [{
          text: 'OK',
          role: 'cancel',
          cssClass: 'secondary'
        }]
      },
      width: '340px',
      disableClose: true,
    });
    
  }

  toggleLikeDislike(state: boolean) {
    if (this.zone.currentTrack.heartState && this.zone.currentTrack.heartState.like === state) {
      state = null;
    }

    this.trackService.setTrackHeartState(this.zone.currentTrack.id, state)
      .then((heartState) => {
        this.zone.currentTrack.heartState = heartState;
      });
  }

  goToQueue() {
    const dialogRef = this.dialog.open(TrackQueueComponent, {
      data: {
        zone: this.zone
      }
    });

    dialogRef.afterClosed()
      .subscribe((result) => { });
  }

  goToArtist() {
    if (this.zone.currentTrack.artist) {
      this.router.navigate([`/artist/${this.zone.currentTrack.artist.id}`]);
    }
  }

  trackDuration() {
    return this.prepareService.trackDuration(this.zone.currentTrack.duration);
  }

  maxRangeValue() {
    return this.prepareService.trackDurationToSec(this.zone.currentTrack.duration);
  }

}
