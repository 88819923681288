import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Track, Zone } from 'src/app/shared/models/models';
import { Subscription } from 'rxjs';
import { QueueType, QueueReadableType, TrackReadableType } from '../../gql/zone-consts';
import { Store } from '@ngxs/store';
import { ZonesState } from '../../store/zones/zones.state';
import { MediaTypes, SNACK_BAR_DURATION } from '../../models/consts';
import { TracksQueueService } from '../../services/tracks-queue.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { ErrorParserService } from '../../services/error-parcing.service';

interface DialogData {
  zone: Zone;
}

@Component({
  selector: 'app-track-queue',
  templateUrl: './track-queue.component.html',
  styleUrls: ['./track-queue.component.scss']
})

export class TrackQueueComponent implements OnInit, OnDestroy {
  public tracks: { track: Track, type: typeof TrackReadableType }[] = [];
  public tracksToRemoveFromQueue: Track[] = [];
  public type = '';
  public typeReadable = ''; // human readable type name
  public mediaType: MediaTypes;
  public manualPlaylist = false;

  private zoneSub: Subscription;
  constructor(
    public dialogRef: MatDialogRef<TrackQueueComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private store: Store,
    private tracksQueueService: TracksQueueService,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private errorService: ErrorParserService,
    ) { }

  ngOnInit() {
    this.zoneSub = this.store.select(ZonesState.zone(this.data.zone.id))
    .subscribe((zone) => {
      if (zone.currentTrackQueue) {
        this.mediaType = zone.mediaType;
        this.type = zone.currentTrackQueue['__typename'];
        this.typeReadable = QueueReadableType[this.type];
        this.manualPlaylist = QueueType[this.type] === QueueType.ManualTrackQueue;
        this.tracks = zone.currentTrackQueue.tracks
          .filter((track) => this.filterTracks(track, zone.currentTrackQueue.currentPosition))
          .map(this.standardisedTracks);

        // if manual queue exists, merge in with tracks
        // we do this as the current setup as multiple queues and queue types,
        // and this creates the illusion of one
        if (!this.manualPlaylist) {
          const currentTrack = this.tracks.shift();
          zone.manualTrackQueue.tracks
            .filter((track) => this.filterTracks(track, zone.manualTrackQueue.currentPosition + 1))
            .map(this.standardisedTracks)
            .reverse()
            .forEach((track) => this.tracks.unshift(track));
          this.tracks.unshift(currentTrack);
        }
      }
    });
  }

  filterTracks(track, trackPos) {
    return track.position >= trackPos;
  }

  standardisedTracks(track) {
    return {
      track: track.track,
      type: TrackReadableType[track.__typename] || ''
    }
  }

  get MediaTypes() {
    return MediaTypes;
  }

  promptForTrackQueueId = () => {
    this.tracksQueueService.getOverrideTrackQueue(this.data.zone.id)
      .subscribe((res) => {
        this.dialog.open(ConfirmationDialogComponent, {
          data: {
            message: "Are you sure you want to revert current playing queue, back to schedule?",
            buttons: [
              {
                text: 'Cancel',
                role: 'cancel',
                cssClass: 'secondary',
              },
              {
                text: 'Save changes',
                cssClass: 'primary',
                handler: () => {
                  this.revertToSchedule(res.id)
                }
              }
            ]
          }
        });
      });
  };

  revertToSchedule = (overrideQueueId: number) => {
    this.tracksQueueService.deleteOverrideTrackQueue(overrideQueueId)
      .subscribe((res) => {
          this.dialog.closeAll();
          this._snackBar.open(
            `${res ? 'Successfully': 'Failed to'} reverted back to scheduled tracks`,
            null,
            { duration: SNACK_BAR_DURATION }
          );
        }, (error) => {
          const errIdStr = this.errorService.parseError(error);
          this.dialog.open(AlertDialogComponent, {
            data: {
              type: 'error',
              message: `Oops, something went wrong. Please try again.
              ${(!!errIdStr) ? ('Error id: ' + errIdStr) : ''}`
            }
          });
        }
      )
  }

  promptRemoveTrack = (index: number) => {
    const currentTrack = this.tracks[index].track;
    const trackId = currentTrack.id;
    const zoneId = this.data.zone.id;

    this.dialog.open(ConfirmationDialogComponent, {
      id: 'removeQueuedTrackDialog',
      width: '500px',
      data: {
        message: `Are you sure you want to remove
        ${currentTrack.name} by ${currentTrack.artist.name}?`,
        buttons: [
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: "Remove Track",
            cssClass: "primary",
            handler: () => {
              this.tracksQueueService
                .removeTrackFromPlayQueue(trackId, zoneId)
                .subscribe(
                  (data) => {
                    this._snackBar.open(
                      `${data ? 'Successfully': 'Failed when'} removed ${currentTrack.name} from queue`,
                      null,
                      { duration: SNACK_BAR_DURATION }
                    );
                    this.dialog.getDialogById('removeQueuedTrackDialog').close();
                  },
                  (error) => {
                    const errIdStr = this.errorService.parseError(error);
                    this.dialog.open(AlertDialogComponent, {
                      data: {
                        type: "error",
                        message: `Oops, something went wrong. Please try again.
                    ${!!errIdStr ? "Error id: " + errIdStr : ""}`,
                      },
                    });
                  }
                );
            },
          },
        ],
      },
    });
  }

  ngOnDestroy() {
    this.zoneSub.unsubscribe();
  }
}
