import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {LocalStoreService} from '../shared/services/localstore.service';
import {Injectable} from '@angular/core';
import {AuthService} from '../shared/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private localstoreService: LocalStoreService,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isApiRequest = new URL(environment.API_URL).host === new URL(req.url).host;

    if (this.authService.isAuthenticated() && isApiRequest) {
      const token = this.localstoreService.getItemParsed('access_token').loginToken;

      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    return next.handle(req);
  }
}
