import { VenueTypeQuery, VenueTypesQuery, VenueTypeCategoriesQuery, VenueTypeCategoryQuery } from '../gql/venue-type-queries.d';
import { venueType, venueTypeMore, venueTypeCategories, venueTypeCategory, venueTypes } from '../gql/venue-type-queries';

import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VenueTypeService {
  constructor (private apollo: Apollo) { }
  async getVenueTypes() {
    return this.apollo.query<VenueTypesQuery>({
      query: venueTypes,
    }).toPromise();
  }

  async getVenueType(venueTypeId: number) {
    return this.apollo.query<VenueTypeQuery>({
      query: venueType,
      variables: {
        id: venueTypeId
      }
    }).toPromise();
  }

  async getVenueTypeMore(venueTypeId: number, numPlaylist:number, afterPlaylistCursor:string) {
    return this.apollo.query<VenueTypeQuery>({
      query: venueTypeMore,
      variables: {
        id: venueTypeId,
	numPlaylist: numPlaylist,
	afterPlaylistCursor: afterPlaylistCursor,
      }
    }).toPromise();
  }

  async getVenueTypeCategories() {
    return this.apollo.query<VenueTypeCategoriesQuery>({
      query: venueTypeCategories,
    }).toPromise();
  }

  async getVenueTypeCategory(venueTypeCategoryId: number) {
    return this.apollo.query<VenueTypeCategoryQuery>({
      query: venueTypeCategory,
      variables: {
        id: venueTypeCategoryId
      }
    }).toPromise();
  }
}
