import { PromotionInput, PromotionOrderInput, PromotionScheduleInput } from '../../models/inner-models';

import { Promotion } from '../../models/models';

export namespace PromotionAction {

  export class GetPromotions {
    static readonly type = '[Promotions] Get Promotions';
    constructor() {}
  }

  export class UploadPromotion {
    static readonly type = '[Promotions] Upload Promotions';
    constructor(
      public promotionInput: PromotionInput
    ) {}
  }

  export class SetPromotionSchedule {
    static readonly type = '[Promotions] Set Promotion Schedule';
    constructor(
      public promotionVariables: PromotionScheduleInput
    ) {}
  }

  export class GetPromotion {
    static readonly type = '[Promotions] Get Promotion';
    constructor(
      public id: number
    ) {}
  }

  export class EditPromotion {
    static readonly type = '[Promotions] Edit Promotion Name';
    constructor(
      public id: number,
      public name: string
    ) {}
  }

  export class ChangePromotionStatus {
    static readonly type = '[Promotions] Change Status Promotion';
    constructor(
      public id: number,
      public status: boolean
    ) {}
  }

  export class RemovePromoSchedule {
    static readonly type = '[Promotions] Remove Promotion Schedule';
    constructor(
      public ids: number[]
    ) {}
  }

  export class UpdateState {
    static readonly type = '[Promotions] Update State';
    constructor() {}
  }

  export class ReorderPromotionSchedules {
    static readonly type = '[Promotions] Reorders Promotion Schedules in order of array provided';
    constructor(
      public promotionVariables: PromotionOrderInput
    ) {}
  }
}
