<div id="playlist" *ngIf="playlist; else noPlaylist">

  <div class="cover position-relative">

    <div class="playlist-data position-absolute d-flex justify-content-between w-100">
      <div class="info d-flex align-items-end">
        <div class="info-wrapper">
          <div class="name">{{ playlist.name }}</div>
          <div class="genre d-inline-block">{{ playlist.generalGenre }}</div>
          <div class="statistics d-inline-block">{{ playlist.trackCount || 0 }} Tracks <span class="dot d-inline-block rounded-circle"></span> {{ playlist.duration | humanizeDuration }}</div>
        </div>
      </div>

      <div *ngIf="playlist.tracks?.length > 0" class="controls">
        <button class="btn add-btn p-0" [matMenuTriggerFor]="playlistMenu"></button>

        <mat-menu class="mat-menu playlist-menu" #playlistMenu="matMenu">
          <div *ngIf="!playlist.isInLibrary" (click)="addPlaylistToLibrary(playlist.id)" class="menu-item" >
            <div class="menu-item-icon icon-opened-book"></div>
            <span class="menu-item-label">Add to Library</span>
          </div>
          <div *ngIf="playlist.isInLibrary" (click)="deletePlaylist()" class="menu-item">
            <div class="menu-item-icon icon-opened-book"></div>
            <span class="menu-item-label">In Library</span>
            <img class="check-icon" src="../../assets/icons/checkmark.svg" alt="Checkmark icon">
          </div>
          <div class="menu-item" (click)="addToQueue()">
            <div class="menu-item-icon icon-queue"></div>
            <span class="menu-item-label">Add To Playing Queue</span>
          </div>
        </mat-menu>
      </div>
    </div>

  </div>

  <div *ngIf="!!playlist.tracks?.length; else noTrack" class="lists-wrapper d-flex justify-content-space-between">
    <div class="video-preview">
      <ng-container *ngIf="!videoLoading; else spinner">
        <video controls *ngIf="selectedVideoUrl; else unsupportedVideo" (error)="videoLoadError($event)" id="video-container" #video controlsList="nodownload">
          <source [src]="selectedVideoUrl" [type]="selectedVideoUrl | fileMimeType" (error)="getUrlError($event)">
          This browser does not support the HTML5 video element.
        </video>
      </ng-container>
      <ng-template #unsupportedVideo>
        <div class="no-results">
          <img
            src="/assets/images/broken-files.svg"
            class="no-results-img"
            alt="Unsupported file"
          />
          <p class="title">The video has an unsupported format</p>
        </div>
      </ng-template>
    </div>
    <div class="track-list-wrapper">
      <ng-scrollbar appearance=true>
        <ul class="track-list list-unstyled m-0">
          <li *ngFor="let track of tracks">
            <app-clip-item (click)="selectClip(track.id)" [track]="track" [isSelected]="track.id === selectedTrackId"></app-clip-item>
          </li>
        </ul>
      </ng-scrollbar>
    </div>
  </div>

</div>
<ng-template #noPlaylist>
  <div class="no-tracks-message text-uppercase d-flex justify-content-center align-items-center text-center">
    Playlist not found
  </div>
</ng-template>

<ng-template #noTrack>
  <div class="no-tracks-message text-uppercase d-flex justify-content-center align-items-center text-center">
    No Tracks available
  </div>
</ng-template>

<ng-template #spinner>
  <div class="d-flex align-items-center justify-content-center h-100 w-100">
    <app-spinner [isLoading]="videoLoading"></app-spinner>
  </div>
</ng-template>
