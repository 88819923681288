import { Component, Inject, OnDestroy, OnInit, ViewChild, NgZone } from '@angular/core';

import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import { Subscription, Observable } from 'rxjs';

import debounce from 'lodash/debounce';

import { SharedNewTracksContainerDialogComponent } from '../new-tracks-container-dialog/shared-new-tracks-container-dialog.component';
import { Playlist, Track } from '../../models/models';
import { filter, switchMap, tap } from 'rxjs/operators';
import { Store, Select } from '@ngxs/store';
import { PlaylistsState } from '../../store/playlists/playlists.state';
import { PlaylistAction } from '../../store/playlists/playlists.actions';
import { NgScrollbar } from 'ngx-scrollbar';
import { SNACK_BAR_DURATION } from '../../models/consts';

interface DialogData {
  track: Track;
  playlistsWithTrack: number[];
}

@Component({
  selector: 'app-playlist-list-dialog',
  templateUrl: './playlist-list-dialog.component.html',
  styleUrls: ['./playlist-list-dialog.component.scss']
})
export class SharedPlaylistListDialogComponent implements OnInit, OnDestroy {
  @ViewChild(NgScrollbar, { static: false }) scrollbarRef: NgScrollbar;
  private playlistSub: Subscription;
  private scrollSub: Subscription;

  public filteredPlaylists: Playlist[] = [];
  public selectedPlaylistsId: Set<number> = new Set();
  public playlists: Playlist[] = [];
  public isLoading = false;
  public isLoadMore: boolean = false;
  @Select(PlaylistsState.playlists) playlists$: Observable<Playlist[]>;
  @Select(PlaylistsState.hasNext) hasMorePlaylists$: Observable<boolean>;
  @Select(PlaylistsState.errored) errored$: Observable<boolean>;
  public errored: boolean;

  constructor(
    private dialogRef: MatDialogRef<SharedPlaylistListDialogComponent>,
    private dialog: MatDialog,
    private store: Store,
    private ngZone: NgZone,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.searchPlaylist = debounce(this.searchPlaylist, 500);
    this.playlists$ = this.store.select(PlaylistsState.playlists);
    this.hasMorePlaylists$ = this.store.select(PlaylistsState.hasNext);
    this.errored$ = this.store.select(PlaylistsState.errored);
  }

  ngOnInit() {
    this.errored$.subscribe((errored) => this.errored = errored);
    this.isLoading = true;

    const hasPlaylistsinStore = this.store.selectSnapshot(PlaylistsState.isPlaylistsLoaded);
    if (!hasPlaylistsinStore) {
      this.store.dispatch(new PlaylistAction.GetMyPlaylists())
      .subscribe(() => this.isLoading = false);
    }

    this.playlistSub = this.playlists$
    .pipe(
      filter((playlists) => !!playlists)
    )
    .subscribe((playlists) => {
      this.filteredPlaylists = this.playlists = playlists;
      this.isLoading = false;
    });
  }

  ngAfterViewInit() {
    if (this.scrollbarRef) {
      this.scrollSub = this.scrollbarRef.scrolled
      .pipe(
        switchMap(() => this.hasMorePlaylists$),
        filter((val: any) => !this.isLoadMore && val && val.target.scrollBottom < 10),
        tap(() => this.ngZone.run(() => {
          this.isLoadMore = true;
          this.store.dispatch(new PlaylistAction.GetMorePlaylists(20))
          .subscribe(() => this.isLoadMore = false);
        }))
      ).subscribe();
    }
  }

  createNewPlaylist() {
    this.dialog.open(SharedNewTracksContainerDialogComponent, {
      data: {
        tracksContainerType: 'PLAYLIST'
      }
    });
  }

  searchPlaylist(term: string) {
    if (term === '') {
      this.filteredPlaylists = this.playlists;
    } else {
      this.filteredPlaylists = this.playlists.filter((playlist) => {
        return playlist.name.toLowerCase().includes(term.toLowerCase());
      });
    }
  }

  onPlaylistSelected(playlistId: number) {
    if (this.selectedPlaylistsId.has(playlistId)) {
      this.selectedPlaylistsId.delete(playlistId);
    } else {
      this.selectedPlaylistsId.add(playlistId);
    }
  }

  addTrackToPlaylists() {
    this.selectedPlaylistsId.forEach((playlistId) => {
      this.store.dispatch(new PlaylistAction.AddTracksToPlaylist([this.data.track.id], playlistId))
        .subscribe(() => {
          this._snackBar.open(
            `Track successfully added to the playlist ${this.playlists.find(({id}) => id === playlistId).name}`,
            null,
            { duration: SNACK_BAR_DURATION }
          );
        })
    });

    this.dialogRef.close();
  }

  ngOnDestroy() {
    if (this.playlistSub) {
      this.playlistSub.unsubscribe();
    }
  }
}
