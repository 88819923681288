import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { SchedulesAction } from './schedules.actions';


export interface SchedulesStateModel {
  prevStyle: string;
}

@State<SchedulesStateModel> ({
  name: 'schedules',
  defaults: {
    prevStyle: 'cal-time-events',
  }
})

export class SchedulesState implements NgxsOnInit {
  constructor(){}

  ngxsOnInit(ctx?: StateContext<SchedulesStateModel>) {
    ctx.patchState({ prevStyle: 'cal-time-events' });
  }

  @Action(SchedulesAction.UpdatePrevStyle)
  async updatePrevStyle(ctx:StateContext<SchedulesStateModel>, action:SchedulesAction.UpdatePrevStyle) {
    ctx.patchState({prevStyle: action.prevStyle})
  }

  @Selector()
  static prevStyle(state: SchedulesStateModel) {
    return state.prevStyle;
  }
}
