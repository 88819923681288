import { MonitorModeData, PlayerSetting, Zone } from '../models/models';
import {
  addPlaylistToZoneQueue,
  addTrackToPlayQueue,
  createPlayerCommand,
  createZone,
  deleteZone,
  getZones,
  jukeoEnabledZone,
  jukeoSetEnabledZone,
  jukeoSetZonePlaylist,
  jukeoSetZoneTracks,
  jukeoZonePlaylists,
  jukeoZoneTracks,
  makePlaylistAvailableOffline,
  updateZone,
  zoneChangeSubscription,
  zonePlayerEventSubscription,
  zonePlayerMonitorChangeSubscription,
  zonePlayingStateChange
} from '../gql/zone-consts';
import { delay, map, retryWhen, tap } from 'rxjs/operators';
import { resetActivationCodeUsingDevCredentials, setPlayerSettings } from '../gql/player-consts';

import { Apollo } from 'apollo-angular';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { MediaTypes } from '../models/consts';
import { TimerService } from './timer.service';
import { PlayerService } from './player.service';
import { VenueService } from './venue.service';
import { removeTrackFromPlayQueue } from '../gql/track-consts';

@Injectable({
  providedIn: 'root'
})
export class ZoneService {
  private currentZone: Zone;
  private zones: Zone[] = [];

  public onCurrentZoneChanged: BehaviorSubject<Zone> = new BehaviorSubject(null);
  public onZonesChanged: BehaviorSubject<Zone[]> = new BehaviorSubject([]); // not used
  public onPlayingStateTrackQueueIdUpdated: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  constructor(
    private apollo: Apollo,
    private timerService: TimerService,
    private playerService: PlayerService,
    private venueService: VenueService
  ) {
  }

  getZonesByVenue(venueId = this.venueService.getSelectedVenueId(), count = 500, cursor?: string) {
    return this.apollo.query({
      query: getZones,
      variables: {
        venueId,
        first: count,
        after: cursor
      },
      fetchPolicy: 'no-cache'
    })
      .pipe(
        map((data: any) => data.data.zonesConnection)
      );
  }

  zoneChangeSubscription(zoneId: number) {
    return this.apollo.subscribe({
      query: zoneChangeSubscription,
      variables: { id: zoneId, venueId: this.venueService.getSelectedVenueId() }
    })
      .pipe(
        map((data: any) => {
          return data.data.zoneChange;
        })
      )
  }

  createZone(
    name: string,
    address1: string,
    address2: string,
    postcode: string,
    city: string,
    state: string,
    country: string,
    venueId: number,
    mediaType: MediaTypes,
    channelId: number
  ) {
    return this.apollo.mutate({
      mutation: createZone,
      variables: {
        name,
        address1,
        address2,
        postcode,
        city,
        state,
        country,
        venueId: venueId || this.venueService.getSelectedVenueId(),
        mediaType,
        channelId
      }
    })
      .pipe(
        map((data: any) => data.data.createZone)
      );
  }

  zonePlayerEventSubscription(zoneId: number) {
    return this.apollo.subscribe({
      query: zonePlayerEventSubscription,
      variables: { zoneId, venueId: this.venueService.getSelectedVenueId() }
    })
      .pipe(
        retryWhen(errors =>
          errors.pipe(
            tap(err => {
              console.log(err);
            }),
            delay(1000)
          )
        ),
        map((data: any) => data.data.zonePlayerEvent)
      );
  }

  monitorModeSub(zoneId: number) {
    return this.apollo.subscribe({
      query: zonePlayerMonitorChangeSubscription,
      variables: { zoneId }
    })
      .pipe(
        map((data: any) => data.data.zonePlayerMonitorStateChange)
      )
      .subscribe((monitorData: MonitorModeData) => {
        this.timerService.changeTimer(
          zoneId,
          monitorData.playing,
          monitorData.trackDurationSeconds,
          monitorData.trackPositionSeconds
        );
        this.onPlayingStateTrackQueueIdUpdated.next(monitorData.trackQueueId);
        this.playerService.changePlayer(
          zoneId,
          monitorData.playing,
          { //Track
            id: monitorData.trackId,
            name: monitorData.trackName,
            artist: {
              name: monitorData.trackArtistName,
              //dummy values
              id: 'dummy',
              likes: 0,
              countOfTracks: 0,
              artwork: {
                artworkUrl100: monitorData.trackArtworkUrl100,
                artworkUrl1000: monitorData.trackArtworkUrl1000
              }
            },
            album: {
              id: 'test',
              name: monitorData.trackAlbumName
            },
            artwork: {
              artworkUrl100: monitorData.trackArtworkUrl100,
              artworkUrl1000: monitorData.trackArtworkUrl1000
            }
          }
        );
      });
  }

  videoPlayerSubscription(playerId: number) {
    return this.apollo.subscribe({
      query: zonePlayingStateChange,
      variables: { playerId }
    })
      .pipe(
        map((data: any) => {
          return data.data.zonePlayingStateChange;
        })
      )
  }

  createPlayerCommand(playerCommandType: string, zoneId: number, trackPosSeconds?: number) {
    return this.apollo.mutate({
      mutation: createPlayerCommand,
      variables: { zoneId, playerCommandType, trackPosSeconds: (trackPosSeconds) ? trackPosSeconds : null }
    })
      .pipe(
        map((data: any) => data.data.createPlayerCommand)
      );
  }

  updateZone(zoneId, values, venueId?) {
    return this.apollo.mutate({
      mutation: updateZone,
      variables: { zoneId, values, venueId: venueId || this.venueService.getSelectedVenueId() }
    })
      .pipe(
        map((data: any) => data.data.updateZone)
      );
  }

  deleteZone(zoneId: number) {
    return this.apollo.mutate({
      mutation: deleteZone,
      variables: { zoneId }
    })
      .pipe(
        map((data: any) => data.data.deleteZone)
      );
  }

  removeTrackFromPlayQueue(trackId, zoneId) {
    return new Promise<any[]>((resolve, reject) => {
      this.apollo.mutate({
        mutation: removeTrackFromPlayQueue,
        variables: { zoneId, trackId }
      })
        .subscribe(
          (data: any) => {
            // Make changes when API will be
            resolve(data);
          },
          error => {
            console.log(error);
            reject(error);
          }
        );
    });
  }

  removeTracksFromQueue(zoneId, trackIds) {
    const promises = trackIds.map(item =>
      this.removeTrackFromPlayQueue(
        item,
        zoneId
      )
    );
    return Promise.all(promises)
      .then(values => {
        const zoneIndex = this.zones.findIndex(item => item.id === zoneId);
        if (zoneIndex > -1) {
          this.zones[zoneIndex].currentTrackQueue.tracks = this.zones[zoneIndex].currentTrackQueue.tracks
            .filter(track => trackIds.indexOf(track.track.id) === -1);
          if (this.currentZone && this.currentZone.id === zoneId) {
            this.currentZone = this.zones[zoneIndex];
            this.onCurrentZoneChanged.next(this.currentZone);
          }
        }

        this.onZonesChanged.next(this.zones);
      })
      .catch(error => {
        console.log(error);
      });
  }

  addPlaylistToZoneQueue(zoneId: number, playlistId: number, forbidSimilar: boolean = true, shuffle: boolean = false) {
    return new Promise<any[]>((resolve, reject) => {
      this.apollo.mutate({
        mutation: addPlaylistToZoneQueue,
        variables: { zoneId, playlistId, forbidSimilar, shuffle }
      })
        .subscribe(
          (data: any) => {
            this.zones = this.zones.map(item => {
              if (item.id === zoneId) {
                item.overrideTrackQueue = data.data.overrideZoneTracksUsingPlaylistUntilNextTimeslot;
              }
              return item;
            });
            this.onZonesChanged.next(this.zones);
            resolve(data);
          },
          error => {
            console.log(error);
            reject(error);
          }
        );
    });
  }

  addTrackToPlayQueue(trackId, zoneId) {
    return new Promise<any>((resolve, reject) => {
      this.apollo.mutate({
        mutation: addTrackToPlayQueue,
        variables: { trackId, zoneId }
      })
        .subscribe(
          (data: any) => {
            if (data.data.addTrackToPlayQueue) {
              resolve(true);
            }
            reject(data.data.errors);
          },
          error => {
            console.log(error);
            reject(error);
          }
        );
    });
  }

  resetActivationCodeUsingDevCredentials(zoneId: number) {
    return new Promise<any>((resolve, reject) => {
      this.apollo.mutate({
        mutation: resetActivationCodeUsingDevCredentials,
        variables: { zoneId }
      })
        .subscribe(
          (data: any) => {
            if (data.data.resetZoneActivationCode) {
              resolve(data.data.resetZoneActivationCode);
            }
          },
          (error) => {
            console.log(error);
            reject(error);
          }
        );
    });
  }

  setPlayerSettings(playerId: number, values: PlayerSetting[]) {
    return this.apollo.mutate({
      mutation: setPlayerSettings,
      variables: { playerId, values }
    }).toPromise();
  }

  makePlaylistAvailableOffline(playlistId: number, setting: boolean, zoneId?: number) {
    return new Promise<boolean>((resolve, reject) => {
      this.apollo.mutate({
        mutation: makePlaylistAvailableOffline,
        variables: { playlistId, zoneId, setting },
      }).subscribe(
        (data: any) => {
          if (data.data.makePlaylistAvailableOffline) {
            resolve(data.data.makePlaylistAvailableOffline);
          }
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    })
  }

  getJukeosByVenue(venueId = this.venueService.getSelectedVenueId()) {
    return this.apollo.query({
      query: jukeoEnabledZone,
      variables: {
        venueId
      },
      fetchPolicy: 'no-cache'
    })
      .pipe(
        map((data: any) => {
          return data.data.jukeoEnabledZones;
        })
      );
  }

  updateJukeoSettings(zoneIds: number[], enable: boolean, venueId?: number) {
    return this.apollo.mutate({
      mutation: jukeoSetEnabledZone,
      variables: { zoneIds, enable, venueId: venueId || this.venueService.getSelectedVenueId() }
    })
      .pipe(
        map((data: any) => data.data.jukeoSetEnableZone)
      );
  }

  getJukeoZonePlaylists(zoneIds: number[], venueId?: number) {
    return this.apollo.mutate({
      mutation: jukeoZonePlaylists,
      variables: { zoneIds, venueId: venueId || this.venueService.getSelectedVenueId() }
    })
      .pipe(
        map((data: any) => data.data.jukeoZonePlaylists)
      );
  }

  jukeoSetZonePlaylist(zoneId: number, playlistIds: number[], venueId?: number) {
    return this.apollo.mutate({
      mutation: jukeoSetZonePlaylist,
      variables: { zoneId, playlistIds, venueId: venueId || this.venueService.getSelectedVenueId() }
    })
      .pipe(
        map((data: any) => data.data.jukeoSetZonePlaylists)
      );
  }

  getJukeoZoneTracks(zoneIds: number[], venueId?: number) {
    return this.apollo.mutate({
      mutation: jukeoZoneTracks,
      variables: { zoneIds, venueId: venueId || this.venueService.getSelectedVenueId() }
    })
      .pipe(
        map((data: any) => data.data.jukeoZoneTracks)
      );
  }

  jukeoSetZoneTracks(zoneId: number, trackIds: number[], venueId?: number) {
    return this.apollo.mutate({
      mutation: jukeoSetZoneTracks,
      variables: { zoneId, trackIds, venueId: venueId || this.venueService.getSelectedVenueId() }
    })
      .pipe(
        map((data: any) => data.data.jukeoSetZoneTracks)
      );
  }
}
