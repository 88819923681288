import gql from 'graphql-tag';
import { CombinedFragments } from './fragments';

export const getTrack = gql`
  query track(
    $id:Int!
    $venueId: Int!
  ) {
    track(id: $id) {
      ...TrackData
      duration
      heartState(venueId: $venueId){
        like
      }
      playlists {
        id
      }
      isInLibrary(venueId: $venueId)
    }
  }
  ${CombinedFragments.fragments.track}
`;

export const getTrackContentUrl = gql`
  query track(
    $id:Int!
  ) {
    track(id: $id) {
      id
      contentUrl
    }
  }
`;

export const getTracksForLibrary = gql`
  query libraryTracksConnection(
    $venueId: Int!
    $first: Int
    $after: Cursor
    $last: Int
    $before: Cursor
  ) {
    libraryTracksConnection(
      venueId: $venueId
      first: $first
      after: $after
      last: $last
      before: $before
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          track {
            ...TrackData
            isInLibrary(venueId: $venueId)
            heartState(venueId: $venueId) {
              like
            }
            playlists {
              id
            }
          }
        }
      }
    }
  }
  ${CombinedFragments.fragments.track}
`;

export const addTrackToLibrary = gql`
  mutation addTrackToLibrary(
    $trackId: Int!
    $venueId: Int!
  ) {
    addTrackToLibrary(
      trackId: $trackId
      venueId: $venueId
    )
  }
`;

export const removeTrackFromLibrary = gql`
  mutation removeTrackFromLibrary(
    $trackId: Int!
    $venueId: Int!
  ) {
    removeTrackFromLibrary(
      trackId: $trackId
      venueId: $venueId
    )
  }
`;

export const setTrackHeartState = gql`
  mutation updateTrackHeartState (
    $venueId: Int!
    $trackId: Int!
    $like: Boolean
  ) {
    updateTrackHeartState(
      venueId: $venueId
      trackId: $trackId
      like: $like
    ) {
      like
    }
  }
`;

export const addTrackToPlaylist = gql`
  mutation addTrackToPlaylist(
    $trackId: Int!
    $playlistId: Int!
  ) {
    addTrackToPlaylist(
      playlistId: $playlistId
      trackId: $trackId
    )
  }
`;

export const addTracksToPlaylist = gql`
  mutation addTracksToPlaylist(
    $trackIds: [Int!]!
    $playlistId: Int!
  ) {
    addTracksToPlaylist(
      playlistId: $playlistId
      trackIds: $trackIds
    )
  }
`;

export const addTrackToPlayQueue = gql`
  mutation addTrackToPlayQueue(
    $trackId: Int!
    $zoneId: Int!
  ) {
    addTrackToPlayQueue(
      trackId: $trackId
      zoneId: $zoneId
    )
  }
`;

export const removeTrackFromPlayQueue = gql`
  mutation removeTrackFromPlayQueue(
    $trackId: Int!
    $zoneId: Int!
  ) {
    removeTrackFromPlayQueue(
      trackId: $trackId
      zoneId: $zoneId
    )
  }
`;

export const getSimilarTracks = gql`
  query findSimilarTracks(
    $trackIds: [Int!]!
    $perTrackLimit: Int
  ) {
    findSimilarTracks(
      trackIds: $trackIds
      perTrackLimit: $perTrackLimit
    ) {
      ...TrackData
      genre {
        id
        name
      }
    }
  }
  ${CombinedFragments.fragments.track}
`;

export const removeTracksFromLibrary = gql`
  mutation removeTracksFromLibrary(
    $trackIds: [Int!]
    $venueId: Int!
  ) {
    removeTracksFromLibrary(
      trackIds: $trackIds
      venueId: $venueId
    )
  }
`;
