import { PlaylistInput } from '../../models/models';

export namespace NewPlaylistAction {

  export class GetNewPlaylists {
    static readonly type = '[New Playlists] Get Playlists';
    constructor() {}
  }

  export class GetMorePlaylists {
    static readonly type = '[New Playlists] Get More Playlists';
    constructor(
      public count?: number
    ) {}
  }

  export class CreatePlaylist {
    static readonly type = '[New Playlists] Create New New Playlist';
    constructor(
      public playlistInput: PlaylistInput,
      public cover?: any
    ) {}
  }

  export class EditPlaylist {
    static readonly type = '[New Playlists] Edit Playlist';
    constructor(
      public id: number,
      public playlistInput: PlaylistInput,
      public cover?: any
    ) {}
  }

  export class DeletePlaylist {
    static readonly type = '[New Playlists] Delete Playlist';
    constructor(
      public id: number
    ) {}
  }

  export class AddTracksToPlaylist {
    static readonly type = '[New Playlists] Add Tracks To Playlist';
    constructor(
      public trackIds: Array<number>,
      public playlistId: number
    ) {}
  }

  export class RemoveTracksFromPlaylist {
    static readonly type = '[New Playlists] Remove Tracks From Playlist';
    constructor(
      public trackIds: Array<number>,
      public playlistId: number
    ) {}
  }
  export class UpdatePlaylistCover {
    static readonly type = '[New Playlist] Update Playlist Cover';
  }

  export class ChangePlaylistLibraryState {
    static readonly type = '[New Playlists] Change Playlist Library State';
    constructor(
      public playlistIds: number[],
      public isInLibrary: boolean
    ) {}
  }
}