import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SpotifyCallbackComponent } from './spotify-callback.component';
import { SpotifyService } from './shared/services/spotify.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'spotifyCallback',
    component: SpotifyCallbackComponent,
    resolve: { token: SpotifyService }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    { scrollPositionRestoration: 'top' }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
