import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrackListItemComponent } from './track-list-item.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { SharedPlaylistDialogModule } from '../playlist-list-dialog/playlist-list-dialog.module';
import { NgxPermissionsModule } from 'ngx-permissions';

@NgModule({
  declarations: [TrackListItemComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatMenuModule,
    MatSnackBarModule,
    NgxPermissionsModule,
    SharedPlaylistDialogModule
  ],
  exports: [TrackListItemComponent]
})
export class TrackListItemModule { }
