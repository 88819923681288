import gql from 'graphql-tag';

export const schedules = gql`
  query schedules {
    schedules {
      id
      name
      venue {
        id
      }
      isDraft
      createdAt
      zones {
        id
      }
    }
  }
`;

export const createSchedule = gql`
  mutation createSchedule(
    $venueId: Int!
    $name: String!
    $colourCode: String
  ) {
    createSchedule(
      venueId: $venueId,
      name: $name,
      colourCode: $colourCode
    ) {
      id
      name
      zones {
        id
      }
    }
  }
`;

export const editSchedule = gql`
  mutation renameSchedule(
    $id: Int!
    $name: String!
  ) {
    renameSchedule(
      id: $id,
      name: $name
    )
  }
`;

export const updateSchedule = gql`
  mutation updateSchedule(
    $id: Int!
    $values: ScheduleInput!
  ) {
    updateSchedule(
      id: $id,
      values: $values
    ) {
      id
      name
      zones {
        id
      }
      cloneOfSchedule {
        id
      }
    }
  }
`;

export const deleteSchedule = gql`
  mutation deleteSchedule(
    $id: Int!
  ) {
    deleteSchedule(
      id: $id,
    )
  }
`;

export const cloneSchedule = gql`
  mutation cloneSchedule(
    $id: Int!
  ) {
    cloneSchedule(
      id: $id,
    ) {
      id
      cloneOfSchedule {
        id
      }
      name
      isDraft
      zones {
        id
      }
    }
  }
`;

export const allTimeslotsInDayMatchesInWeekFromDay = gql`
  query allTimeslotsInDayMatchesInWeekFromDay(
    $scheduleId: Int!,
    $day: Date
  ) {
    allTimeslotsInDayMatchesInWeekFromDay(
      scheduleId: $scheduleId,
      day: $day
    ) {
      mon
      tue
      wed
      thu
      fri
      sat
      sun
    }
  }
`;

export const deleteOverrideTrackQueue = gql`
  mutation deleteOverrideTrackQueue(
    $overrideQueueId: Int!
  ) {
    deleteOverrideTrackQueue(
      id: $overrideQueueId
    )
  }
`;

export const getOverrideTrackQueue = gql`
  query getOverrideTrackQueue(
    $zoneId: Int!
  ) {
    zone(id: $zoneId) {
      overrideTrackQueue {
        id
        bucket {
          name
        }
        playlist {
          name
          logoImageUrl
        }
        tracks {
          track {
            name
          }
        }
      }
    }
  }
`

export const currentUserZonePermissions = gql`
  query currentUsersZones(
    $venueId: Int!
  ) {
    currentUserZonePermissions(
      venueId: $venueId
    )
  }
`

export const removeTrackFromPlayQueue = gql`
  mutation removeTrackFromPlayQueue(
    $trackId: Int!
    $zoneId: Int!
  ) {
    removeTrackFromPlayQueue(
      trackId: $trackId
      zoneId: $zoneId
    )
  }
`
