<p [title]="zone.name" class="zone-name text-truncate text-center">{{ zone.name }}</p>

<div class="player-details-container d-flex justify-content-between align-items-center" *ngIf="!!zone.currentTrack">
  <div class="track-details-container d-flex align-items-center w-100">
    <div class="album-cover-wrapper">
      <img
        *ngIf="!zone.currentTrack.artwork || !zone.currentTrack.artwork.artworkUrl100"
        src="../../../../assets/images/default-cover.jpg"
        alt="Default album cover"
        class="album-cover"
      />
      <img
        *ngIf="zone.currentTrack.artwork && zone.currentTrack.artwork.artworkUrl100"
        [src]="zone.currentTrack.artwork.artworkUrl100"
        appDefault
        alt="Album cover"
        class="album-cover"
      />
    </div>

    <div class="track-details d-flex flex-column">
      <p [title]="zone.currentTrack.name" class="track-name text-truncate p-0">{{ zone.currentTrack.name }}</p>
      <p
        *ngIf="zone.currentTrack?.artist?.name"
        [title]="zone.currentTrack?.artist?.name"
        class="artist-name text-truncate p-0"
      >
        {{ zone.currentTrack?.artist?.name }}
      </p>
    </div>
  </div>
</div>

<div class="player-slider-container">
  <mat-slider [min]="0" [max]="timer?.duration || maxRangeValue()" [value]="timer?.position" class="player-slider w-100"></mat-slider>
  <div class="player-time d-flex justify-content-end">
    <span class="current-time">{{ timer?.position | formatTime }}</span>
    <span class="total-time">{{ trackDuration() }}</span>
  </div>
</div>

<div class="player-actions-container d-flex justify-content-between align-items-center">
  <div
    (click)="toggleLikeDislike(true)"
    [ngClass]="{
      'icon-heart-empty': !zone.currentTrack?.heartState?.like,
      'icon-heart-filled': zone.currentTrack?.heartState?.like
    }"
    class="icon"
  >
  </div>

  <div class="player-controls-container d-flex align-items-center">
    <button (click)="playPause()" class="btn player-play-btn rounded-circle d-flex justify-content-center align-items-center p-0">
      <div *ngIf="!zone?.playing; else pauseIcon" class="icon icon-play"></div>
      <ng-template #pauseIcon>
        <div class="icon icon-pause"></div>
      </ng-template>
    </button>
    <button (click)="playNextTrack()" class="btn player-next-btn p-0">
      <div class="icon icon-next-track"></div>
    </button>
  </div>

  <div
    (click)="toggleLikeDislike(false)"
    [ngClass]="{
      'icon-heart-broken': zone.currentTrack?.heartState?.like === null || zone.currentTrack?.heartState?.like,
      'icon-heart-broken-filled': zone.currentTrack?.heartState?.like === false
    }"
    class="icon"
  >
  </div>
</div>
