import {
  ADD_ZONE_PERMISSION,
  BUCKET_PERMISSION,
  CHANNEL_PERMISSION,
  CREATE_VENUE_PERMISSION,
  CURATION_PERMISSION,
  DELETE_PLAYLIST_PERMISSION,
  EDIT_PLAYLIST_PERMISSION,
  EDIT_VENUE_PERMISSION,
  PROMOTIONS_PERMISSION,
  REQUEST_ZONE_KEY_PERMISSION,
  VIEW_SCHEDULE_PERMISSION
} from './permissions';

import { uniq } from 'lodash';

const MASTER_ADMIN = [
  CREATE_VENUE_PERMISSION,
  EDIT_VENUE_PERMISSION,
  ADD_ZONE_PERMISSION,
  REQUEST_ZONE_KEY_PERMISSION,
  EDIT_PLAYLIST_PERMISSION,
  DELETE_PLAYLIST_PERMISSION,
  VIEW_SCHEDULE_PERMISSION,
  BUCKET_PERMISSION,
  CHANNEL_PERMISSION,
  PROMOTIONS_PERMISSION,
  CURATION_PERMISSION
];

const VENUE_ADMIN = [
  CREATE_VENUE_PERMISSION,
  EDIT_VENUE_PERMISSION,
  ADD_ZONE_PERMISSION,
  REQUEST_ZONE_KEY_PERMISSION,
  EDIT_PLAYLIST_PERMISSION,
  DELETE_PLAYLIST_PERMISSION,
  VIEW_SCHEDULE_PERMISSION,
  BUCKET_PERMISSION,
  CHANNEL_PERMISSION,
  PROMOTIONS_PERMISSION,
  CURATION_PERMISSION
];

const CUSTOMER_ADMIN = [
  EDIT_PLAYLIST_PERMISSION,
  DELETE_PLAYLIST_PERMISSION,
  VIEW_SCHEDULE_PERMISSION,
  BUCKET_PERMISSION,
  CHANNEL_PERMISSION,
  PROMOTIONS_PERMISSION,
];

const CURATOR = [
  EDIT_PLAYLIST_PERMISSION,
  DELETE_PLAYLIST_PERMISSION,
  BUCKET_PERMISSION,
  CHANNEL_PERMISSION,
  PROMOTIONS_PERMISSION,
  CURATION_PERMISSION,
  VIEW_SCHEDULE_PERMISSION
];

const SCHEDULER = [
  VIEW_SCHEDULE_PERMISSION,
  BUCKET_PERMISSION,
  CHANNEL_PERMISSION,
  PROMOTIONS_PERMISSION,
];

const MANAGER = uniq([...CURATOR, ...SCHEDULER]);

const USER = [
  EDIT_PLAYLIST_PERMISSION,
  BUCKET_PERMISSION
];

const ROLES = {
  admin: MASTER_ADMIN,
  ca: CUSTOMER_ADMIN,
  curator: CURATOR,
  scheduler: SCHEDULER,
  'venue-admin': VENUE_ADMIN,
  manager: MANAGER,
  user: USER,
};

export default ROLES;
