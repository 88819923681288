import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  private sidebarToggleSource = new Subject<boolean>();
  sidebarToggle$ = this.sidebarToggleSource.asObservable();

  toggleSidebar(isVisible: boolean) {
    this.sidebarToggleSource.next(isVisible);
  }
}
