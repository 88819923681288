import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Channel, Playlist } from 'src/app/shared/models/models';
import { UntypedFormGroup, UntypedFormControl, Validators, ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ImageUploadDialogComponent } from 'src/app/shared/components/image-upload-dialog/image-upload-dialog.component';
import * as moment from 'moment';
import { DATE_FORMAT_FOR_BACKEND, MediaTypes } from '../../models/consts';
import { PlaylistFormData } from '../../models/inner-models';
import { PlaylistService } from '../../services/playlist.service';

@Component({
  selector: 'app-playlist-form',
  templateUrl: './playlist-form.component.html',
  styleUrls: ['./playlist-form.component.scss']
})
export class PlaylistFormComponent implements OnInit {
  @Input() playlist?: Playlist;
  @Input() mode: string;
  @Input() mediaType?: MediaTypes = MediaTypes.AUDIO;
  @Output() onSaved: EventEmitter<PlaylistFormData> = new EventEmitter<PlaylistFormData>();

  private coverFile: any;

  public playlistForm: UntypedFormGroup;
  public coverImage: any;
  public loading: boolean = false;
  public minDate = new Date();
  public entityName: string;
  public channelTypes: Channel[];

  constructor(
    private dialog: MatDialog,
    private playlistService: PlaylistService
  ) { }

  ngOnInit() {
    this.entityName = this.mediaType === MediaTypes.AUDIO ? 'Playlist' :
      this.mediaType === MediaTypes.VIDEO ? 'Video Playlist' : 'Channel';

    this.playlistForm = new UntypedFormGroup({
      playlistName: new UntypedFormControl('', [Validators.required]),
      playlistDescription: new UntypedFormControl(''),
      isPlaylistLoved: new UntypedFormControl(false),
      isPlaylistPromoted: new UntypedFormControl(false),
      isPublished: new UntypedFormControl(false),
      lovedStart: new UntypedFormControl(new Date()),
      lovedEnd: new UntypedFormControl(new Date()),
      promotedStart: new UntypedFormControl(new Date()),
      promotedEnd: new UntypedFormControl(new Date()),
      channelId: new UntypedFormControl(-1)
    });

    // if (this.mediaType === MediaTypes.CHANNEL) {
    //   this.playlistForm.controls.channelId.setValidators(channelTypeRequired);
    // }

    if (!this.playlistService.channels) {
      this.playlistService.getChannelTypes()
      .subscribe((channels) => {
        this.channelTypes = channels;
      });
    } else {
      this.channelTypes = this.playlistService.channels;
    }

    if (this.playlist) {
      this.playlistForm.patchValue({
        playlistName: this.playlist.name,
        playlistDescription: this.playlist.description || '',
        isPlaylistLoved: !!this.playlist.loved,
        isPlaylistPromoted: !!this.playlist.promoted,
        isPublished: !!this.playlist.isPublished,
        lovedStart: (!!this.playlist.loved) ? this.playlist.loved.start : new Date(),
        lovedEnd: (!!this.playlist.loved) ? this.playlist.loved.end : new Date(),
        promotedStart: (!!this.playlist.promoted) ? this.playlist.promoted.start : new Date(),
        promotedEnd: (!!this.playlist.promoted) ? this.playlist.promoted.end : new Date(),
        channelId: 
          this.playlist.mediaType === MediaTypes.CHANNEL && !!this.playlist.channels &&
          this.playlist.channels.length ?
          this.playlist.channels[0].id : -1
      });

      if (this.playlist.loved && moment(this.playlist.loved.end).isBefore()) {
        this.playlistForm.patchValue({
          lovedEnd: new Date(),
          lovedStart: new Date(),
          isPlaylistLoved: false
        });
      }

      if (this.playlist.promoted && moment(this.playlist.promoted.end).isBefore()) {
        this.playlistForm.patchValue({
          promotedEnd: new Date(),
          promotedStart: new Date(),
          isPlaylistPromoted: false
        });
      }
    }
  }

  get mediaTypes() {
    return MediaTypes;
  }

  get isPromoted() {
    return this.playlistForm.controls.isPlaylistPromoted.value;
  }

  get isLoved() {
    return this.playlistForm.controls.isPlaylistLoved.value;
  }

  get startPromotedValue() {
    return this.playlistForm.controls.promotedStart.value;
  }

  get startLovedValue() {
    return this.playlistForm.controls.lovedStart.value;
  }

  onLovedDateChange(event) {
    if (event.value > this.playlistForm.controls.lovedEnd.value) {
      this.playlistForm.patchValue({
        lovedEnd: event.value
      });
    }
  }

  onPromotedDateChange(event) {
    if (event.value > this.playlistForm.controls.promotedEnd.value) {
      this.playlistForm.patchValue({
        promotedEnd: event.value
      });
    }
  }

  savePlaylist() {

    if (this.playlistForm.controls.playlistName.invalid) {
      return;
    }

    this.loading = true;
    const playlistInput = {
      name: this.playlistForm.value.playlistName,
      description: this.playlistForm.value.playlistDescription,
      isPublished: this.playlistForm.value.isPublished,
      loved: (this.isLoved) ? {
        start: moment(this.playlistForm.value.lovedStart).format(DATE_FORMAT_FOR_BACKEND),
        end: moment(this.playlistForm.value.lovedEnd).format(DATE_FORMAT_FOR_BACKEND)
      } : null,
      promoted: (this.isPromoted) ? {
        start: moment(this.playlistForm.value.promotedStart).format(DATE_FORMAT_FOR_BACKEND),
        end: moment(this.playlistForm.value.promotedEnd).format(DATE_FORMAT_FOR_BACKEND)
      } : null,
      channelId: this.playlistForm.value.channelId || null
    };

    this.onSaved.emit({playlistInput, coverImage: this.coverFile});
    this.playlistForm.markAsUntouched();
    this.loading = false;
  }

  uploadPlaylistCover() {
    this.dialog.open(ImageUploadDialogComponent).afterClosed().subscribe((data) => {
      if (data) {
        this.playlistForm.markAsTouched();
        this.coverImage = data.image;
        this.coverFile = data.file;
      }
    });
  }

}

export function channelTypeRequired(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    const channelId = control.value;

    return !channelId || channelId < 0 ? { channelTypeRequired: true } : null;
  }
};
