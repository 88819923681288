import { Injectable } from '@angular/core';

import { Apollo } from 'apollo-angular';

import * as moment from 'moment';

import { BehaviorSubject } from 'rxjs';
import { catalogPlaylistsSearch, catalogSearch, curationSearch, searchArtists, searchTracks, searchTracksByArtist } from '../gql/consts';
import { PrepareDataService } from './prepare-data.service';
import { LocalStoreService } from './localstore.service';
import { VenueService } from './venue.service';
import { map, tap } from 'rxjs/operators';
import { SearchValues } from '../models/consts';
import { Searches } from '../models/inner-models';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private recentSearches: any[] = [];
  private searchResult: Searches;

  public onIsSearchingChanges: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public onSearchChanges: BehaviorSubject<Searches> = new BehaviorSubject<Searches>(null);
  public onResentSearchChanges: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  constructor(
    private localstoreService: LocalStoreService,
    private prepareService: PrepareDataService,
    private venueService: VenueService,
    private apollo: Apollo
  ) {
    this.recentSearches = this.localstoreService.getItemParsed('searchHistory') || [];
    this.onResentSearchChanges.next(this.recentSearches);
  }

  searchCatalog(text: string, scope?: SearchValues, limit: number = 5, offset?: number) {
    this.onIsSearchingChanges.next(true);

    return this.apollo.mutate({
      mutation: catalogSearch,
      variables: {
        text,
        scope: scope || null,
        venueId: this.venueService.getSelectedVenueId(),
        limit,
        offset
      }
    })
      .pipe(
        map((data: any) => data.data.createCatalogSearch)
      );
  }

  /**
     * Catalogue Search playlists by string
     * @param size 
     * @param search 
     * @returns 
     */
  searchArtists(text: string, limit: number, offset: number) {
    return this.apollo.query({
      query: searchArtists,
      variables: {
        offset: offset,
        limit: limit,
        text: text,
        scope: 'ARTIST'
      },
      fetchPolicy: 'no-cache'
    })
      .pipe(
        map((data: any) => data.data.rawCatalogSearch.artists)
      );

  }

  /**
     * Catalogue Search playlists by string
     * @param size 
     * @param search 
     * @returns 
     */
  searchTracks(text: string, limit: number, offset: number) {
    return this.apollo.query({
      query: searchTracks,
      variables: {
        offset: offset,
        limit: limit,
        text: text,
        scope: 'TRACK'
      },
      fetchPolicy: 'no-cache'
    })
      .pipe(
        map((data: any) => data.data.rawCatalogSearch.tracks)
      );
  }

  /**
     * Catalogue Search playlists by string
     * @param size 
     * @param search 
     * @returns 
     */
  searchTracksByArtist(trackName: string, artistName: string, limit: number, offset: number, venueId: number) {
    return this.apollo.query({
      query: searchTracksByArtist,
      variables: {
        offset: offset,
        limit: limit,
        trackName: trackName,
        artistName: artistName,
        venueId: venueId
      },
      fetchPolicy: 'no-cache'
    })
      .pipe(
        map((data: any) => data.data.rawCatalogSearchTrackWithArtist.tracks)
      );

  }

  searchCatalogPlaylists(text: string, scope?: SearchValues, limit: number = 5, offset?: number) {
    this.onIsSearchingChanges.next(true);

    return this.apollo.mutate({
      mutation: catalogPlaylistsSearch,
      variables: {
        text,
        scope: scope || null,
        venueId: this.venueService.getSelectedVenueId(),
        limit,
        offset
      }
    })
      .pipe(
        map((data: any) => data.data.createCatalogSearch),
      );
  }

  curationSearch(text: string) {
    const searchVariables = {
      text,
      venueId: this.venueService.getSelectedVenueId()
    };
    return this.apollo.mutate({
      mutation: curationSearch,
      variables: searchVariables
    }).pipe(
      map((data: any) => {
        const searchRes = data.data.createCatalogSearch;
        searchRes.playlists = data.data.createCatalogSearch.playlists.map(item => {
          item.artwork = this.prepareService.playlistArtwork(item);

          return item;
        });

        searchRes.albums = data.data.createCatalogSearch.albums.map(item => {
          item.artwork = this.prepareService.playlistArtwork(item);

          return item;
        });

        return searchRes;
      })
    )
  }

  sortBy(arr, column, fromAtoB = false) {
    const mappedArr = arr.map((x) => x);
    mappedArr.sort((a, b) => {
      const first = a[column], second = b[column];
      if (first > second) {
        return fromAtoB ? 1 : -1;
      }
      if (second > first) {
        return !fromAtoB ? 1 : -1;
      }
      return 0;
    });
    return mappedArr;
  }
}
