import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {NgScrollbarModule} from 'ngx-scrollbar';
import {ChooseVenueComponent} from 'src/app/shared/components/choose-venue/choose-venue.component';
import {AuthRoutingModule} from './auth-routing.module';
import {LoginComponent} from './login/login.component';
import {AuthComponent} from './auth.component';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthInterceptor} from './auth-interceptor';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ImagePreloadModule } from '../shared/directives/image-preload/image-preload.module';
import { SpinnerModule } from '../system/spinner/spinner.module';

@NgModule({
  declarations: [
    LoginComponent,
    AuthComponent,
    ChooseVenueComponent,
    ResetPasswordComponent,
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgScrollbarModule,
    ImagePreloadModule,
    SpinnerModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
  ]
})
export class AuthModule {
}
