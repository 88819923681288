<div class="left-sidebar-menu h-100 position-relative">
  <ng-container *ngIf="!isCuration; else curation;">
    <div class="menu-wrapper">
      <!-- <div class="venue-wrapper" (click)="changeVenue()">
        <p [title]="venue?.name" class="venue-name m-0 text-truncate">{{ venue?.name }}</p>
        <div class="icon icon-chevron-down"></div>
      </div> -->
      <label>Catalogue</label>
      <a routerLink="/catalogue-playlists" routerLinkActive="active-link">
        <div class="menu-item-icon">
          <div class="custom-icon icon-playlist"></div>
        </div>
        <div class="menu-item-text">
          Catalogue
        </div>
      </a>
      <a routerLink="/search" routerLinkActive="active-link">
        <div class="menu-item-icon">
          <div class="custom-icon icon-search"></div>
        </div>
        <div class="menu-item-text">
          Search
        </div>
      </a>

      <label>My Audalize</label>
      <a routerLink="/discover" routerLinkActive="active-link">
        <div class="menu-item-icon">
          <div class="custom-icon icon-home"></div>
        </div>
        <div class="menu-item-text">
          Home
        </div>
      </a>

      <a routerLink="/library/tracks" routerLinkActive="active-link">
        <div class="menu-item-icon">
          <div class="custom-icon icon-song"></div>
        </div>
        <div class="menu-item-text">
          Favourites
        </div>
      </a>

      <a routerLink="/library/playlists" routerLinkActive="active-link">
        <div class="menu-item-icon">
          <div class="custom-icon icon-playlist"></div>
        </div>
        <div class="menu-item-text">
          Playlists
        </div>
      </a>

      <div *ngxPermissionsOnly="'BUCKET_PERMISSION'">
        <a routerLink="/library/buckets" routerLinkActive="active-link">
          <div class="menu-item-icon">
            <div class="custom-icon icon-bucket"></div>
          </div>
          <div class="menu-item-text">
            Buckets
          </div>
        </a>
      </div>

      <div *ngxPermissionsOnly="'CHANNEL_PERMISSION'">
        <a routerLink="/channel-schedule" routerLinkActive="active-link" *ngIf="hasChannelZone">
          <div class="menu-item-icon">
            <div class="custom-icon icon-channel"></div>
          </div>
          <div class="menu-item-text">
            Channels
          </div>
        </a>
      </div>

      <div *ngxPermissionsOnly="'VIEW_SCHEDULE_PERMISSION'">
        <a routerLink="/schedules" routerLinkActive="active-link" class="schedule-icon">
          <div class="menu-item-icon">
            <div class="custom-icon icon-schedule"></div>
          </div>
          <div class="menu-item-text">
            Schedules
          </div>
        </a>
      </div>

      <div *ngxPermissionsOnly="'PROMOTIONS_PERMISSION'">
        <a routerLink="/promotion" routerLinkActive="active-link" *ngIf="hasChannelZone || hasVideoZone">
          <div class="menu-item-icon">
            <div class="custom-icon icon-promotion"></div>
          </div>
          <div class="menu-item-text">
            Promotions
          </div>
        </a>
      </div>

      <!-- <div *ngxPermissionsExcept="'user'"> -->
      <!--   <a routerLink="/zones" routerLinkActive="active-link"> -->
      <!--     <div class="menu-item-icon"> -->
      <!--       <div class="custom-icon icon-zones"></div> -->
      <!--     </div> -->
      <!--     <div class="menu-item-text"> -->
      <!--       Zones -->
      <!--     </div> -->
      <!--   </a> -->
      <!-- </div> -->

      <label>Account</label>
      <div *ngxPermissionsExcept="'user'">
        <a routerLink="/my-teams" routerLinkActive="active-link">
          <div class="menu-item-icon my-team position-relative">
            <div class="custom-icon icon-team"></div>
            <div [class.active]="hasUnreadMessages" class="unread-msg-icon position-absolute rounded-circle"></div>
          </div>
          <div class="menu-item-text">
            Users
          </div>
        </a>
      </div>

      <div *ngIf="!isProd || canSeeJukeo">
        <a routerLink="/jukeo-settings" routerLinkActive="active-link" *ngxPermissionsExcept="'user'">
          <div class="menu-item-icon">
            <div class="custom-icon icon-settings"></div>
          </div>
          <div class="menu-item-text">
            Jukeo Settings
          </div>
        </a>
      </div>

      <div *ngxPermissionsOnly="['ca', 'venue-admin', 'admin']">
        <a routerLink="/venues" routerLinkActive="active-link">
          <div class="menu-item-icon">
            <div class="custom-icon icon-map-flag"></div>
          </div>
          <div class="menu-item-text">
            Venues
          </div>
        </a>
      </div>

      <a routerLink="/curation" (click)="selectMenuItem()" *ngxPermissionsOnly="'CURATION_PERMISSION'">
	<div class="menu-item-icon">
          <div class="custom-icon icon-schedules-curation"></div>
	</div>
	<div class="menu-item-text">
          Curation
	</div>
      </a>

      <a (click)="support()" class="btn-link">
        <div class="menu-item-icon">
          <div class="custom-icon icon-team"></div>
        </div>
        <div class="menu-item-text">
          Support
        </div>
      </a>

      <a (click)="logout()" class="btn-link">
        <div class="menu-item-icon">
          <div class="custom-icon icon-logout"></div>
        </div>
        <div class="menu-item-text">
          Logout
        </div>
      </a>
    </div>
  </ng-container>

  <div class="sidebar-auxiliary-layer position-absolute"></div>
</div>

<ng-template #curation>
  <div class="menu-wrapper">
    <a routerLink="/curation/playlists" routerLinkActive="active-link">
      <div class="menu-item-icon">
        <div class="custom-icon icon-discover"></div>
      </div>
      <div class="menu-item-text">
        Playlists
      </div>
    </a>

    <a routerLink="/curation/video-playlists" routerLinkActive="active-link">
      <div class="menu-item-icon">
        <div class="custom-icon icon-general"></div>
      </div>
      <div class="menu-item-text">
        Video Playlists
      </div>
    </a>

    <a routerLink="/curation/channels" routerLinkActive="active-link">
      <div class="menu-item-icon">
        <div class="custom-icon icon-channels-curation"></div>
      </div>
      <div class="menu-item-text">
        Channels
      </div>
    </a>

    <a routerLink="/curation" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:
      true}">
      <div class="menu-item-icon">
        <div class="custom-icon icon-schedules-curation"></div>
      </div>
      <div class="menu-item-text">
        Curation
      </div>
    </a>

    <a routerLink="/venues" routerLinkActive="active-link">
      <div class="menu-item-icon">
        <div class="custom-icon icon-venue"></div>
      </div>
      <div class="menu-item-text">
        Venues
      </div>
    </a>
  </div>
<ng-template>
