import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';

import { NgScrollbarModule } from 'ngx-scrollbar';

import { TrackItemComponent } from './track-item.component';
import { ImagePreloadModule } from '../../directives/image-preload/image-preload.module';
import { SpinnerModule } from 'src/app/system/spinner/spinner.module';

@NgModule({
  declarations: [TrackItemComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatMenuModule,
    MatSnackBarModule,
    NgScrollbarModule,
    ImagePreloadModule,
    SpinnerModule
  ],
  exports: [TrackItemComponent]
})
export class TrackItemModule { }
