import { Component, OnInit, Inject, OnDestroy, Input } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Track, Zone } from 'src/app/shared/models/models';
import { Subscription, Observable } from 'rxjs';
import { QueueType, QueueReadableType, TrackReadableType } from 'src/app/shared/gql/zone-consts';
import { Store, Select } from '@ngxs/store';
import { ZonesState } from 'src/app/shared/store/zones/zones.state';
import { MediaTypes, SNACK_BAR_DURATION } from 'src/app/shared/models/consts';
import { TracksQueueService } from 'src/app/shared/services/tracks-queue.service';
import { ConfirmationDialogComponent } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { AlertDialogComponent } from 'src/app/shared/components/alert-dialog/alert-dialog.component';
import { ErrorParserService } from 'src/app/shared/services/error-parcing.service';
import { SharedPlaylistListDialogComponent } from 'src/app/shared/components/playlist-list-dialog/playlist-list-dialog.component';
import { TrackService } from 'src/app/shared/services/track.service';
import { ZoneService } from 'src/app/shared/services/zone.service';
import { Router } from '@angular/router';


interface DialogData {
  zone: Zone;
}

@Component({
  selector: 'rhs-track-queue',
  templateUrl: './rhs-track-queue.component.html',
  styleUrls: ['./rhs-track-queue.component.scss']
})

export class RhsTrackQueueComponent implements OnInit, OnDestroy {
  public tracks: { track: Track, type: typeof TrackReadableType }[] = [];
  public tracksToRemoveFromQueue: Track[] = [];
  public type = '';
  public typeReadable = ''; // human readable type name
  public mediaType: MediaTypes;
  public manualPlaylist = false;
  @Input() curZone: Zone;

  private currentZone: Zone;
  private zoneSub: Subscription;
  @Select(ZonesState.currentZone) currentZone$: Observable<Zone>;

  showArtist: boolean = true;

  constructor(
    // public dialogRef: MatDialogRef<RhsTrackQueueComponent>,
    // @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private store: Store,
    private tracksQueueService: TracksQueueService,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private errorService: ErrorParserService,
    private trackService: TrackService,
    private zoneService: ZoneService,
    private router: Router,
  ) { 
    this.currentZone$ = this.store.select(ZonesState.currentZone);
  }

  ngOnInit() {
    //this.zoneSub = this.store.select(ZonesState.zone(this.curZone.id))
    this.zoneSub = this.currentZone$
      .subscribe((zone) => {
        this.currentZone = zone;
        if (zone.currentTrackQueue) {
          this.mediaType = zone.mediaType;
          this.type = zone.currentTrackQueue['__typename'];
          this.typeReadable = QueueReadableType[this.type];
          this.manualPlaylist = QueueType[this.type] === QueueType.ManualTrackQueue;
          this.tracks = zone.currentTrackQueue.tracks
            .filter((track) => this.filterTracks(track, zone.currentTrackQueue.currentPosition))
            .map(this.standardisedTracks);
          // if manual queue exists, merge in with tracks
          // we do this as the current setup as multiple queues and queue types,
          // and this creates the illusion of one
          if (!this.manualPlaylist) {
            const currentTrack = this.tracks.shift();
            zone.manualTrackQueue.tracks
              .filter((track) => this.filterTracks(track, zone.manualTrackQueue.currentPosition + 1))
              .map(this.standardisedTracks)
              .reverse()
              .forEach((track) => this.tracks.unshift(track));
            this.tracks.unshift(currentTrack);
          }
        }
      });
  }

  filterTracks(track, trackPos) {
    return track.position >= trackPos;
  }

  standardisedTracks(track) {
    return {
      track: track.track,
      type: TrackReadableType[track.__typename] || ''
    }
  }

  get MediaTypes() {
    return MediaTypes;
  }

  promptForTrackQueueId = () => {
    this.tracksQueueService.getOverrideTrackQueue(this.curZone.id)
      .subscribe((res) => {
        this.dialog.open(ConfirmationDialogComponent, {
          data: {
            message: "Are you sure you want to revert current playing queue, back to schedule?",
            buttons: [
              {
                text: 'Cancel',
                role: 'cancel',
                cssClass: 'secondary',
              },
              {
                text: 'Save changes',
                cssClass: 'primary',
                handler: () => {
                  this.revertToSchedule(res.id)
                }
              }
            ]
          }
        });
      });
  };

  revertToSchedule = (overrideQueueId: number) => {
    this.tracksQueueService.deleteOverrideTrackQueue(overrideQueueId)
      .subscribe((res) => {
        this.dialog.closeAll();
        this._snackBar.open(
          `${res ? 'Successfully' : 'Failed to'} reverted back to scheduled tracks`,
          null,
          { duration: SNACK_BAR_DURATION }
        );
      }, (error) => {
        const errIdStr = this.errorService.parseError(error);
        this.dialog.open(AlertDialogComponent, {
          data: {
            type: 'error',
            message: `Oops, something went wrong. Please try again.
              ${(!!errIdStr) ? ('Error id: ' + errIdStr) : ''}`
          }
        });
      }
      )
  }

  promptRemoveTrack = (index: number) => {
    const currentTrack = this.tracks[index].track;
    const trackId = currentTrack.id;
    const zoneId = this.curZone.id;

    this.dialog.open(ConfirmationDialogComponent, {
      id: 'removeQueuedTrackDialog',
      width: '500px',
      data: {
        message: `Are you sure you want to remove
        ${currentTrack.name} by ${currentTrack.artist.name}?`,
        buttons: [
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: "Remove Track",
            cssClass: "primary",
            handler: () => {
              this.tracksQueueService
                .removeTrackFromPlayQueue(trackId, zoneId)
                .subscribe(
                  (data) => {
                    this._snackBar.open(
                      `${data ? 'Successfully' : 'Failed when'} removed ${currentTrack.name} from queue`,
                      null,
                      { duration: SNACK_BAR_DURATION }
                    );
                    this.dialog.getDialogById('removeQueuedTrackDialog').close();
                  },
                  (error) => {
                    const errIdStr = this.errorService.parseError(error);
                    this.dialog.open(AlertDialogComponent, {
                      data: {
                        type: "error",
                        message: `Oops, something went wrong. Please try again.
                    ${!!errIdStr ? "Error id: " + errIdStr : ""}`,
                      },
                    });
                  }
                );
            },
          },
        ],
      },
    });
  }

  toggleLikeDislike(track, state: boolean) {
    if (track.heartState && track.heartState.like === state) {
      state = null;
    }

    this.trackService.setTrackHeartState(track.id, state)
      .then((heartState) => {
        track.heartState = heartState;
      });
  }

  addToPlaylist(track) {
    this.dialog.open(SharedPlaylistListDialogComponent, {
      data: {
        track: track,
        playlistsWithTrack: track.playlists.map((playlist) => playlist.id)
      }
    });
  }

  addTrackToLibrary(track) {
    this.trackService.addTrackToLibrary(track.id)
      .then((res) => {
        track.isInLibrary = true;
        this._snackBar.open(
          `Track successfully added to the favourites`,
          null,
          { duration: SNACK_BAR_DURATION }
        );
      })
      .catch((err) => {
        const errIdStr = this.errorService.parseError(err);
        this.dialog.open(AlertDialogComponent, {
          data: {
            type: 'error',
            message: `Oops, something went wrong. Please try again.
          ${(!!errIdStr) ? ('Error id: ' + errIdStr) : ''}`
          }
        });
      });
  }

  removeTrackFromLibrary(track) {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: 'Are you sure you want to remove the track from favourites?',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary'
          },
          {
            text: 'Remove',
            cssClass: 'primary',
            handler: () => {
              this.dialog.closeAll();
              this.trackService.removeTrackFromLibrary(track.id)
                .then(() => {
                  track.isInLibrary = false;
                  this._snackBar.open(
                    `Track successfully removed from the favourites`,
                    null,
                    { duration: SNACK_BAR_DURATION }
                  );
                })
                .catch((err) => {
                  const errIdStr = this.errorService.parseError(err);
                  this.dialog.open(AlertDialogComponent, {
                    data: {
                      type: 'error',
                      message: `Oops, something went wrong. Please try again.
                      ${(!!errIdStr) ? ('Error id: ' + errIdStr) : ''}`
                    }
                  });
                });
            }
          }
        ]
      },
      width: '340px'
    });
  }

  addToPlayingQueue(track) {
    // this.dialog.open(ZonesListComponent)
    //   .afterClosed()
    //   .subscribe(data => {
    //     if (!data) {
    //       return;
    //     }
    //     if (data.error) {
    //       this.dialog.open(AlertDialogComponent, {
    //         data: {
    //           type: 'error',
    //           message: data.error
    //         }
    //       });
    //     } else {
    //       this.confirmAddTrackInQueue(data.id);
    //     }
    //   });
    this.confirmAddTrackInQueue(this.currentZone.id, track.id);
  }

  confirmAddTrackInQueue(zoneId, trackId) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: 'The track will start after the current song finishes',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary'
          },
          {
            text: 'OK',
            cssClass: 'primary',
            handler: () => {
              this.zoneService.addTrackToPlayQueue(trackId, zoneId)
                .then(() => {
                  dialogRef.close();
                  this._snackBar.open(
                    `Track successfully added to the queue`,
                    null,
                    { duration: SNACK_BAR_DURATION }
                  );
                })
                .catch((error) => {
                  const errIdStr = this.errorService.parseError(error);
                  dialogRef.close();
                  this.dialog.open(AlertDialogComponent, {
                    data: {
                      type: 'error',
                      message: `Oops, something went wrong. Please try again.
                    ${(!!errIdStr) ? ('Error id: ' + errIdStr) : ''}`
                    }
                  });
                });
            }
          }
        ]
      },
      width: '340px'
    });
  }

  goToArtist(track) {
    this.router.navigate(['/artist', track.artist.id]);
  }

  addTrackToPlaylist(track) {
    this.dialog.open(SharedPlaylistListDialogComponent, {
      data: {
        track: track,
        //playlistsWithTrack: track.playlists.map((playlist) => playlist.id),
        //here the track data does not have playlists field.
        playlistsWithTrack: [],
      }
    });
  }

  ngOnDestroy() {
    this.zoneSub.unsubscribe();
  }
}
