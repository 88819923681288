import { Component, Inject } from '@angular/core';

import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

interface ConfirmationDialogButton {
  text: string;
  role?: string;
  cssClass?: string;
  handler?: Function;
}

interface DialogData {
  title?: string;
  message: string;
  showSpinner?: boolean;
  buttons: ConfirmationDialogButton[];
}

@Component({
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  public toggleSpinner() {
    this.data.showSpinner = !this.data.showSpinner;
  }
}
