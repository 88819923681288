import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';

import { Observable } from 'rxjs';

import { LocalStoreService } from './localstore.service';
import { SpotifyHardcodedOptions, SpotifyLimits } from '../models/consts';
declare global {
  interface Window {
    spotifyCallback: any;
  }
}
@Injectable({
  providedIn: 'root'
})
export class SpotifyService {
  private nextPlaylistsURL: string;

  constructor(
    private http: HttpClient,
    private localstoreService: LocalStoreService
  ) { }

  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
    window.opener.spotifyCallback(new URLSearchParams(route.fragment));
  }

  authorize() {
    return new Promise<any>((resolve) => {
      const clientId = environment.SPOTIFY.CLIENT_ID;
      const redirectUrl = `${window.location.origin}/spotifyCallback`;
      const scopes = ['playlist-read-private'];

      const authPopup = window.open(
        `https://accounts.spotify.com/authorize?client_id=${clientId}&response_type=token&redirect_uri=${redirectUrl}&scope=${scopes}`,
        'Login Spotify',
        'width=800,height=600'
      );

      window['spotifyCallback'] = (searchParams) => {
        this.localstoreService.setItemStringified('spotify_token', searchParams.get('access_token'));
        this.localstoreService.setItemStringified('spotify_token_expire_date', Date.now() + searchParams.get('expires_in') * 1000);

        delete window['spotifyCallback'];

        authPopup.close();
        resolve(undefined);
      };
    });
  }

  isAuthorized() {
    const token = this.localstoreService.getItemParsed('spotify_token');
    const expiredDate = this.localstoreService.getItemParsed('spotify_token_expire_date');
    const isTokenExpired = expiredDate ? Date.now() >= expiredDate : true;

    return token && !isTokenExpired;
  }

  getSpotifyPlaylists(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.http.get(`${environment.SPOTIFY.API_URL}me/playlists?limit=${SpotifyLimits.PLAYLISTS_LIMIT}`, this.getHttpOptions())
        .subscribe(
          (data: any) => {
            if (data.items && data.items.length > 0) {
              this.nextPlaylistsURL = data.next;

              resolve(data.items);
            }
            resolve([]);
          },
          (error) => {
            if (error && error.status === 401) {
              this.localstoreService.removeItem('spotify_token');
              this.localstoreService.removeItem('spotify_token_expire_date');
              reject();
            }

            reject(error);
          }
        );
    });
  }

  getMoreSpotifyPlaylists(): Promise<any> {
    if (this.nextPlaylistsURL) {
      return new Promise<any>((resolve, reject) => {
        this.http.get(this.nextPlaylistsURL, this.getHttpOptions())
          .subscribe(
            (data: any) => {
              if (data.items && data.items.length > 0) {
                this.nextPlaylistsURL = data.next;

                resolve(data.items);
              }
              resolve([]);
            },
            (error) => {
              if (error && error.status === 401) {
                this.localstoreService.removeItem('spotify_token');
                this.localstoreService.removeItem('spotify_token_expire_date');
              }

              reject(error);
            }
          );
      });
    }

    return Promise.resolve([]);
  }

  getSpotifyPlaylistTracks(url, offset, limit): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.http.get(`${url}?offset=${offset}&limit=${limit}`, this.getHttpOptions())
        .subscribe(
          (data: any) => {
            if (data.items && data.items.length > 0) {
              const tracks = data.items.filter((item) => item.track);

              resolve(tracks);
            }

            resolve([]);
          },
          (error) => {
            console.log(error);
            reject(error);
          }
        );
    });
  }

  getSpotifyRecommended(tracksSeed: string, genreSeed?: string, artistsSeed?: string): Observable<any> {
    return this.http.get(`${environment.SPOTIFY.API_URL}recommendations?`
      + `limit=${SpotifyLimits.RECOMMENDED_TRACKS_LIMIT}&market=${SpotifyHardcodedOptions.market}`
      + `&seed_tracks=${tracksSeed}`, this.getHttpOptions());
  }

  // correct type: Promise<SpotifyApi.SearchResponse>
  async searchSpotify(query: string, types: string[], options: Record<string, any>): Promise<any> {
    const params = new URLSearchParams({
      ...options,
      type: types.join(','),
      q: query,
    });

    return this.http.get(`${environment.SPOTIFY.API_URL}search?${params}`, this.getHttpOptions()).toPromise();;
  }

  // correct type: Promise<SpotifyApi.PlaylistTrackResponse>
  async getPlaylistTracks(playlistId: string): Promise<any> {
    return this.http.get(`${environment.SPOTIFY.API_URL}playlists/${playlistId}/tracks`, this.getHttpOptions()).toPromise();
  }

  getHttpOptions() {
    const token = this.localstoreService.getItemParsed('spotify_token');

    return {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token
      })
    };
  }
}
