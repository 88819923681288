<div class="right-sidebar-menu h-100 position-relative">
	<div class="menu-wrapper">
		<div *ngIf="zonesWithPlayer.length > 0" class="player-container">
			<!-- <div class="header-text">Now Playing</div> -->
			<div class="player-header d-flex justify-content-between align-items-center">
				<div class="header-text-top">Now Playing</div>
				<!-- <div (click)="goToQueue()" class="icon icon-queue"></div> -->
			</div>
			<div class="player-wrapper position-relative">
				<div class="players-carousel-nav nav-container"></div>
				<div class="carousel-container">
					<owl-carousel #owlMenuCarousel [options]="{
		       items: 1,
		       dots: false,
		       mouseDrag: false,
		       nav: true,
		       navContainer: '.players-carousel-nav',
		       navClass: ['btn owl-prev-menu', 'btn owl-next-menu'],
		       onChanged: onZoneChanged
		       }" [items]="zoneIdArr">
						<div *ngFor="let id of zoneIdArr; let i = index" class="player-group-wrapper">
							<ng-container *ngIf="activeZoneIndex === i">
								<app-player-item [zone]="zonesWithPlayer[i]" [timer]="timers[id]"></app-player-item>
								<div class="header-text">Playing From</div>
								<ng-container *ngIf="todayTimeslots && todayTimeslots.length > 0">
									<app-playing-timeslot [zone]="zonesWithPlayer[i]" [atimeslot]="todayTimeslots[0]"
										[timeslots]="todayTimeslots">
									</app-playing-timeslot>
								</ng-container>
								<ng-container *ngIf="!todayTimeslots || todayTimeslots.length === 0">
									<app-playing-timeslot [zone]="zonesWithPlayer[i]" [atimeslot]="null"
										[timeslots]="[]">
									</app-playing-timeslot>
								</ng-container>
							</ng-container>
						</div>
					</owl-carousel>

					<div class="action-line">
						<div class="mini-btn" (click)="goToPlaylist(0)">
							<div class="icon icon-plus-primary"></div>
							<div class="txt-mini-btn">Queue Playlist</div>
						</div>
						<div class="mini-btn" (click)="goToBucket(0)">
							<div class="icon icon-plus-primary"></div>
							<div class="txt-mini-btn">Queue Bucket</div>
						</div>
						<div class="mini-btn" (click)="goToTrack()">
							<div class="icon icon-plus-primary"></div>
							<div class="txt-mini-btn">Queue Track</div>
						</div>
					</div>

					<div class="switcher">
						<label>
							<button class="label revert-schedule" (click)="promptRevertToSchedule()">Revert to
								Schedule</button>
						</label>
					</div>

					<div class="switcher">
						<label>
							<input [checked]="view === 'NOW'" (change)="setView('NOW')" value="NOW" type="radio" />
							<div class="label">Playing Now</div>
						</label>

						<label>
							<input [checked]="view === 'HISTORY'" (change)="setView('HISTORY')" value="HISTORY"
								type="radio" />
							<div class="label">History</div>
						</label>
					</div>
					<div [hidden]="view !== 'NOW'">
						<rhs-track-queue [curZone]="zone"> </rhs-track-queue>
					</div>
					<div [hidden]="view === 'NOW'">
						<rhs-zone-history> </rhs-zone-history>
					</div>
				</div>
			</div>
		</div>

	</div>
	<div class="sidebar-auxiliary-layer position-absolute"></div>
</div>