import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { append, patch, updateItem } from '@ngxs/store/operators'

import { Venue } from 'src/app/shared/models/models';
import { VenueAction } from '../venue/venue.actions';
import { VenueListAction } from './venue-list.actions';
import { VenueService } from 'src/app/shared/services/venue.service';
import { VenueState } from '../venue/venue.state';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

type Context = StateContext<VenueListStateModel>;

export interface VenueListStateModel {
  venues: Venue[];
  minimalVenues: Venue[];
  isVenuesLoaded: boolean;
  lastVenueActionedId: number;
}

@State<VenueListStateModel>({
  name: 'venueList',
  defaults: {
    venues: [],
    minimalVenues: [],
    isVenuesLoaded: false,
    lastVenueActionedId: null
  },
})
@Injectable()
export class VenueListState {

  constructor(
    private venueService: VenueService,
    private store: Store
    ) {}

  @Action(VenueListAction.GetVenues)
  fetch({ patchState }: Context, { nameLike }: VenueListAction.GetVenues) {
    return this.venueService.getVenues(nameLike)
      .pipe(
        tap((venues: Venue[]) => {
          patchState({
            venues: [ ...venues ],
            isVenuesLoaded: true
          })
        })
      );
  }

  @Action(VenueListAction.GetVenuesList)
  fetchList({ patchState }: Context, { nameLike }: VenueListAction.GetVenuesList) {
    return this.venueService.getVenuesList(nameLike)
      .pipe(
        tap((venues: Venue[]) => {
          patchState({
            minimalVenues: [ ...venues ]
          })
        })
      );
  }

  @Action(VenueListAction.AddVenue)
  add({ setState, getState }: Context, { venueVariables }: VenueListAction.AddVenue) {
    const state = getState();

    return this.venueService.createVenue(venueVariables)
    .pipe(
      tap((newVenue: Venue) => {
        setState(
          patch({
            venues: append([newVenue]),
            lastVenueActionedId: newVenue.id
          })
        );
      })
    );
  }

  @Action(VenueListAction.EditVenue)
  edit({ setState, getState }: Context, { venueVariables, id }: VenueListAction.EditVenue) {
    const state = getState();

    return this.venueService.updateVenue(id, venueVariables)
    .pipe(
      tap((updatedVenue: Venue) => {
        setState(
          patch({
            venues: updateItem<Venue>(venue => venue.id === id, updatedVenue),
            lastVenueActionedId: id
          })
        );
        if (this.store.selectSnapshot(VenueState.venueId) === id) {
          this.store.dispatch(new VenueAction.EditVenue(updatedVenue))
        }
      })
    );
  }

  @Action(VenueListAction.UpdateVenueLogo)
  logo({ setState, getState }: Context, { venueId }: VenueListAction.UpdateVenueLogo) {
    const state = getState();
    return this.venueService.updateVenueLogoImage(venueId)
    .pipe(
      tap((logoUrl: string) => {
        const updatedVenue: Venue = {
          ...state.venues.find((x) => x.id === venueId),
          logoImageUrl: logoUrl
        };

        setState(
          patch ({
            venues: updateItem<Venue>(venue => venue.id === venueId, updatedVenue),
            lastVenueActionedId: venueId
          })
        );

        if (this.store.selectSnapshot(VenueState.venueId) === venueId) {
          this.store.dispatch(new VenueAction.EditVenue(updatedVenue))
        }
      })
    );
  }

  @Action(VenueListAction.UpdateVenueBanner)
  banner({ setState, getState }: Context, { venueId }: VenueListAction.UpdateVenueBanner) {
    const state = getState();
    return this.venueService.updateVenueBannerImage(venueId)
    .pipe(
      tap((bannerUrl: string) => {
        const updatedVenue: Venue = {
          ...state.venues.find((x) => x.id === venueId),
          bannerImageUrl: bannerUrl
        };

        setState(
          patch({
            venues: updateItem<Venue>(venue => venue.id === venueId, updatedVenue),
            lastVenueActionedId: venueId
          })
        );

        if (this.store.selectSnapshot(VenueState.venueId) === venueId) {
          this.store.dispatch(new VenueAction.EditVenue(updatedVenue))
        }
      })
    );
  }

  @Selector()
  static venues(state: VenueListStateModel): Venue[] {
    return state.venues;
  }

  @Selector()
  static minimalVenues(state: VenueListStateModel): Venue[] {
    return state.minimalVenues;
  }

  @Selector()
  static isVenuesLoaded(state: VenueListStateModel): boolean {
    return state.isVenuesLoaded;
  }

  @Selector()
  static lastVenueActionedId(state: VenueListStateModel): number {
    return state.lastVenueActionedId;
  }
}
