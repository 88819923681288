<div class="dialog-header">
  <h2 mat-dialog-title>{{ data && data.zone ? 'Edit' : 'Create' }} Zone</h2>
  <button mat-dialog-close class="btn close-dialog-btn"></button>
</div>

<mat-dialog-content>
  <form [formGroup]="form">
    <div class="zone-modal">

      <div class="flex-row">
        <div class="flex-col">
          <div class="form-group">
            <label for="zoneName">Zone Name</label>
            <input
              placeholder="Enter Zone Name"
              type="text"
              formControlName="name"
              id="zoneName"
              class="form-control"
            />
          </div>
        </div>
        <div class="flex-col">
          <div class="form-group">
            <label for="zoneLocationStoreName">Location / Store Name (Optional)</label>
            <input
              placeholder="Enter Location / Store Name"
              type="text"
              formControlName="storeName"
              id="zoneLocationStoreName"
              class="form-control"
            />
          </div>
        </div>
      </div>

      <div class="flex-row">
        <div class="flex-col">
          <div class="form-group">
            <label for="zoneAddress1">Address Line 1</label>
            <input
              placeholder="Enter Address 1"
              type="text"
              formControlName="address1"
              id="zoneAddress1"
              class="form-control"
            />
          </div>
        </div>
        <div class="flex-col">
          <div class="form-group">
            <label for="zoneAddress2">Address Line 2 (Optional)</label>
            <input
              placeholder="Enter Address 2"
              type="text"
              formControlName="address2"
              id="zoneAddress2"
              class="form-control"
            />
          </div>
        </div>
      </div>

      <div class="flex-row">
        <div class="flex-col">
          <div class="form-group">
            <label for="zoneZipCode">Zip / Post Code (Optional)</label>
            <input
              placeholder="Enter Zip Code"
              type="text"
              formControlName="postcode"
              id="zoneZipCode"
              class="form-control"
            />
          </div>
        </div>
        <div class="flex-col">
          <div class="form-group">
            <label for="zoneCity">City (Optional)</label>
            <input
              placeholder="Enter City"
              type="text"
              formControlName="city"
              id="zoneCity"
              class="form-control"
            />
          </div>
        </div>
      </div>

      <div class="flex-row">
        <div class="flex-col">
          <div class="form-group">
            <label for="zoneStateCountry">State / Country (Optional)</label>
            <input
              placeholder="Enter State / Country"
              type="text"
              formControlName="state"
              id="zoneStateCountry"
              class="form-control"
            />
          </div>
        </div>
        <div class="flex-col">
          <div class="form-group">
            <label for="zoneCountry">Country</label>
            <input
              placeholder="Enter Country"
              type="text"
              formControlName="country"
              id="zoneCountry"
              class="form-control"
            />
          </div>
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-col">
          <div class="form-group">
            <label for="mediaType">Media Type</label>
            <div class="select-wrapper">
              <button
                mat-button
                class="zone-selector"
                (click)="zoneTypeSelect.open()"
                disableRipple
              >
                <div class="d-flex justify-content-between align-items-center">
                  <span class="zone-name"
                    [class.isPlaceholder]="!zoneTypeSelect.triggerValue"
                  >
                    {{zoneTypeSelect.triggerValue || 'Select Media Type'}}
                  </span>
                  <div class="icon icon-chevron-down"></div>
                </div>
              </button>
              <mat-select class="media-select form-control" #zoneTypeSelect formControlName="mediaType">
                <mat-option *ngFor="let type of mediaTypes" [value]="type.mediaType">
                  {{type.name}}
                </mat-option>
              </mat-select>
            </div>
          </div>
        </div>
        <div class="flex-col" *ngIf="form && mediaTypeControl === MediaTypes.CHANNEL">
          <div class="form-group">
            <label for="channelType">Channel Type</label>
            <div class="select-wrapper">
              <button
                mat-button
                class="zone-selector"
                (click)="channelTypeSelect.open()"
                disableRipple
              >
                <div class="d-flex justify-content-between align-items-center">
                  <span class="zone-name"
                    [class.isPlaceholder]="!channelTypeSelect.triggerValue"
                  >
                    {{channelTypeSelect.triggerValue || 'Select Channel Type'}}
                  </span>
                  <div class="icon icon-chevron-down"></div>
                </div>
              </button>
              <mat-select class="media-select form-control" #channelTypeSelect formControlName="channelId">
                <mat-option *ngFor="let type of channelTypes" [value]="type.id">
                  {{type.name}}
                </mat-option>
              </mat-select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>

<div mat-dialog-actions class="align-right">
  <button [disabled]="form.invalid || isSaving" (click)="saveZone()" class="btn save-zone-btn">
    {{ data && data.zone ? 'Edit' : 'Create' }} Zone
  </button>
</div>
