<div *ngIf="data.title" class="dialog-header">
  <h2 mat-dialog-title>{{ data.title }}</h2>
</div>
<div [ngClass]="{ 'centered': !data.title }" mat-dialog-content>{{ data.message }}</div>
<app-spinner *ngIf="data.showSpinner" isLoading="true"></app-spinner>
<div mat-dialog-actions>
  <ng-container *ngFor="let button of data.buttons">
    <button
      *ngIf="button.role === 'cancel'"
      (click)="button.handler && button.handler()"
      [ngClass]="button.cssClass"
      [mat-dialog-close]="true"
      class="btn"
    >
      {{ button.text }}
    </button>
    <button
      *ngIf="button.role !== 'cancel'"
      (click)="button.handler()"
      [ngClass]="button.cssClass"
      class="btn"
    >
      {{ button.text }}
    </button>
  </ng-container>
</div>
