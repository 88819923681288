<div class="position-relative overflow-hidden album-cover-container" (click)="goToAlbum()">
  <div class="cover-wrapper overflow-hidden">
    <img
      [src]="(album.tracks.length && album.tracks[0].artwork?.artworkUrl100) ?
          coverToSize(album.tracks[0].artwork.artworkUrl100) : '/assets/images/default-cover.jpg'"
      class="cover w-100 h-100"
      appDefault
      alt="Album cover"
    />
  </div>
</div>
<div [title]="album.name" class="album-name text-truncate">{{ album.name }}</div>
<div class="album-data text-truncate">
  {{ album.countOfTracks }} Tracks<span class="dot rounded-circle d-inline-block"></span>{{ album.durationString }}
</div>
