import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PromotionOrderInput, PromotionScheduleInput } from '../models/inner-models';
import { changePromoName, getPromotion, getPromotions, removePromoSchedule, setPromotionSchedule, setPromotionScheduleOrder } from '../gql/promotions-consts';
import { map, switchMap } from 'rxjs/operators';

import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { LocalStoreService } from './localstore.service';
import { environment } from 'src/environments/environment';
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PromotionService {

  constructor(
    private apollo: Apollo,
    private http: HttpClient,
    private localstoreService: LocalStoreService
  ) { }

  uploadPromotionImage(promotionFile: File, name: string, durationSec?: number) {
    return from(this.fileParcer(promotionFile))
    .pipe(
      switchMap((fileToUpload) => this.http.post(
        `${environment.API_UPLOAD_BASE_URL}/uploadPromotionPicture/${promotionFile.name}`,
        fileToUpload,
        {
          headers: new HttpHeaders({
            'promotion-name': name
          })
        }
      ))
    );
  }

  uploadPromotionVideo(videoFile: File, name: string) {
    return from(this.fileParcer(videoFile))
    .pipe(
      switchMap((fileToUpload) => this.http.post(
        `${environment.API_UPLOAD_BASE_URL}/uploadPromotionVideo/${videoFile.name}`,
        fileToUpload,
        {
          headers: new HttpHeaders({
            'promotion-name': name
          })
        }
      ))
    );
  }

  editPromotion(promotionId: number, name: string) {
    return this.apollo.mutate({
      mutation: changePromoName,
      variables: { id: promotionId, name }
    })
    .pipe(
      map((data: any) => data.data.updatePromotion)
    )
  }

  setPromotionSchedule({
    promotionId,
    zoneIds,
    status,
    weekDays,
    fromDate,
    toDate,
    fromTime,
    toTime,
    policies,
    promotionContentOrder
  }: PromotionScheduleInput) {
    return this.apollo.mutate({
      mutation: setPromotionSchedule,
      variables: {
        promotionId,
        zoneIds,
        status,
        weekDays,
        fromDate,
        toDate,
        fromTime,
        toTime,
        policies,
        promotionContentOrder
      }
    })
    .pipe(
      map((data: any) => data.data.setPromotionSchedule)
    );
  }

  getPromotions() {
    return this.apollo.query({
      query: getPromotions,
      variables: {id: +this.localstoreService.getItem('venue.id')},
    })
    .pipe(
      map((data: any) => data.data.venue.promotions)
    )
  }

  getPromotionById(id: number) {
    return this.apollo.query({
      query: getPromotion,
      variables: { id },
      fetchPolicy: 'no-cache'
    })
    .pipe(
      map((data: any) => data.data.promotion)
    );
  }

  removeSchedule(id: number) {
    return this.apollo.mutate({
      mutation: removePromoSchedule,
      variables: { id },
      refetchQueries: [{
        query: getPromotions,
        variables: {
          id: +this.localstoreService.getItem('venue.id')
        }
      }],
      awaitRefetchQueries: true
    })
    .pipe(
      map((data: any) => data.data.deletePromotionSchedule)
    )
  }

  fileParcer(file: File) {
    return new Promise<any>((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (err) => {
        reject(err);
      }
    })
  }

  orderPromotionSchedule({
    promotionIdsInOrder
  }: PromotionOrderInput) {
    return this.apollo.mutate({
      mutation: setPromotionScheduleOrder,
      variables: { promotionIdsInOrder },
      refetchQueries: [{
        query: getPromotions,
        variables: {
          id: +this.localstoreService.getItem('venue.id')
        }
      }],
      awaitRefetchQueries: true
    })
    .pipe(
      map((data: any) => data.data.setPromotionScheduleOrder)
    );
  }

}
