import { Action, State, StateContext, Selector } from '@ngxs/store';
import { NewPlaylistAction } from './new-playlists.actions';
import { PlaylistService } from '../../services/playlist.service';
import { Playlist, PaginationInfo } from '../../models/models';
import { tap, map, switchMap } from 'rxjs/operators';
import { from, forkJoin, of } from 'rxjs';
import { PlaylistPrepare } from '../../utils/prepare-playlist';
import { ImageUploadService } from '../../services/image-upload.service';
import { MediaTypes } from '../../models/consts';
import { patch, updateItem } from '@ngxs/store/operators';
import { Injectable } from '@angular/core';

type Context = StateContext<NewPlaylistsStateModel>;

export interface NewPlaylistsStateModel {
  playlists: Playlist[];
  isPlaylistsLoaded: boolean;
  hasNextPage: boolean;
  nextCursor: string;
  lastUpdated: Playlist | null;
}

@State<NewPlaylistsStateModel>({
  name: 'newPlaylists',
  defaults: {
    playlists: null,
    isPlaylistsLoaded: false,
    hasNextPage: false,
    nextCursor: null,
    lastUpdated: null
  }
})
@Injectable()
export class NewPlaylistsState {

  constructor(
      private playlistService: PlaylistService,
      private imageUploadService: ImageUploadService
    ) {}

  @Action(NewPlaylistAction.GetNewPlaylists)
  fetch({patchState}: StateContext<NewPlaylistsStateModel>) {
    return this.playlistService.getSystemPlaylistsConnection(null, 20)
      .pipe(
        tap((paginationInfo: PaginationInfo) => {
          patchState({
            hasNextPage: paginationInfo.pageInfo.hasNextPage,
            nextCursor: paginationInfo.pageInfo.endCursor
          });
        }),
        map(({edges}) => edges.map(({node}) => node)),
        tap((playlists: Playlist[]) => {

          // add artwork to playlist or create collage
          playlists = playlists.map(item => {
            item.artwork = PlaylistPrepare.videoPlaylistArtwork(item);
            return item;
          });
          patchState({
            playlists,
            isPlaylistsLoaded: true
          });
        })
      );
  }

  @Action(NewPlaylistAction.GetMorePlaylists)
  getMore({getState, patchState}: StateContext<NewPlaylistsStateModel>, { count }: NewPlaylistAction.GetMorePlaylists) {
    const state = getState();
    return this.playlistService.getSystemPlaylistsConnection(state.nextCursor)
      .pipe(
        tap((paginationInfo: PaginationInfo) => {
          patchState({
            hasNextPage: paginationInfo.pageInfo.hasNextPage,
            nextCursor: paginationInfo.pageInfo.endCursor
          });
        }),
        map(({edges}) => edges.map(({node}) => node)),
        tap((playlists: Playlist[]) => {
          // add artwork to playlist or create collage
          playlists = playlists.map(item => {
            item.artwork = PlaylistPrepare.videoPlaylistArtwork(item);
            return item;
          });
          
          patchState({
            playlists: [...state.playlists, ...playlists]
          });
        })
      );
  }

  @Action(NewPlaylistAction.CreatePlaylist)
  create({getState, patchState}: StateContext<NewPlaylistsStateModel>, { playlistInput, cover }: NewPlaylistAction.CreatePlaylist) {
    const state = getState();
    let newPlaylist: Playlist;
    return this.playlistService.createNewPlaylist({...playlistInput, mediaType: MediaTypes.AUDIO})
    .pipe(
      tap((playlist) => newPlaylist = playlist),
      switchMap((playlist) => {
        if(!!cover) {
          return from(this.imageUploadService.uploadPlaylistCover(cover, playlist.id))
            .pipe(
              switchMap(() => this.playlistService.updatePlaylistCover(playlist.id)),
              map((logoImageUrl: string) => {
                newPlaylist.logoImageUrl = logoImageUrl;
                return newPlaylist;
              })
            )
        }
        return of(playlist);
      }),
      tap((playlist: Playlist) => {
        playlist.artwork = PlaylistPrepare.videoPlaylistArtwork(playlist);
        const playlists = PlaylistPrepare.sortPlaylists([...state.playlists, playlist]);
        patchState({
          playlists,
          lastUpdated: playlist
        });
      })
    );
  }

  @Action(NewPlaylistAction.EditPlaylist)
  edit({getState, patchState}: StateContext<NewPlaylistsStateModel>, { id, playlistInput, cover }: NewPlaylistAction.EditPlaylist) {
    const state = getState();
    let newPlaylist: Playlist;
    return this.playlistService.updateNewPlaylist(id, playlistInput)
    .pipe(
      tap((playlist) => newPlaylist = playlist),
      switchMap((playlist) => {
        if(!!cover) {
        return from(this.imageUploadService.uploadPlaylistCover(cover, id))
          .pipe(
            switchMap(() => this.playlistService.updatePlaylistCover(id)),
            map((logoImageUrl: string) => {
              newPlaylist.logoImageUrl = logoImageUrl;
              return newPlaylist;
            })
          );
        }
        return of(playlist);
      }),
      tap((playlist: Playlist) => {
        playlist.artwork = PlaylistPrepare.videoPlaylistArtwork(playlist);
        const playlists = PlaylistPrepare.sortPlaylists([...state.playlists.filter(x => x.id !== playlist.id), playlist]);
        patchState({
          playlists,
          lastUpdated: playlist
        });
      })
    );
  }

  @Action(NewPlaylistAction.DeletePlaylist)
  delete({getState, patchState}: StateContext<NewPlaylistsStateModel>, { id }: NewPlaylistAction.DeletePlaylist) {
    const state = getState();
    return this.playlistService.deleteLibraryPlaylist(id)
    .pipe(
      tap(() => {
        patchState({
          playlists: state.playlists.filter((item) => item.id !== id),
          lastUpdated: null
        });
      })
    );
  }

  @Action(NewPlaylistAction.AddTracksToPlaylist)
  addTracksToPlaylist({getState, patchState}: StateContext<NewPlaylistsStateModel>, {trackIds, playlistId}: NewPlaylistAction.AddTracksToPlaylist) {
  return this.playlistService.addTracks(trackIds, playlistId)
    .pipe(
      tap((playlist: Playlist) => {
        const state = getState();
        if (state.isPlaylistsLoaded) {
          const updPlaylist = {
            ...state.playlists.find(x => x.id === playlist.id),
            trackCount: playlist.trackCount,
            duration: playlist.duration,
            generalGenre: playlist.generalGenre,
            artwork: PlaylistPrepare.videoPlaylistArtwork(playlist),
            updatedAt: playlist.updatedAt
          };
          patchState({
            playlists: PlaylistPrepare.sortPlaylists([...state.playlists.filter(x => x.id !== playlist.id), updPlaylist]),
            lastUpdated: updPlaylist
          });
        }
      })
    )
  }

  @Action(NewPlaylistAction.RemoveTracksFromPlaylist)
  removeTracksFromPlaylist({getState, patchState}: StateContext<NewPlaylistsStateModel>, {trackIds, playlistId}: NewPlaylistAction.RemoveTracksFromPlaylist) {
    // @ts-ignore
    return forkJoin(...trackIds.map(item =>
      this.playlistService.removeTrack(item, playlistId)
    ))
    .pipe(
      switchMap(() => from(this.playlistService.getPlaylist(playlistId))),
      tap((playlist) => {
        const state = getState();
        if (state.isPlaylistsLoaded) {
          const updPlaylist = {
            ...state.playlists.find(x => x.id === playlist.id),
            trackCount: playlist.trackCount,
            duration: playlist.duration,
            generalGenre: playlist.generalGenre,
            artwork: PlaylistPrepare.videoPlaylistArtwork(playlist),
            updatedAt: playlist.updatedAt
          };
          patchState({
            playlists: PlaylistPrepare.sortPlaylists([...state.playlists.filter(x => x.id !== playlist.id), updPlaylist]),
            lastUpdated: updPlaylist
          });
        }
      })
    )
  }

  @Action(NewPlaylistAction.ChangePlaylistLibraryState)
  changeLibraryStatus({getState, setState}: StateContext<NewPlaylistsStateModel>, {playlistIds, isInLibrary}: NewPlaylistAction.ChangePlaylistLibraryState) {
    const state = getState();
    if (state.playlists && state.playlists.some(({id}) => playlistIds.includes(id))) {
      const playlistsUpd: Playlist[] = [
        ...state.playlists.map((playlist) => {
          if (playlistIds.find((id) => playlist.id === id)) {
            return {...playlist, isInLibrary};
          }
          return playlist;
        }),
      ]
      setState(
        patch({
          playlists: playlistsUpd
        })
      )
    }
  }

  @Selector()
  static playlists(state: NewPlaylistsStateModel): Playlist[] {
    return state.playlists;
  }

  @Selector()
  static publishedPlaylists(state: NewPlaylistsStateModel): Playlist[] {
    return state.playlists.filter(x => x.isPublished);
  }

  @Selector()
  static isPlaylistsLoaded(state: NewPlaylistsStateModel): boolean {
    return state.isPlaylistsLoaded;
  }

  @Selector()
  static hasNext(state: NewPlaylistsStateModel): boolean {
    return state.hasNextPage;
  }

  @Selector()
  static lastUpdated(state: NewPlaylistsStateModel): Playlist {
    return state.lastUpdated;
  }
}