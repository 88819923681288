import { Component, OnDestroy, OnInit } from '@angular/core';

import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { Observable, Subject, Subscription } from 'rxjs';

import { SettingsService } from 'src/app/shared/services/settings.service';
import { VenueListComponent } from './venue-list/venue-list.component';
import { VenueService } from '../../../shared/services/venue.service';
import { Venue, ZonePreset } from '../../../shared/models/models';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { ZoneService } from 'src/app/shared/services/zone.service';
import { filter, takeUntil } from 'rxjs/operators';
import { VenueState } from 'src/app/shared/store/venue/venue.state';
import { VenueAction } from 'src/app/shared/store/venue/venue.actions';

@Component({
  selector: 'app-venue-settings',
  templateUrl: './venue-settings.component.html',
  styleUrls: ['./venue-settings.component.scss']
})
export class VenueSettingsComponent implements OnInit, OnDestroy {
  @Select(VenueState.venue) venue$: Observable<Venue>;

  private BANDWIDTH_VALUES = [16, 32, 64, 128, 256, 512];
  private settingsSub: Subscription;

  public MAX_CROSSFADE_DURATION = 10;
  public MIN_CROSSFADE_DURATION = 2;
  public isSettingsLoading = false;
  public settingsErrored = false;
  public bandwidthSliderValue = 0;
  public isVenueLoading = false;
  @Select(VenueState.errored) venueErrored$: Observable<boolean>;
  public venueErrored = false;
  public settings: ZonePreset = {
    id: -1,
    schedule: null,
    audioChannels: 'MONO',
    crossFade: true,
    fadeTimeSeconds: this.MIN_CROSSFADE_DURATION,
    downloadSpeed: 16
  };
  public venue: Venue;

  private unSubscribe$: Subject<{}> = new Subject;

  constructor(
    private venueService: VenueService,
    private settingsService: SettingsService,
    private dialog: MatDialog,
    private router: Router,
    private store: Store,
    private zoneService: ZoneService
  ) {
    this.venue$ = this.store.select(VenueState.venue);
    this.venueErrored$ = this.store.select(VenueState.errored);
  }

  ngOnInit() {
    this.isVenueLoading = true;
    this.venueErrored$.subscribe((errored) => this.venueErrored = errored);

    this.venue$
    .pipe(
      filter((venue) => !!venue),
      takeUntil(this.unSubscribe$)
    )
    .subscribe((venue) => {
      this.venue = venue;
      this.isVenueLoading = false;
      this.fetchVenueSettings(this.venue.id);
    });
  }

  fetchVenueSettings(venueId: number) {
    if (this.settingsSub) {
      this.settingsSub.unsubscribe();
    }

    this.isSettingsLoading = true;
    this.settingsService.getDefaultSettings(venueId).then(() => {
      this.settingsSub = this.settingsService.onSettingsChanges.subscribe((settings) => {
        if (settings) {
          this.settings = settings;
        }

        this.isSettingsLoading = false;

        this.setBandwidthSliderValue();
      });
    }).catch(() => this.settingsErrored = true);
  }

  setBandwidthSliderValue() {
    this.bandwidthSliderValue = this.BANDWIDTH_VALUES.findIndex((value) => value === this.settings.downloadSpeed);
  }

  onLimitMusicSelectionChange(value: boolean) {
    this.venue.limitMusicSelection = value;

    this.venueService.updateLimitMusicSelection(this.venue.id, value)
      .then((updated) => {
        if (!updated) {
          this.venue.limitMusicSelection = !value;
        }
      });
  }

  onBandwidthLimatationChange({ value }) {
    this.settings.downloadSpeed = this.BANDWIDTH_VALUES[value];

    this.saveSettings();
  }

  onCrossfadeDurationChange({ value }) {
    this.settings.fadeTimeSeconds = value;
    this.settings.crossFade = true;

    this.saveSettings();
  }

  saveSettings() {
    const venueDefaults = {
      audioChannels: this.settings.audioChannels || 'MONO',
      crossFade: true,
      fadeTimeSeconds: this.settings.fadeTimeSeconds,
      downloadSpeed: this.settings.downloadSpeed
    };

    if (this.settings.schedule) {
      venueDefaults['scheduleId'] = this.settings.schedule.id;
    }

    this.settingsService.updateVenueDefaults(this.venue.id, venueDefaults);
  }

  defaultScheduleRoute() {
    this.router.navigate(['/schedules'], { queryParams: { default: true } });
  }

  chooseVenue() {
    this.dialog.open(VenueListComponent, { width: '640px' })
      .afterClosed()
      .subscribe((venueId: number) => {
        if (venueId) {
          this.store.dispatch(new VenueAction.ChangeCurrentVenue(venueId));
        }
      }, () => this.venueErrored = true);
  }

  ngOnDestroy() {
    this.unSubscribe$.next(true);
    this.unSubscribe$.complete();

    if (this.settingsSub) {
      this.settingsSub.unsubscribe();
    }
  }
}
