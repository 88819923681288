<div class="spinner-wrapper d-flex justify-content-center align-items-center" [ngClass]="{ minimal: minimal, 'p-0': noPadding }">
  <mat-progress-spinner *ngIf="isLoading && !errored" mode="indeterminate" [diameter]="diameter"></mat-progress-spinner>

  <div *ngIf="errored" class="d-flex justify-content-center">
    <div class="no-results-found-message-container d-flex justify-content-center">
      <div class="message-wrapper d-flex align-items-center justify-content-center flex-column">
        <div class="something-went-wrong-image"></div>
          <p>Something went wrong while processing your request.</p>
          <p class="small">Please contact support if you continue to see this message.</p>
          <button type="button" class="btn" (click)="reload()">Reload</button>
      </div>
    </div>
  </div>
</div>
