import { Action, State, StateContext, Selector, Store } from '@ngxs/store';
import { PlaylistAction } from './playlists.actions';
import { PlaylistService } from '../../services/playlist.service';
import { Playlist, PaginationInfo } from '../../models/models';
import { tap, map, filter, switchMap, concatAll, mergeMap, catchError } from 'rxjs/operators';
import { SpotifyService } from '../../services/spotify.service';
import { from, forkJoin, of, empty } from 'rxjs';
import { BucketAction } from '../buckets/buckets.actions';
import { PlaylistPrepare } from '../../utils/prepare-playlist';
import { BucketsState } from '../buckets/buckets.state';
import { StateReset } from 'ngxs-reset-plugin';
import { Injectable } from '@angular/core';
import { MediaTypes } from '../../models/consts';

type Context = StateContext<PlaylistsStateModel>;

export interface PlaylistsStateModel {
  playlists: Playlist[];
  isPlaylistsLoaded: boolean;
  hasNextPage: boolean;
  errored: boolean;
  nextCursor: string;
  spotifyImportedTracksCount: number;
}

@State<PlaylistsStateModel>({
  name: 'playlists',
  defaults: {
    playlists: null,
    isPlaylistsLoaded: false,
    hasNextPage: false,
    errored: false,
    nextCursor: null,
    spotifyImportedTracksCount: 0
  }
})
@Injectable()
export class PlaylistsState {

  constructor(
      private playlistService: PlaylistService,
      private spotifyService: SpotifyService,
      private store: Store
    ) {}

  @Action(PlaylistAction.GetMyPlaylists)
  fetch({patchState}: StateContext<PlaylistsStateModel>) {
    return this.playlistService.getEssentialPlaylistsConnection(null, 20, MediaTypes.AUDIO, this.playlistService.playlistFilter$.value)
      .pipe(
        tap((paginationInfo: PaginationInfo) => {
          patchState({
            hasNextPage: paginationInfo.pageInfo.hasNextPage,
            nextCursor: paginationInfo.pageInfo.endCursor
          });
        }),
        map(({edges}) => edges.map(({node}) => node)),
        tap((playlists: Playlist[]) => {
          // add artwork to playlist or create collage
          playlists = playlists.map(item => {
            item = { ...item, artwork: PlaylistPrepare.playlistArtwork(item) };
            return item;
          });
          patchState({
            playlists,
            isPlaylistsLoaded: true
          });
        }),
        catchError((err) => {
          console.error(err);
          patchState({ errored: true })
          return of([]);
        })
      );
  }

  @Action(PlaylistAction.GetMorePlaylists)
  getMore({getState, patchState}: StateContext<PlaylistsStateModel>, { count }: PlaylistAction.GetMorePlaylists) {
    const state = getState();
    return this.playlistService.getEssentialPlaylistsConnection(state.nextCursor, count || 20, MediaTypes.AUDIO, this.playlistService.playlistFilter$.value)
      .pipe(
        tap((paginationInfo: PaginationInfo) => {
          patchState({
            hasNextPage: paginationInfo.pageInfo.hasNextPage,
            nextCursor: paginationInfo.pageInfo.endCursor
          });
        }),
        map(({edges}) => edges.map(({node}) => node)),
        tap((playlists: Playlist[]) => {
          // add artwork to playlist or create collage
          playlists = playlists.map(item => {
            item = { ...item, artwork: PlaylistPrepare.playlistArtwork(item) };
            return item;
          });

          patchState({
            playlists: [...state.playlists, ...playlists]
          });
        }),
        catchError((err) => {
          console.error(err);
          patchState({ errored: true })
          return of([]);
        })
      );
  }

  @Action(PlaylistAction.AddPlaylist)
  add({getState, patchState}: StateContext<PlaylistsStateModel>, { playlist }: PlaylistAction.AddPlaylist) {
    const state = getState();
    return this.playlistService.addPlaylistToLibrary(playlist.id)
    .pipe(
      filter((result) => !!result),
      tap(() => {
        if (state.isPlaylistsLoaded) {
          playlist = {
            ...playlist,
            isInLibrary: true
          };
          const playlists = (state.playlists) ? PlaylistPrepare.sortPlaylists([...state.playlists, playlist]) : [playlist];
          patchState({
            playlists
          });
        }
      }),
      catchError((err) => {
        console.error(err);
        patchState({ errored: true })
        return of([]);
      })
    );
  }

  @Action(PlaylistAction.AddCatalogPlaylistsToLibrary)
  addMultiplePlaylists({getState, patchState}: StateContext<PlaylistsStateModel>, { playlistsIds }: PlaylistAction.AddCatalogPlaylistsToLibrary) {
    const state = getState();
    return this.playlistService.addCatalogPlaylistsToLibrary(playlistsIds)
    .pipe(
      filter((result) => !!result),
      tap((catalogPlaylists: Playlist[]) => {
        if (state.isPlaylistsLoaded) {
          const newPlaylists = catalogPlaylists.map((playlist) => ({
            ...playlist,
            isInLibrary: true
          }));
          const playlists = (state.playlists) ? PlaylistPrepare.sortPlaylists([...state.playlists, ...newPlaylists]) : newPlaylists;
          patchState({
            playlists
          });
        }
      })
    );
  }

  @Action(PlaylistAction.CreatePlaylist)
  create({getState, patchState}: StateContext<PlaylistsStateModel>, { name }: PlaylistAction.CreatePlaylist) {
    const state = getState();
    return this.playlistService.createMyPlaylist(name)
    .pipe(
      filter((result) => !!result),
      tap((playlist: Playlist) => {
        playlist.artwork = PlaylistPrepare.playlistArtwork(playlist);
        const playlists = (state.playlists) ? PlaylistPrepare.sortPlaylists([...state.playlists, playlist]) : [playlist];
        patchState({
          playlists
        });
      }),
      catchError(() => {
        patchState({ errored: true })
        return of([]);
      })
    );
  }

  @Action(PlaylistAction.RemovePlaylistFromLibrary)
  removeFromLibrary({getState, patchState}: StateContext<PlaylistsStateModel>, { id }: PlaylistAction.RemovePlaylistFromLibrary) {
    const state = getState();
    return this.playlistService.removeFromLibrary(id)
    .pipe(
      tap((removedId: number) => {
        if (removedId && state.playlists) {
          patchState({
            playlists: state.playlists.filter((item) => item.id !== removedId)
          });
        }
      }),
      mergeMap((res) => {
        if(this.store.selectSnapshot(BucketsState.isBucketsLoaded)) {
          return this.store.dispatch(new BucketAction.UpdateBucketOnPlaylistDelete([id]))
        }
        return of(res);
      })
    );
  }

  @Action(PlaylistAction.RemoveMultiplePlaylistsFromLibrary)
  removeMultipleFromLib({getState, patchState}: StateContext<PlaylistsStateModel>, { ids }: PlaylistAction.RemoveMultiplePlaylistsFromLibrary) {
    const state = getState();
    return this.playlistService.removeMultipleCatalogPlFromLibrary(ids)
    .pipe(
      tap((removedPlaylists: number[]) => {
        if (state.playlists && state.playlists.some(({id}) => removedPlaylists.includes(id))) {

          patchState({
            playlists: state.playlists.filter(({id}) => !removedPlaylists.includes(id))
          });
        }
      }),
      mergeMap((res) => {
        if(this.store.selectSnapshot(BucketsState.isBucketsLoaded)) {
          return this.store.dispatch(new BucketAction.UpdateBucketOnPlaylistDelete(res))
        }
        return of(res);
      })
    );
  }

  @Action(PlaylistAction.DeletePlaylist)
  delete({getState, patchState}: StateContext<PlaylistsStateModel>, { id }: PlaylistAction.DeletePlaylist) {
    const state = getState();
    return this.playlistService.deleteLibraryPlaylist(id)
    .pipe(
      tap(() => {
        patchState({
          playlists: state.playlists.filter((item) => item.id !== id)
        });
      }),
      mergeMap((res) => {
        if(this.store.selectSnapshot(BucketsState.isBucketsLoaded)) {
          return this.store.dispatch(new BucketAction.UpdateBucketOnPlaylistDelete([id]))
        }
        return of(res);
      }),
      catchError(() => {
        patchState({ errored: true })
        return of([]);
      })
    );
  }

  @Action(PlaylistAction.DeleteMultiplePlaylists)
  deleteMultiple({getState, patchState}: StateContext<PlaylistsStateModel>, { ids }: PlaylistAction.DeleteMultiplePlaylists) {
    const state = getState();
    return this.playlistService.deletePlaylists(ids)
    .pipe(
      tap(() => {
        patchState({
          playlists: state.playlists.filter(({id}) => !ids.includes(id))
        });
      }),
      mergeMap((res) => {
        if(this.store.selectSnapshot(BucketsState.isBucketsLoaded)) {
          return this.store.dispatch(new BucketAction.UpdateBucketOnPlaylistDelete(ids))
        }
        return of(res);
      })
    );
  }

  @Action(PlaylistAction.ImportSpotifyPlaylist)
  importFromSpotify(
    {getState, patchState}: StateContext<PlaylistsStateModel>,
    {playlist, spotifyPlaylistImportId}: PlaylistAction.ImportSpotifyPlaylist
  ) {
    let playlistId = 0;
    return this.playlistService.createSpotifyPlaylist(playlist.name, spotifyPlaylistImportId)
    .pipe(
      mergeMap((newPlaylist) => {
        playlistId = newPlaylist.id;
        const limit = 100;
        const total = playlist.tracks.total;
        const observables = [];
        for (let i = 0; i <= total; i += limit) {
          observables.push(from(this.spotifyService.getSpotifyPlaylistTracks(playlist.tracks.href, i, limit)));
        }
        // @ts-ignore
        return forkJoin(...observables);
      }),
      mergeMap((data) => {
        let tracksPackage = [];
        const observables = [];
        data.forEach((group, i) => {
          if (tracksPackage.length < 1000) {
              tracksPackage = [...tracksPackage, ...group];
              if (i === data.length - 1) {
                observables.push(
                  this.playlistService.addSpotifyTracks(
                    playlistId,
                    PlaylistPrepare.prepareSpotifyTracksPackage(tracksPackage)
                  )
                );
                tracksPackage = [];
              }
            } else {
              observables.push(
                this.playlistService.addSpotifyTracks(
                  playlistId,
                  PlaylistPrepare.prepareSpotifyTracksPackage(tracksPackage)
                )
              );
              tracksPackage = [];
            }
        });
        return forkJoin(...observables);
      }),
      catchError(() => {
        patchState({ errored: true })
        return of([]);
      }),
      switchMap(() => this.playlistService.publishSpotifyPlaylist(playlistId)),
      tap((playlist: Playlist) => {
        const state = getState();
        playlist.artwork = PlaylistPrepare.playlistArtwork(playlist);
        patchState({
          playlists: PlaylistPrepare.sortPlaylists([...state.playlists, playlist]),
          spotifyImportedTracksCount: (state.spotifyImportedTracksCount + playlist.trackCount)
        });
      }),
      catchError(() => {
        patchState({ errored: true })
        return of([]);
      })
    );
  }

  @Action(PlaylistAction.SpotifyImportedTracksReset)
  resetSpotifyTracksCount({patchState}: StateContext<PlaylistsStateModel>) {
    patchState({
      spotifyImportedTracksCount: 0
    })
  }

  @Action(PlaylistAction.AddTracksToPlaylist)
  addTracksToPlaylist({getState, patchState}: StateContext<PlaylistsStateModel>, {trackIds, playlistId}: PlaylistAction.AddTracksToPlaylist) {
  return this.playlistService.addTracks(trackIds, playlistId)
    .pipe(
      map((playlist: Playlist) => {
        const state = getState();
        if (state.isPlaylistsLoaded) {
          const updPlaylist = {
            ...state.playlists.find(x => x.id === playlist.id),
            trackCount: playlist.trackCount,
            duration: playlist.duration,
            generalGenre: playlist.generalGenre,
            artwork: PlaylistPrepare.playlistArtwork(playlist),
            updatedAt: playlist.updatedAt
          };
          patchState({
            playlists: PlaylistPrepare.sortPlaylists([...state.playlists.filter(x => x.id !== playlist.id), updPlaylist])
          });
          return updPlaylist;
        }
        return playlist;
      }),
      mergeMap((playlist) => {
        if(this.store.selectSnapshot(BucketsState.isBucketsLoaded)) {
          return this.store.dispatch(new BucketAction.UpdateBucketOnPlaylistChange(playlist))
        }
        return of(playlist);
      }),
      catchError(() => {
        patchState({ errored: true })
        return of([]);
      })
    )
  }

  @Action(PlaylistAction.RemoveTracksFromPlaylist)
  removeTracksFromPlaylist({getState, patchState}: StateContext<PlaylistsStateModel>, {trackIds, playlistId}: PlaylistAction.AddTracksToPlaylist) {
    return forkJoin(...trackIds.map(item =>
      this.playlistService.removeTrack(item, playlistId)
    ))
    .pipe(
      switchMap(() => from(this.playlistService.getPlaylist(playlistId))),
      map((playlist) => {
        const state = getState();
        if (state.isPlaylistsLoaded) {
          const updPlaylist = {
            ...state.playlists.find(x => x.id === playlist.id),
            trackCount: playlist.trackCount,
            duration: playlist.duration,
            generalGenre: playlist.generalGenre,
            artwork: PlaylistPrepare.playlistArtwork(playlist),
            updatedAt: playlist.updatedAt
          };
          patchState({
            playlists: PlaylistPrepare.sortPlaylists([...state.playlists.filter(x => x.id !== playlist.id), updPlaylist])
          });
          return updPlaylist;
        }
        return playlist;
      }),
      mergeMap((playlist) => {
        if(this.store.selectSnapshot(BucketsState.isBucketsLoaded)) {
          return this.store.dispatch(new BucketAction.UpdateBucketOnPlaylistChange(playlist))
        }
        return of(playlist);
      }),
      catchError(() => {
        patchState({ errored: true })
        return of([]);
      })
    )
  }

  @Action(PlaylistAction.UpdatePlaylistsOnBucketTrackRemove)
  updatePlaylist({getState, patchState}: StateContext<PlaylistsStateModel>, {trackId, playlistsIds}: PlaylistAction.UpdatePlaylistsOnBucketTrackRemove)
  {
    const state = getState();

    if (!!state.isPlaylistsLoaded) {
      const newPlaylists = [...state.playlists].map((playlist) => {
        if (playlistsIds.includes(playlist.id)) {
           const updatedPlaylist = {
              ...playlist,
              tracks: playlist.tracks.filter(({id}) => id !== trackId)
            }
            updatedPlaylist.artwork = PlaylistPrepare.playlistArtwork(updatedPlaylist);
          return updatedPlaylist;
        }
        return playlist;
      });
      patchState({
        playlists: PlaylistPrepare.sortPlaylists(newPlaylists)
      })
    }
  }

  @Action(PlaylistAction.UpdateState)
  updateState({getState, dispatch}: StateContext<PlaylistsStateModel>) {
    const state = getState();

    dispatch (new StateReset(PlaylistsState))
    .pipe(
      switchMap(() => {
        if(!!state.isPlaylistsLoaded) {
          return dispatch(new PlaylistAction.GetMyPlaylists())
        }
        return empty();
      })
    )
    .subscribe();
  }

  @Selector()
  static playlists(state: PlaylistsStateModel): Playlist[] {
    return state.playlists;
  }

  @Selector()
  static isPlaylistsLoaded(state: PlaylistsStateModel): boolean {
    return state.isPlaylistsLoaded;
  }

  @Selector()
  static hasNext(state: PlaylistsStateModel): boolean {
    return state.hasNextPage;
  }

  @Selector()
  static errored(state: PlaylistsStateModel): boolean {
    return state.errored;
  }
}

