<div class="dialog-header">
  <h2 mat-dialog-title>{{ data && data.venue ? 'Edit' : 'Create' }} Venue</h2>
  <button mat-dialog-close class="btn close-dialog-btn"></button>
</div>

<mat-dialog-content>
  <form [formGroup]="venueForm">
    <div class="venue-modal d-flex flex-column">

      <div class="d-flex justify-content-between">
        <div class="flex-col m-0">
          <div class="d-flex justify-content-between">
            <div class="flex-col">
              <div class="form-group">
                <label>Venue's Logo</label>
                <div class="preview-img-wrapper position-relative overflow-hidden text-center">
                  <img
                    *ngIf="data && data.venue.logoImageUrl"
                    [src]="data.venue.logoImageUrl"
                    [ngClass]="{ 'hidden': logoImage }"
                    alt="Venue logo image"
                    class="preview-img"
                  />
                  <img *ngIf="logoImage" [src]="logoImage" alt="Venue logo image" class="preview-img" />
                  <button (click)="uploadVenueLogo()" class="btn position-absolute rounded-circle">
                    <div class="icon icon-plus-primary"></div>
                  </button>
                </div>
              </div>
            </div>

            <div class="flex-col">
              <div class="form-group">
                <label>Venue's Banner</label>
                <div class="preview-img-wrapper position-relative overflow-hidden text-center">
                  <img
                    *ngIf="data && data.venue.bannerImageUrl"
                    [src]="data.venue.bannerImageUrl"
                    [ngClass]="{ 'hidden': bannerImage }"
                    alt="Venue banner image"
                    class="preview-img"
                  />
                  <img *ngIf="bannerImage" [src]="bannerImage" alt="Venue banner image" class="preview-img" />
                  <button (click)="uploadVenueBanner()" class="btn position-absolute rounded-circle">
                    <div class="icon icon-plus-primary"></div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex-col m-0">
          <!-- <div class="d-flex justify-content-between">
            <div class="flex-col">
              <div class="form-group">
                <label for="venueAddress">Venue's Address</label>
                <input
                  appGooglePlacesAutocomplete
                  placeholder="Enter Address"
                  type="text"
                  formControlName="venueAddress"
                  id="venueAddress"
                  class="form-control"
                  [options]="googlePlaceOptions"
                  (addressChanged)="handleAddressChange($event)"
                />
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="flex-col">
              <div class="form-group">
                <label for="venueLatitude">Latitude</label>
                <input
                  placeholder="Enter Latitude"
                  type="text"
                  formControlName="venueLatitude"
                  id="venueLatitude"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
            <div class="flex-col">
              <div class="form-group">
                <label for="venueLongitude">Longitude</label>
                <input
                  placeholder="Enter Longitude"
                  type="text"
                  formControlName="venueLongitude"
                  id="venueLongitude"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
          </div> -->
        </div>
      </div>

      <div class="d-flex justify-content-between">
        <div class="flex-col">
          <div class="form-group">
            <label for="venueName">Venue's Name</label>
            <input
              placeholder="Enter Venue's Name"
              type="text"
              formControlName="venueName"
              id="venueName"
              class="form-control"
            />
          </div>
        </div>
        <div class="flex-col">
          <div class="form-group">
            <label for="venueEmail">Email</label>
            <input
              placeholder="Enter Email"
              type="text"
              formControlName="venueEmail"
              id="venueEmail"
              class="form-control"
            />
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-between">
        <div class="flex-col f-5">
          <div class="form-group">
            <label for="venueContactName">Name</label>
            <input
              placeholder="Enter Name"
              type="text"
              formControlName="venueContactName"
              id="venueContactName"
              class="form-control"
            />
          </div>
        </div>
        <div class="flex-col f-5">
          <div class="form-group">
            <label for="venueContactNumber">Contact Number</label>
            <input
              placeholder="Enter Contact Number"
              type="tel"
              formControlName="venueContactNumber"
              id="venueContactNumber"
              class="form-control"
              [maxLength]="25"
            />
          </div>
        </div>

        <div class="flex-col f-3">
          <div class="form-group">
            <label for="venueStatus">Status</label>
            <input
              formControlName="venueStatus"
              id="venueStatus"
              class="form-control"
              readonly
              [matMenuTriggerFor]="statusMenu"
            />
            <mat-menu #statusMenu="matMenu" class="status-list-menu">
              <div class="status-list-wrapper">
                <ul class="status-list list-unstyled m-0 p-0">
                  <li
                    *ngFor="let status of statuses; index as i"
                    (click)="selectStatus(status)"
                    [id]="venueForm.value.venueStatus === status ? 'status-checked' : 'status-' + i"
                    class="status-item d-flex justify-content-between align-items-center"
                  >
                    <span>{{ status }}</span>
                    <span class="icon-checkmark"></span>
                  </li>
                </ul>
              </div>
            </mat-menu>
          </div>
        </div>

      </div>
    </div>
  </form>
</mat-dialog-content>

<div mat-dialog-actions class="align-right">
  <button [disabled]="venueForm.invalid || isSending" (click)="saveVenue()" class="btn save-venue-btn p-0">
    <span>Save</span>
  </button>
</div>
