<div (click)="selectTrack()" class="track-cover-container">
  <div [ngClass]="{ blurred: isAdded || isSelected }" class="cover-wrapper">
    <!-- <div class="quarter-images" *ngIf="track.artwork?.length === 4"> -->
    <!--   <img -->
    <!--     *ngFor="let artwork of track.artwork" -->
    <!--     [src]="artwork" -->
    <!--     appDefault -->
    <!--     class="quarter-img" -->
    <!--     alt="Track cover" -->
    <!--   /> -->
    <!-- </div> -->
    <img
      *ngIf="track.artwork"
      appDefault
      [src]="(track.artwork && track.artwork.artworkUrl100) ? coverToSize(track.artwork.artworkUrl100) : '/assets/images/default-cover.jpg'"
      class="cover"
      alt="Track cover"
    />
  </div>
  <button *ngIf="isRemovable" (click)="$event.stopPropagation(); remove()" class="menu-button">
    <div class="menu">
      <div class="cover-blur"></div>
      <div class="cover-dark"></div>
      <div class="custom-icon icon-delete"></div>
    </div>
  </button>
  <div *ngIf="isSelected" class="checkmark-wrapper"></div>
  <div *ngIf="isAdded" class="added-label-wrapper">
    <div class="added-label">Added</div>
  </div>
</div>
<div [title]="track.name" class="track-name text-truncate">{{ track.name }}</div>
<!-- <div class="track-genre text-truncate">{{ track.generalGenre }}</div> -->
<div class="track-genre text-truncate">{{ track.artist.name }}</div>
<div class="track-genre text-truncate">{{ track.album.name }}</div>
