import { Component, Input, OnInit } from '@angular/core';

import { TodayTimeslot } from '../../../../shared/models/models';

@Component({
  selector: 'app-timeslot-preview-item',
  templateUrl: './timeslot-preview-item.component.html',
  styleUrls: ['./timeslot-preview-item.component.scss']
})
export class TimeslotPreviewItemComponent implements OnInit {
  @Input() timeslot: TodayTimeslot;

  constructor() { }

  ngOnInit() {
  }

}
