import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';

import { SharedBucketPreviewItemComponent } from './bucket-preview-item.component';
import { ImagePreloadModule } from '../../directives/image-preload/image-preload.module';
import { SpinnerModule } from 'src/app/system/spinner/spinner.module';

@NgModule({
  declarations: [
    SharedBucketPreviewItemComponent
  ],
  imports: [
    CommonModule,
    MatMenuModule,
    ImagePreloadModule,
    SpinnerModule
  ],
  exports: [
    SharedBucketPreviewItemComponent
  ]
})
export class SharedBucketPreviewItemModule { }
