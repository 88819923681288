import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { MimeTypeByExtension } from '../models/consts';

@Pipe({
  name: 'fileMimeType'
})
export class FileMimeTypePipe implements PipeTransform {

  transform(filename: string, args?: any): any {
    const extension = (/[.]/.exec(filename)) ? filename.split('.').pop() : '';
    if (!extension) {
      return MimeTypeByExtension.default;
    }
    return MimeTypeByExtension[extension] || MimeTypeByExtension.default;
  }

}

@NgModule({
  imports: [],
  declarations: [FileMimeTypePipe],
  exports: [FileMimeTypePipe]
})
export class FileMimeTypeModule { }
