import { Venue } from 'src/app/shared/models/models';
import { VenuePayload } from '../../models/inner-models';

export namespace VenueListAction {
  export class GetVenues {
    static type = '[Venue List] Get Venues';

    constructor(
      public nameLike?: string,
    ) {}
  }

  export class GetVenuesList {
    static type = '[Venue List] Get minimal data for Venues';

    constructor(
      public nameLike?: string,
    ) {}
  }

  export class AddVenue {
    static type = '[Venue List] Add Venue';

    constructor(
      public venueVariables: VenuePayload
    ) {}
  }

  export class EditVenue {
    static type = '[Venue List] Update Venue';

    constructor(
      public id: number,
      public venueVariables: VenuePayload
    ) {}
  }

  export class UpdateVenueLogo {
    static type = '[Venue List] Update Venue Logo';

    constructor(
      public venueId: number
    ) {}
  }

  export class UpdateVenueBanner {
    static type = '[Venue List] Update Venue Banner';

    constructor(
      public venueId: number
    ) {}
  }
}
