import { Injectable } from '@angular/core';

import { Apollo } from 'apollo-angular';

import { BehaviorSubject, throwError } from 'rxjs';

import { PrepareDataService } from './prepare-data.service';
import { Timeslot } from '../models/models';
import {
  createTimeslotsForRepeatedDay,
  copyAllTimeslotsInDayToDays,
  getTimeslotWeekDays,
  copyTimeslotToDays,
  timeslotsForDay,
  createTimeslot,
  updateTimeslot,
  deleteTimeslot,
  timeslotsForRange,
  overlappedTimeslots,
  updateRepeatedTimeslot
} from '../gql/timeslot-consts';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class TimeslotService {
  private timeslots: any[];

  public onErrored: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public onTimeslotsChanged: BehaviorSubject<any[]> = new BehaviorSubject(null);

  constructor(private prepareService: PrepareDataService, private apollo: Apollo) {
  }

  getTimeslotsForRange(scheduleId, from, to) {
    return this.apollo.query({
      query: timeslotsForRange,
      variables: { scheduleId, from, to },
      fetchPolicy: 'no-cache'
    })
      .pipe(
        map((data: any) => data.data.timeslots)
      )
      .subscribe((timeslots) => {
        this.timeslots = timeslots;
        this.onTimeslotsChanged.next(this.timeslots);
      }, () => this.onErrored.next(true));
  }

  getTimeslotsForDay(scheduleId, day, onlyCheck = false, silent = false) {
    return new Promise<any[]>((resolve, reject) => {
      this.apollo.query({
        query: timeslotsForDay,
        variables: { scheduleId, day }
      })
        .subscribe(
          (data: any) => {
            if (!onlyCheck) {
              if (data.data.timeslotsForDay) {
                this.timeslots = data.data.timeslotsForDay.map((timeslot) => {
                  let timeslotCopy = {...timeslot};
                  if (timeslot.repeatOf) {
                    let repeatOf = {...timeslot.repeatOf};
                    timeslotCopy.repeatOf = this.addTimeslotArtworks(repeatOf);

                    return timeslotCopy;
                  }

                  return this.addTimeslotArtworks({...timeslot});
                });

                if (!silent) {
                  this.onTimeslotsChanged.next(this.timeslots);
                }
              }

              resolve(this.timeslots);
            } else {
              resolve(data.data.timeslotsForDay);
            }
          },
          (error) => {
            this.onErrored.next(true);
            console.log(error);
            reject(error);
          }
        );
    });
  }

  createTimeslotsForRepeatedDay(scheduleId, day) {
    return new Promise<any>((resolve, reject) => {
      if (this.timeslots.some(item => item.repeatOf)) {
        this.apollo.mutate({
          mutation: createTimeslotsForRepeatedDay,
          variables: {
            scheduleId,
            day
          }
        })
          .pipe(
            map((data: any) => data.data.createTimeslotsForRepeatedDay)
          )
          .subscribe(
            (timeslots) => {

              timeslots.forEach(timeslot => {
                timeslot = this.addTimeslotArtworks({...timeslot});
                let tIndex = this.timeslots.findIndex(x => x.day === timeslot.day && x.repeatOf && x.repeatOf.id === timeslot.copyOf.id);
                if (tIndex !== -1) {
                  this.timeslots[tIndex] = timeslot;
                }
              });
              this.onTimeslotsChanged.next(this.timeslots);
              resolve(timeslots);
            },
            (error) => {
              console.log(error);
              reject(error);
            }
          );
      } else {
        resolve(true);
      }
    });
  }

  createTimeslot(scheduleId, day, from, to, playlistId, bucketId, deleteOverlapped = false, repeatUntilDate = null) {
    // Passing null makes this a never ending event
    // Defauling to scheduled "to" day
    if (!repeatUntilDate) {
      repeatUntilDate = day;
    }
    return new Promise<any[]>((resolve, reject) => {
      this.apollo.mutate({
        mutation: createTimeslot,
        variables: {
          scheduleId,
          day,
          from,
          to,
          playlistId,
          bucketId,
          deleteOverlapped,
          repeatUntilDate
        }
      })
        .subscribe(
          (data: any) => {
            if (data.data.createTimeslot) {
              let createTimeslot = data.data.createTimeslot;
              const timeslot = this.addTimeslotArtworks({...createTimeslot});

              this.timeslots.push(timeslot);
              this.onTimeslotsChanged.next(this.timeslots);
            }

            resolve(this.timeslots);
          },
          (error) => {
            console.log(error);
            reject(error);
          }
        );
    });
  }

  createTimeslotAfterDeletation(scheduleId, day, from, to, playlistId, bucketId, repeatUntilDate) {
    return this.apollo.mutate({
      mutation: createTimeslot,
      variables: {
        scheduleId,
        day,
        from,
        to,
        playlistId,
        bucketId,
        repeatUntilDate,
        deleteOverlapped: true
      }
    });
  }

  copyTimeslot(scheduleId, day, from, to, playlistId, bucketId, deleteOverlapped = false, repeatUntilDate = null) {
    return this.apollo.mutate({
      mutation: createTimeslot,
      variables: { scheduleId, day, from, to, playlistId, bucketId, deleteOverlapped, repeatUntilDate }
    })
  }

  updateTimeslot(id, values, deleteOverlapped = false, freezeTimeslotsInAWeek = false) {
    return new Promise<any[]>((resolve, reject) => {
      this.apollo.mutate({
        mutation: updateTimeslot,
        variables: { id, values, deleteOverlapped, freezeTimeslotsInAWeek }
      })
        .subscribe(
          (data: any) => {
            this.timeslots = this.timeslots.map(item => {
              if (item.id === id) {
                if (item.repeatOf) {
                  item.from = values.from;
                  item.to = values.to;
                  item.playlist = data.data.updateTimeslot.playlist;
                  item.bucket = data.data.updateTimeslot.bucket;
                }
              }

              return item;
            });

            this.onTimeslotsChanged.next(this.timeslots);

            resolve(this.timeslots);
          },
          (error) => {
            console.log(error);
            reject(error);
          }
        );
    });
  }

  updateRepeatedTimeslot(originId: number, day: string, values, deleteOverlapped = false, freezeTimeslotsInAWeek = false) {
    return new Promise<any[]>((resolve, reject) => {
      this.apollo.mutate({
        mutation: updateRepeatedTimeslot,
        variables: { id: originId, day, values, deleteOverlapped, freezeTimeslotsInAWeek }
      })
        .subscribe(
          (data: any) => {
            this.timeslots = this.timeslots.map(item => {
              if (item.id === originId) {
                if (item.repeatOf) {
                  item.from = values.from;
                  item.to = values.to;
                  item.playlist = data.data.updateRepeatedTimeslot.playlist;
                  item.bucket = data.data.updateRepeatedTimeslot.bucket;
                }
              }

              return item;
            });

            this.onTimeslotsChanged.next(this.timeslots);

            resolve(this.timeslots);
          },
          (error) => {
            console.log(error);
            reject(error);
          }
        );
    });
  }

  deleteTimeslot(id, includingRepeats = false) {
    return new Promise<any[]>((resolve, reject) => {
      this.apollo.mutate({
        mutation: deleteTimeslot,
        variables: { id, includingRepeats }
      })
        .subscribe(
          (data: any) => {
            if (data.data.deleteTimeslot) {
              this.timeslots = this.timeslots.filter(
                item => item.id && item.id !== id ||
                  item.repeatOf && item.repeatOf.id !== id
              );
              this.onTimeslotsChanged.next(this.timeslots);
            }

            resolve(this.timeslots);
          },
          (error) => {
            console.log(error);
            reject(error);
          }
        );
    });
  }

  repeatTimeslot(id, daysOfWeek, from, to, repeatUntilDate) {
    return this.apollo.mutate({
      mutation: copyTimeslotToDays,
      variables: { id, daysOfWeek, from, to, repeatUntilDate }
    }).pipe(
      map((data: any) => data.data.copyTimeslotToDays)
    )
  }

  repeatDay(scheduleId, day, daysOfWeek, from, to) {
    return new Promise<any[]>((resolve, reject) => {
      this.apollo.mutate({
        mutation: copyAllTimeslotsInDayToDays,
        variables: {
          scheduleId,
          day,
          daysOfWeek,
          from,
          to
        }
      })
        .subscribe(
          (data: any) => {
            resolve(this.timeslots);
          },
          (error) => {
            console.log(error);
            reject(error);
          }
        );
    });
  }

  getTimeslotWeekDays(id) {
    return this.apollo.query({
      query: getTimeslotWeekDays,
      variables: {
        id
      }
    }).pipe(
      map((data: any) => data.data.timeslot)
    )
  }

  addTimeslotArtworks(timeslot: Timeslot) {
    if (timeslot.playlist) {
      timeslot.playlist = {
        ...timeslot.playlist, artwork: this.prepareService.playlistArtwork(timeslot.playlist)
      };
      // Object.assign({artwork: this.prepareService.playlistArtwork(timeslot.playlist)}, timeslot.playlist);
      // timeslot.playlist.artwork = this.prepareService.playlistArtwork(timeslot.playlist);
    }

    if (timeslot.bucket) {
      timeslot.bucket = Object.assign({ artwork: this.prepareService.bucketArtwork(timeslot.bucket) }, timeslot.bucket);
      // timeslot.bucket.artwork = this.prepareService.bucketArtwork(timeslot.bucket);
    }

    return timeslot;
  }

  getOverlappedTimeslots(scheduleId: number, day: string, from: string, to: string) {
    return this.apollo.query({
      query: overlappedTimeslots,
      variables: {
        scheduleId,
        day,
        from,
        to
      }
    }).pipe(
      map((data: any) => data.data.overlappedTimeslots)
    )
  }
}
