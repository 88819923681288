import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainMenuComponent } from './main-menu.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { RouterModule } from '@angular/router';
import { NgxPermissionsModule } from 'ngx-permissions';
import { VenuesModule } from '../venues/venues.module';

@NgModule({
  declarations: [
    MainMenuComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatMenuModule,
    NgxPermissionsModule,
    RouterModule,
    VenuesModule
  ],
  exports: [
    MainMenuComponent
  ]
})
export class MainMenuModule { }
