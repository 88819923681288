import { Component, Inject, OnDestroy, OnInit, ViewChild, AfterViewInit, NgZone, DoCheck } from '@angular/core';

import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

//import { Subscription } from 'rxjs';

import debounce from 'lodash/debounce';
import uniqBy from 'lodash/uniqBy';

import { ConfirmationDialogComponent } from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { PlaylistService } from '../../../shared/services/playlist.service';
import { Bucket, Playlist, Track, PlaylistCursor } from '../../../shared/models/models';
//import { tap, map, filter } from 'rxjs/operators';
import { NgScrollbar } from 'ngx-scrollbar';
//import { Store } from '@ngxs/store';
import { BucketsState } from 'src/app/shared/store/buckets/buckets.state';
import { BucketAction } from 'src/app/shared/store/buckets/buckets.actions';
//import { SNACK_BAR_DURATION, TrackSource } from 'src/app/shared/models/consts';
import { ErrorParserService } from 'src/app/shared/services/error-parcing.service';
import { AlertDialogComponent } from 'src/app/shared/components/alert-dialog/alert-dialog.component';
import { ZonesListComponent } from 'src/app/shared/components/zone-list-dialog/zone-list-dialog.component';
import { ZoneService } from 'src/app/shared/services/zone.service';
import { MediaTypes, PlaylistColumns, PlaylistMediaType, SCROLL_BOTTOM_OFFSET, SNACK_BAR_DURATION, ViewType, TrackSource } from 'src/app/shared/models/consts';
import { ZonesState } from 'src/app/shared/store/zones/zones.state';
import { User, Venue, Zone, TodayTimeslot } from 'src/app/shared/models/models';

import { Observable, Subject, Subscription } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { tap, map, filter, takeUntil } from 'rxjs/operators';


interface DialogData {
  bucket: Bucket;
}

@Component({
  selector: 'app-queue-playlist-dialog',
  templateUrl: './queue-playlist-dialog.component.html',
  styleUrls: ['./queue-playlist-dialog.component.scss']
})
export class QueuePlaylistDialogComponent implements OnInit, OnDestroy, DoCheck {
  @ViewChild('trackScroll', { static: false }) scrollbarRef: NgScrollbar;
  @Select(ZonesState.currentZone) currentZone$: Observable<Zone>;
  public view: 'PLAYLISTS' | 'TRACKS' = 'PLAYLISTS';
  public playlistsToAdd: Set<number> = new Set();
  public filteredPlaylists: Playlist[] = [];
  public addPlaylistsMode = true;
  public playlists: Playlist[];
  public isLoading = true;
  public errored = false;
  public bucket: Bucket;
  public tracks: Track[] = [];
  public currentZone: Zone;
  public tracksLoading = true;
  public tracksErrored = false;
  public isShuffled = true;

  public cursors: PlaylistCursor[] = [];
  public loadMore: boolean = false;

  private bucketSub: Subscription;
  private scrollSub: Subscription;
  private unSubscribe$: Subject<{}> = new Subject;
  private currentZoneSub: Subscription;

  mediaView: PlaylistMediaType = PlaylistMediaType.PLAYLIST;


  constructor(
    private playlistService: PlaylistService,
    private ngZone: NgZone,
    private dialog: MatDialog,
    private store: Store,
    private errorService: ErrorParserService,
    private _snackBar: MatSnackBar,
    private zoneService: ZoneService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.currentZone$ = this.store.select(ZonesState.currentZone);
    this.searchPlaylist = debounce(this.searchPlaylist, 500);
  }

  ngOnInit() {
    this.addPlaylist();
    
    this.currentZoneSub = this.currentZone$
    .pipe(
      filter((zone) => !!zone),
      takeUntil(this.unSubscribe$)
    )
    .subscribe((zone) => {
      this.currentZone = zone;
      console.log('queue playlist on zone ', zone.name);
    });

    // this.bucketSub = this.store.select(BucketsState.bucketById(this.data.bucket.id))
    // .subscribe((bucket) => {
    //   this.setView('PLAYLISTS');
    //   this.bucket = bucket;

    //   const promises = [];
    //   this.bucket.playlists.forEach((playlist, i) => {
    //     const tracksToLoad = i === 0 ? 20 : 0;
    //     promises.push(this.loadPlaylistPromise(playlist.id, tracksToLoad));
    //   });
    //   Promise.all(promises)
    //     .then(() => this.tracksLoading = false)
    //     .catch(() => this.tracksErrored = true);

    //   this.isLoading = false;
    // }, () => this.errored = true);
  }

  ngDoCheck() {
    if (this.scrollbarRef && !this.scrollSub) {
      this.scrollSub = this.scrollbarRef.scrolled.pipe(
        filter((e: any) => !this.loadMore && e.target.offsetHeight + e.target.scrollTop > e.target.scrollHeight - 10),
        tap(() => this.ngZone.run(() => {
          this.loadMore = true;
          this.tracksLoading = true;
          this.getMoreTracks();
        }))
      ).subscribe();
    }
  }

  get trackSource() {
    return TrackSource;
  }

  setView(view: 'PLAYLISTS' | 'TRACKS') {
    this.view = view;
  }

  backToBucketDetails() {
    this.playlistsToAdd.clear();
    this.addPlaylistsMode = false;
    this.isLoading = false;
  }

  addPlaylist() {
    this.addPlaylistsMode = true;
    this.isLoading = true;

    // const playlistIds = this.bucket.playlists.map((bucketPlaylist) => bucketPlaylist.id);

    // this.playlistService.getPlaylistsForBucket(playlistIds)
    this.playlistService.getPlaylistsForBucket([])
    .then((playlists) => {
      this.filteredPlaylists = this.playlists = playlists;
      this.isLoading = false;
    }).catch(() => this.errored = true);
  }

  onPlaylistRemoved(playlistId: number) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: 'Are you sure you want to remove the playlist from the bucket?',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary'
          },
          {
            text: 'Remove',
            cssClass: 'danger',
            handler: () => {
              dialogRef.close();
              this.store.dispatch(new BucketAction.DeletePlaylistFromBucket(playlistId, this.bucket.id))
                .subscribe(
                  () => {
                    this._snackBar.open(
                      `Playlist successfully removed from the bucket`,
                      null,
                      { duration: SNACK_BAR_DURATION }
                    );
                  },
                  (error) => {
                    const errIdStr = this.errorService.parseError(error);
                    this.dialog.open(AlertDialogComponent, {
                      data: {
                        type: 'error',
                        message: `Oops, something went wrong. Please try again.
                        ${(!!errIdStr) ? ('Error id: ' + errIdStr) : ''}`
                      }
                    });
                  }
                );
            }
          }
        ]
      },
      width: '350px'
    });
  }

  trackChange(event, isUpdated = false) {
    if (!isUpdated) {
      this.tracks = this.tracks.filter(x => x.id !== event);
    } else {
      this.tracks = this.tracks.splice(this.tracks.findIndex(({id}) => id === event.id), 1, event);
    }
  }


  // addPlaylistsToBucket() {
  //   if (this.playlistsToAdd.size > 0) {
  //     this.store.dispatch(new BucketAction.AddPlaylistsToBucket(Array.from(this.playlistsToAdd), this.bucket.id))
  //       .subscribe(() => {
  //         this._snackBar.open(
  //           `Playlists successfully added to the bucket ${this.bucket.name}`,
  //           null,
  //           { duration: SNACK_BAR_DURATION }
  //         );
  //       }, () => this.errored = true);
  //   }

  //   this.addPlaylistsMode = false;
  // }

  get hasSelected() {
    return (this.playlistsToAdd.size > 0);
  }
  
  // addPlaylistsToBucket() {
  //   console.log(this.playlistsToAdd);
  // }

  addPlaylistsToBucket() {
  // addToPlayingQueue() {
    if (!this.hasSelected) {
      return
    }
    // enqueue directly into current zone
    // this.dialog.open(
    //     ZonesListComponent,
    //     {
    //       data: {
    //         mediaType: this.mediaView === PlaylistMediaType.PLAYLIST ? MediaTypes.AUDIO : MediaTypes.VIDEO,
    //         hasShuffle: true
    //       }
    //     }
    //   )
    //   .afterClosed()
    //   .subscribe(data => {
    //     if (!data) {
    //       return;
    //     }
    //     if (data.error) {
    //       this.dialog.open(AlertDialogComponent, {
    //         data: {
    //           type: 'error',
    //           message: data.error
    //         }
    //       });
    //     } else {
    //       this.confirmAddPlaylistInQueue(data.id, data.isShuffled);
    //     }
    //   });
    this.confirmAddPlaylistInQueue(this.currentZone.id, this.isShuffled);
  }

  confirmAddPlaylistInQueue(zoneId: number, shuffle: boolean) {
    // The playlistIndex is not used, directly using the playlist.id
    //const playlistIndex = this.playlists.findIndex(({isChecked}) => isChecked);
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: 'The playlist will start after the current song finishes',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary'
          },
          {
            text: 'OK',
            cssClass: 'primary',
            handler: () => {
              this.zoneService.addPlaylistToZoneQueue(
                zoneId,
                //this.playlists[playlistIndex].id,
		this.playlistsToAdd.values().next().value, //Set operation
                undefined,
                shuffle
              )
              .then(() => {
                dialogRef.close();
                //this.playlists[playlistIndex].isChecked = false;
                this._snackBar.open(
                  `Playlist successfully added to the queue`,
                  null,
                  { duration: SNACK_BAR_DURATION }
                );
              })
              .catch((error) => {
                const errIdStr = this.errorService.parseError(error);
                dialogRef.close();
                this.dialog.open(AlertDialogComponent, {
                  data: {
                    type: 'error',
                    message: `Oops, something went wrong. Please try again.
                    ${(!!errIdStr) ? ('Error id: ' + errIdStr) : ''}`
                  }
                });
              });
            }
          }
        ]
      },
      width: '340px'
    });
  }

  searchPlaylist(term: string) {
    if (term === '') {
      this.filteredPlaylists = this.playlists;
    } else {
      this.filteredPlaylists = this.playlists.filter((playlist) => {
        return playlist.name.toLowerCase().includes(term.toLowerCase());
      });
    }
  }

  onPlaylistSelected(playlistId: number) {
    // if (this.playlistsToAdd.has(playlistId)) {
    //   this.playlistsToAdd.delete(playlistId);
    // } else {
    //   this.playlistsToAdd.add(playlistId);
    // }
    this.playlistsToAdd.clear();
    this.playlistsToAdd.add(playlistId);
  }

  loadPlaylistPromise(id: number, tracksToLoad: number, markAsLoaded = false) {
    return this.playlistService.getPlaylist(id, tracksToLoad)
      .then((playlist) => {
        this.tracks = (this.tracks || []).concat(playlist.tracksConnection.edges.map(({node}) => node));
        this.cursors.push({
          playlistId: id,
          endCursor: playlist.tracksConnection.pageInfo.hasNextPage ? playlist.tracksConnection.pageInfo.endCursor : null
        });

        if (markAsLoaded) {
          this.isLoading = false;
        }
      })
      .catch((err) => {
        console.error(err);
        this.errored = true;
      })
  }

  getMoreTracks() {
    const lastIndex = this.cursors.findIndex(({endCursor}) => !!endCursor);
    if (lastIndex !== -1) {
      this.playlistService.nextTrackPage(this.cursors[lastIndex].playlistId, this.cursors[lastIndex].endCursor)
        .pipe(
          tap((tracksConnection: any) => {
            if (tracksConnection.pageInfo.hasNextPage) {
              this.cursors[lastIndex].endCursor = tracksConnection.pageInfo.endCursor;
            } else {
              this.cursors[lastIndex].endCursor = null;
            }
          }),
          map(({edges}) => edges.map(({ node }) => node))
        )
        .subscribe((tracks) => {
          this.tracks = [...this.tracks, ...tracks];
          this.loadMore = false;
        }, () => this.tracksErrored = true);
    } else {
      this.tracksLoading = false;
    }
  }

  toggleShuffle() {
    this.isShuffled = !this.isShuffled;
  }

  ngOnDestroy() {
    if (this.scrollSub) {
      this.scrollSub.unsubscribe();
    }
    if (this.bucketSub) {
      this.bucketSub.unsubscribe();
    }
    this.unSubscribe$.next(true);
    this.unSubscribe$.complete();
  }
}
