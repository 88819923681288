import { Component, Inject, OnDestroy, OnInit, ViewChild, NgZone } from '@angular/core';

import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import { Subscription, Observable } from 'rxjs';

import debounce from 'lodash/debounce';

import { SharedNewTracksContainerDialogComponent } from '../new-tracks-container-dialog/shared-new-tracks-container-dialog.component';
import { Bucket } from '../../models/models';
import { Select, Store } from '@ngxs/store';
import { BucketsState } from '../../store/buckets/buckets.state';
import { BucketAction } from '../../store/buckets/buckets.actions';
import { NgScrollbar } from 'ngx-scrollbar';
import { switchMap, filter, tap } from 'rxjs/operators';
import { SNACK_BAR_DURATION } from '../../models/consts';

interface DialogData {
  playlistId: number;
}

@Component({
  selector: 'app-bucket-list-dialog',
  templateUrl: './bucket-list-dialog.component.html',
  styleUrls: ['./bucket-list-dialog.component.scss']
})
export class SharedBucketListDialogComponent implements OnInit, OnDestroy {
  @ViewChild(NgScrollbar, { static: false }) scrollbarRef: NgScrollbar;
  private bucketSub: Subscription;
  private scrollSub: Subscription;

  public loadedPlaylistsInBucket: Set<number> = new Set();
  public selectedBucketsId: Set<number> = new Set();
  public filteredBuckets: Bucket[] = [];
  public buckets: Bucket[] = [];
  public isLoading = false;
  public isLoadMore: boolean = false;
  @Select(BucketsState.buckets) buckets$: Observable<Bucket[]>;
  @Select(BucketsState.hasNext) hasMoreBuckets$: Observable<boolean>;
  @Select(BucketsState.errored) errored$: Observable<boolean>;
  public errored: boolean;

  constructor(
    private dialogRef: MatDialogRef<SharedBucketListDialogComponent>,
    private dialog: MatDialog,
    private store: Store,
    private ngZone: NgZone,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.searchBucket = debounce(this.searchBucket, 500);
    this.buckets$ = this.store.select(BucketsState.buckets);
    this.hasMoreBuckets$ = this.store.select(BucketsState.hasNext);
    this.errored$ = this.store.select(BucketsState.errored);
  }

  ngOnInit() {
    this.errored$.subscribe((errored) => this.errored = errored);
    this.isLoading = true;

    const hasBucketsinStore = this.store.selectSnapshot(BucketsState.isBucketsLoaded);
    if (!hasBucketsinStore) {
      this.store.dispatch(new BucketAction.GetBuckets())
        .subscribe(() => this.isLoading = false);
    }

    this.bucketSub = this.buckets$
      .pipe(
        filter((buckets) => !!buckets)
      )
      .subscribe((buckets) => {

        this.isLoading = false;
        this.filteredBuckets = this.buckets = buckets;

        buckets.forEach(bucket => bucket.playlists.forEach(i => {
          if (this.data.playlistId === i.id) {
            this.loadedPlaylistsInBucket.add(bucket.id);
          }
        }));
      });

  }
  ngAfterViewInit() {
    if (this.scrollbarRef) {
      this.scrollSub = this.scrollbarRef.scrolled
        .pipe(
          switchMap(() => this.hasMoreBuckets$),
          filter((val: any) => !this.isLoadMore && val && val.target.scrollBottom < 10),
          tap(() => this.ngZone.run(() => {
            this.isLoadMore = true;
            this.store.dispatch(new BucketAction.GetMoreBuckets(10))
              .subscribe(() => this.isLoadMore = false);
          }))
        ).subscribe();
    }
  }

  createNewBucket() {
    this.dialog.open(SharedNewTracksContainerDialogComponent, {
      data: {
        tracksContainerType: 'BUCKET'
      }
    });
  }

  searchBucket(term: string) {
    if (term === '') {
      this.filteredBuckets = this.buckets;
    } else {
      this.filteredBuckets = this.buckets.filter((bucket) => {
        return bucket.name.toLowerCase().includes(term.toLowerCase());
      });
    }
  }

  onBucketSelected(bucketId: number) {
    if (this.loadedPlaylistsInBucket.has(bucketId)) {
      return;
    }

    if (this.selectedBucketsId.has(bucketId)) {
      this.selectedBucketsId.delete(bucketId);
    } else {
      this.selectedBucketsId.add(bucketId);
    }
  }

  addPlaylistToBuckets() {
    this.selectedBucketsId.forEach((bucketId) => {
      this.store.dispatch(new BucketAction.AddPlaylistsToBucket([this.data.playlistId], bucketId))
        .subscribe(() => {
          this._snackBar.open(
            `Playlist successfully added to the bucket ${this.buckets.find(({ id }) => id === bucketId).name}`,
            null,
            { duration: SNACK_BAR_DURATION }
          );
        });
    });

    this.dialogRef.close();
  }

  ngOnDestroy() {
    if (this.bucketSub) {
      this.bucketSub.unsubscribe();
    }
  }
}
