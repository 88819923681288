<li
  [ngClass]="{
    'edit-mode': isEditMode,
    'justify-content-between': from === trackSource.ALBUM,
    'justify-content-start': from !== trackSource.ALBUM,
    'bucket-view': from === trackSource.BUCKET,
    'only-name': from === trackSource.ALBUM
  }"
  class="track-items-wrapper d-flex align-items-center"
>
  <div [ngClass]="{'selected': isSelected}" class="track-item-wrapper d-flex align-items-center">
    <div *ngIf="isEditMode" class="icon-wrapper">
      <div (click)="trackSelection()" class="icon" [class.icon-delete]="!isSelected" [class.icon-plus]="isSelected"></div>
    </div>
    <div class="track-cover overflow-hidden">
      <img
        appDefault
        [src]="(track.artwork && track.artwork.artworkUrl100) ?
                track.artwork.artworkUrl100 : '/assets/images/default-cover.jpg'"
        class="w-100 h-100"
        alt="Track cover"
      />
    </div>
    <div class="track-details d-flex" [class.short-view]="from === trackSource.BUCKET">
      <div class="track-title text-truncate" [class.only-name]="from === trackSource.ALBUM">
        <span [title]="track.name">{{ track.name }}</span>
      </div>
      <ng-container *ngIf="from !== trackSource.ALBUM">
        <div class="track-artist text-truncate">
          <span [title]="track.artist?.name">{{ track.artist?.name }}</span>
        </div>
        <div class="track-album text-truncate">
          <span [title]="track.album?.name">{{ track.album?.name }}</span>
        </div>
      </ng-container>
    </div>
  </div>

  <button *ngIf="!isEditMode" [matMenuTriggerFor]="trackMenu" class="btn menu-btn p-0">
    <span class="icon icon-more"></span>
  </button>

  <div [hidden]="!isEditMode" class="draggable" cdkDragHandle>
    <div class="drag-handle"></div>
  </div>

  <mat-menu class="mat-menu track-menu" #trackMenu="matMenu">
    <div (click)="toggleLikeDislike(true)" class="menu-item">
      <div
        [ngClass]="{
          'icon-heart-empty': !track.heartState?.like,
          'icon-heart-filled': track.heartState?.like
        }"
        class="menu-item-icon"
      >
      </div>
      <span class="menu-item-label">Like</span>
    </div>
    <div class="menu-item" (click)="addTrackToPlaylist();">
      <div class="menu-item-icon icon-add-to-list"></div>
      <span class="menu-item-label">Add to Playlist</span>
    </div>
    <div *ngIf="!track.isInLibrary" (click)="addTrackToLibrary()" class="menu-item" >
      <div class="menu-item-icon icon-opened-book"></div>
      <span class="menu-item-label">Add to Favourites</span>
    </div>
    <div *ngIf="track.isInLibrary" (click)="removeTrackFromLibrary()" class="menu-item" >
      <div class="menu-item-icon icon-opened-book-strike"></div>
      <span class="menu-item-label">Remove from Favourites</span>
    </div>
    <div class="menu-item" (click)="goToArtist();">
      <div class="menu-item-icon icon-artist"></div>
      <span class="menu-item-label">View the Artist</span>
    </div>
    <div class="menu-item" (click)="addToPlayingQueue()">
      <div class="menu-item-icon icon-queue"></div>
      <span class="menu-item-label">Add To Playing Queue</span>
    </div>
    <div (click)="toggleLikeDislike(false)" class="menu-item">
      <div
        class="menu-item-icon"
        [ngClass]="{
          'icon-heart-broken': track.heartState?.like === null || track.heartState?.like,
          'icon-heart-broken-filled': track.heartState?.like === false
        }"
      >
      </div>
      <span class="menu-item-label">Dislike</span>
    </div>
    <div (click)="removeFromBucket()" *ngIf="from === trackSource.BUCKET">
      <div *ngxPermissionsOnly="['ca', 'venue-admin', 'admin', 'curator', 'manager']" class="menu-item">
        <div class="menu-item-icon icon-remove-from-bucket"></div>
        <span class="menu-item-label">Remove from bucket</span>
      </div>
    </div>
  </mat-menu>
</li>
