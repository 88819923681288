import { Directive, Input, HostBinding, ElementRef } from '@angular/core';

@Directive({
  selector: 'img[appDefault]',
  host: {
    '(error)':'updateUrl()',
    '[src]':'src'
   }
})
export class ImagePreloadDirective {
  @Input() src: string;
  @Input() appDefault: string;
  @Input() defaultClass: string;

  constructor(
    private elem: ElementRef
  ) { }

  updateUrl() {
    this.src = this.appDefault || '/assets/images/default-cover.jpg';
    if (this.defaultClass) {
      this.elem.nativeElement.className += ` ${this.defaultClass}`;
    }
  }

}
