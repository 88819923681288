import { BucketsState } from './buckets/buckets.state';
import { ChannelsState } from './channels/channels.state';
import { NewChannelState } from './new-channels/new-channels.state';
import { NewPlaylistsState } from './new-playlists/new-playlists.state';
import { NewVideoPlaylistsState } from './new-video-playlist/new-video-playlists.state';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { PlaylistsState } from './playlists/playlists.state';
import { PromotionsState } from './promotions/promotions.state';
import { SearchCatalogueState } from './search-catalogue/search-catalogue.state';
import { SearchState } from './search/search.state';
import { VenueListState } from './venue-list/venue-list.state';
import { VenueState } from './venue/venue.state';
import { VideoPlaylistsState } from './video-playlists/video-playlists.state';
import { ZonesState } from './zones/zones.state';
import { SchedulesState } from './schedules/schedules.state';
import { environment } from '../../../environments/environment';

const MODULES = [
    PlaylistsState,
    VideoPlaylistsState,
    ChannelsState,
    BucketsState,
    NewPlaylistsState,
    NewVideoPlaylistsState,
    NewChannelState,
    SearchState,
    VenueState,
    VenueListState,
    ZonesState,
    PromotionsState,
    SearchCatalogueState,
    SchedulesState
];

@NgModule({
  imports: [
    NgxsModule.forRoot(MODULES, { developmentMode: false }),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
    NgxsResetPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({
      key: ['venue.id']
    })
  ]
})
export class StoreModule {
}
