import { Injectable } from '@angular/core';

import * as moment from 'moment';

import flatten from 'lodash/flatten';
import compact from 'lodash/compact';
import chunk from 'lodash/chunk';

@Injectable({
  providedIn: 'root'
})
export class PrepareDataService {
  constructor() { }

  durationHumanize(duration) {
    const res = duration.split(':');
    let timeString = '';

    if (Number(res[0]) > 24) {
      const days = Math.trunc(Number(res[0]) / 24);

      timeString += days.toString() + ((days > 1) ? ' Days ' : ' Day ');
      res[0] = (Number(res[0]) - (days * 24)).toString();
    }

    timeString += Number(res[0]).toString() + ((Number(res[0]) > 1) ? ' Hours ' : ' Hour ');
    timeString += Number(res[1]).toString() + ' min';

    return timeString;
  }

  trackDuration(duration) {
    if (!duration) {
      return '00:00';
    }
    const res = duration.split(/[:.]/);

    if (Number(res[0]) === 0 && res.length > 2) {
      return `${res[1]}:${res[2]}`;
    }

    return duration;
  }

  tracksCountInBucket(playlist: any[]) {
    let trackCount = 0;

    playlist.forEach(x => trackCount += x.trackCount);

    return trackCount;
  }

  durationInSecondsHumanize(duration) {
    if (duration === 0) {
      return '0 min';
    }

    const days = Math.trunc(duration / (60 * 60 * 24));
    const hours = Math.trunc((duration - (days * 60 * 60 * 24)) / 3600);
    const minutes = Math.trunc((duration - (days * 60 * 60 * 24) - (hours * 3600)) / 60);

    let timeString = '';

    if (days > 0) {
      timeString += days + (days === 1 ? ' Day ' : ' Days ');
    }

    if (hours > 0) {
      timeString += hours + (hours === 1 ? ' Hour' : ' Hours');
    }

    timeString += ' ' + minutes + ' min';

    return timeString;
  }

  paginateTracks(wholeArray, page, limit = 10) {
    const newData = wholeArray.slice(0, (page + 1) * limit);

    newData.forEach(x => x.duration = this.trackDuration(x.duration));

    return newData;
  }

  bytesToMb(bytes) {
    return (bytes / 1048576).toFixed(2);
  }

  paginateData(wholeArray, page, limit = 10) {
    return wholeArray.slice(0, (page + 1) * limit);
  }

  sortTimeslots(a, b) {
    if (a.from < b.from) {
      return -1;
    }

    if (a.from > b.from) {
      return 1;
    }

    return 0;
  }

  trackDurationToSec(duration) {
    return moment.duration(duration, 's').asSeconds();
  }

  playlistArtwork(playlist, count = 4, isLarge: boolean = false) {
    const artwork = [];
    let i = 0;

    if (playlist.logoImageUrl) {
      artwork.push(playlist.logoImageUrl);

      return artwork;
    }

    if (playlist.tracks && playlist.tracks.length >= count) {
      while (artwork.length < count && playlist.tracks.length > i) {
        if (playlist.tracks[i].artwork && playlist.tracks[i].artwork.artworkUrl100) {
          const src = isLarge ?  playlist.tracks[i].artwork.artworkUrl1000 : playlist.tracks[i].artwork.artworkUrl100;

          if (!artwork.includes(src)) {
            artwork.push(src);
          }
        }

        i++;
      }
    } else if (playlist.tracks && playlist.tracks.length >= 1 && playlist.tracks[0] && playlist.tracks[0].artwork) {
      if (count > 4) {
        artwork.push(playlist.tracks[0].artwork.artworkUrl1000);
      } else {
        artwork.push(isLarge ? playlist.tracks[0].artwork.artworkUrl1000 : playlist.tracks[0].artwork.artworkUrl100);
      }
    }

    return artwork;
  }

  playlistArtworkConn(playlist, count = 4) {
    const artwork = [];
    let i = 0;
    if (playlist.logoImageUrl) {
      artwork.push(playlist.logoImageUrl);

      return artwork;
    }

    if (playlist.tracksConnection && playlist.tracksConnection.totalCount >= count) {
      //console.log("prep data", playlist.tracksConnection.edges[i]);
      while (artwork.length < count && playlist.tracksConnection.totalCount > i) {
	//console.log("prep data i", i);
        if (playlist.tracksConnection.edges[i] && playlist.tracksConnection.edges[i].node.artwork && playlist.tracksConnection.edges[i].node.artwork.artworkUrl100) {
          const src = playlist.tracksConnection.edges[i].node.artwork.artworkUrl100;

          if (!artwork.includes(src)) {
            artwork.push(src);
          }
        }

        i++;
      }
    } else if (playlist.tracksConnection && playlist.tracksConnection.totalCount >= 1 && playlist.tracksConnection.edges[0] && playlist.tracksConnection.edges[0].node.artwork) { //in case playlist.tracksConnection.totalCount < count
      //basically it only shows one artwork.
      if (count > 4) {
        artwork.push(playlist.tracksConnection.edges[0].node.artwork.artworkUrl1000);
      } else {
        artwork.push(playlist.tracksConnection.edges[0].node.artwork.artworkUrl100);
      }
    }

    return artwork;
  }

  bucketArtwork(bucket, count = 4) {
    const artwork = [];

    if (bucket.playlists) {
      const playlists = bucket.playlists;
      let trackIndex = 0, playlistIndex = 0;

      while (artwork.length < count && playlists.length !== playlistIndex) {
        if (playlists[playlistIndex].logoImageUrl) {
          artwork.push(playlists[playlistIndex].logoImageUrl);
          playlistIndex++;
          trackIndex = 0;
          continue;
        }

        if (!playlists[playlistIndex].tracks ||
          playlists[playlistIndex].tracks.length === 0 ||
          playlists[playlistIndex].tracks.length <= trackIndex
          ) {
          playlistIndex++;
          trackIndex = 0;
          continue;
        }
        if (playlists[playlistIndex].tracks[trackIndex].artwork &&
          playlists[playlistIndex].tracks[trackIndex].artwork.artworkUrl100
        ) {
          const src = playlists[playlistIndex].tracks[trackIndex].artwork.artworkUrl100;
          if (!artwork.includes(src)) {
            artwork.push(src);
          }
          if ((playlistIndex !== playlists.length - 1)) {
            playlistIndex ++;
            trackIndex = 0;
            continue;
          }
        }
        trackIndex++;
      }
    }
    return artwork;
  }

  coverToSize(url: string, size: string) {
    return url.replace('100x100', size);
  }

  makeTwoLineList(list: any[], columns = 5) {
    // 1. Prepare items order for chunking
    const chunkedList = chunk(list, columns * 2);
    const sortedList = flatten(chunkedList.map((listChunk) => {
      const arr = [];
      const listChunkLen = listChunk.length;

      if (listChunkLen < columns * 2 && listChunkLen >= columns) {
        for (let i = 0; i < columns; i++) {
          arr.push(listChunk[i], listChunk[i + columns]);
        }

        return compact(arr);
      } else {
        const middleItemIndex = Math.floor(listChunkLen / 2);

        for (let i = 0; i < middleItemIndex; i++) {
          arr.push(listChunk[i], listChunk[i + middleItemIndex]);
        }

        if (arr.length < listChunkLen) {
          arr.push(listChunk[listChunkLen - 1]);
        }

        return arr;
      }
    }));

    // 2. Chunk items for carousel with specified columns
    const sortedListLen = sortedList.length;

    if (sortedListLen < columns * 2 && sortedListLen > columns) {
      const delimiter = (sortedListLen - columns) * 2;
      const a = sortedList.slice(0, delimiter);
      const b = sortedList.slice(delimiter, sortedListLen);

      return chunk(a, 2).concat(chunk(b, 1));
    } else if (sortedListLen <= columns) {
      return chunk(sortedList, 1);
    }

    return chunk(sortedList, 2);
  }

  // TODO: albumArtwork() {}

  getHours(isSameOrBefore) {
    const MAX_HOURS = 23;
    const MIN_TWO_DIGIT_VALUE = 10;
    const hours: string[] = [];

    const limitedHours = isSameOrBefore ?
      moment().format('HH') : -1
    for (let i = 0; i < MIN_TWO_DIGIT_VALUE; i++) {
      Number(limitedHours) < i && hours.push('0' + i);
    }

    for (let i = MIN_TWO_DIGIT_VALUE; i <= MAX_HOURS; i++) {
      Number(limitedHours) < i && hours.push('' + i);
    }

    return hours;
  }

  getMinutes() {
    const MAX_MINUTES = 59;
    const MIN_TWO_DIGIT_VALUE = 10;
    const minutes: string[] = [];

    for (let i = 0; i < MIN_TWO_DIGIT_VALUE; i++) {
      minutes.push('0' + i);
    }

    for (let i = MIN_TWO_DIGIT_VALUE; i <= MAX_MINUTES; i++) {
      minutes.push('' + i);
    }

    return minutes;
  }
}
