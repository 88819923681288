<div (click)="selectBucket()" class="bucket-cover-container">
  <div [ngClass]="{ blurred: isSelected }" class="cover-wrapper">
    <div class="cover-wrapper">
      <div class="quarter-images" *ngIf="bucket.artwork?.length === 4">
        <img
          *ngFor="let artwork of bucket.artwork"
          [src]="artwork"
          appDefault
          class="quarter-img"
          alt="Bucket cover"
        />
      </div>
      <img
        *ngIf="bucket.artwork?.length < 4"
        [src]="(bucket.artwork && bucket.artwork[0]) ? coverToSize(bucket.artwork[0]) : '/assets/images/default-cover.jpg'"
        appDefault
        class="cover"
        alt="Bucket cover"
      />
    </div>
  </div>
  <div *ngIf="isSelected" class="checkmark-wrapper"></div>
</div>
<div [title]="bucket.name" class="bucket-name text-truncate">{{ bucket.name }}</div>
<div class="bucket-tracks-details">
  <span>{{ bucket.trackCount || 0 }} Tracks</span>
  <span class="dot-separator"></span>
  <span>{{ bucket.duration?.split(':')[0] || '00' }}h , {{ bucket.duration?.split(':')[1] || '00' }}m</span>
</div>
