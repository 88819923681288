import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

import { NgxPermissionsModule } from 'ngx-permissions';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { SharedPlaylistItemComponent } from './playlist-item.component';
import { ImagePreloadModule } from '../../directives/image-preload/image-preload.module';
import { SpinnerModule } from 'src/app/system/spinner/spinner.module';

@NgModule({
  declarations: [
    SharedPlaylistItemComponent
  ],
  imports: [
    CommonModule,
    MatMenuModule,
    MatDialogModule,
    MatTooltipModule,
    NgScrollbarModule,
    NgxPermissionsModule,
    ImagePreloadModule,
    SpinnerModule
  ],
  exports: [
    SharedPlaylistItemComponent
  ]
})
export class SharedPlaylistItemModule { }
