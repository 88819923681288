<div class="lists-wrapper">
	<div class="track-list-wrapper edit-mode">
		<ng-scrollbar appearance=true class="track-list">
			<div *ngFor="let trackObj of tracks; let i = index" class="track-items-wrapper" [class.active]="i === 0">

				<div class="player-details-container d-flex justify-content-between align-items-center"
					*ngIf="!!trackObj">
					<div class="track-details-container d-flex align-items-center w-100">
						<div class="album-cover-wrapper">
							<img *ngIf="trackObj.track.artwork && trackObj.track.artwork.artworkUrl100"
								[src]="trackObj.track.artwork.artworkUrl100" appDefault alt="Album cover"
								class="album-cover" />
							<img *ngIf="!trackObj.track.artwork || !trackObj.track.artwork.artworkUrl100"
								src="../../../../assets/images/default-cover.jpg" alt="Default album cover"
								class="album-cover" />
						</div>

						<div class="track-details d-flex flex-column">
							<!-- <p [title]="trackObj.track.name" class="track-name text-truncate p-0">{{ trackObj.track.name.substr(0, 16) }}</p> -->
							<div *ngIf="trackObj['type'] == 'Manual'" class="d-flex">
								<div class="track-name text-truncate p-0">{{ trackObj.track.name }}</div>
								<div class="btn menu-btn p-0" title="Added manually">
									<span class="icon icon-manual-edit"></span>
								</div>
							</div>

							<div *ngIf="trackObj['type'] !== 'Manual'">
								<div class="track-name text-truncate p-0">{{ trackObj.track.name }}</div>
							</div>
							<!-- <p [title]="trackObj.track.name" class="track-name text-truncate p-0">{{ trackObj.track.name }}</p> -->
							<p *ngIf="trackObj.track?.artist?.name" [title]="trackObj.track?.artist?.name"
								class="artist-name text-truncate p-0">
								{{ trackObj.track?.artist?.name }}
							</p>
						</div>

						<div class="float-right">
							<button [matMenuTriggerFor]="trackMenu" class="btn menu-btn p-0">
								<span class="icon icon-more"></span>
							</button>
						</div>

						<mat-menu class="mat-menu track-menu" #trackMenu="matMenu">
							<div (click)="toggleLikeDislike(trackObj.track, true)" class="menu-item">
								<div [ngClass]="{
			     'icon-heart-empty': !trackObj.track.heartState?.like,
			     'icon-heart-filled': trackObj.track.heartState?.like
			     }" class="menu-item-icon">
								</div>
								<span class="menu-item-label">Like</span>
							</div>
							<div class="menu-item" (click)="addTrackToPlaylist(trackObj.track);">
								<div class="menu-item-icon icon-add-to-list"></div>
								<span class="menu-item-label">Add to Playlist</span>
							</div>
							<div *ngIf="!trackObj.track.isInLibrary" (click)="addTrackToLibrary(trackObj.track)"
								class="menu-item">
								<div class="menu-item-icon icon-opened-book"></div>
								<span class="menu-item-label">Add to Favourites</span>
							</div>
							<div *ngIf="trackObj.track.isInLibrary" (click)="removeTrackFromLibrary(trackObj.track)"
								class="menu-item">
								<div class="menu-item-icon icon-opened-book-strike"></div>
								<span class="menu-item-label">Remove from Favourites</span>
							</div>
							<div class="menu-item" *ngIf="showArtist" (click)="goToArtist(trackObj.track);">
								<div class="menu-item-icon icon-artist"></div>
								<span class="menu-item-label">View the Artist</span>
							</div>
							<div class="menu-item" (click)="addToPlayingQueue(trackObj.track)">
								<div class="menu-item-icon icon-queue"></div>
								<span class="menu-item-label">Add To Playing Queue</span>
							</div>
							<div *ngIf="trackObj['type'] == 'Manual'" class="menu-item" (click)="promptRemoveTrack(i)">
								<div class="menu-item-icon icon-delete"></div>
								<span class="menu-item-label">Remove From Playing Queue</span>
							</div>
							<div (click)="toggleLikeDislike(trackObj.track, false)" class="menu-item">
								<div class="menu-item-icon" [ngClass]="{
			     'icon-heart-broken': trackObj.track.heartState?.like === null || trackObj.track.heartState?.like,
			     'icon-heart-broken-filled': trackObj.track.heartState?.like === false
			     }">
								</div>
								<span class="menu-item-label">Dislike</span>
							</div>
						</mat-menu>
					</div>
				</div>

			</div>
		</ng-scrollbar>

	</div>
</div>