import { APP_INITIALIZER, NgModule } from '@angular/core';
import { PermissionsService } from './permissions.service';
import { NgxPermissionsModule } from 'ngx-permissions';

@NgModule({
  declarations: [],
  imports: [NgxPermissionsModule.forRoot()],
  exports: [NgxPermissionsModule],
  providers: [
    PermissionsService,
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [PermissionsService],
      useFactory: (permissions: PermissionsService) => () => permissions.init(),
    }
  ],
})
export class PermissionsModule {
}
