<div class="track-cover-container">

  <div class="cover-wrapper">
    <img
      [src]="(track.artwork && track.artwork.artworkUrl100) ? coverToSize(track.artwork.artworkUrl100) : '/assets/images/default-cover.jpg'"
      appDefault
      class="cover"
      alt="Track cover"
    />
  </div>

  <div class="controls-wrapper">
    <button class="menu-button" [matMenuTriggerFor]="appMenu">
      <div class="menu">
        <div class="cover-blur">
          <img
            [src]="(track.artwork && track.artwork.artworkUrl100) ? track.artwork.artworkUrl100 : '/assets/images/default-cover.jpg'"
            appDefault
            class="cover"
            alt="Track cover"
          />
        </div>
        <div class="cover-dark"></div>
        <div class="custom-icon icon-more"></div>
      </div>
    </button>

    <mat-menu class="track-menu" #appMenu="matMenu">
      <div (click)="toggleLikeDislike(true)" class="menu-item">
        <div
          [ngClass]="{
            'icon-heart-empty': !track.heartState?.like,
            'icon-heart-filled': track.heartState?.like
          }"
          class="menu-item-icon"
        >
        </div>
        <span class="menu-item-label">Like</span>
      </div>
      <div (click)="addToPlaylist()" class="menu-item">
        <div class="menu-item-icon icon-add-to-list"></div>
        <span class="menu-item-label">Add to Playlist</span>
      </div>
      <div *ngIf="!track.isInLibrary" (click)="addToLibrary()" class="menu-item" >
        <div class="menu-item-icon icon-opened-book"></div>
        <span class="menu-item-label">Add to Library</span>
      </div>
      <div *ngIf="track.isInLibrary" (click)="removeFromLibrary()" class="menu-item">
        <div class="menu-item-icon icon-opened-book"></div>
        <span class="menu-item-label">In Library</span>
        <img class="check-icon" src="../../assets/icons/checkmark.svg">
      </div>
      <div (click)="goToArtist()" class="menu-item">
        <div class="menu-item-icon icon-artist"></div>
        <span class="menu-item-label">View the Artist</span>
      </div>
      <div (click)="addToPlayingQueue(track)" class="menu-item">
        <div class="menu-item-icon icon-queue"></div>
        <span class="menu-item-label">Add to Playing Queue</span>
      </div>
      <div (click)="toggleLikeDislike(false)" class="menu-item">
        <div
          [ngClass]="{
            'icon-heart-broken': track.heartState?.like === null || track.heartState?.like,
            'icon-heart-broken-filled': track.heartState?.like === false
          }"
          class="menu-item-icon"
        >
        </div>
        <span class="menu-item-label">Dislike</span>
      </div>
    </mat-menu>
  </div>
</div>
<div [title]="track.name" class="track-name text-truncate">{{ track.name }}</div>
<div [title]="track.artist?.name + ' - ' + track.album?.name" class="track-details text-truncate">{{ track.artist?.name }}{{track.artist?.name && track.album?.name && ' - '}}{{ track.album?.name }}</div>
