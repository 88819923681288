import { CombinedFragments, MinimalFragments } from './fragments';

import gql from 'graphql-tag';
import paginationQueryTemplate from './paginationQueryTemplate';

export const PlayerCommandType = {
  resume: 'RESUME',
  pause: 'PAUSE',
  crossFadeNext: 'CROSS_FADE_NEXT',
  next: 'PLAY_NEXT',
  prev: 'PLAY_PREVIOUS',
  toTrack: 'JUMP_TO_TRACK',
  trackToPos: 'SET_TRACK_POS_SECS',
  trackPosRatio: 'SET_TRACK_POS_RATIO',
  monitorOn: 'MONITOR_ON',
  monitorOff: 'MONITOR_OFF'
};

export const QueueType = {
  ManualTrackQueue: 'manual',
  OverrideTrackQueue : 'override',
  ScheduleTrackQueue: 'schedule',
  VenueDefaultScheduleTrackQueue: 'default'
}

export const QueueReadableType = {
  ManualTrackQueue: 'Manually Selected Playlist',
  OverrideTrackQueue: 'Override Playlist',
  ScheduleTrackQueue: 'Scheduled Playlist',
  VenueDefaultScheduleTrackQueue: 'Default Scheduled Playlist'
}

export const TrackReadableType = {
  ManualTrackQueueItem: 'Manual',
  OverrideTrackQueueItem: 'Override',
  ScheduleTrackQueueItem: 'Scheduled',
  VenueDefaultScheduleTrackQueueItem: 'Default Scheduled'
}

const trackQueueItem = `
  id
  position
  track {
    ...TrackData
  }
`;

const currentTrackQueue = `
  currentTrackQueue {
    __typename
    ... on ManualTrackQueue
      {
        id
        currentPosition
        tracks(filter: NOT_PLAYED) {
          ${trackQueueItem}
        }
      }
      ... on OverrideTrackQueue
      {
        id
        currentPosition
        tracks(filter: NOT_PLAYED) {
          ${trackQueueItem}
        }
      }
      ... on ScheduleTrackQueue
      {
        id
        currentPosition
        tracks {
          ${trackQueueItem}
        }
      }
      ... on VenueDefaultScheduleTrackQueue
      {
        id
        currentPosition
        tracks {
          ${trackQueueItem}
        }
      }
  }
`;

const manualTrackQueue = `
  manualTrackQueue {
    id
    currentPosition
    tracks(filter: NOT_PLAYED) {
      ${trackQueueItem}
    }
  }
`

const zoneData = `
  id
  name
  address1
  address2
  mediaType
  player {
    id
    online
  }
  schedule {
    id
    name
    isDraft
  }
  audioChannels
  fadeTimeSeconds
  venue {
    id
  }
  playedHistory(limit: 20) {
    edges {
      node {
        track {
          ...TrackData
        }
      }
    }
  }
  playing
  track {
    id
  }
  subscribedToChannels {
    id
  }
  currentTrack {
    ...TrackMinData
    duration
    album {
      ...AlbumMinData
    }
    artist {
      ...ArtistMinData
      artwork {
        artworkUrl100
        artworkUrl1000
      }
    }
    heartState(venueId: $venueId) {
      like
    }
  }
  ${currentTrackQueue}
  ${manualTrackQueue}
`;

export const zonesForVenue = gql`
  query venue(
    $id: Int!
  ) {
    venue(
      id: $id
    ) {
      zones {
        ${zoneData}
      }
    }
  }
  ${MinimalFragments.fragments.album}
  ${MinimalFragments.fragments.artist}
  ${MinimalFragments.fragments.track}
  ${CombinedFragments.fragments.track}
`;

export const zones = gql`
  query zones ($venueId: Int!) {
    zones {
      ${zoneData}
    }
  }
  ${MinimalFragments.fragments.album}
  ${MinimalFragments.fragments.artist}
  ${MinimalFragments.fragments.track}
  ${CombinedFragments.fragments.track}
`;

export const createZone = gql`
  mutation createZone(
    $venueId: Int!
    $name: String!
    $address1: String!
    $address2: String
    $postcode: String
    $city: String
    $state: String
    $country: String!
    $mediaType: MediaType,
    $channelId: Int
  ) {
    createZone(
      venueId: $venueId
      name: $name
      address1: $address1
      address2: $address2
      postcode: $postcode
      city: $city
      state: $state
      country: $country
      mediaType: $mediaType
      channelId: $channelId
    ) {
      ${zoneData}
    }
  }
${MinimalFragments.fragments.album}
${MinimalFragments.fragments.artist}
${MinimalFragments.fragments.track}
${CombinedFragments.fragments.track}
`;

export const updateZone = gql`
  mutation updateZone(
    $zoneId: Int!
    $values: ZoneInput!
    $venueId: Int!
  ) {
    updateZone(
      zoneId: $zoneId,
      values: $values
    ) {
      ${zoneData}
    }
  }
  ${MinimalFragments.fragments.album}
  ${MinimalFragments.fragments.artist}
  ${MinimalFragments.fragments.track}
  ${CombinedFragments.fragments.track}
`;

export const deleteZone = gql`
  mutation deleteZone(
    $zoneId: Int!
  ) {
    deleteZone(
      id: $zoneId
    )
  }
`;

export const addPlaylistToZoneQueue = gql`
  mutation overrideZoneTracksUsingPlaylistUntilNextTimeslot(
    $zoneId: Int!
    $playlistId: Int!
    $forbidSimilar: Boolean
    $shuffle: Boolean
  ) {
    overrideZoneTracksUsingPlaylistUntilNextTimeslot(
      zoneId: $zoneId,
      playlistId: $playlistId,
      forbidSimilar: $forbidSimilar,
      shuffle: $shuffle
    ) {
      id
      from
      to
      playlist {
        id
        name
      }
      bucket {
        id
        name
      }
      tracks {
        id
        position
        track {
          ...TrackData
        }
      }
    }
  }
  ${CombinedFragments.fragments.track}
`;

export const removeTrackFromPlayQueue = gql`
  mutation removeTrackFromPlayQueue(
    $trackId: Int!
    $zoneId: Int!
  ) {
    removeTrackFromPlayQueue (
      trackId: $trackId
      zoneId: $zoneId
    )
  }
`;

export const addTrackToPlayQueue = gql`
  mutation addTrackToPlayQueue(
    $trackId: Int!
    $zoneId: Int!
  ) {
    addTrackToPlayQueue (
      trackId: $trackId
      zoneId: $zoneId
    ) {
      id
      position
      track {
        ...TrackData
      }
    }
  }
  ${CombinedFragments.fragments.track}
`;

export const zoneChangeSubscription = gql`
  subscription zoneChange(
    $id: Int!
    $venueId: Int!
  ) {
    zoneChange(id: $id) {
      ${zoneData}
    }
  }
  ${MinimalFragments.fragments.album}
  ${MinimalFragments.fragments.artist}
  ${MinimalFragments.fragments.track}
  ${CombinedFragments.fragments.track}
`;

export const zonePlayerChangeSubscription = gql`
  subscription zonePlayerChange(
    $zoneId: Int!
    $venueId: Int!
  ) {
    zonePlayerChange(zoneId: $zoneId) {
      ${zoneData}
    }
  }
  ${MinimalFragments.fragments.album}
  ${MinimalFragments.fragments.artist}
  ${MinimalFragments.fragments.track}
  ${CombinedFragments.fragments.track}
`;

export const zonePlayerEventSubscription = gql`
  subscription zonePlayerEvent(
    $zoneId: Int!
    $venueId: Int!
  ) {
    zonePlayerEvent(zoneId: $zoneId) {
      id
      player {
        id
        online
      }
      zone {
        ${zoneData}
      }
      at
      sequence
      playerEventType {
        id
        name
      }
      createdAt
      customJson
      track {
        ...TrackData
      }
      from
      to
      artworkUrl100
      artworkUrl1000
    }
  }
  ${MinimalFragments.fragments.album}
  ${MinimalFragments.fragments.artist}
  ${MinimalFragments.fragments.track}
  ${CombinedFragments.fragments.track}
`;

export const zonePlayerMonitorChangeSubscription = gql`
  subscription zonePlayerMonitorStateChange(
    $zoneId: Int!
  ) {
    zonePlayerMonitorStateChange(zoneId: $zoneId) {
      playing
      trackId
      trackName
      trackArtistName
      trackAlbumName
      trackPositionSeconds
      trackDurationSeconds
      tracksInQueue
      trackArtworkUrl100
      trackArtworkUrl1000
      trackQueueId
    }
  }
`;

export const zonePlayingStateChange = gql`
  subscription zonePlayingStateChange(
    $playerId: Int!
  ) {
    zonePlayingStateChange(
      playerId: $playerId
    ) {
      zone {
        id
        name
      }
      track {
        id
        name
        album {
          id
          name
        }
        artist {
          id
          name
        }
        artwork {
          artworkUrl100
          artworkUrl1000
        }
      }
      isPlaying
      playingState {
        trackQueueId
        trackQueueTypename
        trackQueueItemId
        position
        time
        state
        length
      }
    }
  }
`;

export const createPlayerCommand = gql`
  mutation createPlayerCommand(
    $zoneId: Int!
    $playerCommandType: PlayerCommandType!
    $trackId: Int
    $trackPosSeconds: Float
  ) {
    createPlayerCommand(
      zoneId: $zoneId
      playerCommandType: $playerCommandType
      trackId: $trackId
      trackPosSeconds: $trackPosSeconds
    ) {
      id
      player {
        id
      }
      failed
      failMessage
      actioned
    }
  }
`;

// new zones queries
export const getZones = gql`
  query zonesConnection(
    $venueId: Int!
    $nameSearch: String
    $first: Int
    $after: Cursor
    $last: Int
    $before: Cursor
  ) {
    zonesConnection(
      venueId: $venueId
      nameSearch: $nameSearch
      first: $first
      after: $after
      last: $last
      before: $before
    ) {
      ${paginationQueryTemplate(zoneData)}
    }
  }
  ${MinimalFragments.fragments.album}
  ${MinimalFragments.fragments.artist}
  ${MinimalFragments.fragments.track}
  ${CombinedFragments.fragments.track}
`;

export const makePlaylistAvailableOffline = gql`
  mutation makePlaylistAvailableOffline(
    $playlistId: Int!
    $zoneId: Int
    $setting: Boolean!
  ) {
    makePlaylistAvailableOffline(
      playlistId: $playlistId
      zoneId: $zoneId
      setting: $setting
    )
  }
`;

export const jukeoSetEnabledZone = gql`
  mutation jukeoSetEnabledZone(
    $zoneIds: [Int!]!,
    $venueId: Int!,
    $enable: Boolean
  ) {
    jukeoSetEnableZone(zoneIds: $zoneIds, venueId: $venueId, enable: $enable)
  }
`;

export const jukeoEnabledZone = gql`
  query jukeoEnabledZone($venueId: Int!) {
    jukeoEnabledZones(venueId: $venueId) {
      id
      name
    }
  }
`;

export const jukeoSetZonePlaylist = gql`
  mutation jukeoSetZonePlaylist($zoneId: Int!, $venueId: Int!, $playlistIds: [Int!]!) {
    jukeoSetZonePlaylists(zoneId: $zoneId, venueId: $venueId, playlistIds: $playlistIds)
  }
`;

export const jukeoZonePlaylists = gql`
  query jukeoZonePlaylists($zoneIds: [Int!], $venueId: Int!) {
    jukeoZonePlaylists(zoneIds: $zoneIds, venueId: $venueId) {
      id
      name
    }
  }
`;

export const jukeoSetZoneTracks = gql`
  mutation jukeoSetZoneTracks($zoneId: Int!, $venueId: Int!, $trackIds: [Int!]!) {
    jukeoSetZoneTracks(zoneId: $zoneId, venueId: $venueId, trackIds: $trackIds)
  }
`;

export const jukeoZoneTracks = gql`
  query jukeoZoneTracks($zoneIds: [Int!]!, $venueId: Int!) {
    jukeoZoneTracks(zoneIds: $zoneIds, venueId: $venueId) {
      id
      name
    }
  }
`;
