import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import { EditPlaylistDialogComponent } from '../../../system/playlists/edit-playlist-dialog/edit-playlist-dialog.component';
import { SharedBucketListDialogComponent } from '../bucket-list-dialog/bucket-list-dialog.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { ZonesListComponent } from '../zone-list-dialog/zone-list-dialog.component';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { PlaylistService } from '../../services/playlist.service';
import { ZoneService } from '../../services/zone.service';
import { Playlist } from '../../models/models';
import { PrepareDataService } from '../../services/prepare-data.service';
import { Store } from '@ngxs/store';
import { PlaylistAction } from '../../store/playlists/playlists.actions';
import { NewPlaylistAction } from '../../store/new-playlists/new-playlists.actions';
import { ErrorParserService } from '../../services/error-parcing.service';
import { PlaylistSourcePage, MY_PLAYLISTS_VIEWS, SNACK_BAR_DURATION } from '../../models/consts';

@Component({
  selector: 'app-playlist-item',
  templateUrl: './playlist-item.component.html',
  styleUrls: ['./playlist-item.component.scss']
})
export class SharedPlaylistItemComponent implements OnInit {
  @Input() playlist: Playlist;
  @Input() from: PlaylistSourcePage;

  showInLibraryMenu: boolean;
  showEdit: boolean;
  showDelete: boolean;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private zoneService: ZoneService,
    private prepareService: PrepareDataService,
    private store: Store,
    private errorService: ErrorParserService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.showInLibraryMenu = !MY_PLAYLISTS_VIEWS.includes(this.from);
    this.showEdit = this.from === PlaylistSourcePage.DISCOVER;
    this.showDelete = MY_PLAYLISTS_VIEWS.includes(this.from);
  }

  goToPlaylist() {
    if (this.from === PlaylistSourcePage.DISCOVER) {
      this.router.navigate(['/curation/playlist/', this.playlist.id]);
    } else {
      this.router.navigate(['/playlist/', this.playlist.id]);
    }

  }

  addToBucket() {
    this.dialog.open(SharedBucketListDialogComponent, {
      data: {
        playlistId: this.playlist.id
      }
    });
  }

  addToLibrary() {
    this.store.dispatch([
      new PlaylistAction.AddPlaylist(this.playlist),
      new NewPlaylistAction.ChangePlaylistLibraryState([this.playlist.id], true)
    ])
    .subscribe(() => {
      this.playlist = {...this.playlist, isInLibrary: true};
      this._snackBar.open(
        `Playlist successfully added to the library`,
        null,
        { duration: SNACK_BAR_DURATION }
      );
    }, (error) => {
      const errIdStr = this.errorService.parseError(error);
      this.dialog.open(AlertDialogComponent, {
        data: {
          type: 'error',
          message: `Oops, something went wrong. Please try again.
          ${(!!errIdStr) ? ('Error id: ' + errIdStr) : ''}`
        }
      });
    });
  }

  deletePlaylist() {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: 'Are you sure you want to delete the playlist?',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary'
          },
          {
            text: 'Delete',
            cssClass: 'danger',
            handler: () => {
              this.dialog.closeAll();
              const action = (this.from === PlaylistSourcePage.DISCOVER) ?
                new NewPlaylistAction.DeletePlaylist(this.playlist.id) :
                (
                  (this.playlist.isCatalog) ?
                  [
                    new PlaylistAction.RemovePlaylistFromLibrary(this.playlist.id),
                    new NewPlaylistAction.ChangePlaylistLibraryState([this.playlist.id], false)
                  ] :
                  [
                    new PlaylistAction.DeletePlaylist(this.playlist.id),
                    new NewPlaylistAction.ChangePlaylistLibraryState([this.playlist.id], false)
                  ]
                );
              this.store.dispatch(action)
              .subscribe(
                () => {
                  this._snackBar.open(
                    `Playlist successfully removed${
                      (this.from !== PlaylistSourcePage.DISCOVER) ? ' from the library' : ''
                    }`,
                    null,
                    { duration: SNACK_BAR_DURATION }
                  );
                  this.playlist = {
                    ...this.playlist,
                    isInLibrary: false
                  };
                },
                (error) => {
                  const errIdStr = this.errorService.parseError(error);
                  this.dialog.open(AlertDialogComponent, {
                    data: {
                      type: 'error',
                      message: `Oops, something went wrong. Please try again.
                      ${(!!errIdStr) ? ('Error id: ' + errIdStr) : ''}`
                    }
                  });
                }
              );
            }
          }
        ]
      },
      width: '340px'
    });
  }

  editPlaylist() {
    const dialogRef = this.dialog.open(
      EditPlaylistDialogComponent,
      {
        data: { playlist: this.playlist }
      }
    );
  }

  addToQueue() {
    this.dialog.open(ZonesListComponent)
      .afterClosed()
      .subscribe(data => {
        if (!data) {
          return;
        }
        if (data.error) {
          this.dialog.open(AlertDialogComponent, {
            data: {
              type: 'error',
              message: data.error
            }
          });
        } else {
          this.confirmAddPlaylistInQueue(data.id);
        }
      });
  }

  confirmAddPlaylistInQueue(zoneId) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: 'The playlist will start after the current song finishes',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary'
          },
          {
            text: 'OK',
            cssClass: 'danger',
            handler: () => {
              this.zoneService.addPlaylistToZoneQueue(
                zoneId,
                this.playlist.id
              )
              .then(() => {
                dialogRef.close();
                this._snackBar.open(
                  `Playlist successfully added to the queue`,
                  null,
                  { duration: SNACK_BAR_DURATION }
                );
              })
              .catch((error) => {
                const errIdStr = this.errorService.parseError(error);
                dialogRef.close();
                this.dialog.open(AlertDialogComponent, {
                  data: {
                    type: 'error',
                    message: `Oops, something went wrong. Please try again.
                    ${(!!errIdStr) ? ('Error id: ' + errIdStr) : ''}`
                  }
                });
              });
            }
          }
        ]
      },
      width: '340px'
    });
  }

  coverToSize(url) {
    return this.prepareService.coverToSize(url, '200x200');
  }
}
