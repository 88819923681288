import { PlaylistInput } from '../../models/models';

export namespace NewChannelAction {

  export class GetNewChannels {
    static readonly type = '[New Channels] Get Channels';
    constructor() {}
  }

  export class GetMoreChannels {
    static readonly type = '[New Channels] Get More Channels';
    constructor(
      public count?: number
    ) {}
  }

  export class CreateChannel {
    static readonly type = '[New Channels] Create New Channel';
    constructor(
      public playlistInput: PlaylistInput,
      public cover?: any
    ) {}
  }

  export class EditChannel {
    static readonly type = '[New Channels] Edit Channel';
    constructor(
      public id: number,
      public playlistInput: PlaylistInput,
      public cover?: any
    ) {}
  }

  export class DeleteChannel {
    static readonly type = '[New Channels] Delete Channel';
    constructor(
      public id: number
    ) {}
  }

  export class UpdateTracksInChannel {
    static readonly type = '[New Channels] Add Clip To Channel';
    constructor(
      public playlistId: number
    ) {}
  }

  export class RemoveVideoFromChannel {
    static readonly type = '[New Channels] Remove Clip From Channel';
    constructor(
      public trackIds: Array<number>,
      public playlistId: number
    ) {}
  }

  export class UpdateChannelCover {
      static readonly type = '[New Channel] Update Channel Cover';
  }
}