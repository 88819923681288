import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { SearchCatalogueGenre, SearchCatalogueInterface } from '../../../shared/gql/search-queries.d';
import { VenueType, VenueTypeCategory } from '../../gql/venue-type-queries.d';

import { LocalStoreService } from '../../services/localstore.service';
import { NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { SearchCatalogService } from '../../../shared/services/search-catalogue.service';
import { SearchCatalogueAction } from './search-catalogue.actions';
import { VenueTypeService } from '../../services/venue-type.service';
import { flatten } from 'lodash';
import { Injectable } from '@angular/core';

export interface SearchCatalogueStateModel {
  search: string;
  popularGenres: Array<SearchCatalogueGenre>;
  venueTypes: Array<VenueType>;
  venueTypeCategories: Array<VenueTypeCategory>;
  error: string;
  loading: boolean;
  genre: string;
  venueType: {
    id: number;
    name: string;
    lastCursor: string;
    hasNextPage: boolean;
  } | null;
  venueTypeCategory: {
    id: number;
    name: string;
    artworkUrl100: string;
  } | null;
  catalogue: SearchCatalogueInterface;
  searchHistory: Array<string>;
  previousNav: Array<string>;
  currentNav?: string;
  nextNav: Array<string>;
  venueTypesLoading: boolean;
  genreLoading: boolean;
}

// There are albums that are 1 character long
const MIN_SEARCH_LENGTH = 1;
const EMPTY_CATALOGUE = {
  artists: [],
  tracks: [],
  playlists: [],
}
const SEARCH_HISTORY_KEY = 'searchHistory';

const SEARCH_CATALOGUE_SCHEMA = { key: 'searchCatalogueSchema', value: 'v1' };

@State<SearchCatalogueStateModel>({
  name: 'searchCatalogue',
  defaults: {
    search: "",
    popularGenres: [],
    venueTypes: [],
    error: "",
    loading: false,
    genre: "", // selected genre
    venueType: null, // selected venue type id
    catalogue: EMPTY_CATALOGUE,
    searchHistory: [],
    previousNav: [],
    currentNav: null,
    nextNav: [],
    venueTypesLoading: false,
    genreLoading: false,
    venueTypeCategories: [],
    venueTypeCategory: null, // selected venue type category id
  }
})
@Injectable()
export class SearchCatalogueState implements NgxsOnInit {
  constructor(
    private searchCatalogueService: SearchCatalogService,
    private venueTypeService: VenueTypeService,
    private router: Router,
    private localStore: LocalStoreService,
    private ngZone: NgZone,
  ) {}

  ngxsOnInit(ctx?: StateContext<SearchCatalogueStateModel>) {
    const key = this.localStore.getItem(SEARCH_CATALOGUE_SCHEMA.key);
    if (key && key !== SEARCH_CATALOGUE_SCHEMA.value) {
      this.localStore.setItem(SEARCH_CATALOGUE_SCHEMA.key, SEARCH_CATALOGUE_SCHEMA.value);
      this.localStore.removeItem(SEARCH_HISTORY_KEY);
    } else {
      if (!key) {
        this.localStore.setItem(SEARCH_CATALOGUE_SCHEMA.key, SEARCH_CATALOGUE_SCHEMA.value);
      }

      const searchHistoryLocal = this.localStore.getItemParsed(SEARCH_HISTORY_KEY);

      if (searchHistoryLocal && Array.isArray(searchHistoryLocal)) {
        ctx.patchState({ searchHistory: searchHistoryLocal });
      }
    }
  }

  @Action(SearchCatalogueAction.GetPopularGenres)
  async getPopularGenres(ctx: StateContext<SearchCatalogueStateModel>) {
    ctx.patchState({ genreLoading: true, catalogue: EMPTY_CATALOGUE, search: '', genre: '', venueType: null, venueTypeCategory: null });
    const results = await this.searchCatalogueService.searchPopularGenres();
    ctx.patchState({ genreLoading: false });

    if (results.errors) {
      ctx.patchState({ error: "Could not get popular genres" });
    }

    if (results.data) {
      ctx.patchState({ popularGenres: results.data.searchPopularGenres });
    }
  }

  @Action(SearchCatalogueAction.UpdateSearch)
  async updateSearch(ctx: StateContext<SearchCatalogueStateModel>, action: SearchCatalogueAction.UpdateSearch) {
    const state = ctx.getState();
    if (action.search.length < MIN_SEARCH_LENGTH) {
      ctx.patchState({ search: action.search, catalogue: EMPTY_CATALOGUE });
      return;
    }

    ctx.patchState({ search: action.search, genre: '', venueType: null, loading: true, venueTypeCategory: null });
    const results = await this.searchCatalogueService.searchCatalogue(action.search);
    ctx.patchState({ loading: false,  searchHistory: [...state.searchHistory, action.search] });
    this.updateRecentSearch([...state.searchHistory, action.search]);

    if (results.errors) {
      ctx.patchState({ error: "Could not get popular genres" });
      return;
    }

    if (results.data) {
      ctx.patchState({ catalogue: results.data.searchCatalogue });
    }
  }

  @Action(SearchCatalogueAction.UpdateGenre)
  async updateGenre(ctx: StateContext<SearchCatalogueStateModel>, action: SearchCatalogueAction.UpdateGenre) {
    ctx.patchState({ loading: true, search: '', genre: action.genre, venueType: null });
    const results = await this.searchCatalogueService.searchCatalogue('', action.genre);
    ctx.patchState({ loading: false });

    if (results.errors) {
      ctx.patchState({ error: "Could not get popular genres" });
    }

    if (results.data) {
      ctx.patchState({ catalogue: results.data.searchCatalogue });
    }
  }

  @Action(SearchCatalogueAction.PreviousNavHistory)
  async previousNavHistory(ctx: StateContext<SearchCatalogueStateModel>) {
    const state = ctx.getState();

    const previousNav = [...state.previousNav]; // remove reference by copy
    if (!previousNav.length) return;

    const lastNavHistory = previousNav.pop();
    const nextNav = [...state.nextNav, state.currentNav];

    ctx.patchState({ previousNav, currentNav: lastNavHistory, nextNav });
    this.ngZone.run(() => {
      this.router.navigate([lastNavHistory]);
    });
    ctx.patchState({ loading: false });
  }

  @Action(SearchCatalogueAction.NextNavHistory)
  async nextNavHistory(ctx: StateContext<SearchCatalogueStateModel>) {
    const state = ctx.getState();

    const nextNav = [...state.nextNav];
    if (!nextNav.length) return;

    const lastNavHistory = nextNav.pop();
    const previousNav = [...state.previousNav, state.currentNav];

    ctx.patchState({ previousNav, currentNav: lastNavHistory, nextNav });
    this.ngZone.run(() => {
      this.router.navigate([lastNavHistory]);
    });
    ctx.patchState({ loading: false });
  }

  @Action(SearchCatalogueAction.AddToHistory)
  addToHistory(ctx: StateContext<SearchCatalogueStateModel>, action: SearchCatalogueAction.AddToHistory) {
    this.addToPrevNavHistory(ctx, action.history);
    history.pushState(ctx.getState(), ''); //history is a global variable belongs to browser
    this.ngZone.run(() => {
      this.router.navigate([action.history]);
    })
  }

  @Action(SearchCatalogueAction.RetrieveHistory)
  retrieveHistory(ctx: StateContext<SearchCatalogueStateModel>) {
    ctx.patchState(history.state); //history is a global variable belongs to browser
  }

  @Action(SearchCatalogueAction.SaveToLocalStorage)
  saveToLocalStorage(ctx: StateContext<SearchCatalogueStateModel>, action: SearchCatalogueAction.SaveToLocalStorage) {
    this.localStore.setItemStringified(action.localStorageKey, ctx.getState());
  }

  @Action(SearchCatalogueAction.RetrieveFromLocalStorage)
  retrieveFromLocalStorage(ctx: StateContext<SearchCatalogueStateModel>, action: SearchCatalogueAction.RetrieveFromLocalStorage) {
    ctx.patchState(this.localStore.getItemParsed(action.localStorageKey))
  }

  @Action(SearchCatalogueAction.GetVenueTypes)
  async getVenueTypes(ctx: StateContext<SearchCatalogueStateModel>) {
    ctx.patchState({ venueTypesLoading: true, catalogue: EMPTY_CATALOGUE, search: '', genre: '', venueType: null });
    const results = await this.venueTypeService.getVenueTypes();
    ctx.patchState({ venueTypesLoading: false });

    if (results.errors) {
      ctx.patchState({ error: "Could not get venue types" });
    }

    if (results.data) {
      ctx.patchState({ venueTypes: results.data.venueTypes });
    }
  }

  @Action(SearchCatalogueAction.UpdateVenueType)
  async updateVenueType(ctx: StateContext<SearchCatalogueStateModel>, action: SearchCatalogueAction.UpdateVenueType) {
    const state = ctx.getState()
    if (state.catalogue.playlists.length) {
      return
    }
    ctx.patchState({ loading: true, catalogue: EMPTY_CATALOGUE, search: '', genre: '' });
    const results = await this.venueTypeService.getVenueType(action.id);
    ctx.patchState({ loading: false });

    if (results.errors) {
      ctx.patchState({ error: "Could not get venue types" });
    }

    if (results.data) {
      const playlists = results.data.venueType.playlistsConnection.edges.map(p => p.node);
      const lastCursor = results.data.venueType.playlistsConnection.pageInfo.endCursor;
      const hasNextPage = results.data.venueType.playlistsConnection.pageInfo.hasNextPage;
      const tracks: SearchCatalogueInterface["tracks"] = flatten(playlists.map((playlist) => playlist.tracksConnection.edges)).map(t => t.node).map((track) => ({
        id: track.id,
        name: track.name,
        isrc: track.isrc,
        artists: track.artist ? [{
          id: track.artist.id,
          name: track.artist.name,
          artworkUrl100: track.artist.artwork ? track.artist.artwork.artworkUrl100 : null,
          artworkUrl1000: track.artist.artwork ? track.artist.artwork.artworkUrl1000 : null,
        }] : [],
        album: {
          id: track.album.id,
          name: track.album.name
        },
        artworkUrl100: track.artwork ? track.artwork.artworkUrl100 : null,
        artworkUrl1000: track.artwork ?  track.artwork.artworkUrl1000 : null,
      }));

      const artists = flatten(tracks.filter((track) => track.artists).map((track) => track.artists));

      ctx.patchState({
          catalogue: {
          artists,
          tracks: tracks,
          playlists,
        },
        venueType: {
          id: action.id,
          name: results.data.venueType.venueType,
	  lastCursor: lastCursor,
	  hasNextPage: hasNextPage,
        }
      });
    }
  }

  @Action(SearchCatalogueAction.GetMoreVenueTypePlaylists)
  async getMoreVenueTypePlaylists(ctx: StateContext<SearchCatalogueStateModel>, action: SearchCatalogueAction.GetMoreVenueTypePlaylists) {

    const state = ctx.getState();
    ctx.patchState({ loading: true, catalogue: EMPTY_CATALOGUE, search: '', genre: '' });
    const results = await this.venueTypeService.getVenueTypeMore(action.id, action.numPlaylist, action.afterCursor);
    ctx.patchState({ loading: false });

    if (results.errors) {
      ctx.patchState({ error: "Could not get more venue type playlists" });
    }

    if (results.data) {
      const playlists = results.data.venueType.playlistsConnection.edges.map(p => p.node);
      const lastCursor = results.data.venueType.playlistsConnection.pageInfo.endCursor;
      const hasNextPage = results.data.venueType.playlistsConnection.pageInfo.hasNextPage;
      const tracks: SearchCatalogueInterface["tracks"] = flatten(playlists.map((playlist) => playlist.tracksConnection.edges)).map(t => t.node).map((track) => ({
        id: track.id,
        name: track.name,
        isrc: track.isrc,
        artists: track.artist ? [{
          id: track.artist.id,
          name: track.artist.name,
          artworkUrl100: track.artist.artwork ? track.artist.artwork.artworkUrl100 : null,
          artworkUrl1000: track.artist.artwork ? track.artist.artwork.artworkUrl1000 : null,
        }] : [],
        album: {
          id: track.album.id,
          name: track.album.name
        },
        artworkUrl100: track.artwork ? track.artwork.artworkUrl100 : null,
        artworkUrl1000: track.artwork ?  track.artwork.artworkUrl1000 : null,
      }));

      const artists = flatten(tracks.filter((track) => track.artists).map((track) => track.artists));

      ctx.patchState({
          catalogue: {
            artists: [...state.catalogue.artists, ...artists],
            tracks: [...state.catalogue.tracks, ...tracks],
            playlists: [...state.catalogue.playlists, ...playlists],
        },
        venueType: {
          id: action.id,
          name: results.data.venueType.venueType,
	  lastCursor: lastCursor,
	  hasNextPage: hasNextPage,
        }
      });
    }
  }

  @Action(SearchCatalogueAction.ClearSearchHistory)
  clearSearchHistory(ctx: StateContext<SearchCatalogueStateModel>) {
    ctx.patchState({ searchHistory: [] });
    this.updateRecentSearch([]);
  }

  @Action(SearchCatalogueAction.ClearNavHistory)
  clearNavHistory(ctx: StateContext<SearchCatalogueStateModel>) {
    ctx.patchState({ previousNav: [], nextNav: [], currentNav: null });
  }

  @Action(SearchCatalogueAction.GetVenueTypeCategories)
  async getVenueTypeCategories(ctx: StateContext<SearchCatalogueStateModel>) {
    ctx.patchState({ venueTypesLoading: true, catalogue: EMPTY_CATALOGUE, search: '', genre: '', venueTypeCategory: null });
    const results = await this.venueTypeService.getVenueTypeCategories();
    ctx.patchState({ venueTypesLoading: false });

    if (results.errors) {
      ctx.patchState({ error: "Could not get venue type categories" });
    }

    if (results.data) {
      ctx.patchState({ venueTypeCategories: results.data.venueTypeCategories });
    }
  }

  @Action(SearchCatalogueAction.UpdateVenueTypeCategory)
  async updateVenueTypeCategory(ctx: StateContext<SearchCatalogueStateModel>, action: SearchCatalogueAction.UpdateVenueTypeCategory) {
    ctx.patchState({ loading: true, catalogue: EMPTY_CATALOGUE, search: '', genre: '' });
    const results = await this.venueTypeService.getVenueTypeCategory(action.id);
    ctx.patchState({ loading: false });

    if (results.errors) {
      ctx.patchState({ error: "Could not get venue type categories" });
    }

    if (results.data) {
      ctx.patchState({
        venueTypeCategory: {
          id: action.id,
          name: results.data.venueTypeCategory.name,
          artworkUrl100: results.data.venueTypeCategory.artworkUrl100,
        }
      });
    }
  }

  @Selector()
  static popularGenres(state: SearchCatalogueStateModel) {
    return state.popularGenres;
  }

  @Selector()
  static search(state: SearchCatalogueStateModel) {
    return state.search;
  }

  @Selector()
  static catalogue(state: SearchCatalogueStateModel) {
    return state.catalogue;
  }

  @Selector()
  static genre(state: SearchCatalogueStateModel) {
    return state.genre;
  }

  @Selector()
  static searchHistory(state: SearchCatalogueStateModel) {
    return Array.from(new Set(state.searchHistory)).reverse();
  }

  @Selector()
  static previousNav(state: SearchCatalogueStateModel) {
    return state.previousNav;
  }

  @Selector()
  static nextNav(state: SearchCatalogueStateModel) {
    return state.nextNav;
  }

  @Selector()
  static loading(state: SearchCatalogueStateModel) {
    return state.loading;
  }

  @Selector()
  static error(state: SearchCatalogueStateModel) {
    return state.error;
  }

  @Selector()
  static venueTypes(state: SearchCatalogueStateModel) {
    return state.venueTypes
  }

  @Selector()
  static venueType(state: SearchCatalogueStateModel) {
    return state.venueType
  }

  @Selector()
  static venueTypeCategory(state: SearchCatalogueStateModel) {
    return state.venueTypeCategory
  }

  @Selector()
  static venueTypeLoading(state: SearchCatalogueStateModel) {
    return state.venueTypesLoading;
  }

  @Selector()
  static genreLoading(state: SearchCatalogueStateModel) {
    return state.genreLoading;
  }

  @Selector()
  static venueTypeCategories(state: SearchCatalogueStateModel) {
    return state.venueTypeCategories;
  }

  private addToPrevNavHistory(ctx: StateContext<SearchCatalogueStateModel>, navHistory: string) {
    const state = ctx.getState();
    const previousNav = [...state.previousNav, state.currentNav || '/search'];
    ctx.patchState({ previousNav, currentNav: navHistory, nextNav: [] });
  }

  private updateRecentSearch(searchHistory: Array<string>) {
    this.localStore.setItemStringified(SEARCH_HISTORY_KEY, searchHistory);
  }
}
