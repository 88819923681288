import { Playlist } from '../../models/models';

export namespace ChannelAction {

  export class GetMyChannels {
    static readonly type = '[Library Channel] Get Channel';
    constructor() {}
  }

  export class GetMoreChannels {
    static readonly type = '[Library Channel] Get More Channel';
    constructor(
      public count?: number
    ) {}
  }

  export class AddChannel {
    static readonly type = '[Library Channel] Add Channel To Library';
    constructor(
      public playlist: Playlist
    ) {}
  }

  export class DeleteChannel {
    static readonly type = '[Library Channel] Delete Channel';
    constructor(
      public id: number
    ) {}
  }
}