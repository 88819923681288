import { Injectable, Injector } from '@angular/core';

import { AuthService } from '../../shared/services/auth.service';
import { NgxPermissionsService } from 'ngx-permissions';
import ROLES from './roles';
import { User } from '../../shared/models/models';
import { filter } from 'rxjs/operators';
import { LocalStoreService } from '../../shared/services/localstore.service';
import { APP_PERMISSIONS_KEY } from '../../shared/services/constants';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  constructor(
    private ngxPermissions: NgxPermissionsService,
    private localStore: LocalStoreService,
    private injector: Injector
  ) {
  }

  init() {
    return this.injector.get(AuthService).onUserChanged
      .pipe(filter(Boolean))
      .subscribe((user: User) => {
        const { globalRoles, roles } = user;
        const userRoles = (globalRoles && globalRoles.length) ? globalRoles : roles;
        if (userRoles && userRoles.length) {
          const userPermissions = userRoles
            .filter((r) => r in ROLES)
            .map((r) => ROLES[r])
            .reduce((prev, cur) => [...prev, ...cur], []);
          this.ngxPermissions.loadPermissions([...userRoles, ...userPermissions]);
	  this.localStore.setItemStringified(APP_PERMISSIONS_KEY, [...userRoles, ...userPermissions]);
        }
      });
  }

  async forceUpdate() {
    const user = this.injector.get(AuthService).onUserChanged.getValue();
    const { globalRoles, roles } = user;
    const userRoles = (globalRoles && globalRoles.length) ? globalRoles : roles;
    if (userRoles && userRoles.length) {
      const userPermissions = userRoles
        .filter((r) => r in ROLES)
        .map((r) => ROLES[r])
        .reduce((prev, cur) => [...prev, ...cur], []);
      this.ngxPermissions.loadPermissions([...userRoles, ...userPermissions]);
      this.localStore.setItemStringified(APP_PERMISSIONS_KEY, [...userRoles, ...userPermissions]);
    }
  }
}
