import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorParserService {

  constructor() { }

  parseError(errors) {
    if (!errors) {
      return null;
    }
    const errorsIDs = [];
    if (errors.graphQLErrors) {
      errorsIDs.push(...errors.graphQLErrors.map(({ id }) => id));
    }
    if (errors.networkError && errors.networkError.error && errorsIDs) {
      errorsIDs.push(...errors.networkError.error.errors.map(({ id }) => id));
    }
    if (!errorsIDs.length) {
      return null;
    }
    console.log(errorsIDs);
    
    return (`${errorsIDs[0]}${(errorsIDs.length > 1) ? (' - ' + errorsIDs[errorsIDs.length - 1]) : ''}`);
  }
}
