<div class="dialog-header">
  <h2 mat-dialog-title>Choose Venue</h2>
  <button mat-dialog-close class="btn close-dialog-btn"></button>
</div>

<mat-dialog-content>
  <div class="venues-wrapper">
    <input #searchInput (keyup)="searchVenue(searchInput.value.trim())" placeholder="Search a Venue"
      class="input-search" type="text" />
    <!-- <div *ngIf="!isLoading; else spinner" class="scroll-wrapper"> -->
    <ng-scrollbar appearance=true>
      <ng-container *ngIf="!isLoading; else spinner">
        <div *ngFor="let venue of filteredVenues" (click)="selectVenue(venue.id)"
          class="venue-item d-flex overflow-hidden">
          <div class="d-flex justify-content-center cover-thumbnail">
            <img [src]="venue.bannerImageUrl || '/assets/images/logo-gray.svg'"
              appDefault="/assets/images/logo-gray.svg" defaultClass="default-image"
              [class.default-image]="!venue.bannerImageUrl" alt="Venue banner" />
          </div>
          <div class="data-wrapper">
            <h2 [title]="venue.name" class="block-with-text">{{ venue.name }}</h2>
            <div class="sub-data">
              <p>{{ venue.zones.length }} {{ (venue.zones.length === 1) ? 'Zone' : 'Zones' }}</p>
              <p [title]="venue.address.line1" class="text-truncate">{{ venue.address.line1 || 'n/a' }}</p>
            </div>
          </div>
        </div>
        <app-spinner [isLoading]="isMoreVenuesLoading" [diameter]="50" [minimal]="true"></app-spinner>
      </ng-container>
    </ng-scrollbar>
    <!-- </div> -->
  </div>
</mat-dialog-content>

<ng-template #spinner>
  <div class="spinner-wrapper">
    <app-spinner [isLoading]="isLoading" [errored]="errored" [minimal]="true"></app-spinner>
  </div>
</ng-template>

<ng-template #noVenues>
  <div class="message-wrapper d-flex justify-content-center align-items-center flex-column">
    <div class="no-venues-found-image"></div>
    <p class="text-center">No Venues found</p>
  </div>
</ng-template>