import { Component, OnDestroy, OnInit, Inject } from '@angular/core';

import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ZonesState } from 'src/app/shared/store/zones/zones.state';
import { Player, Zone } from '../../../shared/models/models';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { PlayerService } from '../../../shared/services/player.service';

interface PlayerCommandDialogButton {
  text: string;
  role?: string;
  cssClass?: string;
  handler?: Function;
}

interface DialogData {
  zoneId: number;
  playing: boolean;
  title?: string;
  message: string;
  showSpinner?: boolean;
  buttons: PlayerCommandDialogButton[];
}

@Component({
  templateUrl: './player-command-dialog.component.html',
  styleUrls: ['./player-command-dialog.component.scss']
})

export class PlayerCommandDialogComponent implements OnInit, OnDestroy {
  @Select(ZonesState.currentZone) currentZone$: Observable<Zone>;
  private currentZoneSub: Subscription;
  private startTime = Date.now()
  public zoneId: number;
  public playing: boolean;
  public title: string;
  public message: string;
  public waiting: boolean;
  private playerSub: Subscription;

  constructor(
    public dialogRef: MatDialogRef<PlayerCommandDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private playerService: PlayerService,
    private store: Store
  ) {
    this.currentZone$ = this.store.select(ZonesState.currentZone);
    this.playing = data.playing;
    this.title = data.title;
    this.message = data.message;
    this.waiting = true;
    this.zoneId = data.zoneId;
  }

  public toggleSpinner() {
    this.data.showSpinner = !this.data.showSpinner;
  }

  ngOnInit() {
    this.currentZoneSub = this.currentZone$.subscribe((zone) => {
      if (zone.playing != this.playing) {
	this.waiting = false;
	this.title = 'Request Actioned';
	this.message = 'Success!\nYour command has been actioned by the player.';
      }
    });

    this.playerSub = this.playerService.onPlayersChanged.subscribe((players) => {
      //   const diff = (Date.now() - this.startTime);
      //   if (diff > 20000) {
      // 	this.waiting = false;
      // 	this.title = 'Request Actioned';
      // 	this.message = 'Oops! The player did not respond to your command. Please check the device\'s internet connection and try again.';
      //   }


      const zonePlayer = players.find((player) => player && player.id === this.zoneId);
      if (zonePlayer) {
	if (zonePlayer.playing != this.playing) {
	  this.waiting = false;
	  this.title = 'Request Actioned';
	  this.message = 'Success!\nYour command has been actioned by the player.';
	}
      }
    });

    setTimeout(()=> {
      this.waiting = false;
      this.title = 'Request Actioned';
      this.message = 'Oops! The player did not respond to your command. Please check the device\'s internet connection and try again.';
    }, 20000)
  }

  ngOnDestroy() {
    this.currentZoneSub.unsubscribe();
    this.playerSub.unsubscribe();
  }

}
