import gql from 'graphql-tag';

const VenueTypeCategory = gql`
  fragment VenueTypeCategory  on VenueTypeCategory  {
    id
    name
    artworkUrl100
    venueTypes {
      id
      venueType
      playlistsConnection {
      edges {
       node {
        id
        name
        tracksConnection(first: 1) {
        edges {
         node {
          id
          name
          artist {
            id
            name
          }
         }
        }
        }
       }
      }
      }
    }
  }
`

const VenueType = gql`
  fragment VenueType on VenueType {
    id
    venueType
    playlistsConnection {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
     node {
      id
      name
      logoImageUrl
      tracksConnection(first:4) {
      edges {
       node {
        id
        name
        isrc
        artist {
          id
          name
          artwork {
            artworkUrl100
            artworkUrl1000
          }
        }
        artwork {
          artworkUrl100
          artworkUrl1000
        }
        album {
          id
          name
        }
       }
      }
      }
     }
    }
    }
  }
`
const VenueTypeMore = gql`
  fragment VenueType on VenueType {
    id
    venueType
    playlistsConnection(first:$numPlaylist, after:$afterPlaylistCursor) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
     node {
      id
      name
      logoImageUrl
      tracksConnection(first:4) {
      edges {
       node {
        id
        name
        isrc
        artist {
          id
          name
          artwork {
            artworkUrl100
            artworkUrl1000
          }
        }
        artwork {
          artworkUrl100
          artworkUrl1000
        }
        album {
          id
          name
        }
       }
      }
      }
     }
    }
    }
  }
`

const VenueTypeCategoryFull = gql`
  fragment VenueTypeCategory  on VenueTypeCategory  {
    id
    name
    artworkUrl100
    venueTypes {
      id
      venueType
      playlistsConnection(first:1) {
      edges {
       node {
        id
        name
        logoImageUrl
        tracksConnection(first:4) {
        edges {
         node {
          id
          name
          isrc
          artist {
            id
            name
            artwork {
              artworkUrl100
              artworkUrl1000
            }
          }
          artwork {
            artworkUrl100
            artworkUrl1000
          }
          album {
            id
            name
          }
         }
        }
        }
       }
      }
      }
    }
  }

`
const VenueTypeCategoryShort = gql`
  fragment VenueTypeCategory  on VenueTypeCategory  {
    id
    name
    artworkUrl100
  }

`

const venueType = gql`
  query venueType($id: Int!) {
    venueType(id: $id) {
      ...VenueType
    }
  }
  ${VenueType}
`

const venueTypeMore = gql`
  query venueType($id: Int!, $numPlaylist:Int!, $afterPlaylistCursor:Cursor!) {
    venueType(id: $id) {
      ...VenueType
    }
  }
  ${VenueTypeMore}
`

const venueTypes = gql`
  query venueTypes {
    venueTypes {
      id
      venueType
      category {
        ...VenueTypeCategory
      }
      playlistsConnection {
      edges {
       node {
        id
        name
        tracksConnection(first: 1) {
        edges {
         node {
          id
          name
          isrc
          artist {
            id
            name
          }
          artwork {
            artworkUrl100
            artworkUrl1000
          }
         }
        }
        }
       }
      }
      }
    }
  }

  ${VenueTypeCategory}
`

const venueTypeCategories = gql`
  query venueTypeCategories {
    venueTypeCategories {
      ...VenueTypeCategory
    }
  }

  ${VenueTypeCategoryShort}
`

const venueTypeCategory = gql`
  query venueTypeCategory($id: Int!) {
    venueTypeCategory(id: $id) {
      ...VenueTypeCategory
    }
  }

  ${VenueTypeCategoryShort}
`

export {
  venueType,
  venueTypeMore,
  venueTypes,
  venueTypeCategories,
  venueTypeCategory
}
