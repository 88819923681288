<div id="user-menu">
  <div class="user-data-wrapper">
    <div class="user-menu-button dropdown">

      <div class="venue-wrapper position-relative" *ngxPermissionsOnly="['ca', 'venue-admin', 'admin']">
        <p [title]="venue?.name" class="venue-name m-0 text-truncate">{{ venue?.name }}</p>
        <p [title]="venue?.address.line1" class="venue-address m-0 text-truncate">{{ venue?.address.line1 }}</p>
        <div (click)="selectMenuItem(); goToVenueSettings()" class="icon icon-settings position-absolute"></div>
      </div>

      <div class="flex-wrapper d-flex align-items-center">
        <div class="avatar-wrapper">
          <img
            [src]="user?.avatarImageUrl || '/assets/images/user-default-black.png'"
            appDefault="/assets/images/user-default-black.png"
            class="avatar rounded-circle"
            alt="User avatar"
          />
        </div>
        <div class="icon icon-logout" (click)="selectMenuItem(); logout()"></div>
      </div>

      <div class="user-info-wrapper">
        <div [title]="user?.displayName" class="user-name text-truncate">{{ user?.displayName }}</div>
        <div [title]="user?.userEmail" class="user-email text-truncate">{{ user?.userEmail }}</div>
      </div>
    </div>

    <div class="menu-wrapper" *ngxPermissionsOnly="['ca', 'venue-admin', 'admin']">
      <a routerLink="/venues" (click)="selectMenuItem()">
        <div class="menu-item-icon d-flex align-items-center"><div class="icon icon-map-flag"></div></div>
        <div class="menu-item-text">Venues</div>
      </a>

      <a (click)="selectMenuItem(); openReportingModal()">
        <div class="menu-item-icon d-flex align-items-center"><div class="icon icon-reporting"></div></div>
        <div class="menu-item-text">Reporting</div>
      </a>

      <a routerLink="/my-teams" (click)="selectMenuItem()">
        <div class="menu-item-icon d-flex align-items-center"><div class="icon icon-create-user"></div></div>
        <div class="menu-item-text">Create User</div>
      </a>
    </div>
    <a routerLink="/curation" (click)="selectMenuItem()" *ngxPermissionsOnly="'CURATION_PERMISSION'">
      <button class="btn primary">
        Go To Curation
      </button>
    </a>
  </div>

  <div *ngIf="zonesWithPlayer.length > 0" class="player-container">
    <div class="player-header d-flex justify-content-between align-items-center">
      <div class="header-text">Now Playing</div>
      <div (click)="goToQueue()" class="icon icon-queue"></div>
    </div>

    <div class="player-wrapper position-relative">
      <div class="players-carousel-nav nav-container"></div>
      <div class="carousel-container">
        <owl-carousel
          #owlMenuCarousel
          [options]="{
            items: 1,
            dots: false,
            mouseDrag: false,
            nav: true,
            navContainer: '.players-carousel-nav',
            navClass: ['btn owl-prev-menu', 'btn owl-next-menu'],
            onChanged: onZoneChanged
          }"
          [items]="zoneIdArr"
        >
          <div *ngFor="let id of zoneIdArr; let i = index" class="player-group-wrapper">
            <app-player-item [zone]="zonesWithPlayer[i]" [timer]="timers[id]"></app-player-item>
          </div>
        </owl-carousel>
      </div>
    </div>
  </div>
</div>
