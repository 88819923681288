import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { PrepareDataService } from '../../../shared/services/prepare-data.service';
import { ZoneService } from '../../../shared/services/zone.service';
import { PlayerCommandType } from 'src/app/shared/gql/zone-consts';
import { Timer, Zone } from 'src/app/shared/models/models';
import { TrackService } from '../../../shared/services/track.service';
import { TrackQueueComponent } from '../../../shared/components/track-queue/track-queue.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-player-item',
  templateUrl: './player-item.component.html',
  styleUrls: ['./player-item.component.scss']
})
export class PlayerItemComponent implements OnInit {
  @Input() zone: Zone;
  @Input() timer: Timer;

  constructor(
    private prepareService: PrepareDataService,
    private trackService: TrackService,
    private zoneService: ZoneService,
    private router: Router,
    public dialog: MatDialog
  ) {
  }

  ngOnInit() {
  }

  playPause() {
    const playerCommandType = this.zone.playing ? PlayerCommandType.pause : PlayerCommandType.resume;

    this.zoneService.createPlayerCommand(playerCommandType, this.zone.id)
    .subscribe(
      () => {},
      (error) => {
        console.log(error);
      }
    );
  }

  playPrevTrack() {
    this.zoneService.createPlayerCommand(PlayerCommandType.prev, this.zone.id)
    .subscribe(
      () => {},
      (error) => {
        console.log(error);
      }
    );
  }

  playNextTrack() {
    this.zoneService.createPlayerCommand(PlayerCommandType.next, this.zone.id)
    .subscribe(
      () => {},
      (error) => {
        console.log(error);
      }
    );
  }

  toggleLikeDislike(state: boolean) {
    if (this.zone.currentTrack.heartState && this.zone.currentTrack.heartState.like === state) {
      state = null;
    }

    this.trackService.setTrackHeartState(this.zone.currentTrack.id, state)
      .then((heartState) => {
        this.zone.currentTrack.heartState = heartState;
      });
  }

  goToQueue() {
    const dialogRef = this.dialog.open(TrackQueueComponent, {
      data: {
        zone: this.zone
      }
    });

    dialogRef.afterClosed()
      .subscribe((result) => {});
  }

  goToArtist() {
    if (this.zone.currentTrack.artist) {
      this.router.navigate([`/artist/${this.zone.currentTrack.artist.id}`]);
    }
  }

  trackDuration() {
    return this.prepareService.trackDuration(this.zone.currentTrack.duration);
  }

  maxRangeValue() {
    return this.prepareService.trackDurationToSec(this.zone.currentTrack.duration);
  }

}
