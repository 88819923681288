import { CombinedFragments } from './fragments';
import gql from 'graphql-tag';

const venueExtendedData = `
  id
  name
  isActive
  logoImageUrl
  bannerImageUrl
  contact {
    name
    phoneNumber
  }
  owner {
    userEmail
  }
  address {
    line1
    line2
    postcode
    state
    city
    country
  }
  limitMusicSelection
  zonePreset {
    schedule {
      id
      name
    }
    crossFade
    fadeTimeSeconds
    downloadSpeed
    audioChannels
  }
  zones {
    id
  }
`;

const venueListData = `
  id
  name
  logoImageUrl
  bannerImageUrl
  address {
    line1
    line2
  }
  zones {
    id
  }
  contact {
    name
    phoneNumber
  }
  owner {
    userEmail
  }
  isActive
`;

export const venue = gql`
  query venueFull(
    $venueId: Int!
  ) {
    venue(
      id: $venueId
    ) {
      id
      name
      logoImageUrl
      bannerImageUrl
      address {
        line1
        line2
        city
      }
      zones {
        id
        mediaType
      }
    }
  }
`;

export const venues = gql`
  query venues {
    venues {
      id
      isActive
      logoImageUrl
      bannerImageUrl
      name
      zones {
        id
      }
      address {
        line1
        line2
        city
        state
        postcode
        country
      }
    }
  }
`;

export const venuesExtended = gql`
  query venuesFull($nameLike: String) {
    venues(nameLike: $nameLike, limit: 1000) {
      ${venueExtendedData}
    }
  }
`;

export const venuesConnection = gql `
query VenuesConnection($first: Int, $after: Cursor, $nameLike: String) {
  venuesConnection(first: $first, after: $after, nameLike: $nameLike) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        ${venueListData}
      }
    }
  }
}`

export const venuesList = gql`
  query venuesList($nameLike: String) {
    venues(nameLike: $nameLike, limit: 1000) {
      ${venueListData}
    }
  }
`;

export const getDefaultSchedule = gql`
  query defaultScheduleForVenue(
    $id: Int!
  ) {
    venue(id: $id) {
      id
      name
      schedule
      zonePreset {
        schedule {
          id
          name
        }
      }
    }
  }
`;

export const venueNames = gql`
  query venuesNames {
    venues {
      id
      name
    }
  }
`;

export const venueLogoImage = gql`
  query venueLogo(
    $id: Int!
  ) {
    venue(id: $id) {
      id
      logoImageUrl
    }
  }
`;

export const venueBannerImage = gql`
  query venueBanner(
    $id: Int!
  ) {
    venue(id: $id) {
      id
      bannerImageUrl
    }
  }
`;

export const createVenue = gql`
  mutation createVenue(
    $name: String!
    $contact: ContactInput
    $latitude: String
    $longitude: String
    $isActive: Boolean
    $apraClientNumber: String
    $address: AddressInput,
    $email: String,
  ) {
    createVenue(
      name: $name
      contact: $contact
      latitude: $latitude
      longitude: $longitude
      isActive: $isActive
      apraClientNumber: $apraClientNumber
      address: $address
      email: $email
    ) {
      ${venueExtendedData}
    }
  }
`;

export const updateVenue = gql`
  mutation updateVenue(
    $id: Int!
    $values: UpdateVenueInput
  ) {
    updateVenue(
      id: $id
      values: $values
    ) {
      ${venueExtendedData}
    }
  }
`;

export const updateVenueDefaults = gql`
  mutation updateZonePreset(
    $venueId: Int!
    $values: ZonePresetInput!
  ) {
    updateZonePreset(
      venueId: $venueId,
      values: $values
    ) {
      schedule {
        id
        name
      }
      audioChannels
      fadeTimeSeconds
      crossFade
      downloadSpeed
    }
  }
`;

export const updateVenueLimitMusicSelection = gql`
  mutation updateVenueLimitMusicSelection(
    $venueId: Int!
    $newValue: Boolean!
  ) {
    updateVenueLimitMusicSelection(
      venueId: $venueId
      newValue: $newValue
    )
  }
`;

export const getDefaultSettings = gql`
  query venue(
    $id: Int!
  ) {
    venue(id: $id) {
      id
      name
      schedule
      zonePreset {
        schedule {
          id
          name
        }
        audioChannels
        fadeTimeSeconds
        crossFade
        downloadSpeed
      }
    }
  }
`;

export const enqueueCreateBmsReportJob = gql`
  mutation enqueueCreateBmsReportJob(
    $from: Date!,
    $venueId: Int,
    $to: Date,
    $email: String
  ) {
    enqueueCreateBmsReportJob(
      venueId: $venueId
      from: $from
      to: $to,
      email: $email
    )
  }
`;
