import { SearchCatalogueGenreQuery, SearchCatalogueQuery, SearchSimilarPlaylistQuery } from '../gql/search-queries.d';
import { searchCatalogue, searchPopularGenres, searchSimilarPlaylist } from '../gql/search-queries';

import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SearchCatalogService {
  constructor (private apollo: Apollo) { }
  async searchPopularGenres() {
    return this.apollo.query<SearchCatalogueGenreQuery>({
      query: searchPopularGenres,
    }).toPromise();
  }

  async searchCatalogue(search: string, genre?: string) {
    return this.apollo.query<SearchCatalogueQuery>({
      query: searchCatalogue,
      variables: {
        search,
        genre,
      }
    }).toPromise();
  }

  async searchSimilarPlaylist(artistIds: Array<number>, currentPlaylistId: number) {
    return this.apollo.query<SearchSimilarPlaylistQuery>({
      query: searchSimilarPlaylist,
      variables: {
        artistIds,
        currentPlaylistId
      }
    }).toPromise();
  }
}
