export const CREATE_VENUE_PERMISSION = 'CREATE_VENUE_PERMISSION';
export const EDIT_VENUE_PERMISSION = 'EDIT_VENUE_PERMISSION';
export const ADD_ZONE_PERMISSION = 'ADD_ZONE_PERMISSION';
export const REQUEST_ZONE_KEY_PERMISSION = 'REQUEST_ZONE_KEY_PERMISSION';
export const EDIT_PLAYLIST_PERMISSION = 'EDIT_PLAYLIST_PERMISSION';
export const DELETE_PLAYLIST_PERMISSION = 'DELETE_PLAYLIST_PERMISSION';
export const VIEW_SCHEDULE_PERMISSION = 'VIEW_SCHEDULE_PERMISSION';
export const BUCKET_PERMISSION = 'BUCKET_PERMISSION';
export const CHANNEL_PERMISSION = 'CHANNEL_PERMISSION';
export const PROMOTIONS_PERMISSION = 'PROMOTIONS_PERMISSION';
export const CURATION_PERMISSION = 'CURATION_PERMISSION';
