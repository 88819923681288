import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../services/auth.service';
import { LocalStoreService } from '../services/localstore.service';
import { APP_PERMISSIONS_KEY } from '../services/constants';
import { NgxPermissionsService } from 'ngx-permissions';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private localStore: LocalStoreService,
    private ngxPermissions: NgxPermissionsService,
    private authService: AuthService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isAuthenticated()) {
      const permissions = this.localStore.getItemParsed(APP_PERMISSIONS_KEY)
      if (permissions) {
	this.ngxPermissions.loadPermissions(permissions)
      }
      return true;
    }

    this.router.navigate(['/login']);

    return false;
  }
}
