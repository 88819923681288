<div class="tracks-container-cover overflow-hidden">
  <div class="quarter-images d-flex flex-wrap" *ngIf="timeslot.tracksContainer.artwork?.length === 4">
    <img
      *ngFor="let artwork of timeslot.tracksContainer.artwork"
      appDefault
      [src]="artwork"
      class="quarter-img"
      alt="Cover"
    />
  </div>

  <img
    *ngIf="timeslot.tracksContainer.artwork?.length < 4"
    [src]="(timeslot.tracksContainer.artwork && timeslot.tracksContainer.artwork[0]) ? timeslot.tracksContainer.artwork[0] : '/assets/images/default-cover.jpg'"
    appDefault
    class="cover w-100 h-100"
    alt="Cover"
  />
</div>
<div class="timeslot-details-container d-flex flex-column justify-content-between">
  <div [title]="timeslot.tracksContainer.name" class="tracks-container-name">{{ timeslot.tracksContainer.name }}</div>
  <div [title]="timeslot.zoneName" class="zone-name text-truncate">{{ timeslot.zoneName }}</div>
  <div class="timeslot-timerange d-flex align-items-center">
    {{ timeslot.from.substr(0, 5) }} - {{ timeslot.to.substr(0, 5) }}
  </div>
</div>
