import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-scheduler-v2-intro',
  templateUrl: './scheduler-v2-intro.component.html',
  styleUrls: ['./scheduler-v2-intro.component.scss'],
})
export class SchedulerV2IntroComponent {
constructor(
    public dialogRef: MatDialogRef<SchedulerV2IntroComponent>,
  ) { }

  onClose() {
    this.dialogRef.close();
  }
}
