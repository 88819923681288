import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';

import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import { Store } from '@ngxs/store';
import { PlaylistAction } from '../../store/playlists/playlists.actions';
import { BucketAction } from '../../store/buckets/buckets.actions';
import { SNACK_BAR_DURATION } from '../../models/consts';

interface DialogData {
  tracksContainerType: 'PLAYLIST' | 'BUCKET';
}

@Component({
  selector: 'app-new-tracks-container-dialog',
  templateUrl: './shared-new-tracks-container-dialog.component.html',
  styleUrls: ['./shared-new-tracks-container-dialog.component.scss']
})
export class SharedNewTracksContainerDialogComponent implements OnInit {
  public form: UntypedFormGroup;

  constructor(
    private dialogRef: MatDialogRef<SharedNewTracksContainerDialogComponent>,
    private store: Store,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      tracksContainerName: new UntypedFormControl('', [Validators.required])
    });
  }

  saveTracksContainer() {
    const tracksContainerName = this.form.value.tracksContainerName.trim();

    if (this.data.tracksContainerType === 'PLAYLIST') {
      this.store.dispatch(new PlaylistAction.CreatePlaylist(tracksContainerName))
        .subscribe(() => {
          this.dialogRef.close(this.data.tracksContainerType);
          this._snackBar.open(
            `Playlist ${tracksContainerName} successfully created`,
            null,
            { duration: SNACK_BAR_DURATION }
          );
        });
    } else {
      this.store.dispatch(new BucketAction.CreateBucket(tracksContainerName))
        .subscribe(() => {
          this.dialogRef.close(this.data.tracksContainerType);
          this._snackBar.open(
            `Bucket ${tracksContainerName} successfully created`,
            null,
            { duration: SNACK_BAR_DURATION }
          );
        });
    }
  }
}
