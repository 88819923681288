import { Component, Inject, OnDestroy, OnInit, ViewChild, AfterViewInit, NgZone, DoCheck } from '@angular/core';

import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

//import { Subscription } from 'rxjs';

import debounce from 'lodash/debounce';
import uniqBy from 'lodash/uniqBy';

import { ConfirmationDialogComponent } from 'src/app//shared/components/confirmation-dialog/confirmation-dialog.component';
import { PlaylistService } from 'src/app//shared/services/playlist.service';
import { Bucket, Playlist, Track, PlaylistCursor } from 'src/app//shared/models/models';
//import { tap, map, filter } from 'rxjs/operators';
import { NgScrollbar } from 'ngx-scrollbar';
//import { Store } from '@ngxs/store';
import { BucketsState } from 'src/app/shared/store/buckets/buckets.state';
import { BucketAction } from 'src/app/shared/store/buckets/buckets.actions';
//import { SNACK_BAR_DURATION, TrackSource } from 'src/app/shared/models/consts';
import { ErrorParserService } from 'src/app/shared/services/error-parcing.service';
import { AlertDialogComponent } from 'src/app/shared/components/alert-dialog/alert-dialog.component';
import { ZonesListComponent } from 'src/app/shared/components/zone-list-dialog/zone-list-dialog.component';
import { ZoneService } from 'src/app/shared/services/zone.service';
import { MediaTypes, PlaylistColumns, PlaylistMediaType, SCROLL_BOTTOM_OFFSET, SNACK_BAR_DURATION, ViewType, TrackSource } from 'src/app/shared/models/consts';
import { ZonesState } from 'src/app/shared/store/zones/zones.state';
import { User, Venue, Zone, TodayTimeslot } from 'src/app/shared/models/models';

import { Observable, Subject, Subscription } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { tap, map, filter, takeUntil } from 'rxjs/operators';


interface DialogData {
  bucket: Bucket;
}

@Component({
  selector: 'app-queue-bucket-dialog',
  templateUrl: './queue-bucket-dialog.component.html',
  styleUrls: ['./queue-bucket-dialog.component.scss']
})
export class QueueBucketDialogComponent implements OnInit, OnDestroy, DoCheck {
  @ViewChild('trackScroll', { static: false }) scrollbarRef: NgScrollbar;
  @Select(ZonesState.currentZone) currentZone$: Observable<Zone>;
  @Select(BucketsState.buckets) buckets$: Observable<Bucket[]>;
  public view: 'BUCKETS' | 'TRACKS' = 'BUCKETS';
  public bucketsToAdd: Set<number> = new Set();
  //public filteredBuckets: Bucket[] = [];
  public filteredBuckets: Bucket[] = [];
  public addBucketsMode = true;
  //public buckets: Bucket[];
  public buckets: Bucket[];
  public isLoading = true;
  public errored = false;
  public bucket: Bucket;
  public tracks: Track[] = [];
  public currentZone: Zone;
  public tracksLoading = true;
  public tracksErrored = false;

  //public cursors: BucketCursor[] = [];
  public loadMore: boolean = false;

  private bucketsSub: Subscription;
  private scrollSub: Subscription;
  private unSubscribe$: Subject<{}> = new Subject;
  private currentZoneSub: Subscription;

  //mediaView: BucketMediaType = BucketMediaType.BUCKET;


  constructor(
    //private bucketService: BucketService,
    private ngZone: NgZone,
    private dialog: MatDialog,
    private store: Store,
    private errorService: ErrorParserService,
    private _snackBar: MatSnackBar,
    private zoneService: ZoneService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.currentZone$ = this.store.select(ZonesState.currentZone);
    this.buckets$ = this.store.select(BucketsState.buckets);
    this.searchBucket = debounce(this.searchBucket, 500);
  }

  ngOnInit() {
    // Always fetch buckets on load
    this.store.dispatch(new BucketAction.GetBuckets())
      .subscribe(() => this.isLoading = false);

    this.bucketsSub = this.buckets$
    .pipe(
      filter((buckets) => !!buckets)
    )
    .subscribe((buckets) => {
      // this.buckets = this.filteredBuckets = this.sortedData = buckets.map((plalist) => ({...plalist, isChecked: false}));
      // this.bucketsLoading = false;
      this.filteredBuckets = this.buckets = buckets;
      this.isLoading = false;
    });
    
    this.currentZoneSub = this.currentZone$
    .pipe(
      filter((zone) => !!zone),
      takeUntil(this.unSubscribe$)
    )
    .subscribe((zone) => {
      this.currentZone = zone;
      console.log('queue bucket on zone ', zone.name);
    });

    // this.bucketSub = this.store.select(BucketsState.bucketById(this.data.bucket.id))
    // .subscribe((bucket) => {
    //   this.setView('BUCKETS');
    //   this.bucket = bucket;

    //   const promises = [];
    //   this.bucket.buckets.forEach((bucket, i) => {
    //     const tracksToLoad = i === 0 ? 20 : 0;
    //     promises.push(this.loadBucketPromise(bucket.id, tracksToLoad));
    //   });
    //   Promise.all(promises)
    //     .then(() => this.tracksLoading = false)
    //     .catch(() => this.tracksErrored = true);

    //   this.isLoading = false;
    // }, () => this.errored = true);
  }

  ngDoCheck() {}
  // ngDoCheck() {
  //   if (this.scrollbarRef && !this.scrollSub) {
  //     this.scrollSub = this.scrollbarRef.scrolled.pipe(
  //       filter((e: any) => !this.loadMore && e.target.scrollBottom < 10),
  //       tap(() => this.ngZone.run(() => {
  //         this.loadMore = true;
  //         this.tracksLoading = true;
  //         this.getMoreTracks();
  //       }))
  //     ).subscribe();
  //   }
  // }

  get trackSource() {
    return TrackSource;
  }

  setView(view: 'BUCKETS' | 'TRACKS') {
    this.view = view;
  }

  backToBucketDetails() {
    this.bucketsToAdd.clear();
    this.addBucketsMode = false;
    this.isLoading = false;
  }

  // addBucket() {
  //   this.addBucketsMode = true;
  //   this.isLoading = true;

  //   // const bucketIds = this.bucket.buckets.map((bucketBucket) => bucketBucket.id);

  //   // this.bucketService.getBucketsForBucket(bucketIds)
  //   this.bucketService.getBucketsForBucket([])
  //   .then((buckets) => {
  //     this.filteredBuckets = this.buckets = buckets;
  //     this.isLoading = false;
  //   }).catch(() => this.errored = true);
  // }

  // onBucketRemoved(bucketId: number) {
  //   const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
  //     data: {
  //       message: 'Are you sure you want to remove the bucket from the bucket?',
  //       buttons: [
  //         {
  //           text: 'Cancel',
  //           role: 'cancel',
  //           cssClass: 'secondary'
  //         },
  //         {
  //           text: 'Remove',
  //           cssClass: 'danger',
  //           handler: () => {
  //             dialogRef.close();
  //             this.store.dispatch(new BucketAction.DeleteBucketFromBucket(bucketId, this.bucket.id))
  //               .subscribe(
  //                 () => {
  //                   this._snackBar.open(
  //                     `Bucket successfully removed from the bucket`,
  //                     null,
  //                     { duration: SNACK_BAR_DURATION }
  //                   );
  //                 },
  //                 (error) => {
  //                   const errIdStr = this.errorService.parseError(error);
  //                   this.dialog.open(AlertDialogComponent, {
  //                     data: {
  //                       type: 'error',
  //                       message: `Oops, something went wrong. Please try again.
  //                       ${(!!errIdStr) ? ('Error id: ' + errIdStr) : ''}`
  //                     }
  //                   });
  //                 }
  //               );
  //           }
  //         }
  //       ]
  //     },
  //     width: '350px'
  //   });
  // }

  trackChange(event, isUpdated = false) {
    if (!isUpdated) {
      this.tracks = this.tracks.filter(x => x.id !== event);
    } else {
      this.tracks = this.tracks.splice(this.tracks.findIndex(({id}) => id === event.id), 1, event);
    }
  }



  get hasSelected() {
    return (this.bucketsToAdd.size > 0);
  }
  
  // addBucketsToBucket() {
  // // addToPlayingQueue() {
  //   if (!this.hasSelected) {
  //     return
  //   }
  //   this.confirmAddBucketInQueue(this.currentZone.id, false);
  // }

  getBucketForOverrideZoneTracksUsingBucket() {
    // const bucketsIds = this.buckets
    //   .filter(({isChecked}) => isChecked)
    //   .map(({id}) => id);

    // if (!!bucketsIds.length) {
    //   this.getZoneForOverrideZoneTracksUsingBucket(bucketsIds[0]);
    // }
    if (!this.hasSelected) {
      return
    }
    this.getZoneForOverrideZoneTracksUsingBucket(this.bucketsToAdd.values().next().value);
  }

  getZoneForOverrideZoneTracksUsingBucket(buckedId: number) {
    // this.dialog.open(ZonesListComponent)
    //   .afterClosed()
    //   .subscribe(data => {
    //     if (!data) {
    //       return;
    //     }
    //     if (data.error) {
    //       this.dialog.open(AlertDialogComponent, {
    //         data: {
    //           type: 'error',
    //           message: data.error
    //         }
    //       });
    //       return;
    //     } else {
    //       this.confirmOverrideZoneTracksUsingBucket(data.id, buckedId);
    //     }
    //   });
    this.confirmOverrideZoneTracksUsingBucket(this.currentZone.id, buckedId);
  }

  confirmOverrideZoneTracksUsingBucket(zoneId: number, bucketId: number) {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Override current tracks with bucket?',
        message: "Are you sure you want to override the current tracks playing with bucket tracks? This will play until the next allocated timeslot",
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary'
          },
          {
            text: 'Play',
            cssClass: 'primary',
            handler: () => {
              this.overrideZoneTracksUsingBucket(zoneId, bucketId);
            }
          }
        ],
      },
      width: '500px'
    });
  }

  overrideZoneTracksUsingBucket(zoneId: number, bucketId: number, forbidSimilar?: boolean) {
    this.store.dispatch(new BucketAction.OverrideZoneTracksUsingBucketUntilNextTimeslot(zoneId, bucketId, forbidSimilar))
      .subscribe(() => {
        this.dialog.closeAll();
        this.buckets = this.buckets.map((item) => {
          //item.isChecked = false;
          return item;
        });

        this._snackBar.open(
          `Bucket successfully playing for current timeslot`,
          null,
          { duration: SNACK_BAR_DURATION }
        );
      }, (error) => {
        const errIdStr = this.errorService.parseError(error);
        this.dialog.open(AlertDialogComponent, {
          data: {
            type: 'error',
            message: `Oops, something went wrong. Please try again.
            ${(!!errIdStr) ? ('Error id: ' + errIdStr) : ''}`
          }
        });
      });
  }

  searchBucket(term: string) {
    if (term === '') {
      this.filteredBuckets = this.buckets;
    } else {
      this.filteredBuckets = this.buckets.filter((bucket) => {
        return bucket.name.toLowerCase().includes(term.toLowerCase());
      });
    }
  }

  onBucketSelected(bucketId: number) {
    // if (this.bucketsToAdd.has(bucketId)) {
    //   this.bucketsToAdd.delete(bucketId);
    // } else {
    //   this.bucketsToAdd.add(bucketId);
    // }
    this.bucketsToAdd.clear();
    this.bucketsToAdd.add(bucketId);
  }

  // loadBucketPromise(id: number, tracksToLoad: number, markAsLoaded = false) {
  //   return this.bucketService.getBucket(id, tracksToLoad)
  //     .then((bucket) => {
  //       this.tracks = (this.tracks || []).concat(bucket.tracksConnection.edges.map(({node}) => node));
  //       this.cursors.push({
  //         bucketId: id,
  //         endCursor: bucket.tracksConnection.pageInfo.hasNextPage ? bucket.tracksConnection.pageInfo.endCursor : null
  //       });

  //       if (markAsLoaded) {
  //         this.isLoading = false;
  //       }
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //       this.errored = true;
  //     })
  // }

  // getMoreTracks() {
  //   const lastIndex = this.cursors.findIndex(({endCursor}) => !!endCursor);
  //   if (lastIndex !== -1) {
  //     this.bucketService.nextTrackPage(this.cursors[lastIndex].bucketId, this.cursors[lastIndex].endCursor)
  //       .pipe(
  //         tap((tracksConnection: any) => {
  //           if (tracksConnection.pageInfo.hasNextPage) {
  //             this.cursors[lastIndex].endCursor = tracksConnection.pageInfo.endCursor;
  //           } else {
  //             this.cursors[lastIndex].endCursor = null;
  //           }
  //         }),
  //         map(({edges}) => edges.map(({ node }) => node))
  //       )
  //       .subscribe((tracks) => {
  //         this.tracks = [...this.tracks, ...tracks];
  //         this.loadMore = false;
  //       }, () => this.tracksErrored = true);
  //   } else {
  //     this.tracksLoading = false;
  //   }
  // }

  ngOnDestroy() {
    // if (this.scrollSub) {
    //   this.scrollSub.unsubscribe();
    // }
    if (this.bucketsSub) {
      this.bucketsSub.unsubscribe();
    }
    this.unSubscribe$.next(true);
    this.unSubscribe$.complete();
  }
}
