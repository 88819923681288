<div class="promoted-playlist">
  <div class="quarter-images" *ngIf="playlist.promotedArtwork?.length === 6; else singleCover">
    <img
      *ngFor="let artwork of playlist.promotedArtwork"
      [src]="artwork"
      appDefault="/assets/images/playlist-cover-1.jpg"
      class="quarter-img"
      alt="Playlist artwork"
    />
  </div>
  <ng-template #singleCover>
    <img
      [src]="(playlist.promotedArtwork && playlist.promotedArtwork[0]) ? playlist.promotedArtwork[0] : '/assets/images/logo-gray.svg'"
      [class.default-cover]="!(playlist.promotedArtwork && playlist.promotedArtwork[0])"
      defaultClass="default-cover"
      class="cover-img"
      alt="Playlist artwork"
    />
  </ng-template>

  <div [title]="playlist.name" class="playlist-name text-truncate">{{ playlist.name }}</div>
  <div class="playlist-genre text-truncate">{{ playlist.generalGenre }}</div>
  <div class="playlist-details-container text-truncate">
    <span class="playlist-track-count">{{ playlist.trackCount || 0 }} {{ playlist.trackCount === 1 ? 'Track' : 'Tracks' }}</span>
    <span class="dot-separator"></span>
    <span class="playlist-duration">{{ getPlaylistDuration() }}</span>
  </div>
  <div class="btn-container">
    <button class="btn" (click)="viewPlaylist()">Open</button>
    <button
      class="btn"
      (click)="$event.stopPropagation()"
      [matMenuTriggerFor]="appMenu">
      More
    </button>

    <mat-menu class="playlist-menu" #appMenu="matMenu">
      <div *ngIf="!playlist.isInLibrary" (click)="addToLibrary()" class="menu-item">
        <div class="menu-item-icon icon-opened-book"></div>
        <span class="menu-item-label">Add To Library</span>
      </div>

      <div *ngIf="playlist.isInLibrary" (click)="deletePlaylist()" class="menu-item">
        <div class="menu-item-icon icon-opened-book"></div>
        <span class="menu-item-label">In Library</span>
        <img class="check-icon" src="../../assets/icons/checkmark.svg">
      </div>

      <div (click)="deletePlaylist()" class="menu-item" *ngIf="isMyPlaylist">
        <div class="menu-item-icon icon-delete"></div>
        <span class="menu-item-label">Delete</span>
      </div>

      <div *ngxPermissionsOnly="'BUCKET_PERMISSION'">
        <div (click)="addToBucket()" class="menu-item">
          <div class="menu-item-icon icon-bucket"></div>
          <span class="menu-item-label">Add To Bucket</span>
        </div>
      </div>

      <div class="menu-item" *ngIf="playlist.tracks?.length > 0" (click)="addToQueue()">
        <div class="menu-item-icon icon-queue"></div>
        <span class="menu-item-label">Add To Playing Queue</span>
      </div>
    </mat-menu>
  </div>

  <div class="overlay"></div>
</div>

