import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { SNACK_BAR_DURATION, TrackSource } from '../../models/consts';
import { Track } from '../../models/models';
import { ErrorParserService } from '../../services/error-parcing.service';
import { TrackService } from '../../services/track.service';
import { ZoneService } from '../../services/zone.service';
import { BucketAction } from '../../store/buckets/buckets.actions';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { SharedPlaylistListDialogComponent } from '../playlist-list-dialog/playlist-list-dialog.component';
import { ZonesListComponent } from '../zone-list-dialog/zone-list-dialog.component';

@Component({
  selector: 'app-track-list-item',
  templateUrl: './track-list-item.component.html',
  styleUrls: ['./track-list-item.component.scss']
})
export class TrackListItemComponent implements OnInit {
  @Input() track: Track;
  @Input() from: TrackSource;
  @Input() isEditMode?: boolean;
  @Input() bucketId?: number;
  @Output() onSelect: EventEmitter<number> = new EventEmitter();
  @Output() onTrackDelete: EventEmitter<number> = new EventEmitter<number>();

  public isSelected: boolean = false;

  constructor(
    private trackService: TrackService,
    private dialog: MatDialog,
    private zoneService: ZoneService,
    private errorService: ErrorParserService,
    private router: Router,
    private store: Store,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit() {
  }

  get trackSource() {
    return TrackSource;
  }

  trackSelection() {
    this.isSelected = !this.isSelected;
    this.onSelect.emit(this.track.id);
  }

  toggleLikeDislike(state: boolean) {
    if (this.track.heartState && this.track.heartState.like === state) {
      state = null;
    }

    this.trackService.setTrackHeartState(this.track.id, state)
      .then((heartState) => {
        this.track.heartState = heartState;
      });
  }

  addTrackToPlaylist() {
    this.dialog.open(SharedPlaylistListDialogComponent, {
      data: {
        track: this.track,
        playlistsWithTrack: this.track.playlists.map((playlist) => playlist.id)
      }
    });
  }

  addToPlayingQueue() {
    this.dialog.open(ZonesListComponent)
      .afterClosed()
      .subscribe(data => {
        if (!data) {
          return;
        }
        if (data.error) {
          this.dialog.open(AlertDialogComponent, {
            data: {
              type: 'error',
              message: data.error
            }
          });
        } else {
          this.confirmAddTrackInQueue(data.id);
        }
      });
  }

  confirmAddTrackInQueue(zoneId) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: 'The track will start after the current song finishes',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary'
          },
          {
            text: 'OK',
            cssClass: 'danger',
            handler: () => {
              this.zoneService.addTrackToPlayQueue(this.track.id, zoneId)
              .then(() => {
                dialogRef.close();
                this._snackBar.open(
                  `Track successfully added to the queue`,
                  null,
                  { duration: SNACK_BAR_DURATION }
                );
              })
              .catch((error) => {
                const errIdStr = this.errorService.parseError(error);
                dialogRef.close();
                this.dialog.open(AlertDialogComponent, {
                  data: {
                    type: 'error',
                    message: `Oops, something went wrong. Please try again.
                    ${(!!errIdStr) ? ('Error id: ' + errIdStr) : ''}`
                  }
                });
              });
            }
          }
        ]
      },
      width: '340px'
    });
  }

  removeTrackFromLibrary() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: 'Are you sure you want to remove the track from favourites?',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary'
          },
          {
            text: 'Remove',
            cssClass: 'danger',
            handler: () => {
              this.dialog.closeAll();
              this.trackService.removeTrackFromLibrary(this.track.id)
                .then(() => {
                  this.track.isInLibrary = false;
                  this._snackBar.open(
                    `Track successfully removed from the favourites`,
                    null,
                    { duration: SNACK_BAR_DURATION }
                  );
                })
                .catch((err) => {
                  const errIdStr = this.errorService.parseError(err);
                  this.dialog.open(AlertDialogComponent, {
                    data: {
                      type: 'error',
                      message: `Oops, something went wrong. Please try again.
                      ${(!!errIdStr) ? ('Error id: ' + errIdStr) : ''}`
                    }
                  });
                });
            }
          }
        ]
      },
      width: '340px'
    });
  }

  addTrackToLibrary() {
    this.trackService.addTrackToLibrary(this.track.id)
    .then((res) => {
      this.track.isInLibrary = true;
      this._snackBar.open(
        `Track successfully added to the favourites`,
        null,
        { duration: SNACK_BAR_DURATION }
      );
    })
    .catch((err) => {
      const errIdStr = this.errorService.parseError(err);
      this.dialog.open(AlertDialogComponent, {
        data: {
          type: 'error',
          message: `Oops, something went wrong. Please try again.
          ${(!!errIdStr) ? ('Error id: ' + errIdStr) : ''}`
        }
      });
    });
  }

  goToArtist() {
    this.router.navigate(['/artist', this.track.artist.id]);
  }

  removeFromBucket() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: 'Are you sure you want to remove the track from bucket?',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary'
          },
          {
            text: 'Remove',
            cssClass: 'danger',
            handler: () => {
              dialogRef.close();
              this.store.dispatch(new BucketAction.RemoveTrackFromBucket(this.track.id, this.bucketId))
                .subscribe(() => {
                  this.onTrackDelete.emit(this.track.id);
                  this._snackBar.open(
                    `Track successfully removed from the bucket`,
                    null,
                    { duration: SNACK_BAR_DURATION }
                  );
                },
                (error) => {
                  const errIdStr = this.errorService.parseError(error);
                  this.dialog.open(AlertDialogComponent, {
                    data: {
                      type: 'error',
                      message: `Oops, something went wrong. Please try again.
                      ${(!!errIdStr) ? ('Error id: ' + errIdStr) : ''}`
                    }
                  });
                });
            }
          }
        ]
      },
      width: '340px'
    });
  }

}
