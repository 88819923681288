import { AfterViewInit, Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { PageInfo, PaginationInfo, Track, User, Venue } from 'src/app/shared/models/models';
import { filter, map, takeUntil, tap } from 'rxjs/operators';

import { AuthService } from 'src/app/shared/services/auth.service';
import { CreateEditVenueComponent } from './create-edit-venue/create-edit-venue.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { VenueListAction } from 'src/app/shared/store/venue-list/venue-list.actions';
import { VenueListState } from 'src/app/shared/store/venue-list/venue-list.state';
import { VenueService } from 'src/app/shared/services/venue.service';
import { ZoneListComponent } from './zone-list/zone-list.component';
import { NgScrollbar } from 'ngx-scrollbar';
import { SCROLL_BOTTOM_OFFSET } from 'src/app/shared/models/consts';

@Component({
  selector: 'app-venues',
  templateUrl: './venues.component.html',
  styleUrls: ['./venues.component.scss']
})
export class VenuesComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(NgScrollbar, { static: false }) scrollbarRef: NgScrollbar;
  @Select(VenueListState.venues) venues$: Observable<Array<Venue>>;

  public filteredVenues: Venue[];
  public venues: Venue[];
  public user: User;
  public scrollSub: Subscription;
  private zonePlayerEventMap: Map<number, Subscription> = new Map();

  public isLoading = true;
  public errored = false;
  public filteredQuery = false;
  public allowQuery = true;
  public cursor: string;
  public pageSize: number = 10;
  public pageInfo: PageInfo;
  public venueLike: string;
  public isMoreVenuesLoading = false;

  private debounce = 600;
  private unSubscribe$: Subject<{}> = new Subject;

  constructor(
    private venueService: VenueService,
    private authService: AuthService,
    public dialog: MatDialog,
    private store: Store,
    private ngZone: NgZone,
  ) {
    this.venues$ = this.store.select(VenueListState.venues);
  }

  ngOnInit() {
    this.authService.onUserChanged
      .pipe(
        filter((user: User) => !!user),
        takeUntil(this.unSubscribe$)
      )
      .subscribe((user: User) => {
        this.user = user;
      },
        err => {
          console.log(err);
        }
      );

    this.venueService.getVenuesConnection(this.cursor, this.pageSize, '')
      .pipe(
        tap((paginationInfo: PaginationInfo) => {
          this.pageInfo = paginationInfo.pageInfo;
        }),
        map(({ edges }) => edges.map(({ node }) => node))
      ).subscribe((response) => {
        this.filteredVenues = this.venues = response;
        this.isLoading = false;
      });
  }

  ngAfterViewInit() {
    if (this.scrollbarRef && !this.scrollSub) {
      this.scrollSub = this.scrollbarRef.verticalScrolled.pipe(
        filter((e: any) => {
          return !this.isLoading && e.target.offsetHeight + e.target.scrollTop > e.target.scrollHeight - SCROLL_BOTTOM_OFFSET;
        }),
        tap(() => this.ngZone.run(() => {
          this.isMoreVenuesLoading = true;
          this.loadMore();
        }))
      ).subscribe();
    }
  }

  loadMore() {
    this.venueService.getVenuesConnection(this.pageInfo.endCursor, this.pageSize, this.venueLike).pipe(
      tap((paginationInfo: PaginationInfo) => {
        this.pageInfo = paginationInfo.pageInfo;
      }),
      map(({ edges }) => edges.map(({ node }) => node))
    ).subscribe((response) => {
      this.filteredVenues = [ ...this.venues, ...response ];
      this.isMoreVenuesLoading = false;
    }, (err) => {
      this.isMoreVenuesLoading = false;
    });
  }

  createVenue() {
    this.dialog.open(CreateEditVenueComponent, {
      panelClass: 'full-width-dialog',
      maxWidth: '100vw'
    });
  }

  editVenue(id: number) {
    const venue = this.venues.find(x => x.id === id);
    this.dialog.open(CreateEditVenueComponent, {
      data: {
        venue
      },
      panelClass: 'full-width-dialog',
      maxWidth: '100vw'
    });
  }

  addZones(venue: Venue) {
    this.dialog.open(ZoneListComponent, {
      data: {
        venueId: venue.id
      }
    });
  }

  searchVenue(term: string) {
    if (this.debounceQuery()) {
      this.venueLike = term !== '' ? `%${term}%`: '';
      this.venueService.getVenuesConnection(this.cursor, this.pageSize, this.venueLike)
      .pipe(
        tap((paginationInfo: PaginationInfo) => {
          this.pageInfo = paginationInfo.pageInfo;
        }),
        map(({ edges }) => edges.map(({ node }) => node))
      ).subscribe((response) => {
        this.filteredVenues = this.venues = response;
        this.isLoading = false;
      });
    }
  }

  debounceQuery() {
    if (this.allowQuery) {
      this.allowQuery = false;
      setTimeout(() => {
        this.allowQuery = true;
      }, this.debounce);
      return true;
    } else return false;
  }


  ngOnDestroy() {
    this.unSubscribe$.next(true);
    this.unSubscribe$.complete();
    if (this.scrollSub) {
      this.scrollSub.unsubscribe();
    }
  }
}
function patchState(arg0: { hasNextPage: any; nextCursor: any; }) {
  throw new Error('Function not implemented.');
}

