<div class="d-flex justify-content-center align-items-center login-page w-100 vh-100">
  <div *ngIf="!needToChooseVenue" class="login-page-wrapper">
    <div class="logo-wrapper">
      <h1 class="brend-name">Login to Audalize Music Manager</h1>
    </div>
    <p *ngIf="isInvalid" class="error-message text-center">Incorrect email or password!</p>
    <div class="login-form-wrapper">
      <form [formGroup]="loginForm" (ngSubmit)="login()">
        <div class="form-group">
          <label for="email">Email</label>
          <input id="email" inputmode="email" formControlName="email" type="email" placeholder="Enter Email">
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input id="password" inputmode="password" formControlName="password" type="password" placeholder="Enter Password">
        </div>
        <div class="controls-wrapper">
          <a routerLink="reset" class="d-block text-center forgot-link">Forgot password?</a>
          <div class="control-buttons d-flex justify-content-center p-0">
            <button type="submit" [disabled]="loginForm.invalid" class="btn signin-btn p-0">Sign In</button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <app-choose-venue *ngIf="needToChooseVenue"></app-choose-venue>
</div>
