import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { filter, takeUntil } from 'rxjs/operators';

import { AuthService } from 'src/app/shared/services/auth.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MediaTypes } from 'src/app/shared/models/consts';
import { Router } from '@angular/router';
import { Venue } from 'src/app/shared/models/models';
import { VenueAction } from 'src/app/shared/store/venue/venue.actions';
import { VenueListComponent } from '../venues/venue-settings/venue-list/venue-list.component';
import { VenueState } from 'src/app/shared/store/venue/venue.state';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit, OnDestroy {
  @Select(VenueState.venue) venue$: Observable<Venue>;
  public hasUnreadMessages = false;
  public isCuration = false;
  public hasChannelZone: boolean = false;
  public hasVideoZone: boolean = false;
  isProd = !environment.environment || environment.environment === 'production';
  canSeeJukeo = false;

  public venue: Venue;

  private unSubscribe$: Subject<{}> = new Subject;

  constructor(
    private router: Router,
    private authService: AuthService,
    private dialog: MatDialog,
    private store: Store
  ) { 
    this.venue$ = this.store.select(VenueState.venue);
  }

  ngOnInit() {
    this.isCuration = this.router.url.startsWith('/curation');

    this.router.events
    .pipe(
      takeUntil(this.unSubscribe$)
    )
    .subscribe((val) => {
      this.isCuration = this.router.url.startsWith('/curation');;
    });

    this.venue$
    .pipe(
      filter((venue) => !!venue),
      takeUntil(this.unSubscribe$)
    )
    .subscribe((venue) => {
      this.venue = venue;
      this.hasChannelZone = this.venue.zones.some(({mediaType}) => mediaType === MediaTypes.CHANNEL);
      this.hasChannelZone = this.venue.zones.some(({mediaType}) => mediaType === MediaTypes.VIDEO);
      // this.canSeeJukeo = whitelistedJukeoZones.includes(this.venue.id);
      this.canSeeJukeo = true;
    });

    this.store.select(VenueState.hasZoneByMediaType(MediaTypes.CHANNEL))
    .subscribe((hasChannelZone) => {
      this.hasChannelZone = hasChannelZone;
    });
  }

  logout() {
    this.authService.logout().then((data) => {
      this.router.navigate(['/login']);
    });
  }

  support() {
    window.open("https://support.audalize.com.au/", "_blank");
  }

  changeVenue() {
    this.dialog.open(VenueListComponent, { width: '640px' })
      .afterClosed()
      .subscribe((venueId: number) => {
        if (venueId) {
          this.store.dispatch(new VenueAction.ChangeCurrentVenue(venueId)).subscribe(() => window.location.reload());
        }
      });
  }

  ngOnDestroy() {
    this.unSubscribe$.next(true);
    this.unSubscribe$.complete();
  }
}
