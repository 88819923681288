import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../shared/services/auth.service';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AlertDialogComponent } from '../../shared/components/alert-dialog/alert-dialog.component';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetForm: UntypedFormGroup;
  resetInProgress = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private router: Router,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    this.resetForm = this.formBuilder.group({
      email: ['', {validators: [Validators.required, Validators.email]}],
    });
  }

  reset() {
    this.resetInProgress = true;

    this.authService
      .resetPassword(this.resetForm.controls.email.value)
      .pipe(
        finalize(() => {
          this.resetInProgress = false;
        }),
      )
      .subscribe(() => {
        this.router.navigate(['/login']);
        this.dialog.open(AlertDialogComponent, {
          data: {
            type: 'success',
            message: 'We’ve sent a password reset link to your email address. Please click the link to reset your password.'
          }
        });
      }, () => {
        this.dialog.open(AlertDialogComponent, {
          data: {
            type: 'error',
            message: 'Something went wrong. Unable to reset password.'
          }
        });
      });
  }

}
