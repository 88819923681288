import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { PlaylistAction } from '../../store/playlists/playlists.actions';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { SharedBucketListDialogComponent } from '../bucket-list-dialog/bucket-list-dialog.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { ZonesListComponent } from '../zone-list-dialog/zone-list-dialog.component';
import { ZoneService } from '../../services/zone.service';
import { PrepareDataService } from '../../services/prepare-data.service';
import { ErrorParserService } from '../../services/error-parcing.service';
import { SNACK_BAR_DURATION } from '../../models/consts';
import { NewPlaylistAction } from '../../store/new-playlists/new-playlists.actions';

@Component({
  selector: 'app-promoted-playlist-item',
  templateUrl: './promoted-playlist-item.component.html',
  styleUrls: ['./promoted-playlist-item.component.scss']
})
export class PromotedPlaylistItemComponent implements OnInit {
  @Input() playlist;
  @Input() isMyPlaylist?: boolean;

  constructor(
    private router: Router,
    private store: Store,
    private dialog: MatDialog,
    private zoneService: ZoneService,
    private prepareService: PrepareDataService,
    private errorService: ErrorParserService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.playlist = {
      ...this.playlist,
      promotedArtwork: this.getPlaylistArtwork()
    }
  }

  getPlaylistArtwork() {
    if (this.playlist.tracksConnection && this.playlist.tracksConnection.edges && this.playlist.tracksConnection.edges.length >= 6) {
      return this.prepareService.playlistArtworkConn(this.playlist, 6)
    } else {
      return this.prepareService.playlistArtwork(this.playlist, 6)
    }
  }

  getPlaylistDuration() {
    const playlistDurationUnits = this.playlist.duration.split(':');

    // coercion to Number is needed here to get rid of leading zero
    return `${Number(playlistDurationUnits[0])} Hours ${playlistDurationUnits[1]} min`;
  }

  viewPlaylist() {
    this.router.navigate(['/playlist/' + this.playlist.id]);
  }

  addToLibrary() {
    this.store.dispatch([
      new PlaylistAction.AddPlaylist(this.playlist),
      new NewPlaylistAction.ChangePlaylistLibraryState([this.playlist.id], true)
    ])
    .subscribe(() => {
      this.playlist = {...this.playlist, isInLibrary: true};
      this._snackBar.open(
        `Playlist successfully added to the library`,
        null,
        { duration: SNACK_BAR_DURATION }
      );
    }, (err) => {
      const errIdStr = this.errorService.parseError(err);
      this.dialog.open(AlertDialogComponent, {
        data: {
          type: 'error',
          message: `Oops, something went wrong. Please try again.
          ${(!!errIdStr) ? ('Error id: ' + errIdStr) : ''}`
        }
      });
    });
  }

  deletePlaylist() {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: 'Are you sure you want to delete the playlist?',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary'
          },
          {
            text: 'Delete',
            cssClass: 'danger',
            handler: () => {
              const action = (this.playlist.isCatalog) ?
                [
                  new PlaylistAction.RemovePlaylistFromLibrary(this.playlist.id),
                  new NewPlaylistAction.ChangePlaylistLibraryState([this.playlist.id], false)
                ] :
                [
                  new PlaylistAction.DeletePlaylist(this.playlist.id),
                  new NewPlaylistAction.ChangePlaylistLibraryState([this.playlist.id], false)
                ];

              this.dialog.closeAll();
              this.store.dispatch(action)
              .subscribe(
                () => {
                  this._snackBar.open(
                    `Playlist successfully removed from the library`,
                    null,
                    { duration: SNACK_BAR_DURATION }
                  );
                  this.playlist = {
                    ...this.playlist,
                    isInLibrary: false
                  };
                },
                (error) => {
                  const errIdStr = this.errorService.parseError(error);
                  this.dialog.open(AlertDialogComponent, {
                    data: {
                      type: 'error',
                      message: `Oops, something went wrong. Please try again.
                      ${(!!errIdStr) ? ('Error id: ' + errIdStr) : ''}`
                    }
                  });
                }
              );
            }
          }
        ]
      },
      width: '340px'
    });
  }

  addToQueue() {
    this.dialog.open(ZonesListComponent)
      .afterClosed()
      .subscribe(data => {
        if (!data) {
          return;
        }
        if (data.error) {
          this.dialog.open(AlertDialogComponent, {
            data: {
              type: 'error',
              message: data.error
            }
          });
        } else {
          this.confirmAddPlaylistInQueue(data.id);
        }
      });
  }

  confirmAddPlaylistInQueue(zoneId) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: 'The playlist will start after the current song finishes',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary'
          },
          {
            text: 'OK',
            cssClass: 'danger',
            handler: () => {
              this.zoneService.addPlaylistToZoneQueue(
                zoneId,
                this.playlist.id
              )
              .then(() => {
                dialogRef.close();
                this._snackBar.open(
                  `Playlist successfully added to the queue`,
                  null,
                  { duration: SNACK_BAR_DURATION }
                );
              })
              .catch((error) => {
                const errIdStr = this.errorService.parseError(error);
                dialogRef.close();
                this.dialog.open(AlertDialogComponent, {
                  data: {
                    type: 'error',
                    message: `Oops, something went wrong. Please try again.
                    ${(!!errIdStr) ? ('Error id: ' + errIdStr) : ''}`
                  }
                });
              });
            }
          }
        ]
      },
      width: '340px'
    });
  }

  addToBucket() {
    this.dialog.open(SharedBucketListDialogComponent, {
      data: {
        playlistId: this.playlist.id
      }
    });
  }

}
