<div class="dialog-header">
  <h2 mat-dialog-title>Copy Schedule Between Zones</h2>
  <button mat-dialog-close class="btn close-dialog-btn"></button>
</div>

<div class="zone-wrapper">
  <mat-dialog-content class="right-border-thin">
    <app-spinner *ngIf="isLoading" [isLoading]="isLoading" [errored]="errored" [minimal]="true"></app-spinner>
    <div *ngIf="!isLoading" class="zone-list-wrapper">
      <h4>From zone</h4>
      <ng-scrollbar>
        <ul *ngIf="zones.length > 0" class="zone-list">
          <li
            *ngFor="let zone of zones"
            (click)="selectZone(zone)"
            [ngClass]="{
              'selected': zone.id === sourceZoneId,
              'initial-selected': zone.id === initialSelectedZoneId
            }"
            class="zone-item"
          >
            <p class="zone-name text-truncate">{{ zone.name }}</p>
            <div class="checkmark-container"></div>
          </li>
        </ul>
        <div *ngIf="zones.length === 0" class="no-zones-message text-uppercase">No Zones available</div>
      </ng-scrollbar>
    </div>
  </mat-dialog-content>

  <mat-dialog-content>
    <app-spinner *ngIf="isLoading" [isLoading]="isLoading" [errored]="errored" [minimal]="true"></app-spinner>
    <div *ngIf="!isLoading" class="zone-list-wrapper">
      <h4>To zone</h4>
      <ng-scrollbar>
        <ul *ngIf="zones.length > 0" class="zone-list">
          <li
            *ngFor="let zone of zones"
            (click)="selectDestinationZone(zone)"
            [ngClass]="{
              'selected': destinationZoneIds[zone.id],
              'initial-selected': zone.id === initialSelectedZoneId,
              'disabled': zone.id === sourceZoneId
            }"
            class="zone-item"
          >
            <p class="zone-name text-truncate">{{ zone.name }}</p>
            <div class="checkmark-container"></div>
          </li>
        </ul>
        <div *ngIf="zones.length === 0" class="no-zones-message text-uppercase">No Zones available</div>
      </ng-scrollbar>
    </div>
  </mat-dialog-content>
</div>

<div mat-dialog-actions>
  <button mat-dialog-close class="btn cancel-btn" (click)="cancel()">Cancel</button>
  <button (click)="save()" [disabled]="!sourceZoneId || !hasDestinations" class="btn save-zone-btn">Apply</button>
</div>
