export namespace SearchCatalogueAction {
  export class GetPopularGenres {
    static readonly type = '[SearchCatalogue] Get Popular Genres';
    constructor() {}
  }

  export class UpdateSearch {
    static readonly type = '[SearchCatalogue] Update Search';
    constructor(public search: string) {}
  }

  export class UpdateSearchDebounce {
    static readonly type = '[SearchCatalogue] Update Search (used with debounce)';
    constructor(public search: string) {}
  }

  export class UpdateGenre {
    static readonly type = '[SearchCatalogue] Update Genre';
    constructor(public genre: string) {}
  }

  export class PreviousNavHistory {
    static readonly type = '[SearchCatalogue] Goes to the previous navigation history';
    constructor() {}
  }

  export class NextNavHistory {
    static readonly type = '[SearchCatalogue] Goes to the next navigation history';
    constructor() {}
  }

  export class AddToHistory {
    static readonly type = '[SearchCatalogue] Adds to history';
    constructor(public history: string) {}
  }

  export class RetrieveHistory {
    static readonly type = '[SearchCatalogue] Retrieve history';
    constructor() {}
  }

  export class SaveToLocalStorage {
    static readonly type = '[SearchCatalogue] Save state to local storage';
    constructor(public localStorageKey:string) {}
  }

  export class RetrieveFromLocalStorage {
    static readonly type = '[SearchCatalogue] Retrieve state from local storage';
    constructor(public localStorageKey:string) {}
  }

  export class GetVenueTypes {
    static readonly type = '[SearchCatalogue] Gets venue types'
    constructor() {}
  }

  export class GetMoreVenueTypePlaylists {
    static readonly type = '[SearchCatalogue] Get more venue type playlist'
    constructor(public id: number, public numPlaylist: number, public afterCursor: string) {}
  }

  export class UpdateVenueType {
    static readonly type = '[SearchCatalogue] Updates venue type'
    constructor(public id: number) {}
  }

  export class GetVenueTypeCategories {
    static readonly type = '[SearchCatalogue] Gets venue type categories'
    constructor() {}
  }

  export class UpdateVenueTypeCategory {
    static readonly type = '[SearchCatalogue] Updates venue type category'
    constructor(public id: number) {}
  }

  export class ClearSearchHistory {
    static readonly type = '[SearchCatalogue] Clear search history'
    constructor() {}
  }

  export class ClearNavHistory {
    static readonly type = '[SearchCatalogue] Clear nav history'
    constructor() {}
  }
}
