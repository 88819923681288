import gql from 'graphql-tag';

const SearchCatalogueMinimalData = gql`
  fragment SearchCatalogueMinimalData on SearchCatalogueMinimalData {
    id
    name
  }
`

const SearchCatalogueTrack = gql`
  fragment SearchCatalogueTrack on SearchCatalogueTrack {
    id
    name
    isrc
    artists {
      ...SearchCatalogueMinimalData
    }
    album {
      ...SearchCatalogueMinimalData
    }
    releaseYear
    artworkUrl100
    artworkUrl1000
  }
  ${SearchCatalogueMinimalData}
`

const SearchCatalogueGenre = gql`
  fragment SearchCatalogueGenre on SearchCatalogueGenre {
    genre
    popularTracks {
      ...SearchCatalogueTrack
    }
  }
  ${SearchCatalogueTrack}
`

const SearchCatalogueArtist = gql`
  fragment SearchCatalogueArtist on SearchCatalogueArtist {
    id
    name
    artworkUrl100
    artworkUrl1000
  }
`

const SearchCatalogueInterface = gql`
  fragment SearchCatalogueInterface on SearchCatalogueInterface {
    tracks {
      ...SearchCatalogueTrack
    }
    artists {
      ...SearchCatalogueArtist
    }
    playlists {
      id
      name
      logoImageUrl
      tracks {
        id
        name
        artist {
          id
          name
        }
        artwork {
          artworkUrl100
          artworkUrl1000
        }
      }
    }
  }
  ${SearchCatalogueTrack}
  ${SearchCatalogueArtist}
`

const searchPopularGenres = gql`
  query searchPopularGenres {
    searchPopularGenres {
      ...SearchCatalogueGenre
    }
  }
  ${SearchCatalogueGenre}
`

const searchCatalogue = gql `
  query searchCatalogue($search: String!, $genre: String) {
    searchCatalogue(search: $search, genre: $genre, size: 30) {
      ...SearchCatalogueInterface
    }
  }
  ${SearchCatalogueInterface}
`

const searchSimilarPlaylist = gql`
  query searchSimilarPlaylist($artistIds: [Int!]!, $currentPlaylistId: Int!) {
    searchSimilarPlaylist(artistIds: $artistIds, currentPlaylistId: $currentPlaylistId) {
      id
      name
      logoImageUrl
      tracks {
        id
        name
        artist {
          id
          name
        }
        artwork {
          artworkUrl100
          artworkUrl1000
        }
      }
    }
  }
`

export {
  searchPopularGenres,
  searchCatalogue,
  searchSimilarPlaylist
}
