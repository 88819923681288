import { BucketConnectionFragment, CombinedFragments, MinimalFragments } from './fragments';

import gql from 'graphql-tag';

export const buckets = gql`
  query buckets(
    $first: Int
    $venueId: Int!
  ) {
    buckets {
      id
      name
      duration
      trackCount
      venue {
        id
      }
      createdAt
      updatedAt
      playlists {
        id
        name
        trackCount
        tracks {
          ...TrackData
        }
        tracksConnection(first: $first) {
          ...TrackConnection
        }
        logoImageUrl
      }
    }
  }
  ${CombinedFragments.fragments.track}
  ${CombinedFragments.fragments.paginationTrack}
`;

export const renameBucket = gql`
mutation RenameBucket($id: Int!, $name: String!) {
  renameBucket(id: $id, name: $name)
}`;

export const getBucketsPaginated = gql`
  query bucketsConnection(
    $venueId: Int!
    $first: Int
    $after: Cursor
    $last: Int
    $before: Cursor
  ) {
    bucketsConnection(
      venueId: $venueId
      first: $first
      after: $after
      last: $last
      before: $before
    ) {
      ...BucketConnection
    }
  }
${BucketConnectionFragment}
`;

export const bucketData = gql`
  query bucket(
    $id: Int!
    $venueId: Int!
    $first: Int
  ) {
    bucket(id: $id) {
      ...BucketMinData
      playlists {
        id
        name
        generalGenre
        trackCount
        logoImageUrl
        tracks {
          ...TrackData
          isInLibrary(venueId: $venueId)
          heartState(venueId: $venueId) {
            like
          }
        }
        tracksConnection(first: $first) {
          ...TrackConnection
        }
      }
    }
  }
  ${MinimalFragments.fragments.bucket}
  ${CombinedFragments.fragments.track}
  ${CombinedFragments.fragments.paginationTrack}
`;

export const createBucket = gql`
  mutation createBucket(
    $name: String!
    $venueId: Int!
    $colourCode: String
  ) {
    createBucket(
      name: $name
      venueId: $venueId
      colourCode: $colourCode
    ) {
      id
      name
      duration
      trackCount
      createdAt
      updatedAt
      playlists {
        id
        name
        trackCount
      }
    }
  }
`;

export const deleteBucket = gql`
  mutation deleteBucket(
    $id: Int!
  ) {
    deleteBucket(
      id: $id
    )
  }
`;

export const removeTrackFromBucket = gql`
  mutation removeTrackFromBucket(
    $trackId: Int!
    $bucketId: Int!
    ) {
      removeTrackFromBucket(
        trackId: $trackId
        bucketId: $bucketId
      )
    }
`;

export const deleteBuckets = gql`
  mutation deleteBuckets(
    $ids: [Int!]
  ) {
    deleteBuckets(
      ids: $ids
    )
  }
`

export const overrideZoneTracksUsingBucketUntilNextTimeslot = gql`
  mutation overrideZoneTracksUsingBucketUntilNextTimeslot(
    $zoneId: Int!
    $bucketId: Int!
    $forbidSimilar: Boolean
  ) {
    overrideZoneTracksUsingBucketUntilNextTimeslot(
      zoneId: $zoneId
      bucketId: $bucketId
      forbidSimilar: $forbidSimilar
    ) {
      id
      zone {
        id
        name
      }
      currentPosition
      from
      to
      version
      playlist {
        id
      }
      bucket {
        id
        name
        venue {
          id
          name
        }
      }
      tracks {
        id
        position
      }
      day
      fromTime
      toTime
  	}
  }
`
