import { Component, OnDestroy, OnInit, Inject } from '@angular/core';

import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ZonesState } from 'src/app/shared/store/zones/zones.state';
import { Player, Zone, Track } from 'src/app/shared/models/models';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { PlayerService } from 'src/app/shared/services/player.service';
import { PlayerCommandType } from 'src/app/shared/gql/zone-consts';

interface PlayerCommandDialogButton {
  text: string;
  role?: string;
  cssClass?: string;
  handler?: Function;
}

interface DialogData {
  zoneId: number;
  playing: boolean;
  title?: string;
  message: string;
  showSpinner?: boolean;
  track?: Track;
  buttons: PlayerCommandDialogButton[];
  playerCommand;
}

@Component({
  templateUrl: './player-command-dialog.component.html',
  styleUrls: ['./player-command-dialog.component.scss']
})

export class PlayerCommandDialogComponent implements OnInit, OnDestroy {
  @Select(ZonesState.currentZone) currentZone$: Observable<Zone>;
  private currentZoneSub: Subscription;
  private startTime = Date.now()
  public zoneId: number;
  public playing: boolean;
  public title: string;
  public message: string;
  public waiting: boolean;
  public track: Track;
  public playerCommand;//: PlayerCommandType;//.next | PlayerCommandType.pause | PlayerCommandType.resume;
  private playerSub: Subscription;

  constructor(
    public dialogRef: MatDialogRef<PlayerCommandDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private playerService: PlayerService,
    private store: Store
  ) {
    this.currentZone$ = this.store.select(ZonesState.currentZone);
    this.playing = data.playing;
    this.title = data.title;
    this.message = data.message;
    this.waiting = true;
    this.zoneId = data.zoneId;
    this.track = data.track;
    this.playerCommand = data.playerCommand;
  }

  public toggleSpinner() {
    this.data.showSpinner = !this.data.showSpinner;
  }

  ngOnInit() {
    console.log('this.playing:', this.playing);
    this.currentZoneSub = this.currentZone$.subscribe((zone) => {
      console.log('1. this.playing:', this.playing, ' zone.playing:', zone.playing);
      if (zone.id == this.zoneId && zone.playing != this.playing && (this.playerCommand == PlayerCommandType.pause || this.playerCommand == PlayerCommandType.resume)) {
	console.log('player command dialog section 1');
	this.waiting = false;
	this.title = 'Request Actioned';
	this.message = 'Success!\nYour command has been actioned by the player.';
      }

      console.log("1.track: ", zone.currentTrack.id, this.track.id);
      if (zone.id == this.zoneId && zone.currentTrack.id != this.track.id && this.playerCommand == PlayerCommandType.next) {
	console.log('player command dialog section 2');
	this.waiting = false;
	this.title = 'Request Actioned';
	this.message = 'Success!\nYour command has been actioned by the player.';
      }
    });

    this.playerSub = this.playerService.onPlayersChanged.subscribe((players) => {
      //   const diff = (Date.now() - this.startTime);
      //   if (diff > 20000) {
      // 	this.waiting = false;
      // 	this.title = 'Request Actioned';
      // 	this.message = 'Oops! The player did not respond to your command. Please check the device\'s internet connection and try again.';
      //   }


      const zonePlayer = players.find((player) => player && player.id === this.zoneId);
      if (zonePlayer) {
	console.log('2. this.playing:', this.playing, ' zonePlayer.playing:', zonePlayer.playing);
	if (zonePlayer.playing != this.playing && (this.playerCommand == PlayerCommandType.pause || this.playerCommand == PlayerCommandType.resume)) {
	  console.log('player command dialog section 3');
	  this.waiting = false;
	  this.title = 'Request Actioned';
	  this.message = 'Success!\nYour command has been actioned by the player.';
	}

	console.log("2.track: ", zonePlayer.track.id, this.track.id);
	if (zonePlayer.track.id != this.track.id && this.playerCommand == PlayerCommandType.next) {
	  console.log('player command dialog section 4');
	  this.waiting = false;
	  this.title = 'Request Actioned';
	  this.message = 'Success!\nYour command has been actioned by the player.';
	}
      }
    });

    setTimeout(()=> {
      console.log('player command dialog section 5');
      this.waiting = false;
      this.title = 'Request Actioned';
      this.message = 'Oops! The player did not respond to your command. Please check the device\'s internet connection and try again.';
    }, 20000)
  }

  ngOnDestroy() {
    this.currentZoneSub.unsubscribe();
    this.playerSub.unsubscribe();
  }

}
