<div class="dialog-wrapper">
  <div class="dialog-header">
    <button class="btn back-dialog-btn position-absolute rounded-circle" (click)="goBack()"></button>
    <h2 mat-dialog-title>Report Venues</h2>
    <button [mat-dialog-close]="true" class="btn close-dialog-btn"></button>
  </div>

  <mat-dialog-content [hidden]="isVenueSelected">
    <div class="search-track-wrapper">
      <input
        [(ngModel)]="searchString"
        (input)="search()"
        (keyup.enter)="search()"
        type="text"
        id="search-track"
        class="input-search w-100"
        placeholder="Type something"
      />
    </div>

    <div class="track-list-wrapper">
      <ng-scrollbar *ngIf="sortedVenues && sortedVenues.length > 0">
        <ul class="track-list p-0 m-0 list-unstyled">
          <li
            *ngFor="let venue of sortedVenues"
            (click)="selectVenue(venue)"
            class="track-item-wrapper d-flex justify-content-start align-items-center"
          >
            <div class="data-wrapper d-flex w-100 align-items-center">
              <div class="cover-thumbnail">
                <img
                  [src]="venue.logoImageUrl || '/assets/images/logo-white.svg'"
                  [class.default-logo]="!venue.logoImageUrl"
                  appDefault="/assets/images/logo-white.svg"
                  defaultClass="default-logo"
                  class="rounded-circle"
                  alt="Venue logo"
                />
              </div>

              <div class="info-wrapper d-flex flex-column justify-content-center h-100 m-0">
                <div class="venue-name-wrapper sub-data d-flex align-items-baseline">
                  <h2 [title]="venue.name" class="text-truncate">{{ venue.name }}</h2>
                  <div class="status">{{ venue.isActive ? 'Online' : 'Offline' }}</div>
                </div>
                <div class="sub-data d-flex">
                  <p>{{ venue.owner.userEmail }}</p>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </ng-scrollbar>
      <div *ngIf="sortedVenues && sortedVenues.length === 0" class="no-results">
        <p>No results found</p>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-content *ngIf="isVenueSelected" id="venue-details">
    <div class="track-list-wrapper">

      <div class="player-line-container line-2 plr-8 flex-column">
        <div class="data-wrapper d-flex w-100 align-items-center">
          <div class="venue-info d-flex w-100">
            <div class="cover-thumbnail">
              <img
                [src]="selectedVenue.logoImageUrl || '/assets/images/logo-white.svg'"
                [class.default-logo]="!selectedVenue.logoImageUrl"
                appDefault="/assets/images/logo-white.svg"
                defaultClass="default-logo"
                class="rounded-circle"
                alt="Venue logo"
              />
            </div>

            <div class="info-wrapper d-flex flex-column justify-content-center h-100 m-0">
              <div class="sub-data d-flex">
                <h2 [title]="selectedVenue.name" class="text-truncate">{{ selectedVenue.name }}</h2>
                <div class="status">{{ selectedVenue.isActive ? 'Online' : 'Offline' }}</div>
              </div>
              <div class="sub-data d-flex">
                <p>{{ selectedVenue.owner.userEmail }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="data-wrapper d-flex w-100 align-items-center">
          <div class="venue-additional-info p-0 w-100">
            <div class="address">{{ selectedVenue.address.line1 || 'n/a' }}</div>

            <div class="coordinates-wrapper d-flex align-items-center">
              <div class="icon icon-location-mark"></div>
              <div
                [title]="'Latitude: ' + selectedVenue.latitude + '°   ' + 'Longitude: ' + selectedVenue.longitude + '°'"
                class="coordinates d-flex align-items-center"
                *ngIf="selectedVenue.latitude && selectedVenue.longitude"
              >
                {{ selectedVenue.latitude }}°&nbsp;&nbsp;&nbsp;{{ selectedVenue.longitude }}°
              </div>
              <div
                class="coordinates d-flex align-items-center"
                *ngIf="!selectedVenue.latitude || !selectedVenue.longitude"
              >
                n/a
              </div>
            </div>

            <div class="creator-data d-flex justify-content-between">
              <div class="col-md-6">
                <div class="header">Name</div>
                <div
                  [title]="selectedVenue.contact.name"
                  class="data text-truncate"
                >{{ selectedVenue.contact.name || 'n/a' }}</div>
              </div>
              <div class="col-md-6">
                <div class="header">Phone Number</div>
                <div
                  [title]="selectedVenue.contact.phoneNumber"
                  class="data text-truncate"
                >{{ selectedVenue.contact.phoneNumber || 'n/a' }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </mat-dialog-content>

  <div *ngIf="data && data.type === 'playedTracks' && isVenueSelected" class="form-report">
    <form [formGroup]="reportForm" (ngSubmit)="downloadReport(selectedVenue)">
      <div class="form-group">
        <label for="email">Email</label>
        <input id="email" inputmode="email" formControlName="email" type="email" placeholder="Enter Email">
      </div>

      <div class="form-group-period">
        <div>
          <label for="from">From</label>
          <div>
            <input
              matInput
              [matDatepicker]="pickerFrom"
              id="from"
              inputmode="text"
              formControlName="from"
              type="text"
              placeholder="MM/YYYY"
              [max]="maxDate"
              readonly
            >
            <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
            <mat-datepicker
              #pickerFrom
              startView="multi-year"
              (monthSelected)="chosenMonthHandler($event, pickerFrom)"
              panelClass="example-month-picker"
            ></mat-datepicker>
          </div>
        </div>

        <div>
          <label for="to">To</label>
          <div>
            <input
              matInput
              [matDatepicker]="pickerTo"
              id="to"
              inputmode="text"
              formControlName="to"
              type="text"
              placeholder="MM/YYYY"
              [max]="maxDate"
              readonly
            >
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="align-right dialog-controls text-right">
    <button
      [hidden]="!isVenueSelected"
      (click)="downloadReport(selectedVenue)"
      class="btn action-btn p-0"
      [disabled]="data && data.type === 'playedTracks' && reportForm.invalid"
    >
      Download Report
    </button>
  </div>
</div>
