import gql from 'graphql-tag';

export const resetActivationCodeUsingDevCredentials = gql`
  mutation resetActivationCodeUsingDevCredentials(
    $zoneId: Int!
  ) {
    resetZoneActivationCode(
      zoneId: $zoneId
    )
  }
`;

export const setPlayerSettings = gql`
  mutation setPlayerSettings(
    $playerId: Int!
    $values: [PlayerSettingInput!]!
  ) {
    setPlayerSettings(
      playerId: $playerId
      values: $values
    )
  }
`
