import { Component, Inject, OnDestroy, OnInit } from '@angular/core';

import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { ZoneService } from '../../services/zone.service';
import { Zone } from '../../models/models';
import { ZonesState } from '../../store/zones/zones.state';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter, finalize, tap } from 'rxjs/operators';
import { MediaTypes } from '../../models/consts';

interface DialogData {
  zoneId?: number;
  mediaType?: MediaTypes;
  hasShuffle?: boolean;
}

@Component({
  selector: 'app-zone-list-dialog',
  templateUrl: './zone-list-dialog.component.html',
  styleUrls: ['./zone-list-dialog.component.scss']
})
export class ZonesListComponent implements OnInit, OnDestroy {
  private selectedZoneName: string;

  public selectedZoneId: number;
  public initialSelectedZoneId: number;
  public isLoading = false;
  public errored = false;
  public zones: Zone[] = [];
  public isShuffled = false;
  @Select(ZonesState.zones) zonesStore$: Observable<Zone[]>;

  constructor(
    public dialogRef: MatDialogRef<ZonesListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private store: Store
  ) { 
    this.zonesStore$ = this.store.select(ZonesState.zones);
  }

  ngOnInit() {
    this.isLoading = true;
    this.zonesStore$
    .pipe(
      filter(() => this.store.selectSnapshot(ZonesState.isZonesLoaded)),
      tap(() => this.isLoading = false)
    )
      .subscribe((zones: Zone[]) => {
        const mediaType = (this.data && this.data.mediaType) ? this.data.mediaType : MediaTypes.AUDIO;
        this.zones = zones.filter(zone => zone.player && zone.mediaType === mediaType);
        if (!this.zones.length) {
          this.dialogRef.close({
            error: `There are no active players for zones with '${mediaType}' type. Please activate a zone's player to add music.`
          });
        }
      }, () => this.errored = true);
  }

  toggleShuffle() {
    this.isShuffled = !this.isShuffled;
  }

  selectZone(zone) {
    this.selectedZoneId = zone.id;
    this.selectedZoneName = zone.name;
  }

  save() {
    this.dialogRef.close({
      id: this.selectedZoneId,
      name: this.selectedZoneName,
      isShuffled: this.isShuffled
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  ngOnDestroy() {}
}
