import {
  deleteOverrideTrackQueue,
  getOverrideTrackQueue,
  removeTrackFromPlayQueue,
} from '../gql/schedule-consts';

import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TracksQueueService {
  constructor(
    private apollo: Apollo
  ) {}

  deleteOverrideTrackQueue(overrideQueueId: number) {
    return this.apollo.mutate({
      mutation: deleteOverrideTrackQueue,
      variables: { overrideQueueId }
    })
    .pipe(
      map((data: any) => data.data.deleteOverrideTrackQueue)
    )
  }

  getOverrideTrackQueue(zoneId: number) {
    return this.apollo.query({
      query: getOverrideTrackQueue,
      variables: { zoneId }
    })
    .pipe(
      map((data: any) => data.data.zone.overrideTrackQueue)
    )
  }

  removeTrackFromPlayQueue(trackId: number, zoneId: number) {
    return this.apollo.mutate({
      mutation: removeTrackFromPlayQueue,
      variables: { trackId, zoneId }
    })
    .pipe(
      map((data: any) => data.data.removeTrackFromPlayQueue)
    )
  }
}
