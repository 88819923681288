import { Component, OnDestroy, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { filter, takeUntil } from 'rxjs/operators';

import { AuthService } from 'src/app/shared/services/auth.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
//import { MediaTypes } from 'src/app/shared/models/consts';
import { Router } from '@angular/router';
//import { Venue } from 'src/app/shared/models/models';
import { VenueAction } from 'src/app/shared/store/venue/venue.actions';
import { VenueListComponent } from '../venues/venue-settings/venue-list/venue-list.component';
import { VenueState } from 'src/app/shared/store/venue/venue.state';
import { environment } from 'src/environments/environment';
// import { MiniplayerComponent } from './miniplayer/miniplayer.component';
// import { PlayerCommandDialogComponent } from './miniplayer/player-command-dialog/player-command-dialog.component';
import { PlayerItemComponent } from './player-item/player-item.component';

import { OwlCarousel } from 'ngx-owl-carousel';

import { TrackQueueComponent } from 'src/app/shared/components/track-queue/track-queue.component';
//import { ReportingComponent } from './reports/reporting/reporting.component';
import { VenueService } from 'src/app/shared/services/venue.service';
import { TrackService } from 'src/app/shared/services/track.service';
import { TimerService } from 'src/app/shared/services/timer.service';
import { ZoneService } from 'src/app/shared/services/zone.service';
//import { AuthService } from 'src/app/shared/services/auth.service';
import { User, Venue, Zone, TodayTimeslot } from 'src/app/shared/models/models';
//import { Select, Store } from '@ngxs/store';
//import { filter, takeUntil } from 'rxjs/operators';
//import { VenueState } from 'src/app/shared/store/venue/venue.state';
import { VenueListState } from 'src/app/shared/store/venue-list/venue-list.state';
import { VenueListAction } from 'src/app/shared/store/venue-list/venue-list.actions';
import { ZonesState } from 'src/app/shared/store/zones/zones.state';
import { ZoneAction } from 'src/app/shared/store/zones/zones.actions';
import * as moment from 'moment';
import { TimeslotService } from 'src/app/shared/services/timeslot.service';
import { DATE_FORMAT_FOR_BACKEND, MediaTypes, SNACK_BAR_DURATION } from 'src/app/shared/models/consts';
//import { BucketDetailsDialogComponent } from './bucket-details-dialog/bucket-details-dialog.component';
import { QueuePlaylistDialogComponent } from './queue-playlist-dialog/queue-playlist-dialog.component';
import { QueueBucketDialogComponent } from './queue-bucket-dialog/queue-bucket-dialog.component';
import { QueueTrackDialogComponent } from './queue-track-dialog/queue-track-dialog.component';

import { TracksQueueService } from 'src/app/shared/services/tracks-queue.service';
import { ErrorParserService } from 'src/app/shared/services/error-parcing.service';
import { ConfirmationDialogComponent } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { AlertDialogComponent } from 'src/app/shared/components/alert-dialog/alert-dialog.component';
@Component({
  selector: 'app-rhs-menu',
  templateUrl: './rhs-menu.component.html',
  styleUrls: ['./rhs-menu.component.scss']
})
export class RhsMenuComponent implements OnInit, OnDestroy {
  public zoneIdArr: any = [];
  public zonesWithPlayer: Zone[] = [];

  public timers: any[] = [];
  public user: User;
  public venue: Venue;
  public zone: Zone;
  public todayTimeslots: TodayTimeslot[];
  public todayTimeslotsLoading = false;
  public todayTimeslotsErrored = false;
  public todayTimeslotsLoaded = false;
  public view = "NOW";
  public activeZoneIndex: number = 0;

  @Output() menuItemSelected = new EventEmitter();
  @ViewChild('owlMenuCarousel', { static: false }) owlElement: OwlCarousel;

  @Select(VenueState.venue) venue$: Observable<Venue>;
  private selectedVenueSub: Subscription;
  private timersSub: Subscription;
  private zonesSub: Subscription;
  private venueSub: Subscription;
  private userSub: Subscription;
  private currentZoneSub: Subscription;
  private venueUpdateSub: Subscription;
  private zones: Zone[];
  @Select(ZonesState.zones) zones$: Observable<Zone[]>;
  @Select(ZonesState.currentZone) currentZone$: Observable<Zone>;

  private selectedZoneId: number;

  private unSubscribe$: Subject<{}> = new Subject;

  constructor(
    private router: Router,
    private authService: AuthService,
    private dialog: MatDialog,
    private venueService: VenueService,
    private zoneService: ZoneService,
    private trackService: TrackService,
    private timerService: TimerService,
    private timeslotService: TimeslotService,
    private store: Store,
    private tracksQueueService: TracksQueueService,
    private _snackBar: MatSnackBar,
    private errorService: ErrorParserService,
  ) {
    this.currentZone$ = this.store.select(ZonesState.currentZone);
    this.venue$ = this.store.select(VenueState.venue);
    this.zones$ = this.store.select(ZonesState.zones)
    this.onZoneChanged = this.onZoneChanged.bind(this);
  }

  ngOnInit() {
    this.currentZoneSub = this.currentZone$
      .pipe(
        filter((zone) => !!zone),
        takeUntil(this.unSubscribe$)
      )
      .subscribe((zone) => {
        this.zone = zone;
        //console.log(zone);
        if (!this.selectedZoneId || this.selectedZoneId !== this.zone.id) {
          this.selectedZoneId = this.zone.id;
        }
        if (this.zone && this.owlElement) {
          this.owlElement.to([this.zonesWithPlayer.findIndex(i => i.id === zone.id)]);
        }
      });

    this.venue$
      .pipe(
        filter((venue) => !!venue),
        takeUntil(this.unSubscribe$)
      )
      .subscribe((venue) => {
        this.venue = venue;
        this.fetchZones();
      });

    this.zones$
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((zones) => {
        this.zones = zones;
        //console.log("zones subscribe");
        this.todayTimeslotsLoading = true;
        // TODO: Check if we need to load today timeslots for current zone
        this.getTodayTimeslots(this.zones).then((todayTimeslots: TodayTimeslot[]) => {
          //console.log("zones subscribe, getTimeslot", todayTimeslots);
          this.todayTimeslots = todayTimeslots.sort(this.sortTimeslots);
          this.todayTimeslotsLoading = false;
          this.todayTimeslotsLoaded = true;
        }).catch(() => this.todayTimeslotsErrored = true);
      });
  }

  fetchZones() {
    this.zonesSub = this.zones$
      .pipe(
        filter((zones: Zone[]) => !!zones && !!zones.length),
      )
      .subscribe((zones) => {
        this.zonesWithPlayer = zones.filter((zone) => zone.player && zone.currentTrack);
        //console.log('fetchZoneWPlayer:', this.zonesWithPlayer);
        if (!!this.zoneIdArr.length || this.zoneIdArr.length !== zones.length) {
          this.zoneIdArr = this.zonesWithPlayer.map(({ id }) => id);
        }

        if (!!this.zonesWithPlayer.length && !this.zone) {
          this.selectedZoneId = this.zonesWithPlayer[0].id;
          this.store.dispatch(new ZoneAction.SetCurrentZoneId(this.selectedZoneId));
        } else if (this.zone && !this.zonesWithPlayer.length) {
          this.store.dispatch(new ZoneAction.SetCurrentZoneId());
        }
      });

    this.timersSub = this.timerService.onTimersChanged.subscribe((timers) => {
      timers.forEach((timer) => {
        this.timers[timer.id] = {
          position: timer.position || 0,
          playing: timer.playing,
          duration: timer.duration
        };
      });
    });
  }

  getTodayTimeslots(zones: Zone[]) {
    return new Promise((resolve) => {
      const zonesWithSchedule = zones.filter((zone) => !!zone.schedule);

      const promises = [];
      const currentDay = moment().format(DATE_FORMAT_FOR_BACKEND);
      const todayTimeslots = [];

      zonesWithSchedule.forEach((zone) => {
        const promise = this.timeslotService.getTimeslotsForDay(zone.schedule.id, currentDay, false, true)
          .catch(() => [])
          .then((timeslots) => {
            if (timeslots.length > 0) {
              timeslots.forEach((originalTimeslot) => {
                const timeslot = originalTimeslot.repeatOf || originalTimeslot;

                todayTimeslots.push({
                  zoneName: zone.name,
                  zoneId: zone.id,
                  tracksContainer: timeslot.bucket || timeslot.playlist,
                  from: timeslot.from,
                  to: timeslot.to
                });
              });
            }
          });

        promises.push(promise);
      });

      Promise.all(promises).then(() => {
        resolve(todayTimeslots);
      });
    });
  }

  // onZoneChanged(data) {
  // }
  onZoneChanged(data) {
    const carouselZoneItemIndex = data.item.index;
    this.activeZoneIndex = carouselZoneItemIndex;
    //console.log(data);
    //console.log('onZoneChange', (this));
    if (carouselZoneItemIndex !== null) {
      this.zone = this.zonesWithPlayer[carouselZoneItemIndex];
      if (this.selectedZoneId !== this.zone.id) {
        this.selectedZoneId = this.zone.id;
        this.store.dispatch([
          new ZoneAction.SetCurrentZoneId(this.selectedZoneId),
          new ZoneAction.ZonePlayerEventChangeSubscription(this.zone.id),
          new ZoneAction.MonitorModeSubscription(this.zone.id)
        ])
      }
    }
  }

  ngOnDestroy() {
    this.unSubscribe$.next(true);
    this.unSubscribe$.complete();
  }

  goToQueue() {
    this.dialog.open(TrackQueueComponent, {
      data: {
        zone: this.zone
      }
    });
  }

  sortTimeslots(t1, t2) {
    if (t1.from < t2.from) {
      return -1;
    }

    if (t1.from > t2.from) {
      return 1;
    }

    return 0;
  }

  setView(view: 'PLAYLISTS' | 'TRACKS') {
    this.view = view;
  }

  // addPlaylist() {
  //   this.addPlaylistsMode = true;
  //   this.isLoading = true;

  //   const playlistIds = this.bucket.playlists.map((bucketPlaylist) => bucketPlaylist.id);

  //   this.playlistService.getPlaylistsForBucket(playlistIds)
  //   .then((playlists) => {
  //     this.filteredPlaylists = this.playlists = playlists;
  //     this.isLoading = false;
  //   }).catch(() => this.errored = true);
  // }

  goToPlaylist(playlistId) {
    this.dialog.open(QueuePlaylistDialogComponent, {
      data: {
        //bucket: this.buckets.find(({id}) => bucketId === id)
      }
    });
  }

  goToBucket(bucketId) {
    this.dialog.open(QueueBucketDialogComponent, {
      data: {
        //bucket: this.buckets.find(({id}) => bucketId === id)
      }
    });
  }
  goToTrack() {
    this.dialog.open(QueueTrackDialogComponent, {
      data: {
        //bucket: this.buckets.find(({id}) => bucketId === id)
      }
    });
  }

  promptRevertToSchedule() {
    this.tracksQueueService.getOverrideTrackQueue(this.zone.id)
      .subscribe((res) => {
        let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          data: {
            message: "Are you sure you want to revert current playing queue, back to schedule?",
            buttons: [
              {
                text: 'Cancel',
                role: 'cancel',
                cssClass: 'secondary',
              },
              {
                text: 'Save changes',
                cssClass: 'primary',
                handler: () => {
                  if (!res || !res.id) {
                    this._snackBar.open(
                      `No schedule found to revert back to`,
                      null,
                    );
                    dialogRef.close();
                    return;
                  }
                  this.revertToSchedule(res.id)
                }
              }
            ]
          }
        });
      });
  };

  revertToSchedule = (overrideQueueId: number) => {
    this.tracksQueueService.deleteOverrideTrackQueue(overrideQueueId)
      .subscribe((res) => {
        this.dialog.closeAll();
        this._snackBar.open(
          `${res ? 'Successfully' : 'Failed to'} reverted back to scheduled tracks`,
          null,
          { duration: SNACK_BAR_DURATION }
        );
      }, (error) => {
        const errIdStr = this.errorService.parseError(error);
        this.dialog.open(AlertDialogComponent, {
          data: {
            type: 'error',
            message: `Oops, something went wrong. Please try again.
              ${(!!errIdStr) ? ('Error id: ' + errIdStr) : ''}`
          }
        });
      }
      )
  }
} //class RhsMenuComponent implements OnInit, OnDestroy
