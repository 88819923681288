<div class="app-wrapper">
  <div class="header">
    <app-header [inputSideNav]="sideNav"></app-header>
  </div>

  <mat-sidenav-container>
    <mat-sidenav #sideNav position="end">
      <app-user-menu (menuItemSelected)="sideNav.close()"></app-user-menu>
    </mat-sidenav>

    <mat-sidenav-content>
      <div class="container">
        <div class="row" [ngClass]="{ 'miniplayer-active': showMiniPlayer }">
          <div class="main-menu">
            <app-main-menu></app-main-menu>
          </div>
          <div class="app-content">
            <router-outlet></router-outlet>
          </div>
          <div class="rhs-menu">
	    <app-rhs-menu></app-rhs-menu>
          </div>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>

  <div class="app-footer">
    <app-footer></app-footer>
  </div>

  <!-- <app-miniplayer></app-miniplayer> -->
</div>
