import { Injectable } from '@angular/core';

import { TrackService } from './track.service';

@Injectable({
  providedIn: 'root'
})
export class ClearDataService {

  constructor(
    private trackService: TrackService
  ) { }

  // remove after track storage will be added
  clearDataAfterVenueChange() {
    this.trackService.clearData();
  }

}