import { bucketData, createBucket, deleteBucket, deleteBuckets, getBucketsPaginated, overrideZoneTracksUsingBucketUntilNextTimeslot, removeTrackFromBucket, renameBucket } from '../gql/bucket-consts';
import { deletePlaylistFromBucket, playlistsToBucket } from '../gql/playlist-consts';
import { map, switchMap } from 'rxjs/operators';

import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { PrepareDataService } from './prepare-data.service';
import { VenueService } from './venue.service';

@Injectable({
  providedIn: 'root'
})
export class BucketService {

  constructor(
    private apollo: Apollo,
    private prepareService: PrepareDataService,
    private venueService: VenueService
  ) { }

  getBucketConnection(cursor?, count = 10) {
    return this.apollo.query({
      query: getBucketsPaginated,
      variables: {
        venueId: this.venueService.getSelectedVenueId(),
        first: count,
        after: cursor
      },
      fetchPolicy: "no-cache",
    })
    .pipe(
      map((data:any) => data.data.bucketsConnection)
    );
  }

  getBucketData(bucketId, first = 10) {
    return this.apollo.query({
        query: bucketData,
        variables: {
          id: bucketId,
          venueId: this.venueService.getSelectedVenueId(),
          first
        },
        fetchPolicy: 'no-cache'
      })
      .pipe(
        map((data: any) => data.data.bucket)
      );
  }

  deleteBucket(id: number) {
    return this.apollo.mutate({
        mutation: deleteBucket,
        variables: { id }
      })
      .pipe(
        map((data: any) => data.data.deleteBucket)
      );
  }

  deleteMultipleBuckets(ids: number[]) {
    return this.apollo.mutate({
      mutation: deleteBuckets,
      variables: { ids }
    })
    .pipe(
      map((data: any) => data.data.deleteBuckets)
    )
  }

  createBucket(name: string) {
    return this.apollo.mutate({
      mutation: createBucket,
      variables: { name, venueId: this.venueService.getSelectedVenueId() }
    })
    .pipe(
      map((data: any) => data.data.createBucket)
    )
  }

  renameBucket(id: number, name: string) {
    return this.apollo.mutate({
      mutation: renameBucket,
      variables: { name, id}
    })
    .pipe(
      map((data: any) => data.data.renameBucket)
    )
  }

  addPlaylistsToBucket(playlistIds, bucketId) {
    return this.apollo.mutate({
        mutation: playlistsToBucket,
        variables: { playlistIds, bucketId }
      })
      .pipe(
        switchMap(() => this.getBucketData(bucketId))
      );
  }

  deletePlaylistFromBucket(playlistId, bucketId) {
    return this.apollo.mutate({
        mutation: deletePlaylistFromBucket,
        variables: { bucketId, playlistId }
      })
      .pipe(
        switchMap(() => this.getBucketData(bucketId))
      );
  }

  removeTrackFromBucket(trackId, bucketId) {
    return this.apollo.mutate({
      mutation: removeTrackFromBucket,
      variables: { trackId, bucketId }
    })
    .pipe(
      switchMap(() => this.getBucketData(bucketId))
    );
  }

  overrideZoneTracksUsingBucketUntilNextTimeslot(zoneId, bucketId, forbidSimilar = true) {
    return this.apollo.mutate({
      mutation: overrideZoneTracksUsingBucketUntilNextTimeslot,
      variables: { zoneId, bucketId, forbidSimilar }
    })
    .pipe(
      map((data: any) => data.data.overrideZoneTracksUsingBucketUntilNextTimeslot)
    )
  }
}
