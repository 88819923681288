import { Injectable } from '@angular/core';
import { VenueService } from './venue.service';
import { BehaviorSubject } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { getDefaultSettings, updateVenueDefaults } from '../gql/venue-consts';
import { ZonePreset } from '../models/models';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  public onSettingsChanges: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private venueService: VenueService,
    private apollo: Apollo,
  ) { }

  getDefaultSettings(venueId: number) {
    return new Promise<ZonePreset>((resolve, reject) => {
      this.apollo.query({
        query: getDefaultSettings,
        variables: { id: venueId || this.venueService.getSelectedVenueId() }
      })
      .subscribe(
        (data: any) => {
          this.onSettingsChanges.next(data.data.venue.zonePreset);
          resolve(data.data.venue.zonePreset);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    });
  }

  updateVenueDefaults(venueId: number, values) {
    return new Promise<any[]>((resolve, reject) => {
      this.apollo.mutate({
        mutation: updateVenueDefaults,
        variables: {
          venueId: venueId || this.venueService.getSelectedVenueId(),
          values
        }
      })
      .subscribe(
        (data: any) => {
          this.onSettingsChanges.next(data.data.updateZonePreset);
          resolve(data.data.updateZonePreset);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    });
  }
}
