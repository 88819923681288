import { Component, OnInit, Input } from '@angular/core';
import { Track } from 'src/app/shared/models/models';

@Component({
  selector: 'app-track-item-line',
  templateUrl: './track-item.component.html',
  styleUrls: ['./track-item.component.scss']
})
export class TrackItemComponent implements OnInit {
  @Input() track: Track;

  constructor() { }

  ngOnInit() {}
}
