import { Component, Input, OnInit } from '@angular/core';
import { Track } from '../../models/models';
import { PrepareDataService } from '../../services/prepare-data.service';

@Component({
  selector: 'app-clip-item',
  templateUrl: './clip-item.component.html',
  styleUrls: ['./clip-item.component.scss']
})
export class ClipItemComponent implements OnInit {
  @Input() track: Track;
  @Input() isSelected?: boolean;

  constructor(
    private prepareService: PrepareDataService
  ) { }

  ngOnInit() {
  }

  get trackDuration() {
    return this.prepareService.trackDuration(this.track.duration)
  }

}
