import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { Observable, Subject } from 'rxjs';
import { VenueService } from 'src/app/shared/services/venue.service';
import { Venue } from 'src/app/shared/models/models';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { AlertDialogComponent } from 'src/app/shared/components/alert-dialog/alert-dialog.component';
import { ErrorParserService } from 'src/app/shared/services/error-parcing.service';
import { Select, Store } from '@ngxs/store';
import { filter, takeUntil } from 'rxjs/operators';
import { VenueListState } from 'src/app/shared/store/venue-list/venue-list.state';
import { VenueListAction } from 'src/app/shared/store/venue-list/venue-list.actions';
import { DATE_FORMAT_FOR_BACKEND, SNACK_BAR_DURATION } from 'src/app/shared/models/consts';

interface DialogData {
  type: string;
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-report-venues',
  templateUrl: './report-venues.component.html',
  styleUrls: ['./report-venues.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class ReportVenuesComponent implements OnInit, OnDestroy {
  public reportForm: UntypedFormGroup;
  @Select(VenueListState.venues) venues$: Observable<Array<Venue>>;

  private unSubscribe$: Subject<{}> = new Subject;

  public searchString = '';
  public selectedVenue: Venue;
  public venues: Venue[];
  public sortedVenues: Venue[];
  public isVenueSelected = false;
  public maxDate: Date = new Date();

  constructor(
    private dialogRef: MatDialogRef<ReportVenuesComponent>,
    private venueService: VenueService,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private errorService: ErrorParserService,
    private store: Store,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { 
    this.venues$ = this.store.select(VenueListState.venues);
  }

  ngOnInit() {
    if (!this.store.selectSnapshot(VenueListState.isVenuesLoaded)) {
      this.store.dispatch(new VenueListAction.GetVenues())
    }

    this.venues$
    .pipe(
      filter((arr) => !!arr.length),
      takeUntil(this.unSubscribe$)
    )
    .subscribe(venues => {
      this.sortedVenues = this.venues = venues;
    });
    this.reportForm = this.formBuilder.group({
      email: ['', { validators: [Validators.required, Validators.email]}],
      from: [
        moment().subtract(2, 'month').toISOString(),
        { validators: Validators.required }
      ],
      to: [
        moment().toISOString(),
        { validators: Validators.required }
      ]
    });
  }

  goBack() {
    if (this.isVenueSelected) {
      this.isVenueSelected = false;

      return;
    }

    this.dialogRef.close();
  }

  search() {
    const searchString = this.searchString.trim().toLowerCase();

    if (!searchString) {
      this.sortedVenues = this.venues;
      return;
    }

    this.sortedVenues = [];

    for (let i = 0; i < this.venues.length; ++i) {
      const name = this.venues[i].name.toLowerCase();

      if (name.includes(searchString)) {
        this.sortedVenues.push(this.venues[i]);
      }
    }
  }

  selectVenue(venue) {
    this.isVenueSelected = true;
    this.selectedVenue = venue;
  }

  get formControl() { return this.reportForm.controls; }

  chosenMonthHandler(normalizedMonth, datepicker) {
    this.formControl.from.setValue(moment(normalizedMonth).toISOString())
    const newTo = moment(normalizedMonth).add(2, 'month')
    if (newTo.isAfter(moment())) {
      this.formControl.to.setValue(moment().toISOString())
    } else {
      this.formControl.to.setValue(newTo.toISOString())
    }
    datepicker.close();
  }

  downloadReport(selectedVenue) {
    if (this.data && this.data.type === 'playedTracks') {
      this.venueService.enqueueCreateBmsReportJob(
        this.formControl.email.value,
        selectedVenue.id,
        moment(this.formControl.from.value).startOf('month').format(DATE_FORMAT_FOR_BACKEND),
        moment(this.formControl.to.value).endOf('month').format(DATE_FORMAT_FOR_BACKEND)
      )
      .subscribe(
        () => {
          this._snackBar.open(
            `The report was successfully sent to the mail`,
            null,
            { duration: SNACK_BAR_DURATION }
          );
          this.dialogRef.close()
        },
        (error) => {
          const errIdStr = this.errorService.parseError(error);
          this.dialog.open(
            AlertDialogComponent,
            {
              data: {
                type: 'error',
                message: `Oops, something went wrong. Please try again.
                ${(!!errIdStr) ? ('Error id: ' + errIdStr) : ''}`
              },
              width: '620px'
            }
          );
        }
      )
    }
  }

  ngOnDestroy() {
    this.unSubscribe$.next(true);
    this.unSubscribe$.complete();
  }
}
