import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { Channel, PaginationInfo, Playlist, PlaylistInput, Track } from '../models/models';
import {
  addCatalogPlaylistsToVenue,
  addTracksToSpotifyPlaylistImport,
  channelTypes,
  channels,
  createNewPlaylist,
  createPlaylist,
  createPlaylistFromSpotifyPlaylistImport,
  createSpotifyPlaylistImport,
  curationPlaylistsConnection,
  curationPlaylistsConnectionMini,
  deletePlaylistFromLibrary,
  deletePlaylists,
  fullPlaylist,
  getMyPlaylists,
  getPlaylistsForBucket,
  lovedPlaylists,
  matchSpotifyPlaylistTracks,
  newPlaylistsConnection,
  nextTrackPage,
  playlistCoverImage,
  playlistToLibrary,
  promotedPlaylists,
  removeMultipleCatalogPlFromLibrary,
  removePlaylistFomLibrary,
  removeTrackFromPlaylist,
  resolveTracks,
  updatePlaylist,
  updatePlaylistTracks,
  searchPlaylists,
  getMyPlaylistsMini,
  curationPlaylistsConnectionEssential,
  getMyPlaylistsEssential,
} from '../gql/playlist-consts';
import { addTrackToPlaylist, addTracksToPlaylist } from '../gql/track-consts';
import { map, switchMap, tap } from 'rxjs/operators';

import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { MediaTypes } from '../models/consts';
import { PlaylistPrepare } from '../utils/prepare-playlist';
import { PrepareDataService } from './prepare-data.service';
import { VenueService } from './venue.service';

@Injectable({
  providedIn: 'root'
})
export class PlaylistService {
  private promotedPlaylists: Playlist[];
  private lovedPlaylists: Playlist[];
  private playlists: Playlist[];
  private playlist: Playlist;
  private channelTypes: Channel[];
  
  public playlistFilter$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public onPromotedPlaylistsChanged: BehaviorSubject<Playlist[]> = new BehaviorSubject([]);
  public onLovedPlaylistsChanged: BehaviorSubject<Playlist[]> = new BehaviorSubject([]);
  public onPlaylistChanged: BehaviorSubject<Playlist> = new BehaviorSubject(null);

  constructor(
    private apollo: Apollo,
    private prepareService: PrepareDataService,
    private venueService: VenueService
  ) { }

  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
    const id = route.paramMap.get('id');
    return this.getPlaylist(+id, 10);
  }

  getPlaylist(playlistId, first = 10) {
    return new Promise<any>((resolve, reject) => {
        this.apollo.query({
          query: fullPlaylist,
          variables: { id: playlistId, venueId: this.venueService.getSelectedVenueId(), first },
          fetchPolicy: 'no-cache'
        })
        .subscribe(
          (data: any) => {
            if (data.data.playlist) {
              this.playlist = data.data.playlist;
              this.playlist.tracks = this.playlist.tracksConnection.edges.map(({node}) => node);
              this.playlist.artwork = this.prepareService.playlistArtwork(this.playlist);
              this.playlist.bigArtwork = this.prepareService.playlistArtwork(this.playlist, 8);
              this.onPlaylistChanged.next(this.playlist);
            }
            resolve(this.playlist);
          },
          error => {
            console.log(error);
            reject(error);
          }
        );
    });
  }

  nextTrackPage(playlistId, after, limit = 10, venueId = this.venueService.getSelectedVenueId()) {
    return this.apollo.query({
      query: nextTrackPage,
      variables: {
        after,
        venueId,
        first: limit,
        id: playlistId
      },
      fetchPolicy: 'no-cache'
    })
    .pipe(
      map((data: any) => data.data.playlist.tracksConnection),
      tap((trackConnection: any) => {
        if (this.playlist && this.playlist.id === playlistId) {
          this.playlist.tracks = [...this.playlist.tracks, ...trackConnection.edges.map(({node}) => node)];
        }
      })
    );
  }

  updateTracksForPlaylist(playlistId: number, venueId = this.venueService.getSelectedVenueId()) {
    return this.apollo.query({
      query: updatePlaylistTracks,
      variables: {
        id: playlistId,
        venueId
      },
      fetchPolicy: 'no-cache'
    })
    .pipe(
      map((data: any) => data.data.playlist)
    );
  }

  addTracks(trackIds, playlistId) {
    return this.apollo.mutate({
        mutation: addTracksToPlaylist,
        variables: { playlistId, trackIds },
      })
      .pipe(
        switchMap(() => from(this.getPlaylist(playlistId)))
      );
  }

  addTrackToPLaylist(trackId, playlistId) {
    return new Promise<any>((resolve, reject) => {
      this.apollo.mutate({
        mutation: addTrackToPlaylist,
        variables: { playlistId, trackId }
      })
      .subscribe(
        (data: any) => {
          if (data.data.addTrackToPlaylist) {
            resolve(true);
          } else {
            reject(false);
          }
        },
        error => {
          console.log(error);
          reject(error);
        }
      );
  });
}

  removeTrack(trackId, playlistId) {
    return this.apollo.mutate({
      mutation: removeTrackFromPlaylist,
      variables: { playlistId, trackId }
    });
  }

  deleteLibraryPlaylist(playlistId) {
    return this.apollo.mutate({
      mutation: deletePlaylistFromLibrary,
      variables: { id: playlistId }
    })
    .pipe(
      map((data: any) => data.data.deletePlaylist)
    )
  }

  removeFromLibrary(playlistId) {
    return this.apollo.mutate({
      mutation: removePlaylistFomLibrary,
      variables: {
        venueId: this.venueService.getSelectedVenueId(),
        playlistId
      }
    })
    .pipe(
      map((data: any) => data.data.removeCatalogPlaylistFromVenue)
    );
  }

  getPromotedPlaylists() {
    return new Promise<any[]>((resolve, reject) => {
      this.apollo.query({
        query: promotedPlaylists,
        variables: { venueId: this.venueService.getSelectedVenueId() }
      })
      .subscribe(
        (data: any) => {
          if (data.data.promotedPlaylists) {
            this.promotedPlaylists = PlaylistPrepare.sortPlaylists(data.data.promotedPlaylists.map((item) => {
              item = Object.assign({artwork: this.prepareService.playlistArtwork(item)}, item);
              // item.artwork = this.prepareService.playlistArtwork(item);
              return item;
            }));

            this.onPromotedPlaylistsChanged.next(this.promotedPlaylists);
          }
          resolve(this.promotedPlaylists);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    });
  }

  getLovedPlaylists() {
    return new Promise<any[]>((resolve, reject) => {
      this.apollo.query({
        query: lovedPlaylists,
        variables: { venueId: this.venueService.getSelectedVenueId() }
      })
      .pipe(
        map((data: any) => data.data.lovedPlaylists)
      )
      .subscribe(
        (playlists: Playlist[]) => {
          this.lovedPlaylists = PlaylistPrepare.sortPlaylists(playlists.map((item) => {
            item.artwork = this.prepareService.playlistArtwork(item);
            return item;
          }));

          this.onLovedPlaylistsChanged.next(this.lovedPlaylists);
          resolve(this.lovedPlaylists);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    });
  }

  getChannels(trackCount = 10) {
    return this.apollo.query({
      query: channels,
      variables: {
        firstTracks: trackCount,
        venueId: this.venueService.getSelectedVenueId()
      }
    })
    .pipe(
      map((data: any) => data.data.channels)
    )
  }
  
  getEssentialPlaylistsConnection(cursor?, count = 20, mediaType: MediaTypes = MediaTypes.AUDIO, nameLike: string = '') {
    if (nameLike && nameLike !== '') {
      nameLike = `%${nameLike}%`;
    }
    return this.apollo.query({
      fetchPolicy: 'no-cache',
      query: getMyPlaylistsEssential,
      variables: {
        venueId: this.venueService.getSelectedVenueId(),
        after: cursor,
        first: count,
        mediaType,
        nameLike
      }
    })
    .pipe(
      map((data: any) => data.data.playlistsConnection)
    )
  }

  getMiniPlaylistsConnection(cursor?, count = 20, mediaType: MediaTypes = MediaTypes.AUDIO) {
    return this.apollo.query({
      query: getMyPlaylistsMini,
      variables: {
        venueId: this.venueService.getSelectedVenueId(),
        after: cursor,
        first: count,
        mediaType
      }
    })
    .pipe(
      map((data: any) => data.data.playlistsConnection)
    )
  }

  getPlaylistsConnection(cursor?, count = 20, mediaType: MediaTypes = MediaTypes.AUDIO) {
    return this.apollo.query({
      query: getMyPlaylists,
      variables: {
        venueId: this.venueService.getSelectedVenueId(),
        after: cursor,
        first: count,
        mediaType
      }
    })
    .pipe(
      map((data: any) => data.data.playlistsConnection)
    )
  }

  // function to get playlists not existed to in playlistsId array
  // gets only for one page and doesn't save
  // returns list of prepared playlists that not existed in playlistsId array
  getPlaylistsForBucket(playlistsId: number[]) {
    return new Promise<Playlist[]>((resolve, reject) => {
      this.apollo.query({
        query: getPlaylistsForBucket,
        variables: {
          id: this.venueService.getSelectedVenueId(),
          venueId: this.venueService.getSelectedVenueId(),
          firstTracks: 10
        },
        fetchPolicy: 'no-cache'
      })
      .pipe(
        map((data: any) => data.data.venue.playlists)
      )
        .subscribe(
          (playlists: Playlist[]) => {
            const notInBucketPlaylists = playlists.filter(({id}) => !playlistsId.some(pl => pl === id));
            if (!!notInBucketPlaylists) {
              playlists = PlaylistPrepare.sortPlaylists(notInBucketPlaylists)
              .map(item => {
                item.artwork = this.prepareService.playlistArtwork(item);
                return item;
              });
              resolve(playlists);
            }
            resolve([]);
          },
          (error) => {
            console.log(error);
            reject(error);
          }
        );
    });
  }

  addPlaylistToLibrary(playlistId) {
    return this.apollo.mutate({
        mutation: playlistToLibrary,
        variables: { playlistId, venueId: this.venueService.getSelectedVenueId() }
      })
    .pipe(
      map((data: any) => data.data.addCatalogPlaylistToVenue)
    )
  }

  createMyPlaylist(name: string, mediaType: MediaTypes = MediaTypes.AUDIO) {
    return this.apollo.mutate({
      mutation: createPlaylist,
      variables: { name, venueId: this.venueService.getSelectedVenueId() }
    })
    .pipe(
      map((data:any) => data.data.createPlaylist)
    )
  }

  createSpotifyPlaylist(name, spotifyPlaylistId) {
    return this.apollo.mutate({
      mutation: createSpotifyPlaylistImport,
      variables: {
        venueId: this.venueService.getSelectedVenueId(),
        name,
        spotifyPlaylistId
      }
    })
    .pipe(
      map((data: any) => data.data.createSpotifyPlaylistImport)
    );
  }

  addSpotifyTracks(spotifyPlaylistImportId, tracks) {
    return this.apollo.mutate({
      mutation: addTracksToSpotifyPlaylistImport,
      variables: {
        spotifyPlaylistImportId,
        tracks
      }
    })
    .pipe(
      map((data: any) => data.data.addTracksToSpotifyPlaylistImport)
    )
  }

  matchSpotifyPlaylistTracks(tracks) {
    return this.apollo.mutate({
      mutation: matchSpotifyPlaylistTracks,
      variables: {
        tracks
      }
    })
    .pipe(
      map((data: any) => data.data.matchSpotifyPlaylistTracks)
    )
  }

  publishSpotifyPlaylist(spotifyPlaylistImportId) {
    return this.apollo.mutate({
      mutation: createPlaylistFromSpotifyPlaylistImport,
      variables: {
        spotifyPlaylistImportId,
        venueId: this.venueService.getSelectedVenueId()
      }
    })
    .pipe(
      map((data: any) => data.data.createPlaylistFromSpotifyPlaylistImport)
    )
  }

  updatePlaylistCover(playlistId) {
    return this.apollo.subscribe({
      query: playlistCoverImage,
      variables: { id: playlistId }
    })
    .pipe(
      map((data: any) => data.data.playlist.logoImageUrl)
    );
  }

  getNewPlaylistsConnection(cursor?: string, first: number = 10, mediaType: MediaTypes = MediaTypes.AUDIO, withUnpublished: boolean = false) {
    return this.apollo.query({
        query: newPlaylistsConnection,
        variables: {
          venueId: this.venueService.getSelectedVenueId(),
          first,
          after: cursor,
          withUnpublished,
          mediaType
        }
      })
      .pipe(
        map((data: any) => data.data.newPlaylistsConnection)
      );
  }

  /**
   * Gets essential - needed data only
   * @param cursor 
   * @param first 
   * @param mediaType 
   * @param withUnpublished 
   * @returns 
   */
  getEssentialSystemPlaylistsConnection(cursor?: string, first: number = 10, mediaType: MediaTypes = MediaTypes.AUDIO, withUnpublished: boolean = true) {
    return this.apollo.query({
        query: curationPlaylistsConnectionEssential,
        variables: {
          venueId: this.venueService.getSelectedVenueId(),
          first,
          after: cursor,
          withUnpublished, // get all playlists for store and then filter
          mediaType
        },
        fetchPolicy: 'no-cache'
      })
      .pipe(
        map((data: any) => {
          return  data.data.curationPlaylistsConnection
        })
      );
  }

  getSystemPlaylistsConnection(cursor?: string, first: number = 10, mediaType: MediaTypes = MediaTypes.AUDIO, withUnpublished: boolean = true) {
    return this.apollo.query({
        query: curationPlaylistsConnectionMini,
        variables: {
          venueId: this.venueService.getSelectedVenueId(),
          first,
          after: cursor,
          withUnpublished, // get all playlists for store and than filter
          mediaType
        },
        fetchPolicy: 'no-cache'
      })
      .pipe(
        map((data: any) => {
          return  data.data.curationPlaylistsConnection
        })
      );
  }

  createNewPlaylist(playlistValue: PlaylistInput) {
    return this.apollo.mutate({
      mutation: createNewPlaylist,
      variables: {
        name: playlistValue.name,
        description: playlistValue.description,
        promoted: playlistValue.promoted || null,
        loved: playlistValue.loved || null,
        venueId: this.venueService.getSelectedVenueId(),
        mediaType: playlistValue.mediaType,
        channelId: playlistValue.channelId || null,
        isPublished: playlistValue.isPublished
      }
    })
    .pipe(
      map((data: any) => data.data.createCatalogPlaylist)
    )
  }

  updateNewPlaylist(id: number, playlistInput: PlaylistInput) {
    return this.apollo.mutate({
      mutation: updatePlaylist,
      variables: {
        id,
        values: playlistInput,
        firstTracks: 10,
        venueId: this.venueService.getSelectedVenueId()
      }
    })
    .pipe(
      map((data: any) => data.data.updatePlaylist)
    )
  }

  getChannelTypes() {
    return this.apollo.query({
      query: channelTypes
    })
    .pipe(
      map((data: any) => data.data.channels),
      tap((channels) => this.channelTypes = channels)
    )
  }

  removeMultipleCatalogPlFromLibrary(playlistIds: number[], venueId?: number) {
    return this.apollo.mutate({
      mutation: removeMultipleCatalogPlFromLibrary,
      variables: {
        playlistIds,
        venueId: venueId || this.venueService.getSelectedVenueId()
      }
    })
    .pipe(
      map((data: any) => data.data.removeCatalogPlaylistsFromVenue)
    )
  }

  deletePlaylists(ids: number[]) {
    return this.apollo.mutate({
      mutation: deletePlaylists,
      variables: {
        ids
      }
    })
    .pipe(
      map((data: any) => data.data.deletePlaylists)
    )
  }

  renamePlaylist(id: number, name: string) {
    return this.apollo.mutate({
      mutation: updatePlaylist,
      variables: {
        id,
        values: {
          name
        },
        venueId: this.venueService.getSelectedVenueId()
      }
    })
    .pipe(
      map((data: any) => data.data.updatePlaylist)
    )
  }

  addCatalogPlaylistsToLibrary(playlistIds: number[], venueId?: number) {
    return this.apollo.mutate({
      mutation: addCatalogPlaylistsToVenue,
      variables: {
        playlistIds,
        venueId: venueId || this.venueService.getSelectedVenueId()
      }
    })
    .pipe(
      map((data: any) => data.data.addCatalogPlaylistsToVenue)
    )
  }

  async resolveTracks(tracks: any[]): Promise<Record<string, any>> {
    return await this.apollo.query({ query: resolveTracks, variables: { input: tracks }}).toPromise();
  }

  get channels() {
    return this.channelTypes;
  }

  searchPlaylists(size:number, search: string) {
      return this.apollo.query({
          query: searchPlaylists,
          variables: {
	      size: size,
	      venueId: this.venueService.getSelectedVenueId(),
	      search:search
          },
          fetchPolicy: 'no-cache'
      })
      .pipe(
        map((data: any) => data.data.searchCatalogue.playlists)
      );

  }
}
