import { PromotionFragment } from './fragments';
import gql from 'graphql-tag';

export const getPromotion = gql`
  query promotion(
    $id: Int!
  ) {
    promotion(
      id: $id
    ) {
      ...PromotionMinData,
      schedules {
        ...PromotionScheduleData
      }
    }
  }
${PromotionFragment.promotion}
${PromotionFragment.promotionSchedule}
`;

export const getPromotions = gql`
  query promotionsVenue($id: Int!) {
    venue(id: $id) {
      id
      promotions {
        ...PromotionMinData,
        schedules {
          ...PromotionScheduleData
        }
      }
    }
  }
${PromotionFragment.promotion}
${PromotionFragment.promotionSchedule}
`;

export const setPromotionSchedule = gql`
  mutation setPromotionScheduleForZones(
    $promotionId: Int!
    $zoneIds: [Int!]!
    $status: Boolean
    $weekDays: OptionalDaysOfWeekInput
    $fromDate: String
    $toDate: String
    $fromTime: String
    $toTime: String
    $policies: [PromotionSchedulePolicyInput!]
    $promotionContentOrder: Int
  ) {
    setPromotionScheduleForZones(
      promotionId: $promotionId
      zoneIds: $zoneIds
      status: $status
      weekDays: $weekDays
      fromDate: $fromDate
      toDate: $toDate
      fromTime: $fromTime
      toTime: $toTime
      policies: $policies
      promotionContentOrder: $promotionContentOrder
    )
  }
`;

export const changePromoName = gql`
  mutation updatePromotion(
    $id: Int!
    $name: String
  ) {
    updatePromotion(
      id: $id
      name: $name
    )
  }
`;

export const removePromoSchedule = gql`
  mutation deletePromotionSchedule(
    $id: Int!
  ) {
    deletePromotionSchedule(
      id: $id
    )
  }
`;

export const setPromotionScheduleOrder = gql`
  mutation setPromotionScheduleOrder($promotionIdsInOrder: [Int!]!) {
    setPromotionScheduleOrder(
      promotionIdsInOrder: $promotionIdsInOrder
    )
  }
`
