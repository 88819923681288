import { AfterViewInit, Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription, pipe } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { PageInfo, PaginationInfo, User, Venue } from 'src/app/shared/models/models';
import { debounceTime, filter, map, takeUntil, tap } from 'rxjs/operators';

import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { VenueAction } from '../../store/venue/venue.actions';
import { VenueListAction } from '../../store/venue-list/venue-list.actions';
import { VenueListState } from '../../store/venue-list/venue-list.state';
import { VenueService } from '../../services/venue.service';
import { ZoneService } from '../../services/zone.service';
import { SCROLL_BOTTOM_OFFSET } from '../../models/consts';
import { NgScrollbar } from 'ngx-scrollbar';

@Component({
  selector: 'app-choose-venue',
  templateUrl: './choose-venue.component.html',
  styleUrls: ['./choose-venue.component.scss']
})
export class ChooseVenueComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(NgScrollbar, { static: false }) scrollbarRef: NgScrollbar;
  public onUserChanged: Subscription;
  public user: User;
  searchFilter$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public venues: Array<Venue>;
  public filteredVenues: Array<Venue>;
  @Select(VenueListState.minimalVenues) venues$: Observable<Array<Venue>>;

  public isLoading = true;
  public errored = false;
  public filteredQuery = false;
  public allowQuery = true;
  public cursor: string;
  public pageSize: number = 10;
  public pageInfo: PageInfo;
  public scrollSub: Subscription;
  public isMoreVenuesLoading = false;
  public venueLike: string = '';

  private debounce = 600;
  private unSubscribe$: Subject<{}> = new Subject;

  constructor(
    private venueService: VenueService,
    private authService: AuthService,
    private router: Router,
    private zoneService: ZoneService,
    private store: Store,
    private ngZone: NgZone,
  ) { 
    this.venues$ = this.store.select(VenueListState.minimalVenues);
  }

  ngOnInit() {
    this.onUserChanged = this.authService.onUserChanged
    .pipe(
      takeUntil(this.unSubscribe$)
    )
    .subscribe(user => {
      this.user = user;
    });

    this.venueService.getVenuesConnection(this.cursor, this.pageSize, '')
      .pipe(
        tap((paginationInfo: PaginationInfo) => {
          this.pageInfo = paginationInfo.pageInfo;
        }),
        map(({ edges }) => edges.map(({ node }) => node))
      ).subscribe((response) => {
        this.filteredVenues = this.venues = response;
        this.isLoading = false;
      });

    // Apply debounce to search filter
    this.searchFilter$.pipe(debounceTime(500)).subscribe((value) => {
      this.isLoading = true;
      this.venueService.getVenuesConnection(this.cursor, this.pageSize, value)
      .pipe(
        tap((paginationInfo: PaginationInfo) => {
          this.pageInfo = paginationInfo.pageInfo;
        }),
        map(({ edges }) => edges.map(({ node }) => node))
      ).subscribe((response) => {
        this.filteredVenues = this.venues = response;
        this.isLoading = false;
      });
    })
  }

  ngAfterViewInit() {
    if (this.scrollbarRef && !this.scrollSub) {
      this.scrollSub = this.scrollbarRef.verticalScrolled.pipe(
        filter((e: any) => {
          return !this.isLoading && e.target.offsetHeight + e.target.scrollTop > e.target.scrollHeight - SCROLL_BOTTOM_OFFSET;
        }),
        tap(() => this.ngZone.run(() => {
          this.isMoreVenuesLoading = true;
          this.loadMore();
        }))
      ).subscribe();
    }
  }

  loadMore() {
    this.venueService.getVenuesConnection(this.pageInfo.endCursor, this.pageSize, this.venueLike).pipe(
      tap((paginationInfo: PaginationInfo) => {
        this.pageInfo = paginationInfo.pageInfo;
      }),
      map(({ edges }) => edges.map(({ node }) => node))
    ).subscribe((response) => {
      this.filteredVenues = [...this.filteredVenues, ...response];
      this.isMoreVenuesLoading = false;
    }, (err) => {
      this.isMoreVenuesLoading = false;
    });
  }

  selectVenue(venueId) {
    this.store.dispatch(new VenueAction.ChangeCurrentVenue(venueId))
    .subscribe(() => {
      location.href = '/discover';
      // this.router.navigate(['/discover']);
    });
  }

  searchVenue(term: string) {
    if (term && term !== '') {
      term = `%${term}%`;
    }
    this.searchFilter$.next(term.toString().toLowerCase());
  }

  debounceQuery() {
    if (this.allowQuery) {
      this.allowQuery = false;
      setTimeout(() => {
        this.allowQuery = true;
      }, this.debounce);
      return true;
    } else return false;
  }

  ngOnDestroy() {
    this.unSubscribe$.next(true);
    this.unSubscribe$.complete();
  }
}
