import { UntypedFormGroup, UntypedFormControl, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';

import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import omit from 'lodash/omit';

import { ActivationCodeModalComponent } from '../activation-code-modal/activation-code-modal.component';
import { ZoneService } from 'src/app/shared/services/zone.service';
import { Channel, Zone } from 'src/app/shared/models/models';
import { switchMap, tap } from 'rxjs/operators';
import { from } from 'rxjs';
import { MediaTypeCatalog, MediaTypes, SNACK_BAR_DURATION } from 'src/app/shared/models/consts';
import { PlaylistService } from 'src/app/shared/services/playlist.service';

interface DialogData {
  venueId: number;
  zone?: Zone;
}

@Component({
  selector: 'app-create-edit-zone',
  templateUrl: './create-edit-zone.component.html',
  styleUrls: ['./create-edit-zone.component.scss']
})
export class CreateEditZoneComponent implements OnInit {
  public isSaving = false;
  public form: UntypedFormGroup;
  public mediaTypes = MediaTypeCatalog;
  public channelTypes: Channel[];

  constructor(
    public dialogRef: MatDialogRef<CreateEditZoneComponent>,
    private zoneService: ZoneService,
    private dialog: MatDialog,
    private playlistService: PlaylistService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit() {

    this.form = new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required]),
      storeName: new UntypedFormControl('', []),
      address1: new UntypedFormControl('', [Validators.required]),
      address2: new UntypedFormControl('', []),
      postcode: new UntypedFormControl('', []),
      city: new UntypedFormControl('', []),
      state: new UntypedFormControl('', []),
      country: new UntypedFormControl('', [Validators.required]),
      mediaType: new UntypedFormControl('', [Validators.required]),
      channelId: new UntypedFormControl(-1)
    }, {validators: channelTypeRequired});

    if (!this.playlistService.channels) {
      this.playlistService.getChannelTypes()
      .subscribe((channels) => {
        this.channelTypes = channels;
      });
    } else {
      this.channelTypes = this.playlistService.channels;
    }

    if (this.data && this.data.zone) {
      this.form.patchValue({
        ...this.data.zone,
        channelId: !!this.data.zone.subscribedToChannels && this.data.zone.subscribedToChannels.length ?
          this.data.zone.subscribedToChannels[0].id : -1
      });
    }
  }

  get MediaTypes() {
    return MediaTypes;
  }

  get mediaTypeControl() {
    return this.form.controls.mediaType.value;
  }

  saveZone() {
    if (this.form.invalid) {
      return;
    }

    this.isSaving = true;

    if (this.data && this.data.zone) {
      // update Zone
      this.zoneService.updateZone(this.data.zone.id, omit(this.form.value, ['storeName']), this.data.venueId)
        .subscribe((zone: Zone) => {
          this.dialogRef.close(zone);
          this._snackBar.open(
            `Zone’s changes successfully applied`,
            null,
            { duration: SNACK_BAR_DURATION }
          );
        });
    } else {
      // create Zone
      this.zoneService.createZone(
        this.form.value.name,
        this.form.value.address1,
        this.form.value.address2,
        this.form.value.postcode,
        this.form.value.city,
        this.form.value.state,
        this.form.value.country,
        this.data.venueId,
        this.form.value.mediaType,
        this.form.value.channelId
      )
        .pipe(
          switchMap((zone: Zone) => {
            this.dialogRef.close(zone);
            return from(this.zoneService.resetActivationCodeUsingDevCredentials(zone.id));
          }),
          tap((activationCode) => {
            this.dialog.open(ActivationCodeModalComponent, {
              data: {
                activationCode
              }
            });
          })
        )
        .subscribe();
    }
  }
}

export const channelTypeRequired: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
  const mediaType = control.get('mediaType');
  const channelId = control.get('channelId');

  return mediaType.value === MediaTypes.CHANNEL && (!channelId || channelId.value < 0 )? { channelTypeRequired: true } : null;
};