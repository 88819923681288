import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { Playlist } from '../../models/models';
import { PrepareDataService } from 'src/app/shared/services/prepare-data.service';

@Component({
  selector: 'app-playlist-preview-item',
  templateUrl: './playlist-preview-item.component.html',
  styleUrls: ['./playlist-preview-item.component.scss']
})
export class SharedPlaylistPreviewItemComponent implements OnInit {
  @Input() playlist: Playlist;
  @Input() isAdded: boolean;
  @Input() isSelected: boolean;
  @Input() isRemovable: boolean;
  @Input() isSelectable?: boolean;

  @Output() selected = new EventEmitter<number>();
  @Output() removed = new EventEmitter<number>();

  constructor(
    private prepareService: PrepareDataService
  ) { }

  ngOnInit() {
    if (!('artwork' in this.playlist) ||(Array.isArray(this.playlist.artwork) && this.playlist.artwork.length < 1)) {
      //console.log("pp", this.playlist, this.prepareService.playlistArtworkConn(this.playlist));
      this.playlist.artwork = this.prepareService.playlistArtworkConn(this.playlist);
    }
  }

  selectPlaylist() {
    if (!this.isAdded) {
      this.selected.emit(this.playlist.id);
    }
  }

  remove() {
    this.removed.emit(this.playlist.id);
  }

  coverToSize(url) {
    return this.prepareService.coverToSize(url, '200x200');
  }
}
