<div class="dialog-header">
  <h2 mat-dialog-title>Add Track to Playlist</h2>
  <button mat-dialog-close class="btn close-dialog-btn"></button>
</div>

<mat-dialog-content>

  <ng-template #spinner>
    <app-spinner [isLoading]="isLoading" [errored]="errored" [minimal]="true"></app-spinner>
  </ng-template>

  <div *ngIf="!isLoading; else spinner" class="dialog-content-wrapper">

    <a (click)="createNewPlaylist()" class="create-playlist-link">
      <div class="icon icon-plus"></div>
      <span class="link-label">Create New Playlist</span>
    </a>

    <div *ngIf="playlists && playlists.length > 0; else noPlaylists">
      <input
        #searchInput
        (keyup)="searchPlaylist(searchInput.value.trim())"
        placeholder="Search a Playlist"
        class="input-search"
        type="text"
      />

      <div *ngIf="filteredPlaylists.length > 0; else noPlaylistsFounded" class="playlist-list-container">
        <ng-scrollbar>
          <ul class="playlist-list">
            <li
              *ngFor="let playlist of filteredPlaylists"
              class="playlist-item-container"
            >
              <app-playlist-preview-item
                [playlist]="playlist"
                [isAdded]="data.playlistsWithTrack.includes(playlist.id)"
                [isSelected]="selectedPlaylistsId.has(playlist.id)"
                (selected)="onPlaylistSelected($event)"
              >
              </app-playlist-preview-item>
            </li>
          </ul>
        </ng-scrollbar>
      </div>

      <ng-template #noPlaylistsFounded>
        <div class="no-playlists-found-message-container">
          <div class="message-wrapper">
            <div class="no-playlists-found-image"></div>
            <p>No Playlists found</p>
          </div>
        </div>
      </ng-template>

    </div>
    <ng-template #noPlaylists>
      <div class="no-playlists-message text-uppercase">
        No Playlists available
      </div>
    </ng-template>
  </div>

</mat-dialog-content>

<div mat-dialog-actions>
  <button mat-dialog-close class="btn cancel-btn">Cancel</button>
  <button (click)="addTrackToPlaylists()" class="btn add-btn">Add</button>
</div>

