<div (click)="goToPlaylist()" class="playlist-cover-container">

  <div class="cover-wrapper">
    <div class="quarter-images" *ngIf="playlist.artwork?.length === 4">
      <img
        class="quarter-img"
        *ngFor="let artwork of playlist.artwork"
        [src]="artwork"
        appDefault
        alt="Playlist cover"
      />
    </div>

    <img
      *ngIf="playlist.artwork?.length < 4"
      class="cover"
      appDefault
      [src]="(playlist.artwork && playlist.artwork[0]) ? coverToSize(playlist.artwork[0]) : '/assets/images/default-cover.jpg'"
      alt="Playlist cover"
    />
  </div>

  <div
    class="status"
    [class.published]="playlist.isPublished"
    *ngIf="from === 'discover'"
    [matTooltip]="playlist.isPublished ? 'Published' : 'Unpublished'"
    matTooltipPosition='above'
  >
  </div>

  <div class="controls-wrapper">
    <button (click)="$event.stopPropagation()" class="menu-button" [matMenuTriggerFor]="appMenu">
      <div class="menu">
        <div class="cover-blur"></div>
        <div class="cover-dark"></div>
        <div class="custom-icon icon-more"></div>
      </div>
    </button>

    <mat-menu class="playlist-menu" #appMenu="matMenu">
      <div *ngIf="!playlist.isInLibrary && showInLibraryMenu" (click)="addToLibrary()" class="menu-item">
        <div class="menu-item-icon icon-opened-book"></div>
        <span class="menu-item-label">Add To Library</span>
      </div>

      <div *ngIf="playlist.isInLibrary && showInLibraryMenu" (click)="deletePlaylist()" class="menu-item">
        <div class="menu-item-icon icon-opened-book"></div>
        <span class="menu-item-label">In Library</span>
        <img class="check-icon" src="../../assets/icons/checkmark.svg">
      </div>

      <div *ngxPermissionsOnly="'BUCKET_PERMISSION'">
        <div (click)="addToBucket()" class="menu-item" *ngIf="from !== 'discover'">
          <div class="menu-item-icon icon-bucket"></div>
          <span class="menu-item-label">Add To Bucket</span>
        </div>
      </div>

      <div (click)="editPlaylist()" class="menu-item" *ngIf="showEdit">
        <div class="menu-item-icon icon-edit-empty"></div>
        <span class="menu-item-label">Edit</span>
      </div>

      <div *ngxPermissionsOnly="'DELETE_PLAYLIST_PERMISSION'">
        <div (click)="deletePlaylist()" class="menu-item" *ngIf="showDelete">
          <div class="menu-item-icon icon-delete"></div>
          <span class="menu-item-label">Delete</span>
        </div>
      </div>

      <div class="menu-item" *ngIf="playlist.tracks?.length > 0" (click)="addToQueue()">
        <div class="menu-item-icon icon-queue"></div>
        <span class="menu-item-label">Add To Playing Queue</span>
      </div>
    </mat-menu>
  </div>

</div>

<div [title]="playlist.name" class="playlist-name text-truncate">{{ playlist.name }}</div>
<div class="playlist-genre text-truncate">{{ playlist.generalGenre }}</div>
