import { Action, State, StateContext, Selector, createSelector, Store } from '@ngxs/store';
import { SearchAction } from './search.actions';
import { catchError, tap } from 'rxjs/operators';
import { Track, Playlist, Album, Artist, SearchMeta } from '../../models/models';
import { SearchService } from '../../services/search.service';
import { StateReset } from 'ngxs-reset-plugin';
import { SearchValues } from '../../models/consts';
import { Searches } from '../../models/inner-models';
import { PlaylistPrepare } from '../../utils/prepare-playlist';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';

type Context = StateContext<SearchStateModel>;

export interface SearchStateModel {
  term: string;
  isSearchFinished: boolean;
  isPageLoaded?: boolean;
  errored?: boolean;
  tracks?: Array<Track>;
  artists?: Array<Artist>;
  playlists?: Array<Playlist>;
  videoPlaylists?: Array<Playlist>;
  channels?: Array<Playlist>;
  albums?: Array<Album>;
  meta: SearchMeta;
}

@State<SearchStateModel>({
  name: 'search',
  defaults: {
    term: 'string',
    isSearchFinished: false,
    tracks: null,
    artists: null,
    playlists: null,
    videoPlaylists: null,
    albums: null,
    channels: null,
    meta: null
  }
})
@Injectable()
export class SearchState {

  constructor(
      private searchService: SearchService,
      private store: Store
    ) {}

  @Action(SearchAction.GetAllByTerm)
  fetch({patchState, getState}: StateContext<SearchStateModel>, { term }: SearchAction.GetAllByTerm) {
    const state = getState();
    if (state.term !== term) {
      this.store.dispatch(new StateReset(SearchState));
      patchState({
        term: term
      })
    }

    return this.searchService.searchCatalog(term)
      .pipe(
        tap((searchResults) => {
					patchState({
						isSearchFinished: true,
            tracks: searchResults.tracks || [],
            playlists: searchResults.playlists.map(item => {
              item.artwork = PlaylistPrepare.playlistArtwork(item);
              return item;
            }) || [],
            videoPlaylists: searchResults.videoPlaylists.map(item => {
              item.artwork = PlaylistPrepare.playlistArtwork(item);
              return item;
            }) || [],
            channels: searchResults.channelPlaylists.map(item => {
              item.artwork = PlaylistPrepare.playlistArtwork(item);
              return item;
            }) || [],
            albums: searchResults.albums || [],
            artists: searchResults.artists || [],
            meta: searchResults.meta
          })
        }),
        catchError(() => {
          patchState({ errored: true })
          return of([]);
        })
      );
  }

  @Action(SearchAction.GetMoreByScope)
  getMore({getState, patchState}: StateContext<SearchStateModel>, { scope, count }: SearchAction.GetMoreByScope) {
    const state = getState();
    const currentEntity = (scope !== 'CHANNEL_PLAYLIST' && scope !== 'VIDEO_PLAYLIST') ? (scope.toLowerCase() + 's')
      : scope === 'CHANNEL_PLAYLIST' ? 'channels' : 'videoPlaylists';

    patchState({isPageLoaded: false})
    return this.searchService.searchCatalog(state.term, scope, count || 20, state[currentEntity].length)
      .pipe(
        tap((searchResults) => {
          patchState({
            isPageLoaded: true,
            tracks: scope === 'TRACK' ? [...state.tracks, ...searchResults.tracks] : [...state.tracks],
            albums: scope === 'ALBUM' ? [...state.albums, ...searchResults.albums] : [...state.albums],
            artists: scope === 'ARTIST' ? [...state.artists, ...searchResults.artists] : [...state.artists],
            playlists:
              scope === 'PLAYLIST' ?
              [...state.playlists, ...searchResults.playlists.map(item => {
                item.artwork = PlaylistPrepare.playlistArtwork(item);
                return item;
              })] :
              [...state.playlists],
            videoPlaylists:
              scope === 'VIDEO_PLAYLIST' ?
              [...state.videoPlaylists, ...searchResults.videoPlaylists.map(item => {
                item.artwork = PlaylistPrepare.playlistArtwork(item);
                return item;
              })] :
              [...state.videoPlaylists],
            channels:
              scope === 'CHANNEL_PLAYLIST' ?
              [...state.channels, ...searchResults.channelPlaylists.map(item => {
                item.artwork = PlaylistPrepare.playlistArtwork(item);
                return item;
              })] :
              [...state.channels]
          })
        }),
        catchError(() => {
          patchState({ errored: true })
          return of([]);
        })
      );
  }

  @Selector()
  static getGeneralResult(state: SearchStateModel): Searches {
    return {
      tracks: state.tracks.length > 5 ? state.tracks.slice(0, 5) : state.tracks,
      albums: state.albums.length > 5 ? state.albums.slice(0, 5) : state.albums,
      artists: state.artists.length > 5 ? state.artists.slice(0, 5) : state.artists,
      playlists: state.playlists.length > 5 ? state.playlists.slice(0, 5) : state.playlists,
      videoPlaylists: state.videoPlaylists.length > 5 ? state.videoPlaylists.slice(0, 5) : state.videoPlaylists,
      channels: state.channels.length > 5 ? state.channels.splice(0, 5) : state.channels
    };
  }

  @Selector()
  static isFinishedSearch(state: SearchStateModel): boolean {
    return state.isSearchFinished;
  }

  @Selector()
  static isPageLoaded(state: SearchStateModel): boolean {
    return state.isPageLoaded;
  }

  @Selector()
  static errored(state: SearchStateModel): boolean {
    return state.errored;
  }

  @Selector()
  static meta(state: SearchStateModel): SearchMeta {
    return state.meta;
  }

  static resultsByScope(scope: SearchValues) {
    return createSelector([SearchState], (state: SearchStateModel) => {
      if (scope === 'TRACK') {
        return state.tracks;
      }
      if (scope === 'ALBUM') {
        return state.albums;
      }
      if (scope === 'ARTIST') {
        return state.artists;
      }
      if (scope === 'PLAYLIST') {
        return state.playlists;
      }
      if (scope === 'VIDEO_PLAYLIST') {
        return state.videoPlaylists;
      }
      if (scope === 'CHANNEL_PLAYLIST') {
        return state.channels;
      }
    });
  }
}
