import { Action, State, StateContext, Selector, Store } from '@ngxs/store';
import { VideoPlaylistAction } from './video-playlists.actions';
import { PlaylistService } from '../../services/playlist.service';
import { Playlist, PaginationInfo } from '../../models/models';
import { tap, map, filter, mergeMap, switchMap, catchError } from 'rxjs/operators';
import { BucketAction } from '../buckets/buckets.actions';
import { PlaylistPrepare } from '../../utils/prepare-playlist';
import { MediaTypes } from '../../models/consts';
import { StateReset } from 'ngxs-reset-plugin';
import { empty, from, of } from 'rxjs';
import { Injectable } from '@angular/core';

type Context = StateContext<VideoPlaylistsStateModel>;

export interface VideoPlaylistsStateModel {
  playlists: Playlist[];
  isPlaylistsLoaded: boolean;
  errored: boolean;
  hasNextPage: boolean;
  nextCursor: string;
}

@State<VideoPlaylistsStateModel>({
  name: 'videoPlaylists',
  defaults: {
    playlists: null,
    isPlaylistsLoaded: false,
    errored: false,
    hasNextPage: false,
    nextCursor: null
  }
})
@Injectable()
export class VideoPlaylistsState {

  constructor(
      private playlistService: PlaylistService,
      private store: Store
    ) {}

  @Action(VideoPlaylistAction.GetMyVideoPlaylists)
  fetch({patchState}: StateContext<VideoPlaylistsStateModel>) {
    return this.playlistService.getPlaylistsConnection(null, 20, MediaTypes.VIDEO)
      .pipe(
        tap((paginationInfo: PaginationInfo) => {
          patchState({
            hasNextPage: paginationInfo.pageInfo.hasNextPage,
            nextCursor: paginationInfo.pageInfo.endCursor
          });
        }),
        map(({edges}) => edges.map(({node}) => node)),
        tap((playlists: Playlist[]) => {

          // add artwork to playlist or create collage
          playlists = playlists.map(item => {
            item.artwork = PlaylistPrepare.videoPlaylistArtwork(item);
            return item;
          });
          patchState({
            playlists,
            isPlaylistsLoaded: true
          });
        }),
        catchError(() => {
          patchState({ errored: true });
          return of([]);
        })
      );
  }

  @Action(VideoPlaylistAction.GetMoreVideoPlaylists)
  getMore({getState, patchState}: StateContext<VideoPlaylistsStateModel>, { count }: VideoPlaylistAction.GetMoreVideoPlaylists) {
    const state = getState();
    return this.playlistService.getPlaylistsConnection(state.nextCursor, count || 20, MediaTypes.VIDEO)
      .pipe(
        tap((paginationInfo: PaginationInfo) => {
          patchState({
            hasNextPage: paginationInfo.pageInfo.hasNextPage,
            nextCursor: paginationInfo.pageInfo.endCursor
          });
        }),
        map(({edges}) => edges.map(({node}) => node)),
        tap((playlists: Playlist[]) => {
          // add artwork to playlist or create collage
          playlists = playlists.map(item => {
            item.artwork = PlaylistPrepare.videoPlaylistArtwork(item);
            return item;
          });

          patchState({
            // @ts-ignore
            playlists: [...state.playlists, ...playlists]
          });
        }),
        catchError(() => {
          patchState({ errored: true });
          return of([]);
        })
      );
  }

  @Action(VideoPlaylistAction.AddVideoPlaylist)
  add({getState, patchState}: StateContext<VideoPlaylistsStateModel>, { playlist }: VideoPlaylistAction.AddVideoPlaylist) {
    const state = getState();
    return this.playlistService.addPlaylistToLibrary(playlist.id)
    .pipe(
      filter((result) => !!result),
      tap(() => {
        if (state.isPlaylistsLoaded) {
          playlist = {
            ...playlist,
            isInLibrary: true
          };
          const playlists = (state.playlists) ? PlaylistPrepare.sortPlaylists([...state.playlists, playlist]) : [playlist];
          patchState({
            playlists
          });
        }
      })
    );
  }

  @Action(VideoPlaylistAction.AddCatalogPlaylistsToLibrary)
  addMultiplePlaylists({getState, patchState}: StateContext<VideoPlaylistsStateModel>, { playlistsIds }: VideoPlaylistAction.AddCatalogPlaylistsToLibrary) {
    const state = getState();
    return this.playlistService.addCatalogPlaylistsToLibrary(playlistsIds)
    .pipe(
      filter((result) => !!result),
      tap((catalogPlaylists: Playlist[]) => {
        if (state.isPlaylistsLoaded) {
          const newPlaylists = catalogPlaylists.map((playlist) => ({
            ...playlist,
            isInLibrary: true
          }));
          const playlists = (state.playlists) ? PlaylistPrepare.sortPlaylists([...state.playlists, ...newPlaylists]) : newPlaylists;
          patchState({
            playlists
          });
        }
      })
    );
  }

  @Action(VideoPlaylistAction.DeleteVideoPlaylist)
  delete({getState, patchState}: StateContext<VideoPlaylistsStateModel>, { id }: VideoPlaylistAction.DeleteVideoPlaylist) {
    const state = getState();
    return this.playlistService.deleteLibraryPlaylist(id)
    .pipe(
      tap(() => {
        if (!!state.playlists) {
          patchState({
            playlists: state.playlists.filter((item) => item.id !== id)
          });
        }
      })
    );
  }

  @Action(VideoPlaylistAction.DeleteMultiplePlaylists)
  deleteMultiple({getState, patchState}: StateContext<VideoPlaylistsStateModel>, { ids }: VideoPlaylistAction.DeleteMultiplePlaylists) {
    const state = getState();
    return this.playlistService.deletePlaylists(ids)
    .pipe(
      tap(() => {
        patchState({
          playlists: state.playlists.filter(({id}) => !ids.includes(id))
        });
      })
    );
  }

  @Action(VideoPlaylistAction.RemovePlaylistFromLibrary)
  removeFromLibrary({getState, patchState}: StateContext<VideoPlaylistsStateModel>, { id }: VideoPlaylistAction.RemovePlaylistFromLibrary) {
    const state = getState();
    return this.playlistService.removeFromLibrary(id)
    .pipe(
      tap((removedId: number) => {
        if (state.playlists && removedId) {
          patchState({
            playlists: state.playlists.filter((item) => item.id !== removedId)
          });
        }
      })
    );
  }

  @Action(VideoPlaylistAction.RemoveMultiplePlaylistsFromLibrary)
  removeMultipleFromLib({getState, patchState}: StateContext<VideoPlaylistsStateModel>, { ids }: VideoPlaylistAction.RemoveMultiplePlaylistsFromLibrary) {
    const state = getState();
    return this.playlistService.removeMultipleCatalogPlFromLibrary(ids)
    .pipe(
      tap((removedPlaylists: number[]) => {
        if (state.playlists && state.playlists.some(({id}) => removedPlaylists.includes(id))) {

          patchState({
            playlists: state.playlists.filter(({id}) => !removedPlaylists.includes(id))
          });
        }
      })
    );
  }

  @Action(VideoPlaylistAction.UpdateState)
  updateState({getState, dispatch}: StateContext<VideoPlaylistsStateModel>) {
    const state = getState();

    dispatch (new StateReset(VideoPlaylistsState))
    .pipe(
      switchMap(() => {
        if(!!state.isPlaylistsLoaded) {
          return dispatch(new VideoPlaylistAction.UpdateState())
        }
        return empty()
      })
    )
    .subscribe();
  }

  @Selector()
  static videoPlaylists(state: VideoPlaylistsStateModel): Playlist[] {
    return state.playlists;
  }

  @Selector()
  static isPlaylistsLoaded(state: VideoPlaylistsStateModel): boolean {
    return state.isPlaylistsLoaded;
  }

  @Selector()
  static errored(state: VideoPlaylistsStateModel): boolean {
    return state.errored;
  }

  @Selector()
  static hasNext(state: VideoPlaylistsStateModel): boolean {
    return state.hasNextPage;
  }
}

