import { Component, OnInit, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

interface DialogData {
  activationCode: string;
}

@Component({
  selector: 'app-activation-code-modal',
  templateUrl: './activation-code-modal.component.html',
  styleUrls: ['./activation-code-modal.component.scss']
})
export class ActivationCodeModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.copyListener = this.copyListener.bind(this);
  }

  ngOnInit() {}

  copyCode() {
    document.addEventListener('copy', this.copyListener);
    document.execCommand('copy');
  }

  copyListener(e: ClipboardEvent) {
    e.clipboardData.setData('text/plain', this.data.activationCode);
    e.preventDefault();
    document.removeEventListener('copy', this.copyListener);
  }
}
