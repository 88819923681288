<div class="track-item-wrapper" [class.selected]="isSelected">
  <div class="track-cover">
    <img
      appDefault
      [src]="track.artwork?.artworkUrl100 || '/assets/images/default-cover.jpg'"
      alt="Track cover"
    />
  </div>
  <div class="track-details">
    <div class="track-title text-truncate">
      <span [title]="track.name">{{ track.artist?.name ? track.artist?.name + ' - ' : '' }}{{ track.name }}</span>
    </div>
    <div class="track-album text-truncate" *ngIf="track?.album?.name">
      <span [title]="track?.album?.name">{{ track.album.name }}</span>
    </div>
  </div>
  <div class="track-duration">
    <span> {{trackDuration}}</span>
  </div>
</div>