import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';

import { NgScrollbarModule } from 'ngx-scrollbar';
import { SpinnerModule } from 'src/app/system/spinner/spinner.module';

import { SharedPlaylistPreviewItemModule } from '../playlist-preview-item/playlist-preview-item.module';
import { SharedPlaylistListDialogComponent } from './playlist-list-dialog.component';

@NgModule({
    declarations: [
        SharedPlaylistListDialogComponent
    ],
    imports: [
        CommonModule,
        MatMenuModule,
        MatDialogModule,
        MatSnackBarModule,
        NgScrollbarModule,
        SharedPlaylistPreviewItemModule,
        SpinnerModule
    ],
    exports: [
        SharedPlaylistListDialogComponent
    ]
})
export class SharedPlaylistDialogModule { }
