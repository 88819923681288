import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { ImageUploadDialogComponent } from '../../../shared/components/image-upload-dialog/image-upload-dialog.component';
import { GoogleMapsAddress } from '../../../shared/directives/google-places-autocomplete/models/address';
import { ImageUploadService } from '../../../shared/services/image-upload.service';
import { GoogleMapsService } from '../../../shared/services/google-maps.service';
import { AddressService } from '../../../shared/services/address.service';
import { Address, Venue } from 'src/app/shared/models/models';
import { AuthService } from '../../../shared/services/auth.service';
import { finalize, first, switchMap, tap } from 'rxjs/operators';
import { AlertDialogComponent } from 'src/app/shared/components/alert-dialog/alert-dialog.component';
import { VenuePayload } from 'src/app/shared/models/inner-models';
import { Store } from '@ngxs/store';
import { VenueListAction } from 'src/app/shared/store/venue-list/venue-list.actions';
import { VenueListState } from 'src/app/shared/store/venue-list/venue-list.state';
import { forkJoin, from, of } from 'rxjs';

interface DialogData {
  venue?: Venue;
}

@Component({
  selector: 'app-create-edit-venue',
  templateUrl: './create-edit-venue.component.html',
  styleUrls: ['./create-edit-venue.component.scss']
})
export class CreateEditVenueComponent implements OnInit, OnDestroy {
  private bannerImageFile: any;
  private logoImageFile: any;
  private venueAddress: Address;
  public googlePlaceOptions = {
    types: ['address']
  };
  public bannerImage: any;
  public logoImage: any;
  public venueForm: UntypedFormGroup;
  public venueOwnerEmail: string = "";
  public statuses = [
    'Online',
    'Offline',
  ];
  public statusAliases = {
    Online: true,
    Offline: false,
    true: 'Online',
    false: 'Offline'
  };

  public isSending = false;

  constructor(
    private dialogRef: MatDialogRef<CreateEditVenueComponent>,
    private imageUploadService: ImageUploadService,
    private googleMapsService: GoogleMapsService,
    private addressService: AddressService,
    private dialog: MatDialog,
    public authService: AuthService,
    public store: Store,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
  }

  ngOnInit() {
    this.venueForm = new UntypedFormGroup({
      venueLogo: new UntypedFormControl(''),
      venueBanner: new UntypedFormControl(''),
      venueAddress: new UntypedFormControl(''),
      venueLatitude: new UntypedFormControl(''),
      venueLongitude: new UntypedFormControl(''),
      venueName: new UntypedFormControl('', [Validators.required]),
      venueEmail: new UntypedFormControl('', [Validators.email]),
      venueContactName: new UntypedFormControl(''),
      venueContactNumber: new UntypedFormControl('', [Validators.pattern(/[^a-zA-Z]+/g)]),
      venueStatus: new UntypedFormControl(''),
    });

    if (this.data && this.data.venue) {
      this.venueForm.setValue({
        venueLogo: this.data.venue.logoImageUrl,
        venueBanner: this.data.venue.bannerImageUrl,
        venueAddress: this.addressService.getFormattedAddress(this.data.venue.address),
        venueLatitude: this.data.venue.latitude || '',
        venueLongitude: this.data.venue.longitude || '',
        venueName: this.data.venue.name,
        venueEmail: this.data.venue.owner.userEmail,
        venueContactName: this.data.venue.contact.name,
        venueContactNumber: this.data.venue.contact.phoneNumber,
        venueStatus: this.statusAliases[String(this.data.venue.isActive)],
      });
      this.venueOwnerEmail = this.data.venue.owner.userEmail;
    }
  }

  uploadVenueLogo() {
    this.dialog.open(ImageUploadDialogComponent)
      .afterClosed()
      .subscribe((data) => {
        if (data) {
          this.logoImage = data.image;
          this.logoImageFile = data.file;
        }
      });
  }

  uploadVenueBanner() {
    this.dialog.open(ImageUploadDialogComponent, { data: { aspectRatio: 1.3 } })
      .afterClosed()
      .subscribe((data) => {
        if (data) {
          this.bannerImage = data.image;
          this.bannerImageFile = data.file;
        }
      });
  }

  selectStatus(status) {
    this.venueForm.patchValue({
      venueStatus: status
    });
  }

  saveVenue() {
    const payload: VenuePayload = {
      name: this.venueForm.value.venueName,
      contact: {
        name: this.venueForm.value.venueContactName,
        phoneNumber: this.venueForm.value.venueContactNumber
      },
      email: this.venueForm.value.venueEmail,
      latitude: String(this.venueForm.value.venueLatitude),
      longitude: String(this.venueForm.value.venueLongitude),
      isActive: this.statusAliases[this.venueForm.value.venueStatus],
      address: this.venueAddress
    };
    this.isSending = true;
    if (this.data && this.data.venue) {
      this.store.dispatch(new VenueListAction.EditVenue(this.data.venue.id, payload))
        .pipe(
          tap(() => {
            const isNewOwner = this.venueOwnerEmail !== this.venueForm.value.venueEmail;
            this.dialogRef.close();
            this.dialog.open(AlertDialogComponent, {
              data: {
                type: 'success',
                message: isNewOwner ?
                    'New Venue owner was set and the invitation has been sent to his email.'
                  :
                    'Vanue successfully updated.'
              }
            });
          }),
          switchMap(() => {
            const id = this.store.selectSnapshot(VenueListState.lastVenueActionedId);
            return this.saveVenueImages(id);
          }),
          finalize(() => this.isSending = false)
        )
        .subscribe();
    } else {
      this.store.dispatch(new VenueListAction.AddVenue(payload))
        .pipe(
          tap(() => {
            const isNewOwner = this.venueForm.value.venueEmail !== "";
            this.dialogRef.close();
            this.dialog.open(AlertDialogComponent, {
              data: {
                type: 'success',
                message: isNewOwner ?
                    'New Venue owner was set and the invitation has been sent to his email.'
                  :
                    'Venue successfully created.'
              }
            });
          }),
          switchMap(() => {
            const id = this.store.selectSnapshot(VenueListState.lastVenueActionedId);
            return this.saveVenueImages(id);
          }),
          finalize(() => this.isSending = false)
        )
        .subscribe();
    }
  }

  private saveVenueImages(venueId) {
    const observables = [];

    if (this.logoImageFile) {
      const observable = from(this.imageUploadService.uploadVenueLogo(this.logoImageFile, venueId))
      .pipe(
        switchMap(() => this.store.dispatch(new VenueListAction.UpdateVenueLogo(venueId)))
      );

      observables.push(observable);
    }

    if (this.bannerImageFile) {
      const observable = from(this.imageUploadService.uploadVenueBanner(this.bannerImageFile, venueId))
      .pipe(
        switchMap(() => this.store.dispatch(new VenueListAction.UpdateVenueBanner(venueId)))
      );

      observables.push(observable);
    }

    return forkJoin(observables);
  }

  handleAddressChange(address: GoogleMapsAddress) {
    const addressData = this.googleMapsService.getAddressData(address);
    const venueAddress = this.addressService.getFormattedAddress(addressData);
    const { line1, postcode, state, city, country } = addressData;

    this.venueAddress = { line1, postcode, state, city, country };

    this.venueForm.patchValue({
      venueLatitude: addressData.latitude,
      venueLongitude: addressData.longitude,
      venueAddress
    });
  }

  cleanUpGoogleMapsPlacesAutocomplete() {
    const placesAutocompleteContainers = document.getElementsByClassName('pac-container');

    Array.from(placesAutocompleteContainers).forEach((placesAutocompleteContainer) => placesAutocompleteContainer.remove());
  }

  ngOnDestroy() {
    this.cleanUpGoogleMapsPlacesAutocomplete();
  }
}
