<div class="artist-picture-container">
  <img
    [src]="(artist.artwork && artist.artwork.artworkUrl100) ? artist.artwork.artworkUrl100 : '/assets/images/user-default-black.png'"
    appDefault="/assets/images/user-default-black.png"
    class="artist-picture rounded-circle"
    alt="Artist image"
  />
</div>
<div class="artist-details-container d-flex flex-column justify-content-between">
  <div [title]="artist.name" class="artist-name" style="-webkit-box-orient: vertical;">{{ artist.name }}</div>
  <div class="artist-track-count">{{ artist.countOfTracks }} {{ artist.countOfTracks === 1 ? 'Track' : 'Tracks' }}</div>
  <div class="artist-like-container d-flex align-items-center">
    <div
      [ngClass]="{
        'icon-heart-filled': artist.likedByMe,
        'icon-heart-empty icon-without-hover': !artist.likedByMe
      }"
      class="icon"
    >
    </div>
    <span class="like-count">{{ artist.likes }}</span>
  </div>
</div>
