<div class="miniplayers-carousel nav-container position-fixed" *ngIf="showMiniPlayer">
  <owl-carousel
    #owlElement
    [options]="{
      items: 1,
      dots: false,
      mouseDrag: false,
      nav: true,
      navContainer: '.miniplayers-carousel-nav',
      navClass: ['btn owl-prev-player', 'btn owl-next-player'],
      itemClass: 'owl-item',
      onChanged: onZoneChanged
    }"
    [items]="zoneIdArr"
  >
    <div *ngFor="let id of zoneIdArr; let i = index" class="player-group-wrapper">
      <div class="player-content d-flex justify-content-between align-items-center" *ngIf="!!zonesWithPlayer[i]">
        <div class="slider-wrapper position-absolute w-75">
          <mat-slider [min]="0" [max]="duration" [value]="playerPosition" class="player-slider w-100"></mat-slider>
        </div>

          <div class="player-details-container d-flex justify-content-between align-items-center">
            <div class="track-details-container d-flex align-items-center w-100">
              <div class="album-cover-wrapper">
                <ng-template #defaultCover>
                  <img
                    src="../../../assets/images/default-cover.jpg"
                    alt="Default album cover"
                    class="album-cover"
                  />
                </ng-template>
                <img
                  *ngIf="zonesWithPlayer[i].currentTrack?.artwork?.artworkUrl100; else defaultCover"
                  [src]="zonesWithPlayer[i].currentTrack?.artwork.artworkUrl100"
                  appDefault
                  alt="Album cover"
                  class="album-cover"
                />
              </div>

              <div class="track-details d-flex flex-column ">
                <p [title]="zonesWithPlayer[i].currentTrack?.name" class="track-name text-truncate m-0">{{ zonesWithPlayer[i].currentTrack?.name }}</p>
                <p
                  *ngIf="zonesWithPlayer[i].currentTrack?.artist?.name"
                  [title]="zonesWithPlayer[i].currentTrack?.artist?.name"
                  class="artist-name text-truncate"
                >
                  {{ zonesWithPlayer[i].currentTrack?.artist?.name }}
                </p>
              </div>
            </div>
          </div>

          <div class="player-actions-container d-flex justify-content-center align-items-center">
            <div
              (click)="handleLikeClick()"
              [ngClass]="{
                'icon-heart-empty': !zonesWithPlayer[i].currentTrack.heartState?.like,
                'icon-heart-filled': zonesWithPlayer[i].currentTrack.heartState?.like
              }"
              class="icon"
            >
            </div>
            <div
              (click)="handleDislikeClick()"
                [ngClass]="{
                'icon-heart-broken': zonesWithPlayer[i].currentTrack.heartState?.like === null || zonesWithPlayer[i].currentTrack.heartState?.like,
                'icon-heart-broken-filled': zonesWithPlayer[i].currentTrack.heartState?.like === false
              }"
              class="icon"
            >
            </div>

            <div class="player-controls-container d-flex align-items-center">
              <button (click)="playPause()" class="btn player-play-btn rounded-circle d-flex justify-content-center align-items-center">
                <div *ngIf="!isPlaying; else pauseIcon" class="icon icon-play"></div>
                <ng-template #pauseIcon>
                  <div class="icon icon-pause"></div>
                </ng-template>
              </button>
              <button (click)="playNextTrack()" class="btn player-next-btn">
                <div class="icon icon-next-track"></div>
              </button>
            </div>

            <div class="icon icon-queue" (click)="goToQueue()"></div>


          </div>
          <!-- Disabled for now -->
          <!-- <div class="volume-controls">
            <div class="icon-wrapper">
              <img src="../../assets/icons/volume-down.svg">
            </div>

            <mat-slider class="volume-slider" (change)="onVolumeChange($event)" min="0" max="2" step="0.1" value="{{ volume }}"></mat-slider>

            <div class="icon-wrapper">
              <img src="../../assets/icons/volume-up.svg">
            </div>
          </div> -->
          <div class="nav-wrapper d-flex justify-content-end">
            <div class="miniplayers-carousel-nav nav-container"></div>
            <div [title]="zonesWithPlayer[i].name" class="zone-name text-truncate text-center">{{ zonesWithPlayer[i].name }}</div>
          </div>

	  <div class="w-25"> </div>

      </div>
    </div>
  </owl-carousel>
</div>
