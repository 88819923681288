import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RhsMenuComponent } from './rhs-menu.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { RouterModule } from '@angular/router';
import { NgxPermissionsModule } from 'ngx-permissions';
import { VenuesModule } from '../venues/venues.module';
// import { MiniplayerComponent } from './miniplayer/miniplayer.component';
// import { PlayerCommandDialogComponent } from './miniplayer/player-command-dialog/player-command-dialog.component';
import { PlayerCommandDialogComponent } from './player-item/player-command-dialog/player-command-dialog.component';
import { OwlModule } from 'ngx-owl-carousel';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { SpinnerModule } from '../spinner/spinner.module';
import { PlayerItemComponent } from './player-item/player-item.component';
import { PlayingTimeslotComponent } from './playing-timeslot/playing-timeslot.component';
import { FormatTimeModule } from '../../shared/pipes/timer.pipe';
import { TimeslotPreviewItemComponent } from './player-item/timeslot-preview-item/timeslot-preview-item.component';

//import { BucketDetailsDialogComponent } from './bucket-details-dialog/bucket-details-dialog.component';
import { SharedPlaylistPreviewItemModule } from 'src/app/shared/components/playlist-preview-item/playlist-preview-item.module';
import { SharedTrackPreviewItemModule } from 'src/app/shared/components/track-preview-item/track-preview-item.module';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { TrackListItemModule } from 'src/app/shared/components/track-list-item/track-list-item.module';
import { QueuePlaylistDialogComponent } from './queue-playlist-dialog/queue-playlist-dialog.component';
import { QueueBucketDialogComponent } from './queue-bucket-dialog/queue-bucket-dialog.component';
import { QueueTrackDialogComponent } from './queue-track-dialog/queue-track-dialog.component';
import { RhsTrackQueueComponent } from './rhs-track-queue/rhs-track-queue.component';
import { RhsZoneHistoryComponent } from './rhs-zone-history/rhs-zone-history.component';
@NgModule({
    declarations: [
        RhsMenuComponent,
        // MiniplayerComponent,
        PlayerCommandDialogComponent,
        PlayerItemComponent,
        PlayingTimeslotComponent,
        TimeslotPreviewItemComponent,
        //BucketDetailsDialogComponent,
        QueuePlaylistDialogComponent,
        QueueBucketDialogComponent,
        QueueTrackDialogComponent,
        RhsTrackQueueComponent,
        RhsZoneHistoryComponent,
    ],
    imports: [
        CommonModule,
        MatDialogModule,
        MatMenuModule,
        NgxPermissionsModule,
        RouterModule,
        OwlModule,
        MatSliderModule,
        SpinnerModule,
        VenuesModule,
        FormatTimeModule,
        SharedPlaylistPreviewItemModule,
        SharedTrackPreviewItemModule,
        NgScrollbarModule,
        TrackListItemModule,
    ],
    exports: [
        RhsMenuComponent
    ]
})
export class RhsMenuModule { }
