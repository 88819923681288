import { SearchValues } from '../../models/consts';

export namespace SearchAction {

  export class GetAllByTerm {
    static readonly type = '[Search] Get General Search';
    constructor(
      public term: string
    ) {}
  }

  export class GetMoreByScope {
    static readonly type = '[Search] Get Scoped Search';
    constructor(
      public scope: SearchValues,
      public count?: number
    ) {}
  }
}