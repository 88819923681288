import { Pipe, PipeTransform, NgModule } from '@angular/core';

@Pipe({
  name: 'humanizeDuration'
})
export class HumanizeDurationPipe implements PipeTransform {

  transform(duration: any, args?: any): any {
    const res = duration.split(':');
    let timeString = '';

    if (Number(res[0]) > 24) {
      const days = Math.trunc(Number(res[0]) / 24);

      timeString += days.toString() + ((days > 1) ? ' Days ' : ' Day ');
      res[0] = (Number(res[0]) - (days * 24)).toString();
    }

    timeString += Number(res[0]).toString() + ((Number(res[0]) > 1) ? ' Hours ' : ' Hour ');
    timeString += Number(res[1]).toString() + ' min';

    return timeString;
  }

}

@NgModule({
  imports: [],
  declarations: [HumanizeDurationPipe],
  exports: [HumanizeDurationPipe]
})
export class HumanizeDurationModule { }
