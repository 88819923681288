import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { PrepareDataService } from 'src/app/shared/services/prepare-data.service';

@Component({
  selector: 'app-album-item',
  templateUrl: './album-item.component.html',
  styleUrls: ['./album-item.component.scss']
})
export class AlbumItemComponent implements OnInit {
  @Input() album;

  constructor(
    private prepareDataService: PrepareDataService,
    private router: Router,
    private prepareService: PrepareDataService
  ) { }

  ngOnInit() {
    this.album = {
      ...this.album,
      durationString: this.prepareDataService.durationInSecondsHumanize(this.album.durationSeconds)
    };
  }

  goToAlbum() {
    this.router.navigate(['/album/', this.album.id]);
  }

  coverToSize(url) {
    return this.prepareService.coverToSize(url, '200x200');
  }
}
