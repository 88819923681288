<div *ngIf="addTracksMode" class="add-tracks-section">
  <div class="dialog-header">
    <!-- <button (click)="backToTrackDetails()" class="btn back-btn"></button> -->
    <h2 mat-dialog-title>Add Tracks</h2>
    <button mat-dialog-close class="btn close-dialog-btn"></button>
  </div>

  <mat-dialog-content>

    <app-spinner *ngIf="isLoading" [isLoading]="isLoading" [errored]="errored" [minimal]="true"></app-spinner>

    <ng-container *ngIf="!isLoading">
      <input
        #searchInput
        *ngIf="tracks.length > 0"
        (keyup)="searchTrack(searchInput.value.trim())"
        placeholder="Search a Track"
        class="input-search"
        type="text"
      />

      <div *ngIf="tracks.length > 0 && filteredTracks.length === 0" class="no-tracks-found-message-container">
        <div class="message-wrapper">
          <div class="no-tracks-found-image"></div>
          <p>No Tracks found</p>
        </div>
      </div>

      <div *ngIf="filteredTracks.length > 0" class="track-list-container">
        <ng-scrollbar>
          <ul class="track-list">
            <li *ngFor="let track of filteredTracks" class="track-item-container">
               <app-track-preview-item
                (selected)="onTrackSelected($event)"
                [isSelected]="tracksToAdd.has(track.id)"
                [track]="track"
                [isSelectable]="true"
                [isAdded]="false"
              >
              </app-track-preview-item>
            </li>
          </ul>
        </ng-scrollbar>
      </div>

      <div *ngIf="filteredTracks.length === 0 && tracks.length === 0" class="no-tracks-message text-uppercase">No Tracks available</div>
    </ng-container>

  </mat-dialog-content>

  <div mat-dialog-actions>
    <button (click)="addToPlayingQueue()" class="btn save-btn">Queue</button>
  </div>
</div>


