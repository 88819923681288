<mat-dialog-content>
  <div class="activation-code-container">
    <h1 class="m-0 text-center">Activation code</h1>
    <p class="text-center">{{ data.activationCode }}</p>
  </div>
</mat-dialog-content>

<div mat-dialog-actions>
  <button mat-dialog-close class="btn" (click)="copyCode()">Copy</button>
</div>
