import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'includesOneOf'
})
export class IncludesOneOfPipe implements PipeTransform {
  transform(value: any[], variants: any[] = []): boolean {
    return value.filter((item) => variants.includes(item)).length > 0;
  }
}
