import { Component, OnInit } from '@angular/core';

import { MiniplayerService } from 'src/app/shared/services/miniplayer.service';
import { VERSION } from 'src/environments/version';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {
  public buildVersion: string;
  public showMiniPlayer: boolean;
  public year = new Date().getFullYear();

  constructor(
    private miniplayerService: MiniplayerService,
  ) { }

  ngOnInit() {
    this.buildVersion = VERSION;
    this.miniplayerService.isVisible.subscribe(visible => {
      this.showMiniPlayer = visible;
    });
  }

}
