import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { ErrorParserService } from '../../services/error-parcing.service';
import { PrepareDataService } from '../../services/prepare-data.service';
import { Router } from '@angular/router';
import { SNACK_BAR_DURATION } from '../../models/consts';
import { SharedPlaylistListDialogComponent } from '../playlist-list-dialog/playlist-list-dialog.component';
import { Track } from '../../models/models';
import { TrackService } from '../../services/track.service';
import { ZoneService } from '../../services/zone.service';
import { ZonesListComponent } from '../zone-list-dialog/zone-list-dialog.component';

@Component({
  selector: 'app-track-item',
  templateUrl: './track-item.component.html',
  styleUrls: ['./track-item.component.scss']
})
export class TrackItemComponent implements OnInit {
  @Input() track: Track;

  constructor(
    private trackService: TrackService,
    private dialog: MatDialog,
    private router: Router,
    private zoneService: ZoneService,
    private prepareService: PrepareDataService,
    private errorService: ErrorParserService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit() {}

  toggleLikeDislike(state: boolean) {
    if (this.track.heartState && this.track.heartState.like === state) {
      state = null;
    }

    this.trackService.setTrackHeartState(this.track.id, state)
      .then((heartState) => {
        this.track.heartState = heartState;
      });
  }

  addToPlaylist() {
    this.dialog.open(SharedPlaylistListDialogComponent, {
      data: {
        track: this.track,
        playlistsWithTrack: this.track.playlists.map((playlist) => playlist.id)
      }
    });
  }

  addToLibrary() {
    this.trackService.addTrackToLibrary(this.track.id)
    .then((res) => {
      this.track.isInLibrary = true;
      this._snackBar.open(
        `Track successfully added to the library`,
        null,
        { duration: SNACK_BAR_DURATION }
      );
    })
    .catch((err) => {
      const errIdStr = this.errorService.parseError(err);
      this.dialog.open(AlertDialogComponent, {
        data: {
          type: 'error',
          message: `Oops, something went wrong. Please try again.
          ${(!!errIdStr) ? ('Error id: ' + errIdStr) : ''}`
        }
      });
    });
  }

  removeFromLibrary() {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: 'Are you sure you want to remove the track from library?',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary'
          },
          {
            text: 'Remove',
            cssClass: 'danger',
            handler: () => {
              this.dialog.closeAll();
              this.trackService.removeTrackFromLibrary(this.track.id)
                .then(() => {
                  this.track.isInLibrary = false;
                  this._snackBar.open(
                    `Track successfully removed from the library`,
                    null,
                    { duration: SNACK_BAR_DURATION }
                  );
                })
                .catch((err) => {
                  const errIdStr = this.errorService.parseError(err);
                  this.dialog.open(AlertDialogComponent, {
                    data: {
                      type: 'error',
                      message: `Oops, something went wrong. Please try again.
                      ${(!!errIdStr) ? ('Error id: ' + errIdStr) : ''}`
                    }
                  });
                });
            }
          }
        ]
      },
      width: '340px'
    });
  }

  addToPlayingQueue(track) {
    this.dialog.open(ZonesListComponent)
      .afterClosed()
      .subscribe(data => {
        if (!data) {
          return;
        }
        if (data.error) {
          this.dialog.open(AlertDialogComponent, {
            data: {
              type: 'error',
              message: data.error
            }
          });
        } else {
          this.confirmAddTrackInQueue(data.id);
        }
      });
  }

  confirmAddTrackInQueue(zoneId) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: 'The track will start after the current song finishes',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary'
          },
          {
            text: 'OK',
            cssClass: 'danger',
            handler: () => {
              this.zoneService.addTrackToPlayQueue(this.track.id, zoneId)
              .then(() => {
                dialogRef.close();
                this._snackBar.open(
                  `Track successfully added to the queue`,
                  null,
                  { duration: SNACK_BAR_DURATION }
                );
              })
              .catch((error) => {
                const errIdStr = this.errorService.parseError(error);
                dialogRef.close();
                this.dialog.open(AlertDialogComponent, {
                  data: {
                    type: 'error',
                    message: `Oops, something went wrong. Please try again.
                    ${(!!errIdStr) ? ('Error id: ' + errIdStr) : ''}`
                  }
                });
              });
            }
          }
        ]
      },
      width: '340px'
    });
  }

  goToArtist() {
    this.router.navigate(['/artist', this.track.artist.id]);
  }

  coverToSize(url) {
    return this.prepareService.coverToSize(url, '200x200');
  }
}
