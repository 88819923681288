<mat-dialog-content>
  <form [formGroup]="playlistForm">
    <div class="venue-modal">

      <div class="flex-row">
        <div class="flex-col m-0 f-1">
          <div class="flex-row">
            <div class="flex-col">
              <div class="form-group">
                <label>{{ entityName + "'s Logo" }}</label>
                <div class="preview-img-wrapper">
                  <img
                    *ngIf="playlist?.logoImageUrl && !coverImage"
                    [src]="playlist.logoImageUrl"
                    class="preview-img"
                    alt="Playlist cover"
                  />
                  <img
                    *ngIf="coverImage"
                    [src]="coverImage"
                    class="preview-img"
                    alt="Playlist cover"
                  />
                  <button (click)="uploadPlaylistCover()" class="btn">
                    <div class="icon icon-plus-primary"></div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex-col m-0 f-2">
          <div class="flex-row">
            <div class="flex-col">
              <div class="form-group">
                <label for="playlistName">{{ entityName + "'s Name" }}</label>
                <input
                  [placeholder]="'Enter ' + entityName + ' Name'"
                  type="text"
                  formControlName="playlistName"
                  id="playlistName"
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <div class="flex-row">
            <div class="flex-col">
              <div class="form-group">
                <label for="playlistDescription">{{ entityName + "'s Description" }}</label>
                <input
                  placeholder="Enter Description"
                  type="text"
                  formControlName="playlistDescription"
                  id="playlistDescription"
                  class="form-control"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex-row">
        <div class="flex-col f-1 pt-10">
          <div class="form-group mat-checkbox-custom">
            <mat-checkbox
              formControlName="isPublished"
              id="isPublished"
              class="form-control-checkbox"
            >
              Publish
            </mat-checkbox>
          </div>
        </div>
        <ng-container *ngIf="mediaType === mediaTypes.CHANNEL">
          <div class="flex-col f-2">
            <div class="form-group inline">
              <label class="inline-label" for="channelType">Channel</label>
              <div class="select-wrapper">
                <button
                  mat-button
                  class="playlist-selector"
                  (click)="channelTypeSelect.open()"
                  disableRipple
                >
                  <div class="d-flex justify-content-between align-items-center">
                    <span class="type-name"
                      [class.isPlaceholder]="!channelTypeSelect.triggerValue"
                    >
                      {{channelTypeSelect.triggerValue || 'Select Channel'}}
                    </span>
                    <div class="icon icon-chevron-down"></div>
                  </div>
                </button>
                <mat-select class="channel-select form-control" #channelTypeSelect formControlName="channelId">
                  <mat-option *ngFor="let type of channelTypes" [value]="type.id">
                    {{type.name}}
                  </mat-option>
                </mat-select>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="mediaType === mediaTypes.AUDIO">
          <div class="flex-col f-1 pt-10">
            <div class="form-group mat-checkbox-custom">
              <mat-checkbox
                formControlName="isPlaylistLoved"
                id="isPlaylistLoved"
                class="form-control-checkbox"
              >
                Loved Playlists
              </mat-checkbox>
            </div>
          </div>

          <div class="flex-col f-1 pt-10">
            <div class="form-group mat-checkbox-custom">
              <mat-checkbox
                formControlName="isPlaylistPromoted"
                id="isPlaylistPromoted"
                class="form-control-checkbox"
              >
                Promoted
              </mat-checkbox>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="flex-row" *ngIf="isLoved">
        <div class="flex-col f-1 pt-40">Loved playlists range</div>
        <div class="flex-col f-1 pt-10">
          <div>
            <label>From</label>
            <input (dateChange)="onLovedDateChange($event)" readonly [min]="minDate" [matDatepicker]="startLoved" (click)="startLoved.open()" formControlName="lovedStart">
            <mat-datepicker #startLoved></mat-datepicker>
          </div>
        </div>
        <div class="flex-col f-1 pt-10">
          <div>
            <label>To</label>
            <input readonly [min]="startLovedValue" [matDatepicker]="endLoved" (click)="endLoved.open()" formControlName="lovedEnd">
            <mat-datepicker #endLoved></mat-datepicker>
          </div>
        </div>
      </div>
      <div class="flex-row" *ngIf="isPromoted">
        <div class="flex-col f-1 pt-40">Promoted playlists range</div>
        <div class="flex-col f-1 pt-10">
          <div>
            <label>From</label>
            <input readonly (dateChange)="onPromotedDateChange($event)" [min]="minDate" [matDatepicker]="startPromoted" (click)="startPromoted.open()" formControlName="promotedStart">
            <mat-datepicker #startPromoted></mat-datepicker>
          </div>
        </div>
        <div class="flex-col f-1 pt-10">
          <div>
            <label>To</label>
            <input readonly [min]="startPromotedValue" [matDatepicker]="endPromoted" (click)="endPromoted.open()" formControlName="promotedEnd">
            <mat-datepicker #endPromoted></mat-datepicker>
          </div>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>

<div class="align-right dialog-controls">
  <button
    [disabled]="loading || !this.playlistForm.controls.playlistName.valid"
    (click)="savePlaylist()"
    class="btn continue-btn"
  >
    {{ mode === 'edit' ? 'Save' : (playlist) ? 'Continue' : 'Create' }}
  </button>
</div>
