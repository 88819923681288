import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SearchService } from 'src/app/shared/services/search.service';
import { Track, Playlist } from 'src/app/shared/models/models';
import { SearchValues } from 'src/app/shared/models/consts';

@Component({
  selector: 'app-add-tracks',
  templateUrl: './add-tracks.component.html',
  styleUrls: ['./add-tracks.component.scss']
})
export class AddTracksComponent implements OnInit {
  @Input() mode: string;
  @Output() onAddTracks: EventEmitter<number[]> = new EventEmitter<number[]>()
  public searchString = '';
  public tracks: Track[];
  public selectedTrackIds: Set<number> = new Set();
  public scope: SearchValues = 'TRACK';
  public isLoading: boolean = false;
  public errored = false;

  constructor(
    private searchService: SearchService,
  ) { }

  ngOnInit() {}

  savePlaylist() {
    const trackIds = Array.from(this.selectedTrackIds);
    this.onAddTracks.emit(trackIds);
  }

  toggleTrackSelection(trackId) {
    if (this.selectedTrackIds.has(trackId)) {
      this.selectedTrackIds.delete(trackId);
    } else {
      this.selectedTrackIds.add(trackId);
    }
  }

  search(searchString) {
    this.searchString = searchString;

    if (!this.searchString) {
      return;
    }
    this.isLoading = true;

    this.tracks = [];

    this.searchService.searchCatalog(this.searchString, this.scope)
      .subscribe(searchRes => {
        if (searchRes.tracks) {
          this.tracks = searchRes.tracks;
        }
      }, (err) => {
        console.log(err);
        this.errored = true;
      }, () => this.isLoading = false
    );
  }

}
