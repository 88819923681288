import { Component, Inject, Input, OnInit } from '@angular/core';
import { Track } from '../../models/models';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-zone-history',
  templateUrl: './zone-history.component.html',
  styleUrls: ['./zone-history.component.scss']
})
export class ZoneHistoryComponent {
  tracks: Track[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }
}
