import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoPlaylistComponent } from './video-playlist.component';
import { RouterModule, Routes } from '@angular/router';
import { PlaylistService } from 'src/app/shared/services/playlist.service';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { ListItemsModule } from 'src/app/shared/modules/list-items.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { HumanizeDurationModule } from 'src/app/shared/pipes/humanize-duration.pipe';
import { ImagePreloadModule } from 'src/app/shared/directives/image-preload/image-preload.module';
import { ClipItemModule } from 'src/app/shared/components/clip-item/clip-item.module';
import { FileMimeTypeModule } from 'src/app/shared/pipes/file-extension.pipe';
import { SpinnerModule } from '../spinner/spinner.module';

const routes: Routes = [
  {
    path: ':id',
    component: VideoPlaylistComponent,
    resolve: { id: PlaylistService }
  }
];

@NgModule({
  declarations: [
    VideoPlaylistComponent
  ],
  imports: [
    CommonModule,
    NgScrollbarModule,
    ScrollingModule,
    DragDropModule,
    MatCardModule,
    ListItemsModule,
    MatMenuModule,
    RouterModule.forChild(routes),
    NgxPermissionsModule,
    HumanizeDurationModule,
    FileMimeTypeModule,
    ImagePreloadModule,
    ClipItemModule,
    SpinnerModule
  ]
})
export class VideoPlaylistModule { }
