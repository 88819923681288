import gql from 'graphql-tag';
import { CombinedFragments, MinimalFragments } from './fragments';

export const loginWithPassword = gql`
  mutation loginWithPassword(
    $email: String!
    $password: String!
  ) {
    loginWithPassword(
      email: $email
      password: $password
    ) {
      loginToken
    }
  }`;

export const logout = gql`
  mutation logout {
    logout
  }`;

export const resetPassword = gql`
  mutation sendResetPasswordEmail(
    $email: String!
  ) {
    sendResetPasswordEmail(
    email: $email
    )
  }`;

export const currentUserNoVenue = gql`
  query me {
    me {
      id
      displayName
      userEmail
      avatarImageUrl
      globalRoles
    }
  }
`;

export const currentUser = gql`
  query me ($venueId: Int!) {
    me {
      id
      displayName
      userEmail
      globalRoles
      roles(venueId: $venueId)
      avatarImageUrl
    }
  }
`;

export const searchArtists = gql`
query SearchArtists($text: String, $scope: TextSearchScope, $offset: Int, $limit: Int) {
  rawCatalogSearch(text: $text, scope: $scope, offset: $offset, limit: $limit) {
    artists {
      name
      id
      countOfTracks
      likes
      artwork {
        artworkUrl100
      }
    }
  }
}
`;

export const searchTracks = gql`
query SearchTracks($text: String, $scope: TextSearchScope, $offset: Int, $limit: Int) {
  rawCatalogSearch(text: $text, scope: $scope, offset: $offset, limit: $limit) {
    tracks {
      name
      id
      artwork {
        artworkUrl100
      }
      artist {
        id
        name
      }
      album {
        id
        name
      }
      playlists {
        id
        name
      }
      releaseYear
      durationSecs
      isExplicit
    }
  }
}
`;

export const searchTracksByArtist = gql `
query RawCatalogSearchTrackWithArtist($trackName: String, $artistName: String, $limit: Int, $offset: Int,  $venueId: Int!) {
  rawCatalogSearchTrackWithArtist(trackName: $trackName, artistName: $artistName, limit: $limit, offset: $offset) {
    tracks {
      id
      name
      heartState(venueId: $venueId) {
        like
      }
      artwork {
        artworkUrl100
      }
      artist {
        id
        name
      }
      artist {
        id
        name
      }
      playlists {
        id
        name
      }
      album {
        id
        name
      }
      releaseYear
      durationSecs
      isExplicit
    }
  }
}
`;

export const catalogSearch = gql`
  mutation createCatalogSearch(
    $text: String
    $venueId: Int!
    $scope: TextSearchScope
    $limit: Int
    $offset: Int
  ) {
      createCatalogSearch(
        text: $text
        scope: $scope
        limit: $limit
        offset: $offset
      ) {
        id
        tracks {
          ...TrackData
          isInLibrary(venueId: $venueId)
          playlists {
            id
            name
          }
          genre {
            id
            name
          }
        }
        albums {
          id
          name
          artists {
            name
          }
          countOfTracks
          tracks(first: 1) {
            id
            duration
            artwork {
              artworkUrl100
              artworkUrl1000
            }
          }
          durationSeconds
        }
        artists {
          id
          name
          albums {
            id
            tracks {
              id
            }
          }
          likes
          likedByMe
          countOfTracks
          artwork {
            artworkUrl100
            artworkUrl1000
          }
        }
        playlists {
          id
          name
          generalGenre
          trackCount
          logoImageUrl
          duration
          mediaType
          isCatalog
          tracks {
            id
            artwork {
              artworkUrl100
              artworkUrl1000
            }
          }
          isInLibrary(venueId: $venueId)
        }
        videoPlaylists {
          id
          name
          generalGenre
          trackCount
          logoImageUrl
          duration
          mediaType
          isCatalog
          tracks {
            id
            artwork {
              artworkUrl100
              artworkUrl1000
            }
          }
          isInLibrary(venueId: $venueId)
        }
        channelPlaylists {
          id
          name
          generalGenre
          trackCount
          logoImageUrl
          duration
          tracks {
            id
            artwork {
              artworkUrl100
              artworkUrl1000
            }
          }
          isInLibrary(venueId: $venueId)
        }
        meta {
          tracks {
            limit
            offset
            found
          }
          albums {
            limit
            offset
            found
          }
          artists {
            limit
            offset
            found
          }
          playlists {
            limit
            offset
            found
          }
          videoPlaylists {
            limit
            offset
            found
          }
          channelPlaylists {
            limit
            offset
            found
          }
        }
      }
    }
    ${CombinedFragments.fragments.track}
`;

export const catalogPlaylistsSearch = gql`
  mutation createCatalogSearch(
    $text: String
    $venueId: Int!
    $scope: TextSearchScope
    $limit: Int
    $offset: Int
  ) {
      createCatalogSearch(
        text: $text
        scope: $scope
        limit: $limit
        offset: $offset
      ) {
        id
        playlists {
          id
          name
          generalGenre
          trackCount
          logoImageUrl
          duration
          mediaType
          isCatalog
          tracksConnection(first: 8) {
            totalCount
            edges {
              node {
                artwork {
                  artworkUrl100
                  artworkUrl1000
                }
              }
            }
          }
          isInLibrary(venueId: $venueId)
        }
      }
    }
`;

export const curationSearch = gql`
  mutation createCatalogSearch(
    $text: String
    $scope: TextSearchScope
  ) {
      createCatalogSearch(
        text: $text
        scope: $scope
      ) {
        id
        tracks {
          ...TrackData
          genre {
            id
            name
          }
        }
        albums {
          id
          name
          countOfTracks
          tracks {
            ...TrackData
            genre {
              id
              name
            }
          }
        }
        playlists {
          id
          name
          generalGenre
          trackCount
          logoImageUrl
          duration
          tracks {
            ...TrackData
            genre {
              id
              name
            }
          }
          createdAt
        }
        artists {
          id
          name
          artwork {
            artworkUrl100
          }
          countOfTracks
          albums {
            ...AlbumMinData
            tracks {
              ...TrackData
              genre {
                id
                name
              }
            }
          }
        }
      }
    }
  ${CombinedFragments.fragments.track}
  ${MinimalFragments.fragments.album}
`;
