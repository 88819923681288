import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClipItemComponent } from './clip-item.component';
import { ImagePreloadModule } from '../../directives/image-preload/image-preload.module';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';

@NgModule({
  declarations: [
    ClipItemComponent
  ],
  imports: [
    CommonModule,
    ImagePreloadModule,
    MatDialogModule,
    MatMenuModule
  ],
  exports: [ClipItemComponent]
})
export class ClipItemModule { }
