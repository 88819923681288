<div class="dialog-header">
  <h2 mat-dialog-title>Choose Zone</h2>
  <button class="shuffle"
    [ngClass]="{
      'hide': data == null || !data.hasShuffle,
      'shuffle-active': isShuffled
    }"
    (click)="toggleShuffle()"
    >
  </button>
  <button mat-dialog-close class="btn close-dialog-btn"></button>
</div>

<mat-dialog-content>
  <app-spinner *ngIf="isLoading" [isLoading]="isLoading" [errored]="errored" [minimal]="true"></app-spinner>
  <div *ngIf="!isLoading" class="zone-list-wrapper">
    <ng-scrollbar>
      <ul *ngIf="zones.length > 0" class="zone-list">
        <li
          *ngFor="let zone of zones"
          (click)="selectZone(zone)"
          [ngClass]="{
            'selected': zone.id === selectedZoneId,
            'initial-selected': zone.id === initialSelectedZoneId
          }"
          class="zone-item"
        >
          <p class="zone-name text-truncate">{{ zone.name }}</p>
          <div class="checkmark-container"></div>
        </li>
      </ul>
      <div *ngIf="zones.length === 0" class="no-zones-message text-uppercase">No Zones available</div>
    </ng-scrollbar>
  </div>
</mat-dialog-content>

<div mat-dialog-actions>
  <button mat-dialog-close class="btn cancel-btn" (click)="cancel()">Cancel</button>
  <button (click)="save()" [disabled]="!selectedZoneId" class="btn save-zone-btn">Apply</button>
</div>
