import { Injectable } from '@angular/core';

import { Address } from '../models/models';

@Injectable({
  providedIn: 'root'
})
export class AddressService {
  constructor() { }

  public getFormattedAddress(address: Address): string {
    const addressArr = [];

    if (address.line1) {
      addressArr.push(address.line1);
    }

    if (address.postcode) {
      addressArr.push(address.postcode);
    }

    if (address.city) {
      addressArr.push(address.city);
    }

    if (address.state) {
      addressArr.push(address.state);
    }

    if (address.country) {
      addressArr.push(address.country);
    }

    return addressArr.join(', ');
  }
}
