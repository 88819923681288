import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySliderChange as MatSliderChange } from '@angular/material/legacy-slider';

import { Observable, Subscription } from 'rxjs';

import { OwlCarousel } from 'ngx-owl-carousel';

import { AlertDialogComponent } from 'src/app/shared/components/alert-dialog/alert-dialog.component';
import { ConfirmationDialogComponent } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { TrackQueueComponent } from '../../shared/components/track-queue/track-queue.component';
import { PrepareDataService } from '../../shared/services/prepare-data.service';
import { MiniplayerService } from 'src/app/shared/services/miniplayer.service';
import { TrackService } from '../../shared/services/track.service';
import { TimerService } from '../../shared/services/timer.service';
import { PlayerService } from '../../shared/services/player.service';
import { PlayerCommandType } from '../../shared/gql/zone-consts';
import { ZoneService } from '../../shared/services/zone.service';
import { Player, Zone } from '../../shared/models/models';
import { filter } from 'rxjs/operators';
import { Select, Store } from '@ngxs/store';
import { ZonesState } from 'src/app/shared/store/zones/zones.state';
import { ZoneAction } from 'src/app/shared/store/zones/zones.actions';
import { ErrorParserService } from 'src/app/shared/services/error-parcing.service';
import { PlayerCommandDialogComponent } from './player-command-dialog/player-command-dialog.component';

@Component({
  selector: 'app-miniplayer',
  templateUrl: './miniplayer.component.html',
  styleUrls: ['./miniplayer.component.scss']
})
export class MiniplayerComponent implements OnInit, OnDestroy {
  @ViewChild('owlElement', { static: false }) owlElement: OwlCarousel;

  private PAGES_WITHOUT_PLAYER = ['zones', 'general'];
  private currentZoneSub: Subscription;
  private miniplayerSub: Subscription;
  private timerSub: Subscription;
  private playerSub: Subscription;
  private timersSub: Subscription;
  private zonesSub: Subscription;

  @Select(ZonesState.zones) zones$: Observable<Zone[]>;
  @Select(ZonesState.currentZone) currentZone$: Observable<Zone>;

  private selectedZoneId: number;

  public zoneIdArr: any = [];
  public zonesWithPlayer: any = [];
  public showMiniPlayer: boolean;
  public isPageSuitable = false;
  public isPlayerActive = false;
  public playerPosition = 0;
  public timers: any[] = [];
  public players: any[] = [];
  public isPlaying = false;
  public duration: number = 1;
  public zone: Zone;
  public volume = 0;

  constructor(
    private miniplayerService: MiniplayerService,
    private prepareService: PrepareDataService,
    private trackService: TrackService,
    private timerService: TimerService,
    private playerService: PlayerService,
    private zoneService: ZoneService,
    private errorService: ErrorParserService,
    private dialog: MatDialog,
    private router: Router,
    private store: Store
  ) {
    this.zones$ = this.store.select(ZonesState.zones);
    this.currentZone$ = this.store.select(ZonesState.currentZone);
    this.onZoneChanged = this.onZoneChanged.bind(this);
  }

  ngOnInit() {
    // the block for check player visibility
    this.miniplayerSub = this.miniplayerService.isVisible.subscribe(visible => (this.showMiniPlayer = visible));

    // the block for check the URL of the current page and  it's suitable to the player
    this.isPageSuitable = this.PAGES_WITHOUT_PLAYER.every((pageName) => !this.router.url.includes(pageName));
    this.setPlayerVisibility();

    this.currentZoneSub = this.currentZone$.subscribe((zone) => {
      this.zone = zone;

      if (this.zone) {
        if (this.owlElement) {
          this.owlElement.to([this.zonesWithPlayer.findIndex(i => i.id === zone.id)]);
        }
        if (!!this.zone.player.settings.length) {
          this.volume = this.getVolumeFromPlayer(this.zone.player);
        }
      }
    });

    this.timerSub = this.timerService.onTimersChanged.subscribe((timers) => {
      if (this.zone) {
        const zoneTimer = timers.find((timer) => timer && timer.id === this.zone.id);
        if (zoneTimer) {
          this.playerPosition = zoneTimer.position || 0;
          this.isPlaying = zoneTimer.playing;
          this.duration = zoneTimer.duration || this.maxRangeValue();
        }
      }
    });

    this.playerSub = this.playerService.onPlayersChanged.subscribe((players) => {
      if (this.zone) {
        const zonePlayer = players.find((player) => player && player.id === this.zone.id);
        if (zonePlayer) {
	  const i = this.zonesWithPlayer.findIndex(i => i.id === this.zone.id)
	  if (i > -1) {
	    this.zonesWithPlayer[i].currentTrack = zonePlayer.track;
	  }
        }
      }
    });

    this.fetchZones();
  }

  fetchZones() {
    this.zonesSub = this.zones$
      .pipe(
        filter((zones: Zone[]) => !!zones && !!zones.length),
      )
      .subscribe((zones) => {
        this.zonesWithPlayer = zones.filter((zone) => zone.player && zone.currentTrack);

        if (!!this.zoneIdArr.length || this.zoneIdArr.length !== zones.length) {
          this.zoneIdArr = this.zonesWithPlayer.map(({ id }) => id);
        }

        this.isPlayerActive = !!this.zonesWithPlayer.length;

        if (!this.zone && this.isPlayerActive) {
          this.selectedZoneId = this.zonesWithPlayer[0].id;
          this.store.dispatch(new ZoneAction.SetCurrentZoneId(this.zonesWithPlayer[0].id));
        } else if (this.zone && !this.isPlayerActive) {
          this.selectedZoneId = null;
          this.store.dispatch(new ZoneAction.SetCurrentZoneId());
        }

        this.setPlayerVisibility();
      });
  }

  playPause() {
    const playerCommandType = this.isPlaying ? PlayerCommandType.pause : PlayerCommandType.resume;

    this.zoneService.createPlayerCommand(playerCommandType, this.zone.id)
      .subscribe(() => undefined, (error) => console.log(error)
    );
    //show modal to prevent user doing other activity
    this.dialog.open(PlayerCommandDialogComponent, {
      data: {
	zoneId: this.zone.id,
        title: 'Request Received',
        playing: this.zone.playing,
        message: 'Your command has been sent successfully.\n Awaiting player\'s response, please hold on...',
        buttons: [{
          text: 'OK',
          role: 'cancel',
          cssClass: 'secondary'
        }]
      },
      width: '340px',
      disableClose: true,
    });
  }

  playPrevTrack() {
    this.zoneService.createPlayerCommand(PlayerCommandType.prev, this.zone.id)
      .subscribe(() => undefined, (error) => console.log(error)
    );
  }

  playNextTrack() {
    this.zoneService.createPlayerCommand(PlayerCommandType.next, this.zone.id)
      .subscribe(() => undefined, (error) => console.log(error)
    );
  }

  toggleLikeDislike(state?: boolean) {
    this.trackService.setTrackHeartState(this.zone.currentTrack.id, state)
      .then((heartState) => (this.zone.currentTrack.heartState = heartState));
  }

  handleLikeClick() {
    if (this.zone.currentTrack.heartState && this.zone.currentTrack.heartState.like) {
      this.removeTracksFromLibrary([this.zone.currentTrack.id]);
      this.toggleLikeDislike(null);
    } else {
      this.addTrackToLibrary(this.zone.currentTrack.id);
      this.toggleLikeDislike(true);
    }
  }

  // on dislike click, remove from library, un-heart it and skip
  handleDislikeClick() {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: 'Are you sure you want to mark this track as something you dislike?',
        buttons: [{
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: 'Yes',
          cssClass: 'danger',
          handler: () => {
            this.toggleLikeDislike(false);
            this.playNextTrack();
            this.removeTracksFromLibrary([this.zone.currentTrack.id])
          }
        }]
      },
      width: '340px'
    });
  }

  // handles removing tracks from library
  removeTracksFromLibrary(tracksIds: number[]) {
    this.trackService.removeMultipleTracks(tracksIds)
    .subscribe(() => {
      this.dialog.closeAll();
    }, (error) => {
      const errIdStr = this.errorService.parseError(error);
      this.dialog.open(AlertDialogComponent, {
        data: {
          type: 'error',
          message: `Oops, something went wrong. Please try again.
          ${(!!errIdStr) ? ('Error id: ' + errIdStr) : ''}`
        }
      });
    });
  }

  addTrackToLibrary(trackId: number) {
    this.trackService.addTrackToLibrary(trackId).catch((err) => {
      const errIdStr = this.errorService.parseError(err);
      this.dialog.open(AlertDialogComponent, {
        data: {
          type: 'error',
          message: `Oops, something went wrong. Please try again.
          ${(!!errIdStr) ? ('Error id: ' + errIdStr) : ''}`
        }
      });
    });
  }

  maxRangeValue() {
    return (!!this.zone && !!this.zone.currentTrack) ? this.prepareService.trackDurationToSec(this.zone.currentTrack.duration) : 1;
  }

  ngOnDestroy() {
    this.miniplayerSub.unsubscribe();
    this.currentZoneSub.unsubscribe();

    if (this.zonesSub) {
      this.zonesSub.unsubscribe();
    }

    if (this.timerSub) {
      this.timerSub.unsubscribe();
    }

    if (this.timersSub) {
      this.timersSub.unsubscribe();
    }
  }

  goToQueue() {
    this.dialog.open(TrackQueueComponent, {
      data: {
        zone: this.zone
      }
    });
  }

  setPlayerVisibility() {
    this.isPlayerActive = !!this.zonesWithPlayer.length;
    this.miniplayerService.setVisibility(this.isPlayerActive && this.isPageSuitable);
  }

  onZoneChanged(data) {
    const carouselZoneItemIndex = data.item.index;

    if (carouselZoneItemIndex !== null && this.zonesWithPlayer) {
      this.zone = this.zonesWithPlayer[carouselZoneItemIndex];

      if (this.selectedZoneId !== this.zone.id) {
        this.selectedZoneId = this.zone.id;
        this.store.dispatch(new ZoneAction.SetCurrentZoneId(this.selectedZoneId));
      }
    }
  }

  getVolumeFromPlayer(player: Player): number {
    const volumeSetting = player.settings.find((s) => s && s.setting === "zoneVolumeGain");
    return volumeSetting ? parseInt(volumeSetting.value) : 0;
  }

  onVolumeChange(event: MatSliderChange) {
    this.zoneService.setPlayerSettings(this.zone.player.id, [{
      setting: "zoneVolumeGain",
      value: event.value.toString(),
      zoneId: this.zone.id
    }])
      .then(() => (this.volume = event.value))
      .catch(() => {
        this.dialog.open(AlertDialogComponent, {
          data: {
            type: 'error',
            message: 'Something went wrong changing volume. Please try again',
          }
        });
      })
  }
}
